import React from "react";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/images/user_dummy.png";
import { Link } from "react-router-dom";
import AuthUtils from "../Pages/Utils/AuthUtils";
import { useAuth } from "../Context/AuthContext";

const sidebarShow = (e) => {
  document.querySelector("body").classList.toggle("sidebar-show");
}

export default function HeaderMobile({ onSkin, user = null }) {
  const { logout } = AuthUtils();
  const [authData, setAuthData] = useAuth();

  const skinMode = (e) => {
    let skin = localStorage.getItem("skin-mode") ? "" : "dark";
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');

      onSkin('');
    }

  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  return (
    <div className="header-main main-mobile-header doc-mobile-header flex justify-between items-center">
      <div onClick={sidebarShow} className="menu-link"><i className="ri-menu-line"></i></div>
      <div className="flex items-center gap-x-1">
      <DarkModeSwitch
        checked={localStorage.getItem("skin-mode")}
        onChange={skinMode}
        size={20}
        sunColor="#506FD9"
      />

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={userAvatar} alt="" /></div>
            {
              authData && <h5 className="capitaize mb-1 text-dark fw-semibold">{authData.first_name && authData.first_name} {authData.last_name && authData.last_name}</h5>
            }
            <nav className="nav">
              <Link to="/user"><i className="ri-edit-2-line"></i> Edit Profile</Link>
            </nav>
            <hr />
            <nav className="nav">
              <div onClick={logout} className="cursor-pointer"><i className="ri-logout-box-r-line"></i> Log Out</div>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      </div>
    </div>
  )
}