import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, Dropdown, Modal, Spinner, Table } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import { FaChevronDown, FaChevronUp, FaRegEye } from "react-icons/fa6";
import moment from 'moment';
import { IoSettingsOutline } from 'react-icons/io5';
import LeadDetailModal from '../Leads/LeadDetailModal';



const ReasonDetailModal = ({ http, logout, skin, reasonDetails, setReasonDetails, showModal, setShowModal }) => {
    const [reasonData, setReasonData] = useState(null);
    const [totalLeads, setTotalLeads] = useState(0);
    const [showAcceptedLeads, setShowAcceptedLeads] = useState(false);
    const [showRejectedLeads, setShowRejectedLeads] = useState(false);
    const [showDuplicatedLeads, setShowDuplicatedLeads] = useState(false);
    const [showErrorLeads, setShowErrorLeads] = useState(false);
    const [showStoredLeads, setShowStoredLeads] = useState(false);
    const [showReturnedLeads, setShowReturnedLeads] = useState(false);
    const [showPendingLeads, setShowPendingLeads] = useState(false);

    const [leadDetailModal, setShowLeadDetailModal] = useState(false);
    const [leadPayload, setLeadPayload] = useState("");
    const [leadsByCampaign, setLeadsByCampaign] = useState([]);

    useEffect(() => {
        if (showModal && reasonDetails) {
            fetchReasonDetails();
        }
    }, [reasonDetails])

    const resetReasonModal = () => {
        setShowModal(false);
        setReasonData(null);
        setReasonDetails(null);
        setTotalLeads(0);
        setShowAcceptedLeads(false);
        setShowRejectedLeads(false);
        setShowDuplicatedLeads(false);
        setShowErrorLeads(false);
        setShowStoredLeads(false);
        setShowReturnedLeads(false);
        setShowPendingLeads(false);
    }

    const handleClose = () => {
        resetReasonModal();
        setLeadPayload("");
        setLeadsByCampaign([]);
        setShowLeadDetailModal(false);
    }

    async function fetchReasonDetails() {
        try {
            const res = await http.get(`/api/reason-detail/${reasonDetails.id}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const {
                    accepted_leads,
                    rejected_leads,
                    duplicated_leads,
                    error_leads,
                    stored_leads,
                    returned_leads,
                    pending_leads
                } = res.data;

                let totalLead = 0;

                let seriesArr = [], labelArr = [], colorArr = [];

                if (accepted_leads) {
                    totalLead += accepted_leads.length;
                    seriesArr.push(accepted_leads.length);
                    labelArr.push("Accepted");
                    colorArr.push("#0CB785");
                }
                if (rejected_leads) {
                    totalLead += rejected_leads.length;
                    seriesArr.push(rejected_leads.length);
                    labelArr.push("Rejected");
                    colorArr.push("#DC3545");
                }
                if (duplicated_leads) {
                    totalLead += duplicated_leads.length;
                    seriesArr.push(duplicated_leads.length);
                    labelArr.push("Duplicated");
                    colorArr.push("#FFC107");
                }
                if (error_leads) {
                    totalLead += error_leads.length;
                    seriesArr.push(error_leads.length);
                    labelArr.push("Error");
                    colorArr.push("#DC3545");
                }
                if (stored_leads) {
                    totalLead += stored_leads.length;
                    seriesArr.push(stored_leads.length);
                    labelArr.push("Stored");
                    colorArr.push("#506FD9");
                }
                if (returned_leads) {
                    totalLead += returned_leads.length;
                    seriesArr.push(returned_leads.length);
                    labelArr.push("Returned");
                    colorArr.push("#DC3545");
                }
                if (pending_leads) {
                    totalLead += pending_leads.length;
                    seriesArr.push(pending_leads.length);
                    labelArr.push("Pending");
                    colorArr.push("#FFC107");
                }

                setTotalLeads(totalLead);
                setReasonData({
                    series: seriesArr,
                    labels: labelArr,
                    colors: colorArr,
                    data: res.data
                });
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error(err);
        }
    }

    const reasonChartOption = {
        labels: reasonData?.labels,
        chart: {
            type: 'donut',
            parentHeightOffset: 0,
            stacked: true,
            toolbar: {
                show: true
            }
        },
        colors: reasonData?.colors,
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toFixed(2) + "%"
            },
            dropShadow: {
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false
            }
        }
    };

    async function getLeadDetails({ leadId, reasonStatus }) {
        setLeadPayload("");

        await http
            .get(`/api/lead-details/${leadId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => {
                setLeadPayload(response.data.data);

                switch (reasonStatus) {
                    case "accepted":
                        setLeadsByCampaign(reasonData.data.accepted_leads);
                        break;
                    case "duplicated":
                        setLeadsByCampaign(reasonData.data.duplicated_leads);
                        break;
                    case "rejected":
                        setLeadsByCampaign(reasonData.data.rejected_leads);
                        break;
                    case "error":
                        setLeadsByCampaign(reasonData.data.error_leads);
                        break;
                    case "returned":
                        setLeadsByCampaign(reasonData.data.returned_leads);
                        break;
                    case "stored":
                        setLeadsByCampaign(reasonData.data.stored_leads);
                        break;
                    case "pending":
                        setLeadsByCampaign(reasonData.data.pending_leads);
                        break;
                }

                setShowLeadDetailModal(true);
                resetReasonModal();
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch lead details!");
            });
    }

    function toggleReasonDetail(reasonStatus) {
        switch (reasonStatus) {
            case "accepted":
                setShowAcceptedLeads(prevStat => !prevStat);
                break;
            case "rejected":
                setShowRejectedLeads(prevStat => !prevStat);
                break;
            case "duplicated":
                setShowDuplicatedLeads(prevStat => !prevStat);
                break;
            case "error":
                setShowErrorLeads(prevStat => !prevStat);
                break;
            case "stored":
                setShowStoredLeads(prevStat => !prevStat);
                break;
            case "returned":
                setShowReturnedLeads(prevStat => !prevStat);
                break;
            case "pending":
                setShowPendingLeads(prevStat => !prevStat);
                break;
        }
    }

    const RenderLeadsTable = ({ leads, skin }) => {
        return (
            <Table
                variant={skin && "dark"}
                hover
                className="mb-0"
                responsive
            >
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                        >
                            Created At
                        </th>
                        <th
                            scope="col"
                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                        >
                            ID
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            First Name
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Last Name
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Phone
                        </th>
                        <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Email
                        </th>
                        {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">State</th> */}
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Supplier Name
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Traffic Source
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Is Reposted?
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Is Test?
                        </th>
                        <th
                            scope="col"
                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map((lead) => {
                        if (lead.payload) {
                            const validJSon = lead.payload;

                            return (
                                <tr key={lead.id}>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                }`}
                                        >
                                            {moment(lead.created_at).format("LL")}
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                } title__td`}
                                        >
                                            {lead.id}
                                        </div>
                                    </td>
                                    {/* {
                                                                            Object.values(validJSon).map(val => (
                                                                                <td onClick={() => getLeadDetails({leadId: lead.id, reasonStatus: "accepted"})} className='px-3 align-middle cursor-pointer'>
                                                                                    <div className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                        {val}
                                                                                    </div>
                                                                                </td>
                                                                            ))
                                                                        } */}
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                } title__td`}
                                        >
                                            {validJSon.firstName ??
                                                validJSon.firstName}
                                            {validJSon.first_name ??
                                                validJSon.first_name}
                                            {validJSon.Firstname ??
                                                validJSon.Firstname}
                                            {validJSon.FirstName ??
                                                validJSon.FirstName}
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                } title__td`}
                                        >
                                            {validJSon.lastName ??
                                                validJSon.lastName}
                                            {validJSon.last_name ??
                                                validJSon.last_name}
                                            {validJSon.Lastname ??
                                                validJSon.Lastname}
                                            {validJSon.LastName ??
                                                validJSon.LastName}
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`whitespace-nowrap ${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                }`}
                                        >
                                            {validJSon.Phone ?? validJSon.Phone}
                                            {validJSon.phone ?? validJSon.phone}
                                            {validJSon.phoneNumber ??
                                                validJSon.phoneNumber}
                                            {validJSon.phone_number ??
                                                validJSon.phone_number}
                                            {validJSon.phoneHome ??
                                                validJSon.phoneHome}
                                            {validJSon.phonehome ??
                                                validJSon.phonehome}
                                            {validJSon.phone_home ??
                                                validJSon.phone_home}
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="px-3 align-middle cursor-pointer"
                                    >
                                        <div
                                            className={`${skin
                                                ? "text-stone-300"
                                                : "text-inherit"
                                                }`}
                                        >
                                            {validJSon.email ?? validJSon.email}
                                            {validJSon.Email ?? validJSon.Email}
                                            {validJSon.email_add ??
                                                validJSon.email_add}
                                            {validJSon.email_address ??
                                                validJSon.email_address}
                                            {validJSon.emailaddress ??
                                                validJSon.emailaddress}
                                            {validJSon.emailAddress ??
                                                validJSon.emailAddress}
                                        </div>
                                    </td>
                                    {/* <td onClick={() => getLeadDetails({leadId: lead.id, reasonStatus: "accepted"})} className='px-3 align-middle cursor-pointer font-medium text-center rounded-md'>
                                                                            <div className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                {validJSon.state}
                                                                            </div>
                                                                        </td> */}
                                    {/* <td onClick={() => getLeadDetails({leadId: lead.id, reasonStatus: "accepted"})} className="align-middle">
                                                                            {
                                                                                lead.supplier_source ?
                                                                                    <div className={`text-sm rounded-md p-1.5 font-medium text-center text-white ${lead.supplier_source === "EXTERNAL" ? "bg-warning" : "bg-success"}`}>
                                                                                        {lead.supplier_source}
                                                                                    </div> : <p className="mb-0 text-center font-medium">-</p>
                                                                            }
                                                                        </td> */}
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="align-middle"
                                    >
                                        {lead.generated_by &&
                                            lead.generated_by.client ? (
                                            <div
                                                className={`${skin
                                                    ? "text-stone-300"
                                                    : "text-inherit"
                                                    }`}
                                            >
                                                {lead.generated_by?.client?.company_name?.[0]?.toUpperCase() +
                                                    lead.generated_by?.client?.company_name?.slice(
                                                        1
                                                    )}
                                            </div>
                                        ) : (
                                            <p className="mb-0 text-center font-medium">
                                                -
                                            </p>
                                        )}
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="align-middle"
                                    >
                                        {lead?.traffic_source ? (
                                            <div
                                                className={`${skin
                                                    ? "text-stone-300"
                                                    : "text-inherit"
                                                    }`}
                                            >
                                                {
                                                    `${lead?.traffic_source?.client?.company_name[0]?.toUpperCase()}${lead?.traffic_source?.client?.company_name?.slice(
                                                        1
                                                    )}${lead.traffic_source?.traffic_channel?.channel_name
                                                        ? " - " + lead?.traffic_source?.traffic_channel?.channel_name
                                                        : ""
                                                    } (${lead?.traffic_source?.id})`
                                                }
                                            </div>
                                        ) : validJSon.traffic_source ? validJSon.traffic_source
                                            : (
                                                <p className="mb-0 text-center font-medium">
                                                    -
                                                </p>
                                            )}
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="align-middle"
                                    >
                                        <div
                                            className={`text-sm rounded-md p-1.5 font-medium text-center text-white ${lead.status === "ACCEPTED"
                                                ? "bg-success"
                                                : lead.status === "DUPLICATED" || lead.status === "PENDING"
                                                    ? "bg-warning"
                                                    : lead.status === "STORED"
                                                        ? "bg-primary"
                                                        : "bg-danger"
                                                }`}
                                        >
                                            {lead.status ? lead.status : "ERROR"}
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="align-middle"
                                    >
                                        {lead.is_repost ? (
                                            <div
                                                className={`text-sm rounded-md p-1.5 font-medium text-center text-white bg-success`}
                                            >
                                                REPOST
                                            </div>
                                        ) : (
                                            <p className="mb-0 text-center font-medium">
                                                -
                                            </p>
                                        )}
                                    </td>
                                    <td
                                        onClick={() => getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })}
                                        className="align-middle"
                                    >
                                        {lead.is_test ? (
                                            <div
                                                className={`text-sm rounded-md p-1.5 font-medium text-center text-white bg-primary`}
                                            >
                                                Is Test
                                            </div>
                                        ) : (
                                            <p className="mb-0 text-center font-medium">
                                                -
                                            </p>
                                        )}
                                    </td>
                                    <td className="px-3 align-middle">
                                        <Dropdown className="static">
                                            <Dropdown.Toggle
                                                id="dropdown-basic"
                                                className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin
                                                    ? "bg-slate-800 hover:bg-slate-700 text-stone-100"
                                                    : "bg-white text-stone-800"
                                                    } rounded-md shadow-sm transition-colors duration-200 ease-linear`}
                                            >
                                                <IoSettingsOutline className="text-lg" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <button
                                                        onClick={() =>
                                                            getLeadDetails({ leadId: lead.id, reasonStatus: "accepted" })
                                                        }
                                                        className="text-inherit flex w-full items-center gap-x-3"
                                                    >
                                                        <FaRegEye className="text-base" />
                                                        View
                                                    </button>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </Table>
        )
    }

    return (
        <>
            <ToastContainer />
            <LeadDetailModal
                http={http}
                skin={skin}
                showModal={leadDetailModal}
                setShowModal={setShowLeadDetailModal}
                lead={leadPayload}
                setLead={setLeadPayload}
                leads={leadsByCampaign}
                setLeads={setLeadsByCampaign}
            />

            <Modal show={showModal} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Rejection Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !reasonData ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </div> :
                            <div className='flex flex-col gap-y-3'>
                                <h5>Total <span className="font-semibold">{totalLeads}</span> lead{totalLeads > 1 ? "s" : ""} found.</h5>
                                <ReactApexChart series={reasonData.series} options={reasonChartOption} type="donut" height={300} className="apex-chart-one mb-4" />

                                <div className="flex flex-col gap-y-5">
                                    {
                                        reasonData.data?.accepted_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.accepted_leads.length} <span className='text-success'>accepted</span></span> lead{reasonData.data.accepted_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("accepted")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showAcceptedLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showAcceptedLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showAcceptedLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.accepted_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.rejected_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.rejected_leads.length} <span className='text-danger'>rejected</span></span> lead{reasonData.data.rejected_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("rejected")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showRejectedLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showRejectedLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showRejectedLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.rejected_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.duplicated_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.duplicated_leads.length} <span className='text-warning'>duplicated</span></span> lead{reasonData.data.duplicated_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("duplicated")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showDuplicatedLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showDuplicatedLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showDuplicatedLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.duplicated_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.error_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.error_leads.length} <span className='text-danger'>error</span></span> lead{reasonData.data.error_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("error")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showErrorLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showErrorLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showErrorLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.error_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.stored_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.stored_leads.length} <span className='text-primary'>stored</span></span> lead{reasonData.data.stored_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("stored")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showStoredLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showStoredLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showStoredLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.stored_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.returned_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.returned_leads.length} <span className='text-danger'>returned</span></span> lead{reasonData.data.returned_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("returned")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showReturnedLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showReturnedLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showReturnedLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.returned_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                    {
                                        reasonData.data?.pending_leads ?
                                            <Card>
                                                <Card.Body>
                                                    <div className='flex flex-col gap-y-3'>
                                                        <div className='flex items-center gap-x-3'>
                                                            <p className='mb-0'>Total <span className='font-semibold'>{reasonData.data.pending_leads.length} <span className='text-warning'>pending</span></span> lead{reasonData.data.pending_leads.length > 1 ? "s" : ""} found.</p>
                                                            <button onClick={() => toggleReasonDetail("pending")} type="button" className='flex items-center gap-x-1 px-1.5 py-1 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 ease-linear'>
                                                                {showPendingLeads ? "Hide" : "Show"} detail
                                                                {
                                                                    showPendingLeads ?
                                                                        <FaChevronUp className='text-sm' /> :
                                                                        <FaChevronDown className='text-sm' />
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            showPendingLeads &&
                                                            <RenderLeadsTable leads={reasonData.data.pending_leads} skin={skin} />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            : ""
                                    }
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReasonDetailModal