import React from 'react'
import { useEffect, useState, useRef } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import AuthUtils from "../Utils/AuthUtils";
import { Link } from "react-router-dom";
import Header from '../../layouts/Header'
import HeaderMobile from '../../layouts/HeaderMobile'
import Sidebar from "../../layouts/Sidebar";
import { Card, Dropdown, Form, Spinner, Table } from "react-bootstrap";
import AddUserModal from '../../Components/Users/AddUserModal'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { IoSettingsOutline } from "react-icons/io5";
import { PAGE_LIMIT_SIZE } from '../Utils/baseConfig'
import { useAuth } from '../../Context/AuthContext'
import Select from "react-select";


export default function ManageUsers() {
    const [authData, setAuthData] = useAuth();

    const [loading, setLoading] = useState([false]);
    const [showModal, setShowModal] = useState(false)
    const [clients, setClients] = useState([])
    const [page, setPage] = useState(1);
    const [formError, setFormError] = useState({
        errId: -1
    })
    const [totalClients, setTotalClients] = useState(0);
    const [roles, setRoles] = useState([]);
    // Selected role
    const [role, setRole] = useState(null);

    const searchTextRef = useRef();
    const userNameRef = useRef();

    const { http, logout } = AuthUtils();

    // const [editModal, setEditModal] = useState(null);

    const fetchRoles = async () => {
        try {
            const res = await http.get("/api/roles/", {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                let roleArr = [];
                const { rolenames } = authData;

                data?.map(r => {
                    roleArr.push({
                        ...r,
                        value: r.name,
                        label: r.name
                    });
                })

                if (!rolenames.includes("SUPER ADMIN")) {
                    roleArr = roleArr.filter(r => r.name !== "SUPER ADMIN");
                }

                setRoles(roleArr);
            }
        } catch (err) {
            toast.error("Failed to fetch roles!");
        }
    }

    const getClients = async (selPage) => {
        setLoading(true);
        await http.get(`/api/clients/?page=${selPage}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async (response) => {
                const { data, total } = response.data;
                setClients(data);
                setTotalClients(total);

                setLoading(false);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch users' data!");

                setClients([]);
                setLoading(false);
            })
    }

    const handleClientDelete = async (client_id) => {
        await http.delete(`/api/delete-client/${client_id}/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                toast.success("Client has been removed.")
                getClients(page);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to delete the user!");
            })
    }

    useEffect(() => {
        if (authData) {
            // Fetching all the roles
            fetchRoles();

            getClients(page);
        }

        return () => {
            setTotalClients(0);
            setPage(1);
        }
    }, [authData])

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    const handleSearchUser = async (e) => {
        e.preventDefault();
        setPage(1);
        setClients([]);
        setLoading(true);

        const searchText = searchTextRef.current.value.trim();

        try {
            const res = await http.get(`/api/clients?query=${searchText}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (res.status === 200) {
                const { data, total } = res.data;
                setClients(data);
                setTotalClients(total);
                setLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
            setLoading(false);
            toast.error("Failed to search user!");
        }
    }

    const handlePageUpdate = async (selPage) => {
        setPage(selPage);
        await getClients(selPage);
    }

    const toggleModal = (modalStatus) => {
        setShowModal(modalStatus);
        setFormError({ errId: -1 });
        if (modalStatus) {
            setTimeout(() => {
                userNameRef.current?.focus();
            }, 50);
        }
    }

    const fetchClientsByRole = async (selRole) => {
        setLoading(true);

        // If no role is selected, then fetch clients of every roles
        if (!selRole) {
            setPage(1);
            getClients(1);

            return;
        }

        // Fetch clients by role
        try {
            const res = await http.get(`/api/clients?role=${selRole.value}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data, total } = res.data;

                setClients(data);
                setTotalClients(total);

                setLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }

            toast.error("Failed to fetch users by role!");
            setClients([]);
            setLoading(false);
        }
    }

    const handleSelectRole = async (e) => {
        setRole(e);
        await fetchClientsByRole(e)
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />
            <AddUserModal
                http={http}
                logout={logout}
                showModal={showModal}
                setShowModal={setShowModal}
                formError={formError}
                setFormError={setFormError}
                getClients={getClients}
                skin={skin}
            />
            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3'>
                        <div className='text-xl font-semibold'>Users</div>
                        <div className='flex flex-col sm:flex-row items-center gap-3'>
                            <div
                                className={`w-full flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                    }`}
                            >
                                {/* <Form.Label className="font-semibold">Search By Filter</Form.Label> */}
                                <Select
                                    options={roles}
                                    value={role}
                                    onChange={handleSelectRole}
                                    placeholder="Filter by role"
                                    classNamePrefix={"my-react-select"}
                                    className="w-full min-w-[200px] whitespace-nowrap"
                                    isSearchable
                                    isClearable
                                    noOptionsMessage={() => "No match found."}
                                />
                            </div>
                            <div className='w-full flex items-center gap-x-3'>
                                <div className='card-post flex-1 sm:flex-auto'>
                                    <Form onSubmit={handleSearchUser}>
                                        <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                                            <Form.Control ref={searchTextRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search user..." />
                                            <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                                                <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                                            </button>
                                        </Form.Group>
                                    </Form>
                                </div>

                                {
                                    !loading &&
                                    <button onClick={() => toggleModal(true)} type="button" className="whitespace-nowrap font-medium cursor-pointer px-2.5 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                                        Add New User
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                    <Card>
                        <Card.Body>
                            {
                                loading ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                    clients.length > 0 ?
                                        <>
                                            <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">First Name</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Last Name</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Email</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Phone No</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Company Name</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Role</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clients.map((client) => (
                                                            client.id !== authData?.client_id &&
                                                            <tr key={client.id}>
                                                                <th scope="row" className='px-3 align-middle'>
                                                                    <Link href={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {client.id}
                                                                    </Link>
                                                                </th>
                                                                <td className='px-3 align-middle title__td'>
                                                                    <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {
                                                                            client?.first_name[0]?.toUpperCase() + client?.first_name?.slice(1,)
                                                                        }
                                                                    </Link>
                                                                </td>
                                                                <td className='px-3 align-middle title__td'>
                                                                    <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {
                                                                            client?.last_name[0]?.toUpperCase() + client?.last_name?.slice(1,)
                                                                        }
                                                                    </Link>
                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {
                                                                            client?.email
                                                                        }
                                                                    </Link>
                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    {
                                                                        client.phone_number ?
                                                                            <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                {
                                                                                    client.phone_number
                                                                                }
                                                                            </Link> :
                                                                            <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                -
                                                                            </Link>
                                                                    }

                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    {
                                                                        client.company_name ?
                                                                            <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                {
                                                                                    client?.company_name
                                                                                }
                                                                            </Link> :
                                                                            <Link to={`/user/${client.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                -
                                                                            </Link>
                                                                    }
                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    {
                                                                        client.user && client.user.roles.length ?
                                                                            <div className='flex flex-wrap gap-x-3 gap-y-2'>
                                                                                {
                                                                                    client.user.roles.map(r => (
                                                                                        <Link key={r.id} to={`/user/${client.id}/`} className={`whitespace-nowrap p-1.5 rounded-md bg-success text-white ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                            {
                                                                                                r?.name
                                                                                            }
                                                                                        </Link>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <Link to={`/user/${client.id}/`} className={`font-medium ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                -
                                                                            </Link>
                                                                    }

                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    <Dropdown className="static">
                                                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                                            <IoSettingsOutline className='text-lg' />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item>
                                                                                <Link to={`/user/${client.id}/`} className='text-inherit flex w-full items-center gap-x-3'>
                                                                                    <MdOutlineEdit className="text-lg text-stone-500" />
                                                                                    Edit
                                                                                </Link>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item>
                                                                                <button onClick={() => handleClientDelete(client.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                                                    <MdDeleteOutline className="text-lg text-stone-500" />
                                                                                    Delete
                                                                                </button>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            {
                                                clients.length > 0 &&
                                                <div className='mt-3 flex justify-end custom__reactPaginate'>
                                                    <PaginationControl
                                                        page={page}
                                                        between={3}
                                                        total={totalClients}
                                                        limit={PAGE_LIMIT_SIZE}
                                                        changePage={(page) => handlePageUpdate(page)}
                                                        ellipsis={5}
                                                    />
                                                </div>
                                            }
                                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
