import moment from 'moment';
import { useEffect, useRef, useState } from 'react'
import { Card, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { FaRegSave } from "react-icons/fa";
import { PAGE_LIMIT_SIZE } from '../../Pages/Utils/baseConfig';
import { PaginationControl } from "react-bootstrap-pagination-control";
import ConversionResponseLogModal from './ConversionResponseLogModal';
import { formattedDate } from '../../Pages/Utils/formatDate';
import { toast } from 'react-toastify';

const ConversionLogModal = ({ http, logout, conversionLog, setConversionLog, showModal, setShowModal, skin, dateRange }) => {
    const [logPage, setLogPage] = useState(1);
    const [isFilterData, setIsFilterData] = useState(false);
    const [conversionLogs, setConversionLogs] = useState([]);
    const [totalConversionLogs, setTotalConversionLogs] = useState(0);
    const [logResponseLog, setLogResponseLog] = useState(null);
    const [showConversionResponseLogModal, setShowConversionResponseLogModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const searchTextRef = useRef();

    function resetConversionLogData() {
        setTotalConversionLogs(0);
        setConversionLogs([]);
    }

    async function fetchConversionLogs(page) {
        resetConversionLogData();
        setLoading(true);
        setIsFilterData(true);

        const formData = new FormData();

        let startDate = "", endDate = "";

        if (dateRange.length > 0) {
            startDate = formattedDate(new Date(dateRange[0].startDate));
            endDate = formattedDate(new Date(dateRange[0].endDate));
        }

        const conversionId = searchTextRef.current?.value?.trim();

        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("conversionId", conversionId);

        try {
            const res = await http.post(`/api/conversion/?page=${page}`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const {total, data: leads} = res.data;

                let leadArr = [];

                setTotalConversionLogs(total);

                if (leads.length) {
                    let externalId = null, postbackUrl = null, ipAddress = null, postbackResponseLog = null;

                    leads.map(lead => {
                        if (lead.payload) {
                            const leadPayload = lead.payload;

                            if (leadPayload["externalid"]) {
                                externalId = leadPayload.externalid;
                            }
                            if (leadPayload["ip_address"]) {
                                ipAddress = leadPayload.ip_address;
                            }
                        }
                        if (lead.postback_response_log) {
                            postbackResponseLog = lead.postback_response_log;
                            
                            postbackUrl = postbackResponseLog?.postback_url;
                        }

                        leadArr.push({
                            id: lead.id,
                            created_at: lead.created_at,
                            externalid: externalId,
                            postback_url: postbackUrl,
                            postback_response_log: postbackResponseLog,
                            ip_address: ipAddress
                        });
                    })
                }

                setConversionLogs(leadArr);

                setLoading(false);
                setIsFilterData(false);
            }
        } catch(err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch conversion logs' data!");

            setLoading(false);
            setIsFilterData(false);
        }
    }

    useEffect(() => {
        if (conversionLog) {
            searchTextRef.current.value = conversionLog;

            fetchConversionLogs(page);
        } else {
            fetchConversionLogs(page);
        }
    }, [conversionLog])

    const handleClose = () => {
        setShowModal(false);
        setConversionLog(null);
        setLoading(false);
        setTotalConversionLogs(0);
        setShowConversionResponseLogModal(false);
        setLogResponseLog(null);
    }

    async function handleSearchLogId(e) {
        e.preventDefault();

        await fetchConversionLogs(page);
    }

    // const handleSelectLogType = e => {
    //     setLogType(e);
    // }

    // const customValueRenderer = (selected, _options) => {
    //     let notSelectedText = "Nothing is selected";

    //     switch (_options) {
    //         case "http_codes":
    //             notSelectedText = "All http codes";
    //             break;
    //     }
    //     return selected.length
    //         ? selected.map(({ label }, index) => `${label}${selected.length > 1 && index !== selected.length - 1 ? ", " : ""}`)
    //         : notSelectedText;
    // };

    // const handleSelectedOptions = ({ data, label }) => {
    //     switch (label) {
    //         case "http_codes":
    //             setLogHttpCodes(data);
    //             break;
    //     }
    // }

    const handleLogFilter = async (page) => {
        await fetchConversionLogs(page);
    }

    const handleReponseLog = (responseLog) => {
        setLogResponseLog(responseLog);
        setShowConversionResponseLogModal(true);
    }

    const handleConversionLogPage = async (page) => {
        setLogPage(page);
        await fetchConversionLogs(page);
    }

    return (
        <>
            {
                logResponseLog &&
                <ConversionResponseLogModal
                    showModal={showConversionResponseLogModal}
                    setShowModal={setShowConversionResponseLogModal}
                    responseLog={logResponseLog}
                    setResponseLog={setLogResponseLog}
                    skin={skin}
                />
            }

            <Modal show={showModal} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Conversions Logs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col gap-y-5'>
                        {
                            dateRange.length > 0 &&
                            <p>
                                The records in the conversion log are stored for <span className='font-medium'>
                                    {moment(
                                        dateRange[0].startDate.toLocaleDateString()
                                    ).format("MMM DD, YYYY")} - {moment(
                                        dateRange[0].endDate.toLocaleDateString()
                                    ).format("MMM DD, YYYY")}
                                </span>.
                            </p>
                        }
                        <div className='flex flex-col gap-y-3'>
                            <div className='grid grid-cols-1 sm:flex flex-wrap items-end gap-2'>
                                <Form onSubmit={handleSearchLogId} className='flex-1'>
                                    <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                                        <Form.Control ref={searchTextRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search by conversion id..." />
                                        <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                                            <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                                        </button>
                                    </Form.Group>
                                </Form>
                                {/* <div className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                    <Select
                                        options={ALL_TYPES}
                                        value={logType}
                                        onChange={handleSelectLogType}
                                        placeholder="All types"
                                        classNamePrefix={"my-react-select"}
                                        className="w-full min-w-[180px] whitespace-nowrap"
                                        isSearchable
                                        isClearable
                                        noOptionsMessage={() => "No match found."}
                                    />
                                </div> */}
                                {/* <div className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                    <MultiSelect
                                        options={ALL_HTTP_CODES}
                                        value={logHttpCodes}
                                        onChange={e => handleSelectedOptions({ data: e, label: "http_codes" })}
                                        valueRenderer={(e) => customValueRenderer(e, "http_codes")}
                                        labelledBy="All HTTP Codes"
                                        className='w-full sm:min-w-[180px] sm:max-w-[180px] rounded-md'
                                    />
                                </div> */}
                                <div>
                                    <button
                                        onClick={() => handleLogFilter(logPage)}
                                        type="submit"
                                        className="font-medium flex items-center justify-center gap-x-1 w-full sm:w-fit rounded-md px-4 py-2.5 bg-indigo-500 hover:bg-indigo-600 text-white btn-sign transition-colors duration-200 ease-linear"
                                        disabled={isFilterData}
                                    >
                                        {isFilterData ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </>
                                        ) : (
                                            <>
                                                Apply
                                                <FaRegSave className='text-base' />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <Card>
                            <Card.Body>
                                {loading ? (
                                    <div className="d-flex justify-content-center">
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                ) :
                                    conversionLogs.length > 0 ? (
                                        <>
                                            <Table
                                                variant={skin && "dark"}
                                                hover
                                                className="mb-0"
                                                responsive
                                            >
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Created At
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Conversion ID
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            URL
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            IP
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {conversionLogs.map((cl) => {
                                                        return (
                                                            <tr key={cl.id}>
                                                                <td
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div
                                                                        className={`${skin
                                                                            ? "text-stone-300"
                                                                            : "text-inherit"
                                                                            } whitespace-nowrap`}
                                                                    >
                                                                        {moment(cl.created_at).format("LL")}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div
                                                                        className={`${skin
                                                                            ? "text-stone-300"
                                                                            : "text-inherit"
                                                                            } whitespace-nowrap`}
                                                                    >
                                                                        {cl.externalid}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        cl.postback_url ?
                                                                            <div
                                                                                className={`${skin
                                                                                    ? "text-stone-300"
                                                                                    : "text-inherit"
                                                                                    }`}
                                                                            >
                                                                                {cl.postback_url}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        cl.ip_address ?
                                                                            <div
                                                                                className={`${skin
                                                                                    ? "text-stone-300"
                                                                                    : "text-inherit"
                                                                                    } whitespace-nowrap`}
                                                                            >
                                                                                {cl.ip_address}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={() => handleReponseLog(cl.postback_response_log)}
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div className='flex items-center gap-x-0.5'>
                                                                        <div className={`w-[4px] h-[4px] rounded-full ${skin ? "bg-stone-400" : "bg-stone-500"}`}></div>
                                                                        <div className={`w-[4px] h-[4px] rounded-full ${skin ? "bg-stone-400" : "bg-stone-500"}`}></div>
                                                                        <div className={`w-[4px] h-[4px] rounded-full ${skin ? "bg-stone-400" : "bg-stone-500"}`}></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>

                                            <div className="mt-3 flex justify-end custom__reactPaginate">
                                                <PaginationControl
                                                    page={logPage}
                                                    between={3}
                                                    total={totalConversionLogs}
                                                    limit={PAGE_LIMIT_SIZE}
                                                    changePage={(page) => handleConversionLogPage(page)}
                                                    ellipsis={5}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <p className="mb-0 text-lg md:text-xl text-center font-semibold text-stone-500">
                                            No data found yet!
                                        </p>
                                    )}
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConversionLogModal