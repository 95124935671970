import { useRef, useState, useEffect } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify"
import CreatableSelect from 'react-select/creatable';

const AddCampaignFieldModal = ({ http, logout, showModal, setShowModal, formError, setFormError, getFields, campaignId, fieldData, setFieldData, skin }) => {
    const fieldNameRef = useRef();
    const fieldTypeRef = useRef();
    const postStatusRef = useRef();
    const headerTitleRef = useRef();
    const phoneFormatRef = useRef();
    const multiListValueRef = useRef();

    const [loading, setIsLoading] = useState(false);
    const [isListSelected, setIsListSelected] = useState(false);
    const [listValue, setListValue] = useState([]);
    const [isPhoneSelected, setIsPhoneSelected] = useState(false);
    const [isFieldVisible, setIsFieldVisible] = useState(true);

    useEffect(() => {
        if (showModal && fieldData) {
            headerTitleRef.current.innerText = "Edit Field";
            fieldNameRef.current.value = fieldData.field_name;
            fieldTypeRef.current.value = fieldData.field_type;
            postStatusRef.current.value = fieldData.post_status;
            setIsFieldVisible(fieldData.field_visiblity);
            if (fieldData.field_type === "phone") {
                setIsPhoneSelected(true);
            }
            if (fieldData.field_type === "list") {
                let listItems = [];
                fieldData.field_values.map(fv => {
                    const data = {
                        label: fv.value_title,
                        value: fv.value_title
                    }
                    listItems.push(data);
                })
                setListValue(listItems);
                setIsListSelected(true);
            }
        }
    }, [fieldData])

    useEffect(() => {
        if (isPhoneSelected && fieldData) {
            phoneFormatRef.current.value = fieldData.number_format;
        }
    }, [isPhoneSelected])

    const handleClose = () => {
        setShowModal(false);
        setIsListSelected(false);
        setListValue([]);
        setFieldData(null);
        setIsPhoneSelected(false);
        setIsFieldVisible(true);
    }

    const showFormError = ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            fieldNameRef.current.classList.add("error__field");
            fieldNameRef.current?.focus();
        } else if (errId === 2) {
            fieldTypeRef.current.classList.add("error__field");
            fieldTypeRef.current?.focus();
        } else if (errId === 4) {
            phoneFormatRef.current.classList.add("error__field");
            phoneFormatRef.current?.focus();
        }

        setIsLoading(false);
    }

    const handlePostFields = async (e) => {
        e.preventDefault();

        showFormError({
            errorId: -1,
        });
        setIsLoading(true);

        const fieldName = fieldNameRef.current.value.trim();
        const fieldType = fieldTypeRef.current.value.trim();
        const postStatus = postStatusRef.current.value.trim();
        let phoneFormat = "";

        if (fieldName === "") {
            showFormError({
                errId: 1,
            })
            return;
        }
        if (fieldType === "") {
            showFormError({
                errId: 2,
            })
            return;
        }
        if (postStatus === "") {
            showFormError({
                errId: 3,
            });
            return;
        }
        if (isPhoneSelected) {
            phoneFormat = phoneFormatRef.current.value.trim();
            if (phoneFormat === "") {
                showFormError({
                    errId: 4,
                });
                return;
            }
        }

        if (fieldData) {    // For updating
            try {
                let listValueArr = [];
                listValue.map(lv => {
                    listValueArr.push(lv.value);
                })

                const formData = {
                    field_name: fieldName,
                    field_type: fieldType,
                    value_title: listValueArr,
                    number_format: isPhoneSelected ? phoneFormat : "",
                    field_visiblity: isFieldVisible,
                    post_status: postStatus,
                    campaign: campaignId
                }

                const resp = await http.put(`/api/edit-field/${fieldData.id}/`, formData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                if (resp.status === 200) {
                    toast.success('Field is updated successfully!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    handleClose();
                    setIsLoading(false);
                    getFields();
                } else {
                    setIsLoading(false);
                    toast.error("Something went wrong");
                }

            } catch (err) {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to update the field!");

                // console.log(err);
                setIsLoading(false);
            }
        } else {    // For creating
            let listValueArr = [];

            listValue.map(lv => {
                listValueArr.push(lv.value);
            })

            const formData = {
                field_name: fieldName,
                field_type: fieldType,
                value_title: listValueArr,
                number_format: isPhoneSelected ? phoneFormat : "",
                post_status: postStatus,
                field_visiblity: isFieldVisible,
                campaign: campaignId
            }

            await http
                .post(`/api/create-field/${campaignId}/`, formData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => {
                    // setFields(response.data.data)
                    toast.success('Field is added successfully!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    handleClose();
                    setIsLoading(false);
                    getFields();
                })
                .catch((err) => {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }
                    toast.error("Failed to add field!");
                    // console.log(error);
                    setIsLoading(false);
                });
        }
    };

    const handleFieldChange = async (e) => {
        showFormError({
            errId: -1
        });

        if (e.target.name === "field__name") {
            if (e.target.value !== "") {
                fieldNameRef.current.classList.remove("error__field");
            } else {
                fieldNameRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "field__type") {
            if (e.target.value !== "") {
                fieldTypeRef.current.classList.remove("error__field");

                if (e.target.value === "list") {
                    setIsListSelected(true);
                } else {
                    setIsListSelected(false);
                }

                if (e.target.value === "phone") {
                    setIsPhoneSelected(true);
                } else {
                    setIsPhoneSelected(false);
                }
            } else {
                fieldTypeRef.current.classList.add("error__field");
            }
        }
    }

    const handleMultiValuePaste = event => {
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        if (!pastedText) return;

        const newOptions = pastedText.split("\n").map((item, index) => ({
            value: item.trim(),
            label: item.trim()
        }))

        setListValue(prevs => [...prevs, ...newOptions]);

        // Prevent the default behavior of pasting
        event.preventDefault();
    }

    useEffect(() => {
        if (isListSelected) {
            multiListValueRef.current?.inputRef?.addEventListener("paste", handleMultiValuePaste);
        } else {
            multiListValueRef.current?.inputRef?.removeEventListener("paste", handleMultiValuePaste);
        }
    }, [isListSelected])

    const handleFieldVisibility = e => {
        setIsFieldVisible(e.target.value);
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title ref={headerTitleRef}>Add Field</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handlePostFields}>
                    <Form.Group className="mb-3 card-post">
                        <Form.Label>Field Name</Form.Label>
                        <Form.Control autoFocus={true} name="field__name" ref={fieldNameRef} onChange={e => handleFieldChange(e)} type="text" placeholder="Enter field name" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Field Type</Form.Label>
                        <Form.Select onChange={e => handleFieldChange(e)} name="field__type" ref={fieldTypeRef} aria-label="Default select example">
                            <option value="" selected>Open this select menu</option>
                            <option value="text">Text</option>
                            <option value="list">List</option>
                            <option value="date">Date & Time</option>
                            <option value="integer">Integer</option>
                            <option value="email">Email</option>
                            <option value="phone">Phone</option>
                            <option value="postal code">Postal Code</option>
                            <option value="zip code">Zip Code</option>
                        </Form.Select>
                    </Form.Group>
                    {
                        isPhoneSelected &&
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Format</Form.Label>
                            <Form.Select name="phone__format" ref={phoneFormatRef} aria-label="Default select example">
                                <option value="" selected>Choose a format</option>
                                <option value="(XXX) XXX-XXXX">(XXX) XXX-XXXX</option>
                                <option value="XXXXXXXXXX">XXXXXXXXXX</option>
                                <option value="XXX-XXX-XXXX">XXX-XXX-XXXX</option>
                                <option value="XXX XXX XXXX">XXX XXX XXXX</option>
                                <option value="+1XXXXXXXXXX">+1XXXXXXXXXX</option>
                                <option value="1XXXXXXXXXX">1XXXXXXXXXX</option>
                            </Form.Select>
                        </Form.Group>
                    }
                    {
                        isListSelected &&
                        <Form.Group className={`mb-3 my-react-select-container ${skin && "dark__mode"}`}>
                            {/* <Form.Label>Multi Value</Form.Label> */}
                            <CreatableSelect isClearable isMulti ref={multiListValueRef} value={listValue} onChange={setListValue} placeholder="Enter allowed values" classNamePrefix={"my-react-select"} />
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Form.Select name="status" ref={postStatusRef} aria-label="Default select example">
                            <option value="required" selected>Required</option>
                            <option value="optional">Optional</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Field Visibility</Form.Label>
                        <Form.Select name="field__visiblility" onChange={handleFieldVisibility} aria-label="Default select example">
                            <option value="true" selected={isFieldVisible ? true : false}>True</option>
                            <option value="false" selected={!isFieldVisible ? true : false}>False</option>
                        </Form.Select>
                    </Form.Group>

                    {
                        fieldData ?
                            <button type="submit" className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                                {
                                    loading ? <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </> : "Update"
                                }
                            </button> : <button type="submit" className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                                {
                                    loading ? <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </> : "Create"
                                }
                            </button>
                    }
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCampaignFieldModal
