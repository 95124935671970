import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { IoPlayCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { BiSave } from "react-icons/bi";
import AuthUtils from "../Utils/AuthUtils";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Dropdown, Form, Spinner, Table } from 'react-bootstrap';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { FaRegCirclePause } from "react-icons/fa6";
import "../../assets/css/create_campaign.css";
import moment from "moment"
import AddCampaignFieldModal from "../../Components/AddCampaignFieldModal";
import AddFilterModal from "../../Components/AddFilterModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { FaChevronDown } from "react-icons/fa";
import AddTrafficSourceFilterModal from "../../Components/AddTrafficSourceFilterModal";
import { useAuth } from "../../Context/AuthContext";


const MAX_NUMBER_OF_FIELDS = 10;

const DUPE_CHECK_SCOPES = [
  // {
  //   value: "BUYER LEVEL",
  //   label: "Buyer level"
  // },
  {
    value: "CAMPAIGN LEVEL",
    label: "Campaign Level"
  },
]

export default function CreateCampaign() {
  const { http, logout } = AuthUtils();

  const [authData, setAuthData] = useAuth();

  // loader
  const [isTrafficSourceLoading, setIsTrafficSourceLoading] = useState(true);
  const [isSupplierLoading, setIsSupplierLoading] = useState(true);
  const [isBuyerLoading, setIsBuyerLoading] = useState(true);
  const [isFieldLoading, setIsFieldLoading] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  const [fieldData, setFieldData] = useState(null);
  const [filterData, setFilterData] = useState(null);

  const [trafficSources, setTrafficSources] = useState([]);

  const [showAllFields, setShowAllFields] = useState(false);

  const campaignId = useParams();
  const [campaign, setCampaign] = useState(null);
  const [isCampaignSaving, setIsCampaignSaving] = useState(false);


  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showTrafficSourceFilterModal, setShowTrafficSourceFilterModal] = useState(false);

  const [suppliers, setSuppliers] = useState([]);

  // field varialble and functions
  const [fields, setFields] = useState([])

  const [dupeNumOfDays, setDupeNumOfDays] = useState(30);
  const [dupeCheckFields, setDupeCheckFields] = useState([]);
  const [dupeCheckScope, setDupeCheckScope] = useState(null);

  // buyers
  const [buyers, setBuyers] = useState([]);
  // filters
  const [globalFilters, setGlobalFilters] = useState([]);
  const [supplierFilters, setSupplierFilters] = useState([]);
  const [buyerFilters, setBuyerFilters] = useState([]);
  const [trafficSourceFilters, setTrafficSourceFilters] = useState([]);
  const [filterType, setFilterType] = useState("GLOBAL");
  const [pingPostToggleMove, setPingPostToggleMove] = useState(false);
  const [formError, setFormError] = useState({
    errorId: -1
  });

  const [distributions, setDistributions] = useState([]);
  const [distributionType, setDistributionType] = useState(null);
  const [isDistributionTypeLoading, setIsDistributionTypeLoading] = useState(true);

  // Only for traffic source role user
  const [campaignPageUrls, setCampaignPageUrls] = useState([]);
  const [isCampaignPageUrlLoading, setIsCampaignPageUrlLoading] = useState(true);

  const pingFieldRef = useRef();
  const searchSupplierRef = useRef();
  const searchBuyerRef = useRef();
  const campaignNameRef = useRef();
  const distributionTypeRef = useRef();

  const [campaignTitle, setCampaignTitle] = useState("");

  const [showDupeChecker, setShowDupeChecker] = useState(false);
  const [showPrePingDupeChecker, setShowPrePingDupeChecker] = useState(false);
  const [dupeCheckerId, setDupeCheckerId] = useState(null);

  // Role based permission
  const [hasAccess, setHasAccess] = useState({
    canViewDistribution: false,
    canViewCampaignPageUrl: false,
    canViewTrafficSources: false,
    canViewSuppliers: false,
    canViewBuyers: false,
    canViewCampaignFields: false,
    canAddCampaignFields: false,
    canUpdateCampaignFields: false,
    canViewTrafficSourceFilters: false,
    canViewSupplierFilters: false,
    canViewBuyerFilters: false,
    canViewDupeCheckerSettings: false,
    canUpdateCampaign: false,
  });

  const handleFormError = ({ errId, errMsg }) => {
    if (errId === 1) {
      campaignNameRef.current.classList.add("error__field");
    } else if (errId === 2) {
      distributionTypeRef.current.classList.add("error__field");
    }
  }

  const postDupeChecker = async () => {
    let fieldArr = [];

    dupeCheckFields?.map(f => {
      fieldArr.push(f.value);
    });

    const formData = {
      is_active: showDupeChecker,
      is_pre_ping: showPrePingDupeChecker,
      campaign: campaignId.id,
      number_of_days: dupeNumOfDays,
      fields: fieldArr,
      dupe_check_scope: dupeCheckScope?.value,
      matching_fields: dupeCheckFields.length === fields.length ? "ALL FIELDS" : "ONE FIELD"
    }

    try {
      const response = await http.post(`/api/post-dupe-checker/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const { id } = response.data.data;

        setDupeCheckerId(id);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to add dupe checker!");
    }
  }

  const putDupeChecker = async () => {
    let fieldArr = [];

    dupeCheckFields?.map(f => {
      fieldArr.push(f.value);
    });

    const formData = {
      is_active: showDupeChecker,
      is_pre_ping: showPrePingDupeChecker,
      campaign: campaignId.id,
      number_of_days: dupeNumOfDays,
      fields: fieldArr,
      dupe_check_scope: dupeCheckScope.value,
      matching_fields: dupeCheckFields.length === fields.length ? "ALL FIELDS" : "ONE FIELD"
    }

    try {
      const response = await http.put(`/api/put-dupe-checker/${dupeCheckerId}/`, formData, {
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.status === 200) {
        const { id } = response.data.data;

        setDupeCheckerId(id);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to update dupe checker!");
    }
  }

  // updating campaign
  const handleCampaignUpdate = async () => {
    setFormError({
      errorId: -1
    })

    setIsCampaignSaving(true);

    // if (authData.rolenames.includes("TRAFFIC SOURCE")) {
    //   await editTrafficSourceCampaign();
    // } else {

    // }
    await editCampaign();
  }

  const editCampaign = async () => {
    if (campaignTitle === "") {
      showFormError({
        errorId: 3
      })
      setIsCampaignSaving(false);
      return;
    }

    const formData = {
      campaign_title: campaignTitle,
      distribution_type: distributionType ? distributionType?.id : distributions[0]?.id,
      is_active: true,
      is_active_ping: pingPostToggleMove,
    }

    try {
      const response = await http.put(`/api/edit-campaign/${campaignId.id}/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (!dupeCheckerId) {
        await postDupeChecker();
      } else {
        await putDupeChecker();
      }

      if (response.status === 200) {
        toast.success("Campaign is updated.")
        setIsCampaignSaving(false);
      }
      else {
        toast.error("Something went wrong");
        setIsCampaignSaving(false);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to update the campaign!");
      setIsCampaignSaving(false);
    }
  }

  async function storeCampaignFields(fields) {
    if (!showAllFields) {
      if (fields.length <= MAX_NUMBER_OF_FIELDS) {
        setShowAllFields(true);
      }
    }

    let systemFieldArr = [], notSystemFieldArr = [];
    fields?.map(field => {
      if (field.field_type === "SYSTEM GENERATED") {
        systemFieldArr.push(field);
      } else {
        notSystemFieldArr.push(field);
      }
    })

    let fieldArr = systemFieldArr.concat(notSystemFieldArr);
    setFields(fieldArr);
    setIsFieldLoading(false);
  }

  //   handling toggle loading
  const getFields = async () => {
    setIsFieldLoading(true);

    http
      .get(`/api/fields/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        storeCampaignFields(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
      });
  };

  const showFormError = ({ errorId }) => {
    setFormError({
      errorId: errorId,
    });
    if (errorId === 2) {
      pingFieldRef.current?.focus();
    } else if (errorId === 3) {
      campaignNameRef.current.classList.add("error__field");
      campaignNameRef.current?.focus();
    }
  }


  const handleFieldDelete = async (fieldId) => {
    await http.delete(`/api/delete-field/${fieldId}/`, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      getFields();
      toast.success("Field is removed successfully.");
    }).catch(err => {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to delete the field!");
    })
  };


  const getTrafficSources = async () => {
    setIsTrafficSourceLoading(true);

    await http
      .get(`/api/traffic-sources/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        setTrafficSources(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        setIsTrafficSourceLoading(false);
        setTrafficSources([]);
      });
    setIsTrafficSourceLoading(false);
  }

  const getSuppliers = async () => {
    setIsSupplierLoading(true);

    await http
      .get(`/api/suppliers/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        // console.log(response.data.data);
        setSuppliers(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        setIsSupplierLoading(false);
        setSuppliers([]);
      });
    setIsSupplierLoading(false);
  };

  const getDupeCheckDetails = async (campId) => {
    try {
      const response = await http.get(`/api/dupe-checkers/${campId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      // console.log(response.data)

      if (response.status === 200) {
        const { id, is_active, number_of_days, fields: dupeCheckAllFields, dupe_check_scope, is_pre_ping } = response.data.data;
        setDupeCheckerId(id);
        setShowDupeChecker(is_active);
        setDupeNumOfDays(number_of_days ? number_of_days : dupeNumOfDays);
        let dupeFields = [];
        dupeCheckAllFields?.map(field => {
          dupeFields.push({
            value: field.id,
            label: field.field_name
          })
        });
        setDupeCheckFields(dupeFields);
        setDupeCheckScope(dupe_check_scope === "BUYER LEVEL" ? { value: "BUYER LEVEL", label: "Buyer Level" } : { value: "CAMPAIGN LEVEL", label: "Campaign Level" });
        setShowPrePingDupeChecker(is_pre_ping);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
    }
  }

  const getDistributions = async () => {
    try {
      const response = await http.get("/api/distributions/", {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        setDistributions(prevDis => [...prevDis, ...response.data.data]);
        setIsDistributionTypeLoading(false);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
    }
  }

  function storeBuyerByIndexing(buyerArr) {
    buyerArr.sort((a, b) => parseInt(a.index) - parseInt(b.index));
    setBuyers(buyerArr);
  }

  const fetchCampaignDetails = async () => {
    await http
      .get(`/api/campaign-details/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(async (response) => {
        setCampaign(response.data.data);

        const { buyers, suppliers, traffic_sources, fields, lead_filters } = response.data.data;

        const { campaign_title, is_active_ping, distribution_type } = response.data.data;
        setCampaignTitle(campaign_title);
        setPingPostToggleMove(is_active_ping ? is_active_ping : false);
        setDistributionType(distribution_type);
        getDupeCheckDetails(campaignId.id);
        storeBuyerByIndexing(buyers);
        setIsBuyerLoading(false);
        setSuppliers(suppliers);
        setIsSupplierLoading(false);
        setTrafficSources(traffic_sources);
        setIsTrafficSourceLoading(false);
        storeCampaignFields(fields);
        storeLeadFilters(lead_filters);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch the campaign!");
      });
  };

  // get all buyers by campaigns
  const getBuyers = async () => {
    setIsBuyerLoading(true);

    await http
      .get(`/api/buyers/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        storeBuyerByIndexing(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        setIsBuyerLoading(false);
        setBuyers([]);
      });

    setIsBuyerLoading(false);
  };


  const deleteSupplier = async (supplierId) => {
    await http
      .delete(`/api/delete-supplier/${supplierId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        toast.success("Supplier is removed successfully.");
        getSuppliers();
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to delete the supplier!");
      });
  };

  const deleteBuyer = async (buyerId) => {
    await http
      .delete(`/api/delete-buyer/${buyerId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        toast.success("Buyer is removed successfully.");
        getBuyers();
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to delete the buyer!");
      });
  };

  async function storeLeadFilters(leadFilters) {
    let globalFilterArr = [], buyerFilterArr = [], supplierFilterArr = [], trafficSourceFilterArr = [];

    for (let i = 0; i < leadFilters.length; i++) {
      const f = leadFilters[i];

      const payoutData = await getPayoutRuleData({filterId: f.id});

      if (f.filter_type === "GLOBAL") {
        globalFilterArr.push({
          ...f,
          payout: (payoutData && payoutData.payout) ? payoutData.payout : 0
        });
      } else if (f.filter_type === "BUYER LEVEL") {
        let multiValArr = [];

        await Promise.all(f.multi_values?.map(async (mv) => {
          if (f.key === "supplier") {
            const supplierName = await getSupplierDetails(mv.value_title);
            if (supplierName) {
              multiValArr.push({
                value_title: supplierName
              })
            }
          } else {
            multiValArr.push(mv);
          }
        }))

        buyerFilterArr.push({
          ...f,
          multi_values: multiValArr,
          payout: (payoutData && payoutData.payout) ? payoutData.payout : 0
        });
      } else if (f.filter_type === "SUPPLIER LEVEL") {
        let multiValArr = [];

        if (f.key === "buyer" && f.value) {
          const buyerName = await getBuyerDetails(f.value);

          multiValArr.push({
            value_title: buyerName
          })
        } else {
          await Promise.all(f.multi_values?.map(async (mv) => {
            if (f.key === "buyer") {
              const buyerName = await getBuyerDetails(mv.value_title);

              if (buyerName) {
                multiValArr.push({
                  value_title: buyerName
                })
              }
            } else {
              multiValArr.push(mv);
            }
          }))
        }

        supplierFilterArr.push({
          ...f,
          multi_values: multiValArr,
          payout: (payoutData && payoutData.payout) ? payoutData.payout : 0
        });
      } else if (f.filter_type === "TRAFFIC SOURCE LEVEL") {
        let multiValArr = [];

        await Promise.all(f.multi_values?.map(async (mv) => {
          if (f.key === "buyer") {
            const buyerName = await getBuyerDetails(mv.value_title);

            if (buyerName) {
              multiValArr.push({
                value_title: buyerName
              })
            }
          } else if (f.key === "supplier") {
            const supplierName = await getSupplierDetails(mv.value_title);
            if (supplierName) {
              multiValArr.push({
                value_title: supplierName
              })
            }
          } else {
            multiValArr.push(mv);
          }
        }))

        trafficSourceFilterArr.push({
          ...f,
          multi_values: multiValArr,
          payout: (payoutData && payoutData.payout) ? payoutData.payout : 0
        });
      }
    }

    setGlobalFilters(globalFilterArr);
    setBuyerFilters(buyerFilterArr);
    setSupplierFilters(supplierFilterArr);
    setTrafficSourceFilters(trafficSourceFilterArr);
    // setTotalFilters(response.data.count);
    setIsFilterLoading(false);
  }

  const getFilters = () => {
    setIsFilterLoading(true);
    http
      .get(`/api/filters/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(async (response) => {
        storeLeadFilters(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        setIsFilterLoading(false);
        setGlobalFilters([]);
        setBuyerFilters([]);
        setSupplierFilters([]);
      });
  };

  const getPayoutRuleData = async ({filterId}) => {
    try {
      const res = await http.get(`/api/payout-rule/${filterId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { data } = res.data;
        return data;
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
    }
  }

  const deletePayoutRule = async (filterId) => {
    try {
      const res = await http.delete(`/api/delete-payout-rule/${filterId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
    }
  }

  const deleteFilter = async (filterId) => {
    await deletePayoutRule(filterId);

    await http
      .delete(`/api/delete-filter/${filterId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        toast.success("Filter is removed successfully.");
        getFilters();
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        } toast.error("Failed to delete the filter!");
      });
  };

  const fetchTrafficSourceCampaignDetails = async () => {
    try {
      const res = await http.get(`/api/campaign-page-details/${campaignId.id}`, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (res.status === 200) {
        const { data } = res.data;

        if (data) {
          const { campaign_title, fields, traffic_sources } = data;

          setCampaignTitle(campaign_title);
          storeCampaignFields(fields);

          let pageUrls = [];

          traffic_sources?.map(ts => {
            if (ts?.page_url) {
              pageUrls.push({
                channel_name: ts.channel_name,
                page_url: ts.page_url
              });
            }
          })

          setCampaignPageUrls(pageUrls);
          setIsCampaignPageUrlLoading(false);
        }
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to fetch the campaign!");
    }
  }

  const handleRoleBasedPermission = () => {
    if (!authData) return;

    let hasAccessOptions = { ...hasAccess };
    if (authData.rolenames.includes("SUPER ADMIN") || authData.rolenames.includes("ADMIN")) {
      hasAccessOptions = {
        canViewDistribution: true,
        canViewCampaignPageUrl: true,
        canViewTrafficSources: true,
        canViewSuppliers: true,
        canViewBuyers: true,
        canViewCampaignFields: true,
        canViewTrafficSourceFilters: true,
        canViewSupplierFilters: true,
        canViewBuyerFilters: true,
        canViewDupeCheckerSettings: true,
        canAddCampaignFields: true,
        canUpdateCampaignFields: true,
        canUpdateCampaign: true
      }
    } else if (authData.rolenames.includes("TRAFFIC SOURCE")) {
      hasAccessOptions.canViewCampaignPageUrl = true;
      hasAccessOptions.canViewCampaignFields = true;
    }

    setHasAccess(hasAccessOptions);
  }

  useEffect(() => {
    handleRoleBasedPermission();

    if (authData && authData.rolenames.includes("TRAFFIC SOURCE")) {
      fetchTrafficSourceCampaignDetails();
    } else {
      getDistributions();
      fetchCampaignDetails();
    }
  }, [authData]);

  const colors2 = fields.map((field) => ({
    value: field.id,
    label: field.field_name,
  }));

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleSearchSupplier = async (e) => {
    e.preventDefault();

    // setSupplierPage(1);
    setSuppliers([]);
    setIsSupplierLoading(true);

    const searchText = searchSupplierRef.current.value.trim();
    // console.log("SEARCHED", searchText)

    try {
      const res = await http.post(`/api/search-supplier/${campaignId.id}/`, {
        query: searchText
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      const { data } = res.data;
      setSuppliers(data);
      setIsSupplierLoading(false);
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      setIsSupplierLoading(false);
      toast.error("Failed to search supplier!");
    }
  }

  const handleSearchBuyer = async (e) => {
    e.preventDefault();

    // setBuyerPage(1);
    setBuyers([]);
    setIsBuyerLoading(true);

    const searchText = searchBuyerRef.current.value.trim();
    // console.log("SEARCHED", searchText)

    try {
      const res = await http.post(`/api/search-buyer/${campaignId.id}/`, {
        query: searchText
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      // console.log(res.data);

      const { data } = res.data;
      setBuyers(data);
      setIsBuyerLoading(false);
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      setIsBuyerLoading(false);
      toast.error("Failed to search buyer!");
    }
  }

  const toggleFieldModal = async () => {
    showFormError({
      errId: -1,
    });
    setShowModal(true);
  }

  const toggleFilterModal = async ({ state, filter }) => {
    showFormError({
      errId: -1,
    });
    setFilterType(filter);
    if (filter === "TRAFFIC SOURCE LEVEL") {
      setShowTrafficSourceFilterModal(true);
    } else {
      setShowModal2(true);
    }
  }

  const handleFieldChange = async (e) => {
    if (e.target.name === "campaign__name") {
      setCampaignTitle(e.target.value);
      if (e.target.value !== "") {
        campaignNameRef.current.classList.remove("error__field");
      } else {
        handleFormError({ errId: 1 });
      }
    }
    if (e.target.name === "distribution__type") {
      setDistributionType(e.target.value);
      if (e.target.value !== "") {
        distributionTypeRef.current.classList.remove("error__field");
      } else {
        handleFormError({ errId: 2 });
      }
    }
  }

  const fetchFieldData = async (fieldId) => {
    if (!hasAccess.canUpdateCampaignFields) return;

    try {
      const res = await http.get(`/api/fetch-field/${fieldId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      const { data } = res.data;
      setFieldData(data);
      setShowModal(true);
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to fetch fields' data!");
      setShowModal(false);
    }
  }

  const fetchFilterData = async (filterId, filterType) => {
    try {
      const res = await http.get(`/api/fetch-filter/${filterId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      const { data } = res.data;
      setFilterData(data);
      if (filterType === "TRAFFIC SOURCE LEVEL") {
        setShowTrafficSourceFilterModal(true);
      } else {
        setShowModal2(true);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      // console.log(err);
      toast.error("Failed to fetch filters' data!");
      setShowModal2(false);
    }
  }

  const updateBuyersOrder = async ({ orderedBuyers }) => {
    let buyerOrderArray = [];

    orderedBuyers?.map((b, index) => {
      buyerOrderArray.push({
        id: b.id,
        index: index + 1
      })
    })

    const formData = {
      "orders": buyerOrderArray
    }

    try {
      const res = await http.put(`/api/edit-buyer-order/${campaignId.id}/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
    }
  }

  const onBuyerDragEnd = (result) => {
    const { source, destination, type, draggableId } = result;

    if (!destination || !draggableId) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    if (type === "group") {
      const reorderedBuyers = [...buyers];

      const [removedBuyer] = reorderedBuyers.splice(source.index, 1);
      reorderedBuyers.splice(destination.index, 0, removedBuyer);
      // console.log(reorderedBuyers);
      setBuyers(reorderedBuyers);

      updateBuyersOrder({ orderedBuyers: reorderedBuyers });
    }
  }

  const toggleActiveBuyer = async (buyer) => {
    const data = {
      ...buyer,
      client_id: buyer?.client?.id,
      is_active: buyer.is_active == null ? true : buyer.is_active ? false : true,
      timezone: buyer.timezone_id,
      price: buyer.price?.toString()
    }

    http.put(`/api/edit-buyer/${buyer.id}/`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        const { is_active } = response.data.data;
        const updatedBuyers = buyers.map(b => b.id === buyer.id ? { ...b, is_active: is_active } : b);
        if (buyer.client) {
          if (is_active) {
            toast.success(`${buyer?.client?.company_name[0].toUpperCase() + buyer?.client?.company_name.slice(1,)} is now active.`);
          } else {
            toast.error(`${buyer?.client?.company_name[0].toUpperCase() + buyer?.client?.company_name.slice(1,)} is now on pause mode.`);
          }
        }
        setBuyers(updatedBuyers);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to update the buyer data!");
      })
  }

  const toggleActiveSupplier = async (supplier) => {
    const data = {
      ...supplier,
      campaign_id: supplier?.campaign,
      client_id: supplier?.client?.id,
      is_active: supplier.is_active == null ? true : supplier.is_active ? false : true,
      timezone: supplier.timezone_id,
      price: supplier.price?.toString()
    }

    http.put(`/api/update-supplier/${supplier.id}/`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        const { is_active } = response.data.data;
        const updatedSuppliers = suppliers.map(s => s.id === supplier.id ? { ...s, is_active: is_active } : s);
        if (supplier.client) {
          if (is_active) {
            toast.success(`${supplier?.client?.company_name[0].toUpperCase() + supplier?.client?.company_name.slice(1,)} is now active.`);
          } else {
            toast.error(`${supplier?.client?.company_name[0].toUpperCase() + supplier?.client?.company_name.slice(1,)} is now on pause mode.`);
          }
        }
        setSuppliers(updatedSuppliers);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to update the supplier data!");
      })
  }

  const forbadeEditingField = () => {
    toast.info("Cannot edit system generated field!");
  }

  const handleTrafficSourceDelete = async (tsId) => {
    await http.delete(`/api/delete-traffic-source/${tsId}/`, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      getTrafficSources();
      toast.success("Traffic source is removed.");
    }).catch(err => {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to delete the traffic source!");
    })
  }

  const getBuyerDetails = async (buyerId) => {
    try {
      const res = await http.get(`/api/fetch-buyer/${buyerId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { client } = res.data.data;
        return client?.company_name;
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      // console.log(err);
    }
  }

  const getSupplierDetails = async (supplierId) => {
    try {
      const res = await http.get(`/api/supplier-details/${supplierId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { client } = res.data.data;
        return client?.company_name;
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      // console.log(err);
    }
  }

  return (
    <>
      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <ToastContainer />
      <AddCampaignFieldModal
        http={http}
        logout={logout}
        showModal={showModal}
        setShowModal={setShowModal}
        formError={formError}
        setFormError={setFormError}
        getFields={getFields}
        campaignId={campaignId.id}
        fieldData={fieldData}
        setFieldData={setFieldData}
        skin={skin}
      />
      <AddFilterModal
        http={http}
        logout={logout}
        showModal={showModal2}
        setShowModal={setShowModal2}
        formError={formError}
        setFormError={setFormError}
        getFilters={getFilters}
        campaignId={campaignId.id}
        fields={fields}
        filterType={filterType}
        setFilterType={setFilterType}
        filterData={filterData}
        setFilterData={setFilterData}
        skin={skin}
        buyers={buyers}
        suppliers={suppliers}
      />
      <AddTrafficSourceFilterModal
        http={http}
        logout={logout}
        showModal={showTrafficSourceFilterModal}
        setShowModal={setShowTrafficSourceFilterModal}
        formError={formError}
        setFormError={setFormError}
        getFilters={getFilters}
        campaignId={campaignId.id}
        filterType={filterType}
        setFilterType={setFilterType}
        filterData={filterData}
        setFilterData={setFilterData}
        skin={skin}
        fields={fields}
        buyers={buyers}
        suppliers={suppliers}
        trafficSources={trafficSources}
      />
      <div className="main main-app p-4 p-lg-5">
        <div className='flex flex-col gap-y-5'>
          <div className='flex flex-col gap-y-5'>
            <Card className="flex-1">
              <Card.Header className={`flex flex-col sm:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${!skin && "bg-primary"}`}>
                <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                  <Link to="/campaigns" className="px-2 py-2 text-white font-semibold hover:underline">Campaigns</Link>
                  <FaLongArrowAltRight />
                  <Form.Control className="w-fit" name="campaign__name" onChange={e => handleFieldChange(e)} ref={campaignNameRef} value={campaignTitle} type="text" placeholder="Enter campaign name" />
                </div>

                {
                  hasAccess.canUpdateCampaign &&
                  <div className="flex items-center gap-x-3 gap-y-3 campaign__headerBtns">
                    <button onClick={handleCampaignUpdate} type="button" className="font-medium flex-1 sm:flex-auto font-medium flex justify-center items-center gap-x-1 px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                      {
                        !isCampaignSaving &&
                        <BiSave />
                      }
                      Save
                      {
                        isCampaignSaving && <Spinner animation="border" variant="light" size="sm" />
                      }
                    </button>
                  </div>
                }
              </Card.Header>
            </Card>

            {
              hasAccess.canViewDistribution && distributions?.length > 0 &&
              <Card>
                <Card.Body>
                  {
                    isDistributionTypeLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      <div className="flex w-full flex-1 flex-col gap-y-1.5">
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Distribution Type</Form.Label>
                        <Form.Select name="distribution__type" className="flex-1" ref={distributionTypeRef} value={distributionType ? distributionType : ""} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                          {
                            distributions.map(dist => (
                              <option key={dist.id} value={dist.id}>{dist.title}</option>
                            ))
                          }
                        </Form.Select>
                      </div>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewCampaignPageUrl && campaignPageUrls?.length > 0 &&
              <Card>
                <Card.Body>
                  {
                    isCampaignPageUrlLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      <div className="flex flex-col gap-y-3">
                        {
                          campaignPageUrls.map((cp, index) => (
                            <Form.Group key={index} className={`card-post my-react-select-container ${skin && "dark__mode"}`}>
                              <Form.Label htmlFor="campaign__pageUrl" className={`capitalize font-medium ${skin && "text-stone-400"}`}>{cp.channel_name} Link/URL</Form.Label>
                              <Form.Control defaultValue={cp.page_url} type="url" placeholder="Enter campaign link/url" readOnly={!hasAccess.canUpdateCampaign} disabled={!hasAccess.canUpdateCampaign} />
                            </Form.Group>
                          ))
                        }
                      </div>
                  }
                </Card.Body>
              </Card>
            }


            {
              hasAccess.canViewTrafficSources &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Traffic Sources</div>
                  <div className="flex items-center gap-x-3">
                    <Link to={`/create-traffic-source/${campaignId.id}/`} className={`flex font-medium items-center gap-x-1 ${skin ? "text-stone-400 hover:text-stone-300" : "text-stone-600 hover:text-sky-600"} transition-colors duration-200 ease-linear`}>
                      <FiPlus />
                      Add Traffic Source
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isTrafficSourceLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      trafficSources.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Created at</th>
                                {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Source ID</th> */}
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Channel Name</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Client</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Posted Leads</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Payout</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                trafficSources.map((ts) => (
                                  <tr key={ts.id}>
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`title__td ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          ts.id
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          moment(ts.created_at).format("ll")
                                        }
                                      </Link>
                                    </td>
                                    {/* <td className='px-3 align-middle'>
                                    <Link to={`/edit-traffic-source/${ts.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                      {
                                        ts.source_id ?
                                          ts.source_id :
                                          <p className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            -
                                          </p>
                                      }
                                    </Link>
                                  </td> */}
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          ts.traffic_channel ?
                                            ts.traffic_channel.channel_name :
                                            <p className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                              -
                                            </p>
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`title__td ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          ts.client ?
                                            ts.client.company_name[0]?.toUpperCase() + ts.client.company_name?.slice(1,) :
                                            <p className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                              -
                                            </p>
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          ts.total_posted ?
                                            ts.total_posted : 0
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link to={`/edit-traffic-source/${ts.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          ts.pricing_type === "PERCENTAGE" ? `${ts.price}%` : `$${ts.price}`
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        <Dropdown className="static">
                                          <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                            <IoSettingsOutline className='text-lg' />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item>
                                              <Link to={`/edit-traffic-source/${ts.id}/`} className='text-inherit flex w-full items-center gap-x-3'>
                                                <MdOutlineEdit className="text-lg text-stone-500" />
                                                Edit
                                              </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item>
                                              <button onClick={() => handleTrafficSourceDelete(ts.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                <MdDeleteOutline className="text-lg text-stone-500" />
                                                Delete
                                              </button>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      }
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewSuppliers &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Suppliers</div>
                  <div className="flex items-center gap-x-3">
                    <Form onSubmit={handleSearchSupplier}>
                      <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                        <Form.Control ref={searchSupplierRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search supplier..." />
                        <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                          <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                        </button>
                      </Form.Group>
                    </Form>
                    <Link to={`/create-supplier/${campaignId.id}/`} className={`flex font-medium items-center gap-x-1 ${skin ? "text-stone-400 hover:text-stone-300" : "text-stone-600 hover:text-sky-600"} transition-colors duration-200 ease-linear`}>
                      <FiPlus />
                      Add Supplier
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isSupplierLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      suppliers.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Supplier</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Selling Price</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Posted</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Source</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Status</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                suppliers.map(supplier => (
                                  <tr key={supplier.id}>
                                    <th scope="row" className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Link href={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {supplier.id}
                                      </Link>
                                    </th>
                                    <td className={`px-3 align-middle title__td ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      {
                                        supplier.client ?
                                          <Link to={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            {
                                              supplier.client?.company_name[0]?.toUpperCase() + supplier.client?.company_name?.slice(1,)
                                            }
                                          </Link> :
                                          <Link to={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            -
                                          </Link>
                                      }
                                    </td>
                                    <td className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Link to={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          supplier.pricing_type === "Percentage" ?
                                            supplier.margin === 0 ? "∞" : `${supplier.margin}%` :
                                            supplier.price === 0 ? "∞" : `$${supplier.price}`
                                        }
                                      </Link>
                                    </td>
                                    <td className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Link to={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {supplier.total_request_sent ? supplier.total_request_sent : 0}
                                      </Link>
                                    </td>
                                    <td className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Link to={`/edit-supplier/${supplier.id}/`} className={`p-1.5 font-medium text-white rounded-md ${supplier.source === "INTERNAL" ? "bg-success" : "bg-warning"}`}>
                                        {supplier.source}
                                      </Link>
                                    </td>
                                    <td className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Link to={`/edit-supplier/${supplier.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'} ${supplier.is_active ? "bg-success" : "bg-danger"} p-1.5 text-white rounded-md`}>
                                        {supplier.is_active ? "Resume" : "Pause"}
                                      </Link>
                                    </td>
                                    <td className={`px-3 align-middle ${!supplier.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                      <Dropdown className="static">
                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                          <IoSettingsOutline className='text-lg' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <Link to={`/edit-supplier/${supplier.id}/`} className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdOutlineEdit className="text-lg text-stone-500" />
                                              Edit
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <button onClick={() => toggleActiveSupplier(supplier)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                              {
                                                supplier.is_active == null || supplier.is_active ?
                                                  <>
                                                    <FaRegCirclePause className="text-lg text-stone-500" />
                                                    Pause
                                                  </> :
                                                  <>
                                                    <IoPlayCircleOutline className="text-lg text-stone-500" />
                                                    Resume
                                                  </>
                                              }
                                            </button>
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item>
                                            <button onClick={() => deleteSupplier(supplier.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdDeleteOutline className="text-lg text-stone-500" />
                                              Delete
                                            </button>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewBuyers &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Buyers</div>
                  <div className="flex items-center gap-x-3">
                    <Form onSubmit={handleSearchBuyer}>
                      <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                        <Form.Control ref={searchBuyerRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search buyer..." />
                        <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                          <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                        </button>
                      </Form.Group>
                    </Form>
                    <Link to={`/create-buyer/${campaignId.id}/`} className={`flex font-medium items-center gap-x-1 ${skin ? "text-stone-400 hover:text-stone-300" : "text-stone-600 hover:text-sky-600"} transition-colors duration-200 ease-linear`}>
                      <FiPlus />
                      Add Buyer
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isBuyerLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      buyers.length > 0 ?
                        <>
                          <DragDropContext onDragEnd={onBuyerDragEnd}>
                            <Droppable droppableId='buyer' type="group">
                              {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                    <thead>
                                      <tr>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap text-center font-semibold">#</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Buyer Name</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Buyer Type</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Phone Number Format</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Buying Price</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Sell</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Status</th>
                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        buyers.map((buyer, index) => (
                                          <Draggable draggableId={`${buyer.id}`} key={buyer.id} index={index}>
                                            {(provided) => (
                                              <tr {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <div className="flex items-center gap-x-3 justify-center">
                                                    <i className={`${skin ? "text-stone-300" : "text-stone-600"} ri-arrow-up-down-line`}></i>
                                                    <p className={`mb-0 ${skin && "text-stone-300"}`}>#{index + 1}</p>
                                                  </div>
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                    {buyer.id}
                                                  </Link>
                                                </td>
                                                <td className={`px-3 align-middle title__td ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  {
                                                    buyer.client ?
                                                      <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                        {
                                                          buyer.client?.company_name[0]?.toUpperCase() + buyer.client?.company_name?.slice(1,)
                                                        }
                                                      </Link> :
                                                      <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                        -
                                                      </Link>
                                                  }
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                    {buyer.buyer_type}
                                                  </Link>
                                                </td>
                                                <td className={`px-3 align-middle title__td ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  {
                                                    buyer.phone_number_format ?
                                                      <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                        {
                                                          buyer.phone_number_format
                                                        }
                                                      </Link> :
                                                      <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                        -
                                                      </Link>
                                                  }
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                    {
                                                      buyer.price === 0 ? "∞" : `$${buyer.price}`
                                                    }
                                                  </Link>
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                    {buyer.total_sell}
                                                  </Link>
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Link to={`/edit-buyer/${buyer.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'} ${buyer.is_active ? "bg-success" : "bg-danger"} p-1.5 text-white rounded-md`}>
                                                    {buyer.is_active ? "Resume" : "Pause"}
                                                  </Link>
                                                </td>
                                                <td className={`px-3 align-middle ${!buyer.is_active && `${skin ? "bg-rose-500" : "bg-rose-200"}`}`}>
                                                  <Dropdown className="static">
                                                    <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                      <IoSettingsOutline className='text-lg' />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                      <Dropdown.Item>
                                                        <Link to={`/edit-buyer/${buyer.id}/`} className='text-inherit flex w-full items-center gap-x-3'>
                                                          <MdOutlineEdit className="text-lg text-stone-500" />
                                                          Edit
                                                        </Link>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item>
                                                        <button onClick={() => toggleActiveBuyer(buyer)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                          {
                                                            buyer.is_active == null || buyer.is_active ?
                                                              <>
                                                                <FaRegCirclePause className="text-lg text-stone-500" />
                                                                Pause
                                                              </> :
                                                              <>
                                                                <IoPlayCircleOutline className="text-lg text-stone-500" />
                                                                Resume
                                                              </>
                                                          }
                                                        </button>
                                                      </Dropdown.Item>
                                                      <Dropdown.Divider />
                                                      <Dropdown.Item>
                                                        <button onClick={() => deleteBuyer(buyer.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                          <MdDeleteOutline className="text-lg text-stone-500" />
                                                          Delete
                                                        </button>
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))
                                      }
                                      {provided.placeholder}
                                    </tbody>
                                  </Table>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewCampaignFields &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Campaign Fields</div>
                  {
                    hasAccess.canAddCampaignFields &&
                    <div className="flex items-center gap-x-3">
                      <button onClick={() => toggleFieldModal(true)} type="button" className="font-medium cursor-pointer px-2.5 py-1.5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                        Add Fields
                      </button>
                    </div>
                  }
                </Card.Header>
                <Card.Body>
                  {
                    isFieldLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      fields.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Field Name</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Field Type</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Status</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Field Visibility</th>
                                {
                                  hasAccess.canUpdateCampaignFields &&
                                  <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                showAllFields ?
                                  fields?.map((field, index) => (
                                    <tr key={index}>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'} title__td`}>
                                          {
                                            field.field_name
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${field.field_type === "SYSTEM GENERATED" ? `font-medium ${skin ? "bg-slate-700 text-stone-300" : "bg-stone-300 text-stone-500"} p-2 rounded-md` : "text-inherit"} ${skin ? "text-stone-300" : ""} whitespace-nowrap`}>
                                          {
                                            field.field_type
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                          {
                                            field.field_type === "SYSTEM GENERATED" ? "required" : field.post_status.toLowerCase()
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                          {field.field_visiblity ? "True" : "False"}
                                        </Link>
                                      </td>
                                      {
                                        hasAccess.canUpdateCampaignFields &&
                                        <td className='px-3 align-middle'>
                                          {
                                            field.field_type !== "SYSTEM GENERATED" ?
                                              <Dropdown className="static">
                                                <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                  <IoSettingsOutline className='text-lg' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item>
                                                    <button onClick={() => fetchFieldData(field.id)} className='text-inherit flex w-full items-center gap-x-3'>
                                                      <MdOutlineEdit className="text-lg text-stone-500" />
                                                      Edit
                                                    </button>
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item>
                                                    <button onClick={() => handleFieldDelete(field.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                      <MdDeleteOutline className="text-lg text-stone-500" />
                                                      Delete
                                                    </button>
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown> : <p className="invisible py-1.5">-</p>
                                          }
                                        </td>
                                      }
                                    </tr>
                                  )) :
                                  fields.slice(0, MAX_NUMBER_OF_FIELDS)?.map((field, index) => (
                                    <tr key={index}>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'} title__td`}>
                                          {
                                            field.field_name
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${field.field_type === "SYSTEM GENERATED" ? `font-medium ${skin ? "bg-slate-700 text-stone-300" : "bg-stone-300 text-stone-500"} p-2 rounded-md` : "text-inherit"} ${skin ? "text-stone-300" : ""} whitespace-nowrap`}>
                                          {
                                            field.field_type
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                          {
                                            field.field_type === "SYSTEM GENERATED" ? "required" : field.post_status.toLowerCase()
                                          }
                                        </Link>
                                      </td>
                                      <td className='px-3 align-middle'>
                                        <Link to="#" onClick={field.field_type === "SYSTEM GENERATED" ? () => forbadeEditingField() : () => fetchFieldData(field.id)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                          {field.field_visiblity ? "True" : "False"}
                                        </Link>
                                      </td>
                                      {
                                        hasAccess.canUpdateCampaignFields &&
                                        <td className='px-3 align-middle'>
                                          {
                                            field.field_type !== "SYSTEM GENERATED" ?
                                              <Dropdown className="static">
                                                <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                  <IoSettingsOutline className='text-lg' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item>
                                                    <button onClick={() => fetchFieldData(field.id)} className='text-inherit flex w-full items-center gap-x-3'>
                                                      <MdOutlineEdit className="text-lg text-stone-500" />
                                                      Edit
                                                    </button>
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item>
                                                    <button onClick={() => handleFieldDelete(field.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                      <MdDeleteOutline className="text-lg text-stone-500" />
                                                      Delete
                                                    </button>
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown> : <p className="invisible py-1.5">-</p>
                                          }
                                        </td>
                                      }
                                    </tr>
                                  ))
                              }
                            </tbody>
                          </Table>

                          {
                            !showAllFields &&
                            <button type="button" className={`mt-3 rounded-md px-3 py-1.5 w-full ${skin ? "bg-slate-700 hover:bg-slate-600 text-stone-300" : "bg-stone-100 hover:bg-stone-200"} flex items-center justify-center gap-x-1 font-medium transition-colors duration-200 transition-colors duration-200 ease-linear`} onClick={() => setShowAllFields(true)}>
                              <>
                                <p className="mb-0">Show All</p>
                                <FaChevronDown />
                              </>
                            </button>
                          }
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewTrafficSourceFilters &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Traffic Source Filters</div>
                  <div className="flex items-center gap-x-3">
                    <button type="button" onClick={() => toggleFilterModal({ state: true, filter: "TRAFFIC SOURCE LEVEL" })} className="flex items-center justify-center gap-x-1 font-medium cursor-pointer px-2.5 py-1.5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                      <i className="text-lg ri-add-fill"></i>
                      Add
                    </button>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isFilterLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      trafficSourceFilters.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Created At</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Traffic Source</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Filter Type</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Key</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Condition</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Value</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Payout</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Output</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                trafficSourceFilters.map(filter => (
                                  <tr key={filter.id}>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'} title__td`}>
                                        {
                                          moment(filter.created_at).format("LL")
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        <div className="flex flex-col gap-y-1.5">
                                          {
                                            filter.traffic_source?.map((ts) => (
                                              <div key={ts.id} className="flex items-center gap-x-1.5">
                                                <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] bg-stone-600 rounded-full"></div>
                                                <p className="whitespace-nowrap mb-0">{`${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`}</p>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.filter_type
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.key ?
                                          <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            {
                                              filter.key
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.conditions
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.value ? filter.value :
                                            <>
                                              {filter?.multi_values.map((mv, index) => (
                                                <div key={index} className="flex items-center gap-x-1.5">
                                                  <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] rounded-full bg-stone-600"></div>
                                                  <p className="my-0">
                                                    {mv.value_title}
                                                  </p>
                                                </div>
                                              ))}
                                            </>
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.payout ?
                                          <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            ${
                                              filter.payout
                                            }
                                          </Link> : <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.result ?
                                          <Link onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} to="#" className={`${filter.result === "REJECT LEADS" ? "bg-danger" : "bg-success text"} text-sm rounded-md p-1.5 font-medium text-center text-white whitespace-nowrap`}>
                                            {
                                              filter.result === "REJECT LEADS" ? "Reject Leads" : "Accept Leads"
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Dropdown className="static">
                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                          <IoSettingsOutline className='text-lg' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <button onClick={() => fetchFilterData(filter.id, "TRAFFIC SOURCE LEVEL")} className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdOutlineEdit className="text-lg text-stone-500" />
                                              Edit
                                            </button>
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item>
                                            <button onClick={() => deleteFilter(filter.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdDeleteOutline className="text-lg text-stone-500" />
                                              Delete
                                            </button>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewSupplierFilters &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Supplier Filters</div>
                  <div className="flex items-center gap-x-3">
                    <button type="button" onClick={() => toggleFilterModal({ state: true, filter: "SUPPLIER LEVEL" })} className="flex items-center justify-center gap-x-1 font-medium cursor-pointer px-2.5 py-1.5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                      <i className="text-lg ri-add-fill"></i>
                      Add
                    </button>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isFilterLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      supplierFilters.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Created At</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Suppliers</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Filter Type</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Key</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Condition</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Value</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Payout</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Output</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                supplierFilters.map(filter => (
                                  <tr key={filter.id}>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'} title__td`}>
                                        {
                                          moment(filter.created_at).format("LL")
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        <div className="flex flex-col gap-y-1.5">
                                          {
                                            filter.suppliers?.map((supplier, index) => (
                                              <div key={supplier.id} className="flex items-center gap-x-1.5">
                                                <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] bg-stone-600 rounded-full"></div>
                                                <p className="mb-0 whitespace-nowrap">{supplier?.client?.company_name[0].toUpperCase() + supplier?.client?.company_name.slice(1,)}</p>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.filter_type
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.key ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            {
                                              filter.key
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.conditions
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          (filter.value && filter.key !== "buyer") ? filter.value :
                                            <>
                                              {filter?.multi_values.map((mv, index) => (
                                                <div key={index} className="flex items-center gap-x-1.5">
                                                  <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] rounded-full bg-stone-600"></div>
                                                  <p className="my-0">
                                                    {mv.value_title}
                                                  </p>
                                                </div>
                                              ))}
                                            </>
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.payout ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            ${
                                              filter.payout
                                            }
                                          </Link> : <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.result ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${filter.result === "REJECT LEADS" ? "bg-danger" : "bg-success text"} text-sm rounded-md p-1.5 font-medium text-center text-white whitespace-nowrap`}>
                                            {
                                              filter.result === "REJECT LEADS" ? "Reject Leads" : "Accept Leads"
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Dropdown className="static">
                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                          <IoSettingsOutline className='text-lg' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <button onClick={() => fetchFilterData(filter.id)} className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdOutlineEdit className="text-lg text-stone-500" />
                                              Edit
                                            </button>
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item>
                                            <button onClick={() => deleteFilter(filter.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdDeleteOutline className="text-lg text-stone-500" />
                                              Delete
                                            </button>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewBuyerFilters &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Buyer Filters</div>
                  <div className="flex items-center gap-x-3">
                    <button type="button" onClick={() => toggleFilterModal({ state: true, filter: "BUYER LEVEL" })} className="flex items-center justify-center gap-x-1 font-medium cursor-pointer px-2.5 py-1.5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                      <i className="text-lg ri-add-fill"></i>
                      Add
                    </button>
                  </div>
                </Card.Header>
                <Card.Body>
                  {
                    isFilterLoading ?
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div> :
                      buyerFilters.length > 0 ?
                        <>
                          <Table variant={skin && "dark"} hover className="mb-0" responsive>
                            <thead>
                              <tr>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Created At</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Buyers</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Filter Type</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Key</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Condition</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Value</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Payout</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Output</th>
                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                buyerFilters.map(filter => (
                                  <tr key={filter.id}>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'} title__td`}>
                                        {
                                          moment(filter.created_at).format("LL")
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        <div className="flex flex-col gap-y-1.5">
                                          {
                                            filter.buyers?.map((buyer, index) => (
                                              <div key={buyer.id} className="flex items-center gap-x-1.5">
                                                <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] bg-stone-600 rounded-full"></div>
                                                <p className="mb-0 whitespace-nowrap">{buyer?.client?.company_name[0].toUpperCase() + buyer?.client?.company_name.slice(1,)}</p>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.filter_type
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.key ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            {
                                              filter.key
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.conditions
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                        {
                                          filter.value ? filter.value :
                                            <>
                                              {filter?.multi_values.map((mv, index) => (
                                                <div key={index} className="flex items-center gap-x-1.5">
                                                  <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] rounded-full bg-stone-600"></div>
                                                  <p className="my-0">
                                                    {mv.value_title}
                                                  </p>
                                                </div>
                                              ))}
                                            </>
                                        }
                                      </Link>
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.payout ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                            ${
                                              filter.payout
                                            }
                                          </Link> : <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      {
                                        filter.result ?
                                          <Link onClick={() => fetchFilterData(filter.id)} to="#" className={`${filter.result === "REJECT LEADS" ? "bg-danger" : "bg-success text"} text-sm rounded-md p-1.5 font-medium text-center text-white whitespace-nowrap`}>
                                            {
                                              filter.result === "REJECT LEADS" ? "Reject Leads" : "Accept Leads"
                                            }
                                          </Link> :
                                          <p className="mb-0 text-center font-medium">-</p>
                                      }
                                    </td>
                                    <td className='px-3 align-middle'>
                                      <Dropdown className="static">
                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                          <IoSettingsOutline className='text-lg' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <button onClick={() => fetchFilterData(filter.id)} className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdOutlineEdit className="text-lg text-stone-500" />
                                              Edit
                                            </button>
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item>
                                            <button onClick={() => deleteFilter(filter.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                              <MdDeleteOutline className="text-lg text-stone-500" />
                                              Delete
                                            </button>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                  }
                </Card.Body>
              </Card>
            }

            {
              hasAccess.canViewDupeCheckerSettings &&
              <Card>
                <Card.Header className={`${!skin && "bg-white"} flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}>
                  <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Settings</div>
                </Card.Header>
                <Card.Body className={`border-b ${skin ? "border-stone-500" : "border-stone-200"}`}>
                  <div className="flex gap-x-3">
                    <div onClick={() => setShowDupeChecker(prevState => !prevState)} className={`cursor-pointer relative min-w-11 h-6 rounded-full ${showDupeChecker ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                      <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showDupeChecker ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                    </div>
                    <div className="flex flex-col gap-y-1.5">
                      <div className="flex flex-wrap items-center gap-x-2">
                        <h5 className={`font-semibold ${skin ? "text-stone-300" : "text-stone-700"}`}>Dupe Checker</h5>
                      </div>
                      <p className={`${skin ? "text-stone-400" : "text-stone-500"} mb-0`}>
                        When this feature is enabled, the Dupe Checker will look back the specified [Number of Days] to see if an incoming lead's [Dupe Check Fields] match a lead that's already been ingested in your campaign. If there's a match, the incoming lead will be rejected and labeled a duplicate.
                      </p>
                    </div>
                  </div>
                </Card.Body>
                {
                  showDupeChecker &&
                  <>
                    <Card.Body className="card-post">
                      <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                        <Form.Group>
                          <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Number of Days</Form.Label>
                          <Form.Control name="dupe__numofDays" min={1} value={dupeNumOfDays} onChange={e => setDupeNumOfDays(e.target.value)} type="number" placeholder="Enter number of days" />
                        </Form.Group>
                        <Form.Group className={`flex-1 my-react-select-container ${skin && "dark__mode"}`}>
                          <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Dupe Check Fields</Form.Label>
                          <Select
                            isClearable
                            isMulti
                            options={colors2}
                            value={dupeCheckFields}
                            onChange={setDupeCheckFields}
                            placeholder="Select fields"
                            classNamePrefix={"my-react-select"}
                            isSearchable
                            className="w-full"
                            noOptionsMessage={() => "No match found."}
                          />
                        </Form.Group>
                        <Form.Group className={`flex-1 my-react-select-container ${skin && "dark__mode"}`}>
                          <label className={`font-medium mb-[0.1rem] ${skin && "text-stone-400"} flex gap-x-3`}>
                            Dupe Check Scope
                            {/* <i className={`ri-information-fill mt-[-0.1rem] cursor-pointer ${skin ? "text-slate-500 hover:text-slate-400" : "text-stone-400 hover:text-stone-500"} text-lg`}></i> */}
                          </label>
                          <Select
                            options={DUPE_CHECK_SCOPES}
                            value={dupeCheckScope}
                            onChange={setDupeCheckScope}
                            placeholder="Select scope"
                            classNamePrefix={"my-react-select"}
                            className="w-full"
                            isSearchable
                            noOptionsMessage={() => "No match found."}
                          />
                        </Form.Group>
                      </div>
                    </Card.Body>
                  </>
                }
              </Card>
            }
          </div>
        </div>
      </div>
    </>
  );
}
