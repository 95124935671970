import React, { useRef } from 'react'
import SidebarMenu from './SidebarMenu'
import SidebarHeader from './SidebarHeader'
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarFooter from './SidebarFooter';

const Sidebar = () => {
    const perfectScrollbarRef = useRef(null);

    return (
        <div className="sidebar">
            <SidebarHeader />
            <PerfectScrollbar className="sidebar-body" ref={perfectScrollbarRef}>
                <SidebarMenu onUpdateSize={() => perfectScrollbarRef.current?.updateScroll()} />
            </PerfectScrollbar>
            <SidebarFooter />
        </div>
    )
}

export default Sidebar