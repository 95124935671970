import React from 'react'
import { useEffect, useState, useRef } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import AuthUtils from "../Utils/AuthUtils";
import { Link } from "react-router-dom";
import { PAGE_LIMIT_SIZE } from '../Utils/baseConfig'
import Header from '../../layouts/Header'
import HeaderMobile from '../../layouts/HeaderMobile'
import Sidebar from "../../layouts/Sidebar";
import { Card, Dropdown, Form, Spinner, Table } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import AddReasonModal from '../../Components/RejectionReason/AddReasonModal'
import ReasonDetailModal from '../../Components/RejectionReason/ReasonDetailModal'
import { GoInfo } from "react-icons/go";
import { useAuth } from '../../Context/AuthContext'


export default function ReasonList() {
    const [loading, setLoading] = useState([false]);
    const [showModal, setShowModal] = useState(false)
    const [reasons, setReasons] = useState([]);
    const reasonNameRef = useRef();
    const [page, setPage] = useState(1);
    const [formError, setFormError] = useState({
        errId: -1
    })
    const [selectedReason, setSelectedReason] = useState(null);
    const [totalReasons, setTotalReasons] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [reasonDetails, setReasonDetails] = useState(null);
    const [showReasonDetailModal, setShowReasonDetailModal] = useState(false);

    const [authData, setAuthData] = useAuth();
    const [hasAccess, setHasAccess] = useState({
        canDelete: false,
        canEdit: false,
        canViewBuyer: false
    });

    const searchTextRef = useRef();

    const { http, logout } = AuthUtils()

    const getReasons = async (selPage) => {
        setLoading(true);
        await http.get(`/api/reasons/?page=${selPage}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                const { data, total } = response.data;

                setReasons(data);
                setTotalReasons(total);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }

                // console.log(error)
                setLoading(false)
                setReasons([]);
            })

        setLoading(false)
    }

    const handleRejectionDelete = async (reasonId) => {
        await http.delete(`/api/delete-reason/${reasonId}/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                toast.success("Reason is removed successfully.");
                getReasons(page)
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
            })
    }

    async function getCampaigns() {
        try {
            const res = await http.get("/api/all-campaigns", {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                let campaignArr = [];

                res.data.data?.map(camp => {
                    campaignArr.push({
                        ...camp,
                        value: camp.id,
                        label: camp.campaign_title
                    })
                })

                setCampaigns(campaignArr);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch campaigns!");
        }
    }

    useEffect(() => {
        if (authData) {
            const { rolenames } = authData;

            if (rolenames.includes("SUPER ADMIN") || rolenames.includes("ADMIN") || rolenames.includes("DEVELOPER")) {
                setHasAccess({
                    canEdit: true,
                    canDelete: true,
                    canViewBuyer: true
                });
            }

            getCampaigns();
            getReasons(page)
        }

        return () => {
            setTotalReasons(0);
            setPage(1);
        }
    }, [authData])

    const toggleModal = (modalStatus) => {
        setShowModal(modalStatus);
        setFormError({ errId: -1 });
        if (modalStatus) {
            setTimeout(() => {
                reasonNameRef.current?.focus();
            }, 50);
        }
    }

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const handleSearchReason = async (e) => {
        e.preventDefault();
        setPage(1);
        setReasons([]);
        setLoading(true);

        const searchText = searchTextRef.current.value.trim();
        // console.log("SEARCHED", searchText)

        try {
            const res = await http.post("/api/search-reason/?page=1", {
                query: searchText
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const { data } = res.data;
            setReasons(data);
            setLoading(false);
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }

            // console.log(err);
            setLoading(false);
            toast.error("Something went wrong!");
        }
    }

    const handlePageUpdate = async (selPage) => {
        setPage(selPage);
        await getReasons(selPage);
    }

    const handleReasonSelected = async (reasonData) => {
        setReasonDetails(reasonData);
        setShowReasonDetailModal(true);
        // Edit reason modal
        // setSelectedReason(reasonData);
        // setShowModal(true);
    }

    const handleEditReason = reasonData => {
        setSelectedReason(reasonData);
        setShowModal(true);
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />
            <AddReasonModal
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                http={http}
                skin={skin}
                logout={logout}
                getReasons={getReasons}
                page={page}
                showModal={showModal}
                setShowModal={setShowModal}
                formError={formError}
                setFormError={setFormError}
                allCampaigns={campaigns}
            />
            <ReasonDetailModal
                http={http}
                reasonDetails={reasonDetails}
                setReasonDetails={setReasonDetails}
                showModal={showReasonDetailModal}
                setShowModal={setShowReasonDetailModal}
                skin={skin}
            />
            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3'>
                        <div className='text-xl font-semibold'>Rejections</div>
                        <div className='flex items-center gap-x-3'>
                            <div className='card-post flex-1 sm:flex-auto'>
                                <Form onSubmit={handleSearchReason}>
                                    <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                                        <Form.Control ref={searchTextRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search reason..." />
                                        <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                                            <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                                        </button>
                                    </Form.Group>
                                </Form>
                            </div>

                            <button onClick={() => toggleModal(true)} type="button" className="font-medium cursor-pointer px-2.5 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                                Add New Reason
                            </button>
                        </div>
                    </div>

                    <Card>
                        <Card.Body>
                            {
                                loading ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                    reasons.length > 0 ?
                                        <>
                                            <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Reason Name</th>
                                                        {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Campaign</th> */}
                                                        {
                                                            hasAccess.canViewBuyer &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Buyers</th>
                                                        }
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Status</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        reasons.map(reason => (
                                                            <tr key={reason.id}>
                                                                <th scope="row" className='px-3 align-middle'>
                                                                    <Link href={`#`} onClick={() => handleReasonSelected(reason)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {reason.id}
                                                                    </Link>
                                                                </th>
                                                                <td className='px-3 align-middle title__td'>
                                                                    <Link to={`#`} onClick={() => handleReasonSelected(reason)} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {reason.reason_name}
                                                                    </Link>
                                                                </td>
                                                                {/* <td className='px-3 align-middle'>
                                                                    <Link to={`#`} onClick={() => handleReasonSelected(reason)} className={`${skin ? 'text-stone-300' : 'text-inherit'} flex flex-col gap-3`}>
                                                                        {
                                                                            reason.reason_list?.map((r, rIndex) => (
                                                                                <p key={rIndex} className='mb-0 whitespace-nowrap'>{`${r.lead_return?.campaign?.campaign_title} ${" (" + r.lead_return.id + ")"}`}</p>
                                                                            ))
                                                                        }
                                                                    </Link>
                                                                </td> */}
                                                                {
                                                                    hasAccess.canViewBuyer &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`#`} onClick={() => handleReasonSelected(reason)} className={`${skin ? 'text-stone-300' : 'text-inherit'} flex flex-col gap-3`}>
                                                                            {
                                                                                reason.reason_list?.map((r, rIndex) => (
                                                                                    <p key={rIndex} className='mb-0 whitespace-nowrap'>{`${r?.lead_return?.buyer?.company_name[0]?.toUpperCase() || ""}${r?.lead_return?.buyer?.company_name?.slice(1,) || ""} ${r?.lead_return?.id ? "(" + r?.lead_return?.id + ")" : ""}`}</p>
                                                                                ))
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                <td className='px-3 align-middle'>
                                                                    <Link to={`#`} onClick={() => handleReasonSelected(reason)} className={`${skin ? 'text-stone-300' : 'text-inherit'} flex flex-col gap-2`}>
                                                                        {
                                                                            reason.reason_list?.map((r, rIndex) => (
                                                                                <p key={rIndex} className={`mb-0 whitespace-nowrap text-sm rounded-md p-1.5 font-medium text-white text-center ${r.status === "ACCEPTED" ? "bg-success" : (r.status === "DUPLICATED" || r.status === "PENDING") ? "bg-warning" : r.status === "STORED" ? "bg-primary" : "bg-danger"}`}>{r.status}</p>
                                                                            ))
                                                                        }
                                                                    </Link>
                                                                </td>

                                                                <td className='px-3 align-middle'>
                                                                    <Dropdown className="static">
                                                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                                            <IoSettingsOutline className='text-lg' />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            {
                                                                                hasAccess && hasAccess?.canEdit &&
                                                                                <Dropdown.Item>
                                                                                    <button onClick={() => handleEditReason(reason)} className='text-inherit flex w-full items-center gap-x-3'>
                                                                                        <GoInfo className="text-base" />
                                                                                        Edit
                                                                                    </button>
                                                                                </Dropdown.Item>
                                                                            }
                                                                            <Dropdown.Item>
                                                                                <button onClick={() => handleReasonSelected(reason)} className='text-inherit flex w-full items-center gap-x-3'>
                                                                                    <MdOutlineEdit className="text-lg" />
                                                                                    Details
                                                                                </button>
                                                                            </Dropdown.Item>
                                                                            {
                                                                                hasAccess && hasAccess?.canDelete &&
                                                                                <>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item>
                                                                                        <button onClick={() => handleRejectionDelete(reason.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                                                            <MdDeleteOutline className="text-lg" />
                                                                                            Delete
                                                                                        </button>
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <div className='mt-3 flex justify-end custom__reactPaginate'>
                                                <PaginationControl
                                                    page={page}
                                                    between={3}
                                                    total={totalReasons}
                                                    limit={PAGE_LIMIT_SIZE}
                                                    changePage={(page) => handlePageUpdate(page)}
                                                    ellipsis={5}
                                                />
                                            </div>
                                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
