import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BiSave } from "react-icons/bi";
import AuthUtils from "../Utils/AuthUtils";
import { Link, useParams } from "react-router-dom";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
import { SiTestcafe } from "react-icons/si"
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Form, Spinner } from "react-bootstrap";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import Select from "react-select";
import { IoMdAdd, IoMdInformationCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";


const BUYER_TYPES = [
    {
        value: "CPA",
        label: "CPA"
    },
    {
        value: "CPL",
        label: "CPL"
    }
]

export default function EditBuyer() {
    const { http, logout } = AuthUtils()
    const buyerId = useParams()
    const [isSaving, setIsSaving] = useState(false);

    const [isFetchingData, setIsFetchingData] = useState(true);
    const [loading, isLoading] = useState(false)
    const [clients, setClients] = useState([])
    const [client, setClient] = useState(null);
    const [buyer, setBuyer] = useState("")
    const [leadVolumeDailyCap, setLeadVolumeDailyCap] = useState("")
    const [leadVolumeWeeklyCap, setLeadVolumeWeeklyCap] = useState("")
    const [leadVolumeMonthlyCap, setLeadVolumeMonthlyCap] = useState("")
    const [fields, setFields] = useState([]);
    const [budgetDailyCap, setBudgetDailyCap] = useState("")
    const [budgetWeeklyCap, setBudgetWeeklyCap] = useState("")
    const [budgetMonthlyCap, setBudgetMonthlyCap] = useState("")
    const [deliveryMethod, setDeliveryMethod] = useState("")
    const [postUrl, setPostUrl] = useState("")
    const [pingUrl, setPingUrl] = useState("")
    const [requestMethod, setRequestMethod] = useState("")
    const [payloadType, setPayloadType] = useState("")
    const [body, setBody] = useState("");
    const [pingBody, setPingBody] = useState("");
    const [responseType, setResponseType] = useState("")
    const [price, setPrice] = useState("")
    const [pingAcceptedCondition, setPingAcceptedCondition] = useState("")
    const [pingAcceptedConditionKey, setPingAcceptedConditionKey] = useState("")
    const [pingAcceptedConditionValue, setPingAcceptedConditionValue] = useState("")

    const [pingDuplicateCondition, setPingDuplicateCondition] = useState("")
    const [pingDuplicateKey, setPingDuplicateKey] = useState("")
    const [pingDuplicateValue, setPingDuplicateValue] = useState("")

    const [acceptedCondition, setAcceptedCondition] = useState("")
    const [acceptedConditionKey, setAcceptedConditionKey] = useState("")
    const [acceptedConditionValue, setAcceptedConditionValue] = useState("")
    const [duplicateCondition, setDuplicateCondition] = useState("")
    const [duplicateKey, setDuplicateKey] = useState("")
    const [duplicateValue, setDuplicateValue] = useState("")
    const [timezone, setTimezone] = useState()
    const [timezones, setTimezones] = useState([])
    // headers section
    const [formError, setFormError] = useState({
        errorId: -1
    });
    const [showPricing, setShowPricing] = useState(false);
    const [showRealTimePrice, setShowRealTimePrice] = useState(false);
    const [showValueReplacer, setShowValueReplacer] = useState(false);
    const [realTimePrice, setRealTimePrice] = useState("");
    const [buyerType, setBuyerType] = useState(null);
    const [pingRequestMethod, setPingRequestMethod] = useState("");
    const [pingPayloadType, setPingPayloadType] = useState("");
    const [phoneFormat, setPhoneFormat] = useState(null);

    const PHONE_FORMAT = [
        {
            value: "(XXX)-XXX-XXXX",
            label: "(XXX)-XXX-XXXX"
        },
        {
            value: "XXXXXXXXXX",
            label: "XXXXXXXXXX"
        },
        {
            value: "XXX-XXX-XXXX",
            label: "XXX XXX XXXX"
        },
        {
            value: "+1XXXXXXXXXX",
            label: "+1XXXXXXXXXX"
        },
        {
            value: "1XXXXXXXXXX",
            label: "1XXXXXXXXXX"
        },
    ];

    const [valueReplacerMappers, setValueReplacerMappers] = useState([{
        index: 0,
        field: null,
        isListType: false,
        fieldValArr: [{
            index: 0,
            fieldValue1: {
                value: "",
                label: ""
            },
            fieldValue2: "",
            fieldValueOptions: []
        }]
    }]);
    const [prevValueReplaceMappers, setPrevValueReplaceMappers] = useState(null);

    const [isDirectPostAcceptedResponseSelected, setIsDirectPostAcceptedResponseStatusSelected] = useState(false);
    const [isDirectPostRejectedResponseSelected, setIsDirectPostRejectedResponseStatusSelected] = useState(false);
    const [isPingPostAcceptedResponseStatusSelected, setIsPingPostAcceptedResponseStatusSelected] = useState(false);
    const [isPingPostRejectedResponseStatusSelected, setIsPingPostRejectedResponseStatusSelected] = useState(false);

    const buyerNameRef = useRef();
    const priceRef = useRef();
    const deliveryMethodRef = useRef();
    const postURLRef = useRef();
    const postMethodRef = useRef();
    const payloadTypeRef = useRef();
    const pingBodyEditorRef = useRef();
    const bodyEditorRef = useRef();
    const realTimePriceRef = useRef();

    const pingAcceptConditionRef = useRef();
    const pingAcceptedConditionKeyRef = useRef();
    const pingAcceptedConditionValueRef = useRef();
    const pingDuplicateConditionRef = useRef();
    const pingDuplicateKeyRef = useRef();
    const pingDuplicateValueRef = useRef();

    const acceptConditionRef = useRef();
    const acceptedKeyRef = useRef();
    const acceptedValueRef = useRef();
    const duplicateConditionRef = useRef();
    const duplicateKeyRef = useRef();
    const duplicateValueRef = useRef();
    const pingURLRef = useRef();
    const pingMethodRef = useRef();
    const pingPayloadTypeRef = useRef()

    const [directPostJsonFormatError, setDirectPostJsonFormatError] = useState(false);
    const [pingPostJsonFormatError, setPingPostJsonFormatError] = useState(false);

    const directPostEditorRef = useRef();
    const pingPostEditorRef = useRef();

    const showFormError = ({ errorId }) => {
        setFormError({
            errorId: errorId,
        });

        if (errorId === 1) {
            buyerNameRef.current.classList.add("error__field");
            buyerNameRef.current?.focus();
        } else if (errorId === 2) {
            toast.error("Please select a timezone!");
        } else if (errorId === 3) {
            priceRef.current.classList.add("error__field");
            priceRef.current?.focus();
        } else if (errorId === 4) {
            deliveryMethodRef.current.classList.add("error__field");
            deliveryMethodRef.current?.focus();
        } else if (errorId === 5) {
            postURLRef.current.classList.add("error__field");
            postURLRef.current?.focus();
        } else if (errorId === 6) {
            postMethodRef.current.classList.add("error__field");
            postMethodRef.current?.focus();
        } else if (errorId === 7) {
            payloadTypeRef.current.classList.add("error__field");
            payloadTypeRef.current?.focus();
        } else if (errorId === 8) {
            acceptConditionRef.current.classList.add("error__field");
            acceptConditionRef.current?.focus();
        } else if (errorId === 9) {
            acceptedKeyRef.current.classList.add("error__field");
            acceptedKeyRef.current?.focus();
        } else if (errorId === 10) {
            acceptedValueRef.current.classList.add("error__field");
            acceptedValueRef.current?.focus();
        } else if (errorId === 11) {
            duplicateConditionRef.current.classList.add("error__field");
            duplicateConditionRef.current?.focus();
        } else if (errorId === 12) {
            duplicateKeyRef.current.classList.add("error__field");
            duplicateKeyRef.current?.focus();
        } else if (errorId === 13) {
            duplicateValueRef.current.classList.add("error__field");
            duplicateValueRef.current?.focus();
        } else if (errorId === 14) {
            pingURLRef.current.classList.add("error__field");
            pingURLRef.current?.focus();
        } else if (errorId === 15) {
            toast.error("Please select a buyer type!");
        } else if (errorId === 16) {
            pingMethodRef.current.classList.add("error__field");
            pingMethodRef.current?.focus();
        } else if (errorId === 17) {
            pingPayloadTypeRef.current.classList.add("error__field");
            pingPayloadTypeRef.current?.focus();
        }
    }


    const fetchBuyerDetails = async () => {
        await http.get(`/api/fetch-buyer/${buyerId.id}/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async (response) => {
                const { client, body, xml_body, buyer_type, phone_number_format, timezone: buyerTimezone, delivery_method, mapped_price_field, payload_type } = response.data.data;

                if (client) {
                    setClient({
                        value: client.id,
                        label: `${client.company_name[0].toUpperCase()}${client.company_name.slice(1,)}`
                    })
                }

                if (buyer_type) {
                    setBuyerType({
                        value: buyer_type,
                        label: buyer_type
                    })
                }

                if (phone_number_format) {
                    setPhoneFormat({
                        value: phone_number_format,
                        label: phone_number_format
                    })
                }

                if (delivery_method && (delivery_method === "DIRECT POST" || delivery_method === "PING POST")) {
                    if (mapped_price_field) {
                        setRealTimePrice(mapped_price_field);
                        setShowRealTimePrice(true);
                    }
                }

                if (payload_type === "Form") {
                    try {
                        setBody(await convertJsonToForm(body));
                    } catch (err) { }
                } else if (payload_type === "JSON") {
                    // setting initial body value
                    setBody(JSON.stringify(body))
                } else if (payload_type === "XML") {
                    setBody(xml_body);
                }

                // setting buyername
                setBuyer(response.data.data);
                // setBuyerName(response.data.data)

                let fieldArr = [];
                response.data.data.campaign?.fields?.map(f => {
                    fieldArr.push({
                        ...f,
                        value: f.id,
                        label: f.field_name
                    })
                })
                setFields(fieldArr);

                await getValueMappers(fieldArr);

                // setting initial value for lead volume daily cap
                setLeadVolumeDailyCap(response.data.data.lead_volume_daily_cap)
                setLeadVolumeWeeklyCap(response.data.data.lead_volume_weekly_cap)
                setLeadVolumeMonthlyCap(response.data.data.lead_volume_monthly_cap)
                // setting initial value for budget cap
                setBudgetDailyCap(response.data.data.budget_daily_cap)
                setBudgetWeeklyCap(response.data.data.budget_weekly_cap)
                setBudgetMonthlyCap(response.data.data.budget_monthly_cap)
                // setting initial delivery method
                setDeliveryMethod(response.data.data.delivery_method)
                // setting post url
                setPostUrl(response.data.data.post_url)
                // setting ping url
                setPingUrl(response.data?.data?.ping_url)
                setPingRequestMethod(response.data?.data?.ping_request_method);
                setPingPayloadType(response.data?.data?.ping_payload_type);
                setPayloadType(payload_type);

                // setting ping accept condition
                setPingAcceptedCondition(response.data.data.ping_accepted_condition)
                if (response.data.data.ping_accepted_condition === "RESPONSE EQUAL" || response.data.data.ping_accepted_condition === "RESPONSE CONTAINS") {
                    setIsPingPostAcceptedResponseStatusSelected(true);
                } else {
                    setIsPingPostAcceptedResponseStatusSelected(false);
                }
                setPingAcceptedConditionKey(response.data.data.ping_accepted_key)
                setPingAcceptedConditionValue(response.data.data.ping_accepted_value)

                // setting ping duplication
                setPingDuplicateCondition(response.data.data.ping_duplicate_condition)
                if (response.data.data.ping_duplicate_condition === "RESPONSE EQUAL" || response.data.data.ping_duplicate_condition === "RESPONSE CONTAINS") {
                    setIsPingPostRejectedResponseStatusSelected(true);
                } else {
                    setIsPingPostRejectedResponseStatusSelected(false);
                }
                setPingDuplicateKey(response.data.data.ping_duplicate_key)
                setPingDuplicateValue(response.data.data.ping_duplicate_value)

                // setting initial request method value
                setRequestMethod(response.data.data.request_method)
                // setting initial payload value
                setPayloadType(response.data.data.payload_type)

                setPingBody(response.data.data.ping_body)
                // setting initial response type value
                setResponseType(response.data.data.response_type)
                // setting initial price value
                setPrice(response.data.data.price)
                setShowPricing(response.data.data.show_price);

                // setting initial accepted condition
                setAcceptedCondition(response.data.data.accepted_condition)
                if (response.data.data.accepted_condition === "RESPONSE EQUAL" || response.data.data.accepted_condition === "RESPONSE CONTAINS") {
                    setIsDirectPostAcceptedResponseStatusSelected(true);
                } else {
                    setIsDirectPostAcceptedResponseStatusSelected(false);
                }

                setAcceptedConditionKey(response.data.data.accepted_condition_key)
                setAcceptedConditionValue(response.data.data.accepted_condition_value)

                // duplicate initial value
                setDuplicateCondition(response.data.data.rejected_condition)

                if (response.data.data.rejected_condition === "RESPONSE EQUAL" || response.data.data.rejected_condition === "RESPONSE CONTAINS") {
                    setIsDirectPostRejectedResponseStatusSelected(true);
                } else {
                    setIsDirectPostRejectedResponseStatusSelected(false);
                }

                setDuplicateKey(response.data.data.rejected_condition_key)
                setDuplicateValue(response.data.data.rejected_condition_value)
                // setting default timezone
                if (buyerTimezone) {
                    setTimezone({
                        value: buyerTimezone.id,
                        label: buyerTimezone.timezone_title
                    })
                }

                setIsFetchingData(false);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch buyer's data!");
                setIsFetchingData(false);
            })
    }

    // Delete all value replace mappers
    const deleteValueReplacer = async () => {
        try {
            const res = await http.get(`/api/valuemappers/${buyerId.id}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data: valueReplaceArr } = res.data;

                for (let i = 0; i < valueReplaceArr.length; i++) {
                    const vr = valueReplaceArr[i];

                    if (!vr.id) continue;

                    try {
                        const res = await http.delete(`/api/delete-valuemapper/${vr.id}/`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                    } catch (err) {
                        const { response } = err;

                        if (response) {
                            const { data } = response;
                            if (data) {
                                if (data.message === "Unauthenticated.") {
                                    logout();
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
        }

        for (let i = 0; i < valueReplacerMappers.length; i++) {
            const vr = valueReplacerMappers[i];

            if (!vr.id) continue;

            try {
                const res = await http.delete(`/api/delete-valuemapper/${vr.id}/`, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
            } catch (err) {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                // toast.error("Failed to delete the value replacer!");
            }
        }
    }

    // Delete a particular single value replace mapper
    const deleteSingleValueReplacerField = async (vrId) => {
        try {
            const res = await http.delete(`/api/delete-valuemapper/${vrId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // toast.error("Failed to delete the value replacer!");
        }
    }

    const updateValueReplacer = async () => {
        for (let i = 0; i < valueReplacerMappers.length; i++) {
            let formData = new FormData();

            const vr = valueReplacerMappers[i];
            const prevVr = prevValueReplaceMappers[i];

            let createVr = false;

            if (prevVr == null || vr.id == null || vr.id !== prevVr.id) {
                createVr = true;
            }

            let fieldArr = [];
            vr.fieldValArr.map(f => {
                fieldArr.push({
                    replace_from_word: f.fieldValue1.label,
                    replace_to_word: f.fieldValue2,
                });
            })

            formData.append("key_name", vr.field?.label);
            formData.append("buyer_id", buyerId.id);

            fieldArr.map(f => {
                formData.append("replacer_data[][replace_from_word]", f.replace_from_word);
                formData.append("replacer_data[][replace_to_word]", f.replace_to_word);
            });

            if (!createVr) {
                try {
                    const res = await http.put(`/api/update-valuemapper/${vr.id}/`, formData, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                } catch (err) {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }
                    toast.error("Failed to update value replace mapper!");
                }
            } else {
                try {
                    const res = await http.post("/api/post-valuemapper/", formData, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                } catch (err) {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }
                    toast.error("Failed to create value replace mapper!");
                }
            }
        }

        // Check if any valuemapper removed
        for (let i = 0; i < prevValueReplaceMappers.length; i++) {
            const vr = valueReplacerMappers[i];
            const prevVr = prevValueReplaceMappers[i];

            if (!vr || vr.id !== prevVr.id) {
                await deleteSingleValueReplacerField(prevVr.id);
            }
        }
    }

    const handleBuyerPut = async () => {
        setIsSaving(true);

        setFormError({
            errorId: -1,
        });

        if (client == null) {
            toast.error("Please select a buyer!");
            setIsSaving(false);
            return;
        }
        if (buyerType == null) {
            showFormError({
                errorId: 15
            });
            setIsSaving(false);
            return;
        }
        if (!timezone || timezone.value === "") {
            showFormError({
                errorId: 2,
            });
            setIsSaving(false);
            return;
        }
        if (showPricing && price === "") {
            showFormError({
                errorId: 3,
            });
            setIsSaving(false);
            return;
        }
        if (directPostJsonFormatError) {
            directPostEditorRef.current?.focus();
            setIsSaving(false);
            return;
        }
        if (pingPostJsonFormatError) {
            pingPostEditorRef.current?.focus();
            setIsSaving(false);
            return;
        }

        if (!deliveryMethod || deliveryMethod === "") {
            showFormError({
                errorId: 4,
            });
            setIsSaving(false);
            return;
        }
        if (deliveryMethod === "PING POST") {
            if (pingUrl === "") {
                showFormError({
                    errorId: 14,
                });
                setIsSaving(false);
                return;
            }
            if (!requestMethod || requestMethod === "") {
                showFormError({
                    errorId: 6,
                })
                setIsSaving(false);
                return;
            }
            if (!payloadType || payloadType === "") {
                showFormError({
                    errorId: 7,
                })
                setIsSaving(false);
                return;
            }
        }
        if (deliveryMethod === "DIRECT POST" || deliveryMethod === "PING POST") {
            if (postUrl === "") {
                showFormError({
                    errorId: 5,
                })
                setIsSaving(false);
                return;
            }
            if (!requestMethod || requestMethod === "") {
                showFormError({
                    errorId: 6,
                })
                setIsSaving(false);
                return;
            }
            if (!payloadType || payloadType === "") {
                showFormError({
                    errorId: 7,
                })
                setIsSaving(false);
                return;
            }
        }

        let directPostBody = body;

        if (payloadType) {
            if (payloadType === "Form") {
                directPostBody = await convertFormToJson(body);
            }
            if (payloadType === "Form" || payloadType === "JSON") {
                directPostBody = JSON.parse(directPostBody);
            }
        }

        const data = {
            // client_id: clnt,
            client_id: client.value,
            buyer_type: buyerType.value,
            phone_number_format: phoneFormat.value,
            campaign_id: buyer?.campaign?.id,
            lead_volume_daily_cap: leadVolumeDailyCap ? leadVolumeDailyCap : 0,
            lead_volume_weekly_cap: leadVolumeWeeklyCap ? leadVolumeWeeklyCap : 0,
            lead_volume_monthly_cap: leadVolumeMonthlyCap ? leadVolumeMonthlyCap : 0,
            budget_daily_cap: budgetDailyCap ? budgetDailyCap : 0,
            budget_weekly_cap: budgetWeeklyCap ? budgetWeeklyCap : 0,
            budget_monthly_cap: budgetMonthlyCap ? budgetMonthlyCap : 0,
            delivery_method: deliveryMethod,
            post_url: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : postUrl,
            ping_url: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingUrl,
            request_method: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : requestMethod,
            payload_type: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : payloadType,
            body: (payloadType === "JSON" || payloadType === "Form") ? (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : directPostBody : "",
            xml_body: payloadType === "XML" ? directPostBody : "",
            response_type: responseType,
            price: showPricing ? price : 0,
            showPrice: showPricing,
            // ping
            ping_body: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingBody,
            ping_accepted_condition: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingAcceptedCondition,
            ping_accepted_key: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingAcceptedConditionKey,
            ping_accepted_value: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingAcceptedConditionValue,
            ping_duplicate_condition: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingDuplicateCondition,
            ping_duplicate_key: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingDuplicateKey,
            ping_duplicate_value: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingDuplicateValue,
            // post
            accepted_condition: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : acceptedCondition,
            accepted_condition_key: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : acceptedConditionKey,
            accepted_condition_value: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : acceptedConditionValue,
            rejected_condition: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : duplicateCondition,
            rejected_condition_key: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : duplicateKey,
            rejected_condition_value: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : duplicateValue,
            timezone: timezone?.value,
            is_active: buyer.is_active,
            is_active_mapper: showValueReplacer,
            mapped_price_field: showRealTimePrice ? realTimePrice : "",
            ping_request_method: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingRequestMethod,
            ping_payload_type: (deliveryMethod !== "DIRECT POST" && deliveryMethod !== "PING POST") ? "" : pingPayloadType,
        }

        await http.put(`/api/edit-buyer/${buyerId.id}/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async (response) => {
                if (showValueReplacer) {
                    await updateValueReplacer();
                } else {
                    await deleteValueReplacer();
                }
                setShowPricing(response.data.data.show_price);
                setPrice(response.data.data.price);
                toast.success("Buyer is updated successfully.")
                setIsSaving(false);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to update the buyer!");
                setIsSaving(false);
            })
    }

    const getClients = async () => {
        isLoading(true)
        await http.get("/api/clients?per_page=all", {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                let clientArr = []
                const { data } = response.data;
                data?.map(c => {
                    const { user, company_name } = c;

                    user.roles?.map(r => {
                        if (r.name === "BUYER") {
                            clientArr.push({
                                ...c,
                                value: c.id,
                                label: company_name[0].toUpperCase() + company_name.slice(1,)
                            });
                        }
                    })
                })

                setClients(clientArr)
                isLoading(false);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch clients' data!");
            })
    }

    const getTimezones = async () => {
        await http.get("/api/timezones/", {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                let timezoneArr = [];
                response.data.data?.map(t => {
                    timezoneArr.push({
                        value: t.id,
                        label: t.timezone_title
                    });
                })
                setTimezones(timezoneArr);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch timezones' data!");
            })
    }

    const getValueMappers = async (fields) => {
        try {
            const res = await http.get(`/api/valuemappers/${buyerId.id}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data: valueReplacerData } = res.data;

                let valRepArr = [];

                if (valueReplacerData.length > 0) {
                    setShowValueReplacer(true);
                } else {
                    valRepArr = [{
                        index: 0,
                        field: null,
                        fieldValArr: [{
                            index: 0,
                            fieldValue1: {
                                value: "",
                                label: ""
                            },
                            fieldValue2: "",
                            fieldValueOptions: []
                        }]
                    }]
                }

                for (let index = 0; index < valueReplacerData.length; index++) {
                    const vr = valueReplacerData[index];

                    const key = fields.find(f => f.field_name === vr.key_name);
                    if (!key) continue;

                    let fieldValArr = [];
                    key.field_values.map(f => {
                        fieldValArr.push({
                            ...f,
                            value: f.id,
                            label: f.value_title
                        })
                    })

                    let fieldArr = [];

                    for (let i = 0; i < vr.replacers.length; i++) {
                        const f = vr.replacers;

                        fieldArr.push({
                            index: i,
                            fieldValue1: {
                                value: f[i].replace_from_word,
                                label: f[i].replace_from_word
                            },
                            fieldValue2: f[i].replace_to_word,
                            fieldValueOptions: fieldValArr
                        })
                    }

                    valRepArr.push({
                        id: vr.id,
                        index: index,
                        field: {
                            value: key.id,
                            label: key.field_name
                        },
                        isListType: key.field_type === "list" ? true : false,
                        fieldValArr: fieldArr
                    })
                }

                setValueReplacerMappers(valRepArr);
                setPrevValueReplaceMappers(valRepArr);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // toast.error("Failed to fetch value mapper data!");
        }
    }

    useEffect(() => {
        fetchBuyerDetails();
        getClients();
        getTimezones();
    }, [])

    const convertJsonToForm = async (data) => {
        try {
            var formText = Object.keys(data).map(function (key) {
                return key + '="' + data[key] + '"';
            }).join('&');

            return formText;
        } catch (err) {
            return "";
        }
    }

    const convertFormToJson = async (data) => {
        try {
            var keyValuePairs = data.split('&');

            // Initialize an empty object to store the key-value pairs
            var jsonObject = {};

            // Iterate through each key-value pair
            keyValuePairs.forEach(function (pair) {
                // Split the pair by '=' to get the key and value
                var parts = pair.split('=');
                // Extract key and value, and remove quotes from the value
                var key = parts[0];
                var value = parts[1].replace(/"/g, ''); // Remove double quotes
                // Add key-value pair to the object
                jsonObject[key] = value;
            });

            // Convert the object to JSON format
            var jsonText = JSON.stringify(jsonObject);
            return jsonText;
        } catch (err) {
            return "";
            // return data;
        }
    }

    function isXml(content) {
        const regex = /<\w+>/; // Matches opening XML tag
        return regex.test(content) && content.trim().startsWith("<"); // Ensure valid starting tag
    }

    // Editor on change event
    const bodyOnChange = async (newValue) => {
        setBody(newValue);

        if (!payloadType || payloadType === "JSON") {
            try {
                const checkVal = JSON.parse(newValue);
                setDirectPostJsonFormatError(false);
            } catch (err) {
                setDirectPostJsonFormatError(true);
                // console.log(err);
            }
        } else if (payloadType === "Form") {
            const jsonText = await convertFormToJson(newValue);

            try {
                const checkVal = JSON.parse(jsonText);
                setDirectPostJsonFormatError(false);
            } catch (err) {
                setDirectPostJsonFormatError(true);
            }
        } else if (payloadType === "XML") {
            const isXmlContent = isXml(newValue);

            if (isXmlContent) {
                setDirectPostJsonFormatError(false);
            } else {
                setDirectPostJsonFormatError(true);
            }
        }
    }

    const pingBodyOnChange = async (newValue) => {
        setPingBody(newValue);

        if (!pingPayloadType || pingPayloadType === "JSON") {
            try {
                const checkVal = JSON.parse(newValue);
                setPingPostJsonFormatError(false);
            } catch (err) {
                setPingPostJsonFormatError(true);
                // console.log(err);
            }
        } else if (pingPayloadType === "Form") {
            const jsonText = await convertFormToJson(newValue);

            try {
                const checkVal = JSON.parse(jsonText);
                setPingPostJsonFormatError(false);
            } catch (err) {
                setPingPostJsonFormatError(true);
            }
        } else if (pingPayloadType === "XML") {
            const isXmlContent = isXml(newValue);

            if (isXmlContent) {
                setPingPostJsonFormatError(false);
            } else {
                setPingPostJsonFormatError(true);
            }
        }
    }

    const pasteOnAceEditor = async ({ editor, pastedData }) => {
        const cursorPosition = editor.getCursorPosition();
        const currentContent = editor.getValue();

        const modifiedContent =
            currentContent.slice(0, editor.session.doc.positionToIndex(cursorPosition)) +
            pastedData +
            currentContent.slice(editor.session.doc.positionToIndex(cursorPosition));

        // Set the modified content as the new editor value
        editor.setValue(modifiedContent);
    }

    // Copy field name to clipboard
    const copyFieldToClipBoard = async (fieldName, editorMode) => {
        navigator.clipboard.writeText(`{{${fieldName}}}`);
        if (editorMode === "pingPost") {
            await pasteOnAceEditor({ editor: pingBodyEditorRef.current.editor, pastedData: `{{${fieldName}}}` });
        } else if (editorMode === "deliveryPost") {
            await pasteOnAceEditor({ editor: bodyEditorRef.current.editor, pastedData: `{{${fieldName}}}` });
        }
    }

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const handleFieldChange = async (e) => {
        if (e.target.name === "price") {
            setPrice(e.target.value);

            if (e.target.value !== "") {
                priceRef.current.classList.remove("error__field");
            } else {
                priceRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "real__timePrice") {
            setRealTimePrice(e.target.value);

            if (e.target.value !== "") {
                realTimePriceRef.current.classList.remove("error__field");
            } else {
                realTimePriceRef.current.classList.add("error__field");
            }
        }
        else if (e.target.name === "delivery__method") {
            setDeliveryMethod(e.target.value);

            if (e.target.value !== "") {
                deliveryMethodRef.current.classList.remove("error__field");
            } else {
                deliveryMethodRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "ping__url") {
            setPingUrl(e.target.value);

            if (e.target.value !== "") {
                pingURLRef.current.classList.remove("error__field");
            } else {
                pingURLRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "ping__requestMethod") {
            setPingRequestMethod(e.target.value);

            if (e.target.value !== "") {
                pingMethodRef.current.classList.remove("error__field");
            } else {
                pingMethodRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "ping__payloadType") {
            setPingPayloadType(e.target.value);

            if (e.target.value !== "") {
                pingPayloadTypeRef.current.classList.remove("error__field");
            } else {
                pingPayloadTypeRef.current.classList.add("error__field");
            }

            if (e.target.value === "JSON") {
                try {
                    setPingBody(await convertFormToJson(pingBody));
                } catch (err) {
                }
                setPingPostJsonFormatError(false);
            } else if (e.target.value === "Form") {
                try {
                    setPingBody(await convertJsonToForm(JSON.parse(pingBody)));
                } catch (err) {
                }
                setPingPostJsonFormatError(false);
            } else if (e.target.value === "XML") {
                setBody(body);
                const isXMLContent = isXml(body);

                if (isXMLContent) {
                    setPingPostJsonFormatError(false);
                } else {
                    setPingPostJsonFormatError(true);
                }
            }
        } else if (e.target.name === "post__url") {
            setPostUrl(e.target.value);

            if (e.target.value !== "") {
                postURLRef.current.classList.remove("error__field");
            } else {
                postURLRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "post__requestMethod") {
            setRequestMethod(e.target.value);

            if (e.target.value !== "") {
                postMethodRef.current.classList.remove("error__field");
            } else {
                postMethodRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "payload__type") {
            setPayloadType(e.target.value);

            if (e.target.value === "JSON") {
                try {
                    const bodyData = await convertFormToJson(body);

                    if (bodyData === "") {
                        setDirectPostJsonFormatError(true);
                    } else {
                        setBody(bodyData);
                        setDirectPostJsonFormatError(false);
                    }
                } catch (err) {
                    setDirectPostJsonFormatError(true);
                }

            } else if (e.target.value === "Form") {
                try {
                    const bodyData = await convertJsonToForm(JSON.parse(body));

                    if (bodyData === "") {
                        setDirectPostJsonFormatError(true);
                    } else {
                        setBody(bodyData);
                        setDirectPostJsonFormatError(false);
                    }
                } catch (err) {
                    setDirectPostJsonFormatError(true);
                }
            } else if (e.target.value === "XML") {
                setBody(body);
                const isXMLContent = isXml(body);

                if (isXMLContent) {
                    setDirectPostJsonFormatError(false);
                } else {
                    setDirectPostJsonFormatError(true);
                }
            }

            if (e.target.value !== "") {
                payloadTypeRef.current.classList.remove("error__field");
            } else {
                payloadTypeRef.current.classList.add("error__field");
            }
        }
    }

    const handleAddValueReplace = () => {
        const fieldIndex = valueReplacerMappers[valueReplacerMappers.length - 1].index;
        setValueReplacerMappers(prevFields => [...prevFields, {
            index: fieldIndex + 1,
            field: null,
            fieldValArr: [{
                index: 0,
                fieldValue1: {
                    value: "",
                    label: ""
                },
                fieldValue2: "",
                fieldValueOptions: []
            }]
        }])
    }

    const handleAddFieldInValueReplace = vrIndex => {
        let vrArr = [...valueReplacerMappers];

        const vrmObj = valueReplacerMappers.find(vr => vr.index === vrIndex);
        if (!vrmObj) return;

        const foundField = fields.find(f => f.id === vrmObj.field?.value);

        let fieldValArr = [];

        if (foundField && foundField.field_type === "list") {
            foundField.field_values?.map(f => {
                fieldValArr.push({
                    ...f,
                    value: f.id,
                    label: f.value_title
                })
            })
        }

        let fieldArr = vrmObj.fieldValArr;
        const fieldIndex = fieldArr[fieldArr.length - 1].index;

        fieldArr.push({
            index: fieldIndex + 1,
            fieldValue1: {
                value: "",
                label: ""
            },
            fieldValue2: "",
            fieldValueOptions: fieldValArr
        })

        valueReplacerMappers.map((vr, index) => {
            if (vr.index === vrmObj.index) {
                vrArr[index].fieldValArr = fieldArr
            }
        })

        setValueReplacerMappers(vrArr);
    }

    const handleRemoveFieldInValueReplace = ({ vrIndex, fieldIndex }) => {
        let vrArr = [...valueReplacerMappers];

        const vrmObj = valueReplacerMappers.find(vr => vr.index === vrIndex);
        if (!vrmObj) return;

        let fieldArr = vrmObj.fieldValArr;

        fieldArr = fieldArr.filter(f => f.index !== fieldIndex);

        valueReplacerMappers.map((vr, index) => {
            if (vr.index === vrmObj.index) {
                vrArr[index].fieldValArr = fieldArr
            }
        })

        setValueReplacerMappers(vrArr);
    }

    const handleRemoveValueReplace = vrIndex => {
        setValueReplacerMappers(valueReplacerMappers.filter(vr => vr.index !== vrIndex));
    }

    const handleValueReplacerField = (e, vrIndex) => {
        let fieldValArr = [];

        if (e.field_type === "list") {

            e.field_values.map(f => {
                fieldValArr.push({
                    ...f,
                    value: f.id,
                    label: f.value_title
                })
            })
        }

        let vrArr = [...valueReplacerMappers];

        valueReplacerMappers.map((vr, index) => {
            if (vr.index === vrIndex) {
                vrArr[index].field = {
                    value: e.value,
                    label: e.label
                };
                vrArr[index].isListType = e.field_type === "list" ? true : false;
                vrArr[index].fieldValArr = [{
                    index: 0,
                    fieldValue1: {
                        value: "",
                        label: ""
                    },
                    fieldValue2: "",
                    fieldValueOptions: fieldValArr
                }]
            }
        })

        setValueReplacerMappers(vrArr);
    }

    const handleValueReplacerFieldChange = ({ vrIndex, value, fieldIndex, fieldType }) => {
        let vrArr = [...valueReplacerMappers];

        if (fieldType === "field1") {
            valueReplacerMappers.map((vr, index) => {
                if (vr.index === vrIndex) {
                    vrArr[index].fieldValArr[fieldIndex].fieldValue1.value = value;
                    vrArr[index].fieldValArr[fieldIndex].fieldValue1.label = value;
                }
            })
        } else if (fieldType === "field2") {
            valueReplacerMappers.map((vr, index) => {
                if (vr.index === vrIndex) {
                    vrArr[index].fieldValArr[fieldIndex].fieldValue2 = value;
                }
            })
        }

        setValueReplacerMappers(vrArr);
    }

    const handleSelectFieldReplacer = ({ e, fieldIndex, vrIndex }) => {
        let vrArr = [...valueReplacerMappers];

        valueReplacerMappers.map((vr, index) => {
            if (vr.index === vrIndex) {
                vrArr[index].fieldValArr[fieldIndex].fieldValue1.value = e.value;
                vrArr[index].fieldValArr[fieldIndex].fieldValue1.label = e.label;
            }
        })

        setValueReplacerMappers(vrArr);
    }

    const handleCampaignCondition = ({ e, postType, statusType }) => {
        switch (postType) {
            case "direct":
                if (statusType === "accepted") {
                    setAcceptedCondition(e.target.value);

                    if (e.target.value === "RESPONSE EQUAL" || e.target.value === "RESPONSE CONTAINS") {
                        setIsDirectPostAcceptedResponseStatusSelected(true);
                    } else {
                        setIsDirectPostAcceptedResponseStatusSelected(false);
                    }
                } else if (statusType === "duplicate") {
                    setDuplicateCondition(e.target.value);

                    if (e.target.value === "RESPONSE EQUAL" || e.target.value === "RESPONSE CONTAINS") {
                        setIsDirectPostRejectedResponseStatusSelected(true);
                    } else {
                        setIsDirectPostRejectedResponseStatusSelected(false);
                    }
                }
                break;
            case "ping":
                if (statusType === "accepted") {
                    setPingAcceptedCondition(e.target.value);
                    if (e.target.value === "RESPONSE EQUAL" || e.target.value === "RESPONSE CONTAINS") {
                        setIsPingPostAcceptedResponseStatusSelected(true);
                    } else {
                        setIsPingPostAcceptedResponseStatusSelected(false);
                    }
                } else if (statusType === "duplicate") {
                    setPingDuplicateCondition(e.target.value);
                    if (e.target.value === "RESPONSE EQUAL" || e.target.value === "RESPONSE CONTAINS") {
                        setIsPingPostRejectedResponseStatusSelected(true);
                    } else {
                        setIsPingPostRejectedResponseStatusSelected(false);
                    }
                }
                break;
        }
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />

            <div className="main main-app p-4 p-lg-5">
                {
                    isFetchingData ? <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                    </div> :
                        <div className='flex flex-col gap-y-5'>
                            {
                                loading ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                    <div className='flex flex-col gap-y-5'>
                                        <Card className="flex-1">
                                            <Card.Header className={`flex flex-col lg:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${!skin && "bg-primary"}`}>
                                                <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                                                    <Link to="/campaigns" className="px-2 py-2 text-white font-semibold hover:underline">Campaigns</Link>
                                                    <FaLongArrowAltRight />
                                                    {
                                                        buyer.campaign &&
                                                        <Link to={`/create-campaign/${buyer.campaign.id}/`} className="px-2 py-2 text-white font-semibold hover:underline">
                                                            {buyer.campaign?.campaign_title}
                                                        </Link>
                                                    }
                                                    <FaLongArrowAltRight />
                                                    <div className="px-2 py-2 font-semibold bg-white rounded-md text-stone-600">Edit Buyer</div>
                                                </div>

                                                <div className="flex items-center w-full lg:w-auto gap-x-3 gap-y-3 campaign__headerBtns edit__campaignHeaderBtns">
                                                    {
                                                        buyer.campaign &&
                                                        <Link to={`/create-campaign/${buyer.campaign.id}/`} className="flex justify-center items-center gap-x-1.5 flex-1 sm:flex-auto font-medium px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                                                            <FaLongArrowAltLeft />
                                                            Go Back
                                                        </Link>
                                                    }
                                                    <Link to={`/test-lead/${buyer?.id}/`} className="flex justify-center items-center gap-x-1.5 flex-1 sm:flex-auto font-medium px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                                                        <SiTestcafe className="text-lg" />
                                                        Test Buyer
                                                    </Link>
                                                    <button onClick={handleBuyerPut} type="button" className="font-medium flex-1 sm:flex-auto font-medium flex justify-center items-center gap-x-1 px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                                                        {
                                                            !isSaving &&
                                                            <BiSave />
                                                        }
                                                        Save
                                                        {
                                                            isSaving && <Spinner animation="border" variant="light" size="sm" />
                                                        }
                                                    </button>
                                                </div>
                                            </Card.Header>
                                        </Card>

                                        <Card>
                                            <Card.Body className="card-post">
                                                <div className="grid grid-cols-1 sm:grid-cols-2 items-start gap-x-5 gap-y-3">
                                                    <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Buyer</Form.Label>
                                                        <Select
                                                            options={clients}
                                                            value={client}
                                                            onChange={setClient}
                                                            placeholder="Select buyer"
                                                            classNamePrefix={"my-react-select"}
                                                            className="w-full md:w-auto min-w-[150px]"
                                                            isSearchable
                                                            noOptionsMessage={() => "No match found."}
                                                        />
                                                    </div>
                                                    <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Buyer Type</Form.Label>
                                                        <Select
                                                            options={BUYER_TYPES}
                                                            value={buyerType}
                                                            onChange={setBuyerType}
                                                            placeholder="Select buyer type"
                                                            classNamePrefix={"my-react-select"}
                                                            className="w-full md:w-auto min-w-[150px]"
                                                            isSearchable
                                                            noOptionsMessage={() => "No match found."}
                                                        />
                                                    </div>

                                                    <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Phone Number Format</Form.Label>
                                                        <Select
                                                            options={PHONE_FORMAT}
                                                            value={phoneFormat}
                                                            onChange={setPhoneFormat}
                                                            placeholder="Select phone format"
                                                            classNamePrefix={"my-react-select"}
                                                            className="w-full md:w-auto min-w-[150px]"
                                                            isSearchable
                                                            noOptionsMessage={() => "No match found."}
                                                        />


                                                    </div>

                                                    <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Timezone</Form.Label>
                                                        <Select
                                                            options={timezones}
                                                            value={timezone}
                                                            onChange={setTimezone}
                                                            placeholder="Select timezone"
                                                            classNamePrefix={"my-react-select"}
                                                            className="w-full"
                                                            isSearchable
                                                            noOptionsMessage={() => "No match found."}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Card>
                                            <Card.Body className="card-post">
                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-2.5">
                                                    <div className="flex items-center gap-x-2">
                                                        <div className="flex items-center flex-wrap flex-1 gap-x-3 justify-between">
                                                            <div className="flex items-center gap-x-1">
                                                                <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Pricing</Form.Label>
                                                                {
                                                                    showPricing &&
                                                                    <div data-tooltip-id="price__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                                                        <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                                                        <Tooltip id="price__info" />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div onClick={() => setShowPricing(prevState => !prevState)} className={`cursor-pointer relative w-11 h-6 rounded-full ${showPricing ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                                                                <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showPricing ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showPricing &&
                                                        <div className="flex items-center gap-x-1 card-post">
                                                            <div className={`font-medium ${skin && "text-stone-400"}`}>$</div>
                                                            <Form.Control name="price" className="flex-1" ref={priceRef} value={price} onChange={e => handleFieldChange(e)} type="number" placeholder="Set default price" />
                                                        </div>
                                                    }
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Card>
                                            <Card.Header>

                                                <div className="flex items-center gap-x-2">
                                                    <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                                                        Leads Volume
                                                    </div>
                                                    <div data-tooltip-id="lead__volumeInfo" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                                        <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                                        <Tooltip id="lead__volumeInfo" />
                                                    </div>
                                                </div>
                                            </Card.Header>

                                            <Card.Body className="card-post">
                                                <div className="flex flex-wrap flex-1 flex-col sm:flex-row items-center gap-3">
                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Daily Cap</Form.Label>
                                                        <Form.Control className="flex-1" value={leadVolumeDailyCap} onChange={e => setLeadVolumeDailyCap(e.target.value)} type="number" placeholder="Set daily cap" />
                                                    </div>
                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Weekly Cap</Form.Label>
                                                        <Form.Control className="flex-1" value={leadVolumeWeeklyCap} onChange={e => setLeadVolumeWeeklyCap(e.target.value)} type="number" placeholder="Set weekly cap" />
                                                    </div>
                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Monthly Cap</Form.Label>
                                                        <Form.Control className="flex-1" value={leadVolumeMonthlyCap} onChange={e => setLeadVolumeMonthlyCap(e.target.value)} type="number" placeholder="Set monthly cap" />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        {/* <Card>
                                    <Card.Header>
                                        <div className="flex items-center gap-x-2">
                                            <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                                                Budget
                                            </div>
                                            <div data-tooltip-id="budget__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                                <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                                <Tooltip id="budget__info" />
                                            </div>
                                        </div>
                                    </Card.Header>

                                    <Card.Body className="card-post">
                                        <div className="flex flex-wrap flex-1 flex-col sm:flex-row items-center gap-3">
                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Daily Cap</Form.Label>
                                                <Form.Control className="flex-1" value={budgetDailyCap} onChange={e => setBudgetDailyCap(e.target.value)} type="number" placeholder="Set daily cap" />
                                            </div>
                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Weekly Cap</Form.Label>
                                                <Form.Control className="flex-1" value={budgetWeeklyCap} onChange={e => setBudgetWeeklyCap(e.target.value)} type="number" placeholder="Set weekly cap" />
                                            </div>
                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Monthly Cap</Form.Label>
                                                <Form.Control className="flex-1" value={budgetMonthlyCap} onChange={e => setBudgetMonthlyCap(e.target.value)} type="number" placeholder="Set monthly cap" />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card> */}

                                        <Card>
                                            <Card.Header>
                                                <div className="flex items-center gap-x-2">
                                                    <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                                                        Delivery Method
                                                    </div>
                                                </div>
                                            </Card.Header>

                                            <Card.Body className="card-post">
                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                    <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Delivery Method</Form.Label>
                                                    <Form.Select name="delivery__method" className="flex-1" ref={deliveryMethodRef} value={deliveryMethod} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                                                        <option value="" selected>Select delivery method</option>
                                                        <option value="DIRECT POST">Direct Post</option>
                                                        {/* <option value="PING POST">Ping/Post</option>
                                                <option value="STORE LEADS">Store Leads</option> */}
                                                    </Form.Select>
                                                </div>

                                                {
                                                    deliveryMethod && deliveryMethod !== "STORE LEADS" && <>
                                                        {
                                                            deliveryMethod === "PING POST" && <>
                                                                <div className="flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3 mt-3">
                                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Ping URL</Form.Label>
                                                                        <Form.Control name="ping__url" className="flex-1" ref={pingURLRef} value={pingUrl} onChange={e => handleFieldChange(e)} type="text" placeholder="Enter ping URL" />
                                                                    </div>
                                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Request Method</Form.Label>
                                                                        <Form.Select className="flex-1" name="ping__requestMethod" ref={pingMethodRef} value={pingRequestMethod} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                                                                            <option value="" selected>Select type</option>
                                                                            <option value="POST">POST</option>
                                                                            <option value="GET">GET</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Payload Type</Form.Label>
                                                                        <Form.Select className="flex-1" name="ping__payloadType" ref={pingPayloadTypeRef} value={pingPayloadType} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                                                                            <option value="" selected>Select type</option>
                                                                            <option value="Form">FORM</option>
                                                                            <option value="JSON">JSON</option>
                                                                            {/* <option value="XML">XML</option> */}
                                                                        </Form.Select>
                                                                    </div>
                                                                </div>

                                                                <div className="w-full my-3 flex flex-col gap-y-3">
                                                                    <div className={`text-base font-medium ${skin && "text-stone-400"}`}>
                                                                        Body: choose from your campaign fields or our system fields.
                                                                        If you would like to transform your data, you can apply
                                                                        these transformers.
                                                                    </div>
                                                                    {
                                                                        fields.length > 0 && <div className="flex flex-wrap gap-x-1.5 gap-y-1.5">
                                                                            {
                                                                                fields.map(field => (
                                                                                    <button key={field.id} onClick={() => copyFieldToClipBoard(field.field_name, "pingPost")} type="button" className={`font-medium px-2 py-1 border ${skin ? "text-stone-400 border-stone-500" : "bg-white border-stone-200"} rounded-md hover:shadow-md transition-all duration-200 ease-linear`}>
                                                                                        {`{{ ${field.field_name} }}`}
                                                                                    </button>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div tabIndex={-1} ref={pingPostEditorRef} className={`relative w-full p-1 custom__aceEditor ${skin ? "custom__aceDarkEditor" : "custom__aceLightEditor"} ${pingPostJsonFormatError ? "error__aceEditor" : ""}`}>
                                                                        {
                                                                            !payloadType ||
                                                                                payloadType === "JSON" ?
                                                                                <AceEditor
                                                                                    ref={pingBodyEditorRef}
                                                                                    mode="json"
                                                                                    theme={`${skin ? "solarized_dark" : "github"}`}
                                                                                    name="body__editor"
                                                                                    onChange={pingBodyOnChange}
                                                                                    value={pingBody}
                                                                                    editorProps={{ $blockScrolling: true }}
                                                                                    wrapEnabled={true}
                                                                                    setOptions={{
                                                                                        enableBasicAutocompletion: true,
                                                                                        enableLiveAutocompletion: true,
                                                                                        enableSnippets: false,
                                                                                        useWorker: false,
                                                                                    }}
                                                                                    fontSize={16}
                                                                                /> :
                                                                                payloadType === "Form" ?
                                                                                    <AceEditor
                                                                                        ref={pingBodyEditorRef}
                                                                                        mode="json"
                                                                                        theme={`${skin ? "solarized_dark" : "github"}`}
                                                                                        name="body__editor"
                                                                                        onChange={pingBodyOnChange}
                                                                                        value={pingBody}
                                                                                        editorProps={{ $blockScrolling: true }}
                                                                                        wrapEnabled={true}
                                                                                        setOptions={{
                                                                                            enableBasicAutocompletion: true,
                                                                                            enableLiveAutocompletion: true,
                                                                                            enableSnippets: false,
                                                                                            useWorker: false,
                                                                                        }}
                                                                                        fontSize={16}
                                                                                    /> : payloadType === "XML" ?
                                                                                        <AceEditor
                                                                                            ref={pingBodyEditorRef}
                                                                                            mode="xml"
                                                                                            theme={`${skin ? "solarized_dark" : "github"}`}
                                                                                            name="body__editor"
                                                                                            onChange={pingBodyOnChange}
                                                                                            value={pingBody}
                                                                                            editorProps={{ $blockScrolling: true }}
                                                                                            wrapEnabled={true}
                                                                                            setOptions={{
                                                                                                enableBasicAutocompletion: true,
                                                                                                enableLiveAutocompletion: true,
                                                                                                enableSnippets: false,
                                                                                                useWorker: false,
                                                                                            }}
                                                                                            fontSize={16}
                                                                                            tabSize={4}
                                                                                        />
                                                                                        : null
                                                                        }
                                                                    </div>

                                                                    <div className="flex flex-col gap-y-3">
                                                                        <div className="my-2 flex items-center gap-x-2">
                                                                            <div className={`w-full h-[1px] ${skin ? "bg-stone-500" : "bg-stone-200"}`}></div>
                                                                            <p className={`mb-0 sm:text-base font-medium whitespace-nowrap ${skin && "text-stone-400"}`}>Ping/POST - Response Mapping</p>
                                                                            <div className={`w-full h-[1px] ${skin ? "bg-stone-500" : "bg-stone-200"}`}></div>
                                                                        </div>

                                                                        <Form.Label className={`font-medium mb-0 uppercase ${skin ? "text-teal-400" : "text-success"}`}>Accepted</Form.Label>
                                                                        <div className="flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3">
                                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                <div className="flex flex-col gap-x-3">
                                                                                    <Form.Select className="flex-1" ref={pingAcceptConditionRef} value={pingAcceptedCondition} onChange={e => handleCampaignCondition({ e: e, postType: "ping", "statusType": "accepted" })} aria-label="Default select example">
                                                                                        <option value="" selected>Select Condition</option>
                                                                                        <option value="KEY EQUALS WITH">Key equal with</option>
                                                                                        <option value="KEY CONTAINS">Key contains</option>
                                                                                        <option value="RESPONSE EQUAL">Response equal</option>
                                                                                        <option value="RESPONSE CONTAINS">Response contains</option>
                                                                                        {/* <option value="STATUS CODE">Status code</option> */}
                                                                                    </Form.Select>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !isPingPostAcceptedResponseStatusSelected &&
                                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                    <Form.Control ref={pingAcceptedConditionKeyRef} className="flex-1" value={pingAcceptedConditionKey} onChange={e => setPingAcceptedConditionKey(e.target.value)} type="text" placeholder="Enter key" />
                                                                                </div>
                                                                            }
                                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                <Form.Control ref={pingAcceptedConditionValueRef} className="flex-1" value={pingAcceptedConditionValue} onChange={e => setPingAcceptedConditionValue(e.target.value)} type="text" placeholder="Enter value" />
                                                                            </div>
                                                                        </div>

                                                                        <Form.Label className={`font-medium mb-0 uppercase ${skin ? "text-rose-400" : "text-danger"}`}>Rejected</Form.Label>
                                                                        <div className={`flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3 pb-3 border-b ${skin ? "border-stone-500" : "border-stone-200"}`}>
                                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                <div className="flex flex-col gap-x-3">
                                                                                    <Form.Select className="flex-1" ref={pingDuplicateConditionRef} value={pingDuplicateCondition} onChange={e => handleCampaignCondition({ e: e, postType: "ping", "statusType": "duplicate" })} aria-label="Default select example">
                                                                                        <option value="" selected>Select Condition</option>
                                                                                        <option value="KEY EQUALS WITH">Key equal with</option>
                                                                                        <option value="KEY CONTAINS">Key contains</option>
                                                                                        <option value="RESPONSE EQUAL">Response equal</option>
                                                                                        <option value="RESPONSE CONTAINS">Response contains</option>
                                                                                        {/* <option value="STATUS CODE">Status code</option> */}
                                                                                    </Form.Select>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                !isPingPostRejectedResponseStatusSelected &&
                                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                    <Form.Control ref={pingDuplicateKeyRef} className="flex-1" value={pingDuplicateKey} onChange={e => setPingDuplicateKey(e.target.value)} type="text" placeholder="Enter key" />
                                                                                </div>
                                                                            }
                                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                                <Form.Control ref={pingDuplicateValueRef} className="flex-1" value={pingDuplicateValue} onChange={e => setPingDuplicateValue(e.target.value)} type="text" placeholder="Enter value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        <div className="flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3 mt-3">
                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Post URL</Form.Label>
                                                                <Form.Control name="post__url" className="flex-1" ref={postURLRef} value={postUrl} onChange={e => handleFieldChange(e)} type="text" placeholder="Enter post URL" />
                                                            </div>
                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Request Method</Form.Label>
                                                                <Form.Select name="post__requestMethod" className="flex-1" ref={postMethodRef} value={requestMethod} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                                                                    <option value="" selected>Select type</option>
                                                                    <option value="POST">POST</option>
                                                                    <option value="GET">GET</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Payload Type</Form.Label>
                                                                <Form.Select name="payload__type" className="flex-1" ref={payloadTypeRef} value={payloadType} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                                                                    <option value="" selected>Select type</option>
                                                                    <option value="Form">FORM</option>
                                                                    <option value="JSON">JSON</option>
                                                                    <option value="XML">XML</option>
                                                                </Form.Select>
                                                            </div>
                                                        </div>

                                                        <div className="w-full my-3 flex flex-col gap-y-3">
                                                            <div className={`text-base font-medium ${skin && "text-stone-400"}`}>
                                                                Body: choose from your campaign fields or our system fields.
                                                                If you would like to transform your data, you can apply
                                                                these transformers.
                                                            </div>
                                                            {
                                                                fields.length > 0 && <div className="flex flex-wrap gap-x-1.5 gap-y-1.5">
                                                                    {
                                                                        fields.map(field => (
                                                                            <button key={field.id} onClick={() => copyFieldToClipBoard(field.field_name, "deliveryPost")} type="button" className={`font-medium px-2 py-1 border ${skin ? "text-stone-400 border-stone-500" : "bg-white border-stone-200"} rounded-md hover:shadow-md transition-all duration-200 ease-linear`}>
                                                                                {`{{ ${field.field_name} }}`}
                                                                            </button>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                            <div tabIndex={-1} ref={directPostEditorRef} className={`relative  w-full p-1 test custom__aceEditor ${skin ? "custom__aceDarkEditor" : "custom__aceLightEditor"} ${directPostJsonFormatError ? "error__aceEditor" : ""}`}>
                                                                {
                                                                    !payloadType ||
                                                                        payloadType === "JSON" ?
                                                                        <AceEditor
                                                                            ref={bodyEditorRef}
                                                                            mode="json"
                                                                            theme={`${skin ? "solarized_dark" : "github"}`}
                                                                            name="body__editor"
                                                                            onChange={bodyOnChange}
                                                                            value={body}
                                                                            editorProps={{ $blockScrolling: true }}
                                                                            wrapEnabled={true}
                                                                            setOptions={{
                                                                                enableBasicAutocompletion: true,
                                                                                enableLiveAutocompletion: true,
                                                                                enableSnippets: false,
                                                                                useWorker: false,
                                                                            }}
                                                                            fontSize={16}
                                                                        /> :
                                                                        payloadType === "Form" ?
                                                                            <AceEditor
                                                                                ref={bodyEditorRef}
                                                                                mode="json"
                                                                                theme={`${skin ? "solarized_dark" : "github"}`}
                                                                                name="body__editor"
                                                                                onChange={bodyOnChange}
                                                                                value={body}
                                                                                editorProps={{ $blockScrolling: true }}
                                                                                wrapEnabled={true}
                                                                                setOptions={{
                                                                                    enableBasicAutocompletion: true,
                                                                                    enableLiveAutocompletion: true,
                                                                                    enableSnippets: false,
                                                                                    useWorker: false,
                                                                                }}
                                                                                fontSize={16}
                                                                            /> : payloadType === "XML" ?
                                                                                <AceEditor
                                                                                    ref={bodyEditorRef}
                                                                                    mode="xml"
                                                                                    theme={`${skin ? "solarized_dark" : "github"}`}
                                                                                    name="body__editor"
                                                                                    onChange={bodyOnChange}
                                                                                    value={body}
                                                                                    editorProps={{ $blockScrolling: true }}
                                                                                    wrapEnabled={true}
                                                                                    setOptions={{
                                                                                        enableBasicAutocompletion: true,
                                                                                        enableLiveAutocompletion: true,
                                                                                        enableSnippets: false,
                                                                                        useWorker: false,
                                                                                    }}
                                                                                    fontSize={16}
                                                                                    tabSize={4}
                                                                                />
                                                                                : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-2.5 py-2">
                                                            <div className="flex items-center gap-x-2">
                                                                <div className="flex items-center flex-wrap flex-1 gap-x-3 justify-between">
                                                                    <div className="flex items-center gap-x-1">
                                                                        <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Real Time Price</Form.Label>
                                                                    </div>
                                                                    <div onClick={() => setShowRealTimePrice(prevState => !prevState)} className={`cursor-pointer relative w-11 h-6 rounded-full ${showRealTimePrice ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                                                                        <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showRealTimePrice ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                showRealTimePrice &&
                                                                <div className="flex flex-col sm:flex-row sm:items-center gap-x-2 gap-y-3">
                                                                    <div className={`font-medium ${skin && "text-stone-400"}`}>API Response Key</div>
                                                                    <Form.Control name="real__timePrice" className="flex-1" ref={realTimePriceRef} value={realTimePrice} onChange={e => handleFieldChange(e)} type="text" placeholder="Set real time price" />
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="flex flex-col gap-y-3">
                                                            <div className="my-2 flex items-center gap-x-2">
                                                                <div className={`w-full h-[1px] ${skin ? "bg-stone-500" : "bg-stone-200"}`}></div>
                                                                <p className={`mb-0 sm:text-base font-medium whitespace-nowrap ${skin && "text-stone-400"}`}>DIRECT POST - Response Mapping</p>
                                                                <div className={`w-full h-[1px] ${skin ? "bg-stone-500" : "bg-stone-200"}`}></div>
                                                            </div>

                                                            <Form.Label className={`font-medium mb-0 uppercase ${skin ? "text-teal-400" : "text-success"}`}>Accepted</Form.Label>
                                                            <div className="flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3">
                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                    <div className="flex flex-col gap-x-3">
                                                                        <Form.Select className="flex-1" ref={acceptConditionRef} value={acceptedCondition} onChange={e => handleCampaignCondition({ e: e, postType: "direct", "statusType": "accepted" })} aria-label="Default select example">
                                                                            <option value="" selected>Select Condition</option>
                                                                            <option value="KEY EQUALS WITH">Key equal with</option>
                                                                            <option value="KEY CONTAINS">Key contains</option>
                                                                            <option value="RESPONSE EQUAL">Response equal</option>
                                                                            <option value="RESPONSE CONTAINS">Response contains</option>
                                                                            {/* <option value="STATUS CODE">Status code</option> */}
                                                                        </Form.Select>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    !isDirectPostAcceptedResponseSelected &&
                                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                        <Form.Control ref={acceptedKeyRef} className="flex-1" value={acceptedConditionKey} onChange={e => setAcceptedConditionKey(e.target.value)} type="text" placeholder="Enter key" />
                                                                    </div>
                                                                }
                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                    <Form.Control ref={acceptedValueRef} className="flex-1" value={acceptedConditionValue} onChange={e => setAcceptedConditionValue(e.target.value)} type="text" placeholder="Enter value" />
                                                                </div>
                                                            </div>

                                                            <Form.Label className={`font-medium mb-0 uppercase ${skin ? "text-rose-400" : "text-danger"}`}>Rejected</Form.Label>
                                                            <div className="flex flex-wrap flex-col sm:flex-row items-start gap-x-5 gap-y-3">
                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                    <div className="flex flex-col gap-x-3">
                                                                        <Form.Select className="flex-1" ref={duplicateConditionRef} value={duplicateCondition} onChange={e => handleCampaignCondition({ e: e, postType: "direct", "statusType": "duplicate" })} aria-label="Default select example">
                                                                            <option value="" selected>Select Condition</option>
                                                                            <option value="KEY EQUALS WITH">Key equal with</option>
                                                                            <option value="KEY CONTAINS">Key contains</option>
                                                                            <option value="RESPONSE EQUAL">Response equal</option>
                                                                            <option value="RESPONSE CONTAINS">Response contains</option>
                                                                            {/* <option value="STATUS CODE">Status code</option> */}
                                                                        </Form.Select>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    !isDirectPostRejectedResponseSelected &&
                                                                    <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                        <Form.Control ref={duplicateKeyRef} className="flex-1" value={duplicateKey} onChange={e => setDuplicateKey(e.target.value)} type="text" placeholder="Enter key" />
                                                                    </div>
                                                                }
                                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                                                                    <Form.Control ref={duplicateValueRef} className="flex-1" value={duplicateValue} onChange={e => setDuplicateValue(e.target.value)} type="text" placeholder="Enter value" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </Card.Body>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <div className="flex items-center gap-x-2">
                                                    <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                                                        Value Replacer
                                                    </div>
                                                </div>
                                            </Card.Header>

                                            <Card.Body className="card-post">
                                                <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-2.5 py-2">
                                                    <div className="flex items-center gap-x-2 mb-2">
                                                        <div className="flex items-center flex-wrap flex-1 gap-x-3 justify-between">
                                                            <div className="flex items-center gap-x-1">
                                                                <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Value Replacer</Form.Label>
                                                                {/* {
                          showValueReplacer &&
                          <div data-tooltip-id="price__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                            <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                            <Tooltip id="price__info" />
                          </div>
                        } */}
                                                            </div>
                                                            <div onClick={() => setShowValueReplacer(prevState => !prevState)} className={`cursor-pointer relative w-11 h-6 rounded-full ${showValueReplacer ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                                                                <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showValueReplacer ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showValueReplacer &&
                                                        valueReplacerMappers.map((vr, index) => (
                                                            <div key={vr.index} className="flex flex-col min-[700px]:flex-row justify-start gap-x-5 gap-y-5">
                                                                <div className="flex flex-col min-[700px]:flex-row gap-x-5 gap-y-3">
                                                                    <div className="flex flex-start min-[700px]:mt-2 gap-x-2 text-white max-[700px]:order-2">
                                                                        {
                                                                            index === valueReplacerMappers.length - 1 ? <button onClick={handleAddValueReplace} type="button" className="flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 rounded-full bg-indigo-500 hover:bg-indigo-600">
                                                                                <IoMdAdd className="text-lg" />
                                                                                <p className="mb-0 inline min-[700px]:hidden">Add</p>
                                                                            </button> :
                                                                                <button disabled onClick={handleAddValueReplace} type="button" className="opacity-0 flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 rounded-full bg-indigo-500 hover:bg-indigo-600">
                                                                                    <IoMdAdd className="text-lg" />
                                                                                    <p className="mb-0 inline min-[700px]:hidden">Add</p>
                                                                                </button>
                                                                        }
                                                                        {
                                                                            (index !== 0 || valueReplacerMappers.length > 1) ?
                                                                                <button onClick={() => handleRemoveValueReplace(vr.index)} type="button" className="flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600">
                                                                                    <MdDeleteOutline className="text-lg" />
                                                                                    <p className="mb-0 inline min-[700px]:hidden">Delete</p>
                                                                                </button> : <button disabled onClick={() => handleRemoveValueReplace(vr.index)} type="button" className="opacity-0 flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600">
                                                                                    <MdDeleteOutline className="text-lg" />
                                                                                    <p className="mb-0 inline min-[700px]:hidden">Delete</p>
                                                                                </button>
                                                                        }

                                                                    </div>
                                                                    <div className={`w-full my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                                        <Select
                                                                            options={fields}
                                                                            value={vr.field}
                                                                            onChange={e => handleValueReplacerField(e, vr.index)}
                                                                            placeholder="Select field"
                                                                            classNamePrefix={"my-react-select"}
                                                                            className="w-full min-w-[150px]"
                                                                            isSearchable
                                                                            noOptionsMessage={() => "No match found."}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="w-full flex flex-col gap-y-3">
                                                                    {
                                                                        vr.fieldValArr.map((field, fieldIndex) => (
                                                                            <div key={field.index} className="w-full flex flex-col min-[700px]:flex-row items-center gap-x-5 gap-y-3">
                                                                                <div className={`flex w-full flex-col min-[700px]:flex-row items-center gap-3 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                                                    {
                                                                                        !vr.isListType ?
                                                                                            <Form.Control className="flex-1" value={field.fieldValue1.label} onChange={e => handleValueReplacerFieldChange({ vrIndex: vr.index, value: e.target.value, fieldIndex: fieldIndex, fieldType: "field1" })} type="text" placeholder="Replace from" />
                                                                                            :
                                                                                            <Select
                                                                                                options={field.fieldValueOptions}
                                                                                                value={(field.fieldValue1 && field.fieldValue1.value) ? field.fieldValue1 : null}
                                                                                                onChange={e => handleSelectFieldReplacer({ e: e, fieldIndex: fieldIndex, vrIndex: vr.index })}
                                                                                                placeholder="Select replace from"
                                                                                                classNamePrefix={"my-react-select"}
                                                                                                className="w-full min-[700px]:w-1/2"
                                                                                                isSearchable
                                                                                                noOptionsMessage={() => "No match found."}
                                                                                            />
                                                                                    }
                                                                                    <Form.Control className="flex-1" value={field.fieldValue2} onChange={e => handleValueReplacerFieldChange({ vrIndex: vr.index, value: e.target.value, fieldIndex: fieldIndex, fieldType: "field2" })} type="text" placeholder="Set value" />
                                                                                </div>
                                                                                <div className="flex w-full min-[700px]:w-auto items-center gap-x-2 text-white">
                                                                                    {
                                                                                        fieldIndex === vr.fieldValArr.length - 1 ?
                                                                                            <button onClick={() => handleAddFieldInValueReplace(vr.index)} type="button" className="flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 rounded-full bg-indigo-500 hover:bg-indigo-600">
                                                                                                <IoMdAdd className="text-lg" />
                                                                                                <p className="mb-0 inline min-[700px]:hidden">Add</p>
                                                                                            </button> :
                                                                                            <button disabled onClick={() => handleAddFieldInValueReplace(vr.index)} type="button" className="opacity-0 flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 rounded-full bg-indigo-500 hover:bg-indigo-600">
                                                                                                <IoMdAdd className="text-lg" />
                                                                                                <p className="mb-0 inline min-[700px]:hidden">Add</p>
                                                                                            </button>
                                                                                    }
                                                                                    {
                                                                                        (fieldIndex !== 0 || vr.fieldValArr.length > 1) ?
                                                                                            <button onClick={() => handleRemoveFieldInValueReplace({ vrIndex: vr.index, fieldIndex: field.index })} type="button" className="flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600">
                                                                                                <MdDeleteOutline className="text-lg" />
                                                                                                <p className="mb-0 inline min-[700px]:hidden">Delete</p>
                                                                                            </button> :
                                                                                            <button disabled onClick={() => handleRemoveFieldInValueReplace({ vrIndex: vr.index, fieldIndex: field.index })} type="button" className="opacity-0 flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600">
                                                                                                <MdDeleteOutline className="text-lg" />
                                                                                                <p className="mb-0 inline min-[700px]:hidden">Delete</p>
                                                                                            </button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                            }
                        </div>
                }
            </div>
        </>
    )

}
