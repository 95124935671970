import moment from 'moment';
import { Modal } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa6';

const LeadExportColumn = ({ convertToCSV, showModal, setShowModal, listOfHeaders, setListOfHeaders, setListOfHeadersKey, setIsExportColumnCustomized, skin, campaign, buyer, supplier, trafficSourceData, dateRange }) => {
    const handleClose = () => {
        setShowModal(false);
    }

    const exportLead = () => {
        const data = convertToCSV(); // Assuming this function prepares your CSV data
        const escapedData = data.map(row =>
            row.map(cell => typeof cell === "string" ? cell.replaceAll(",", " ").replaceAll("\n", " ") : cell).join(",")).join("\n"
            );

        const filename = `${campaign?.label ? campaign.label : buyer ? buyer?.label : supplier ? supplier?.label : trafficSourceData ? trafficSourceData?.label : ""}_${dateRange.length > 0
            ? moment(dateRange[0].startDate.toLocaleDateString()).format("DD-MM-YYYY")
            : moment(new Date()).format("DD-MM-YYYY")
            }-${dateRange.length > 0
                ? moment(dateRange[0].endDate.toLocaleDateString()).format("DD-MM-YYYY")
                : moment(new Date()).format("DD-MM-YYYY")
            }.csv`;

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([escapedData], { type: 'text/csv;charset=utf-8' }));
        downloadLink.download = filename;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const handleSelectColumn = (colIndex) => {
        const headerArr = [...listOfHeaders], headerKeyArr = [];
        headerArr[colIndex].active = !headerArr[colIndex].active;
        setListOfHeaders(headerArr);

        headerArr?.map(h => {
            if (h.active) {
                headerKeyArr.push(h.key);
            }
        })

        setListOfHeadersKey(headerKeyArr);
        setIsExportColumnCustomized(true);
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Lead Exported Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex justify-end'>
                        <button type="button" className={`mb-3 font-medium w-full md:w-fit rounded-md px-5 py-2.5 ${skin ? "bg-slate-700 hover:bg-slate-600" : "bg-slate-300 hover:bg-slate-400"
                            } btn-sign transition-colors duration-200 ease-linear`} onClick={exportLead}>Export to CSV</button>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 min-[991px]:grid-cols-3 gap-3'>
                        {
                            listOfHeaders?.map((l, index) => (
                                <div key={index}
                                    onClick={() =>
                                        handleSelectColumn(index)
                                    } className={`cursor-pointer w-fit flex items-center gap-x-3 ${skin ? "hover:text-white" : "hover:text-black"} transition-colors duration-200 ease-linear`}>
                                    <div
                                        className={`w-[1.5rem] h-[1.5rem] rounded-md border border-stone-200 text-white ${l.active
                                            ? "bg-indigo-600"
                                            : ""
                                            } flex items-center justify-center`}
                                    >
                                        {l.active && <FaCheck />}
                                    </div>
                                    <p className='mb-0'>{l.key}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LeadExportColumn