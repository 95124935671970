import './App.css';
// import scss
import "./scss/style.scss";
import 'remixicon/fonts/remixicon.css'

import { lazy, Suspense, useEffect } from 'react';

import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import Dashboard from "./Pages/Dashboard/dashboard";
import Clients from "./Pages/Dashboard/clients";
import Campaign from './Pages/Campaign/campaigns';
import CreateCampaign from './Pages/Campaign/create_campaign';
import CreateSupplier from './Pages/Campaign/create_supplier';
// import Signup from './Pages/Auth/Signup';
import Signin from './Pages/Auth/Signin';
import 'react-toastify/dist/ReactToastify.css';
import CreateBuyer from "./Pages/Campaign/CreateBuyer";
import EditBuyer from "./Pages/Campaign/EditBuyer";
import LeadTest from "./Pages/Lead/TestLead";
import Leads from "./Pages/Lead/Leads";
import EditSupplier from "./Pages/Campaign/EditSupplier";
import EditProfile from "./Pages/Auth/EditProfile";
import Report from './Pages/Analytics/Report';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import ResetPassword from './Pages/Auth/ResetPassword';
import ReasonList from './Pages/Rejections/ReasonList';
import Returns from './Pages/Lead/Returns';
import ManageUsers from './Pages/Users/ManageUsers';
import EditClientProfile from './Pages/Users/EditClientProfile';
import AddTrafficSource from './Pages/Campaign/AddTrafficSource';
import EditTrafficSource from './Pages/Campaign/EditTrafficSource';
import TestBuyers from './Pages/TestBuyers/TestBuyers';
import moment from 'moment';
import ConversionLog from './Pages/ConversionLog/ConversionLog';
// import PublicDocs from './Pages/supplier_docs/PublicDocs';
import PrivateRoute from './Pages/Auth/PrivateRoute';
import { AuthProvider } from './Context/AuthContext';
import Loader from './Components/Loader';

const SupplierDocs = lazy(() => import('./Pages/supplier_docs/SupplierDocs'));


function App() {
    const location = useLocation();

    useEffect(() => {
        moment.suppressDeprecationWarnings = true;
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location?.pathname])


    // set skin on load
    window.addEventListener("load", function () {
        let skinMode = localStorage.getItem("skin-mode");
        let HTMLTag = document.querySelector("html");

        if (skinMode) {
            HTMLTag.setAttribute("data-skin", skinMode);
        }
    });

    return (
        <div className="App">
            <AuthProvider>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/signin" element={<Signin />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                        {/* <Route path="/supplier-api-docs/public/" element={<PublicDocs />} /> */}

                        <Route element={<PrivateRoute />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/clients" element={<Clients />} />

                            { /** Auth urls */}
                            {/* <Route path="/signup" element={<Signup />} /> */}

                            <Route path="/user" element={<EditProfile />} />
                            <Route path="/user/:id" element={<EditClientProfile />} />
                            <Route path="/manage-users" element={<ManageUsers />} />

                            {/* campaign urls */}
                            <Route path="/campaigns" element={<Campaign />} />
                            <Route path="/create-campaign/:id/" element={<CreateCampaign />} />
                            <Route path="create-buyer/:id/" element={<CreateBuyer />} />
                            <Route path="edit-buyer/:id/" element={<EditBuyer />} />
                            <Route path="/create-traffic-source/:campaignId/" element={<AddTrafficSource />} />
                            <Route path="/edit-traffic-source/:trafficSourceId/" element={<EditTrafficSource />} />

                            {/* Conversion log */}
                            <Route path="/conversions/" element={<ConversionLog />} />
                            {/* Test buyers */}
                            <Route path="/test-buyers/:campaign_id/" element={<TestBuyers />} />

                            {/* Rejection reason urls */}
                            <Route path="/rejection-reasons" element={<ReasonList />} />

                            {/*/!*supplier urls*!/*/}
                            <Route path="/create-supplier/:id/" element={<CreateSupplier />} />

                            <Route path="/supplier-api-docs/:supplierId/" element={<SupplierDocs />} />
                            <Route path="/edit-supplier/:id/" element={<EditSupplier />} />
                            {/*/!*lead urls*!/*/}
                            <Route path="/test-lead/:id/" element={<LeadTest />} />
                            <Route path="/leads" element={<Leads />} />
                            <Route path="/returns" element={<Returns />} />

                            {/* Analytics */}
                            <Route path="/analytics" element={<Report />} />

                            {/* segmentation */}
                            {/* <Route path="/segmentation" element={authData ? <Segmentation /> : <Navigate to="/signin" />} /> */}
                        </Route>
                    </Routes>
                </Suspense>
            </AuthProvider>
            <ToastContainer />
        </div>
    );
}

export default App;
