import { createContext, useContext, useEffect, useState } from "react";
import { getAuthData, UNAUTH_URLS } from "../Pages/Utils/authConfig";
import { useLocation } from "react-router-dom";
import Loader from "../Components/Loader";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const [authData, setAuthData] = useState(null);

    const fetchUserAuth = async () => {
        if (UNAUTH_URLS.includes(location.pathname)) {
            setLoading(false);
            return;
        }

        try {
            const res = await getAuthData();

            if (res.status === 200) {
                setAuthData(res.data);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUserAuth();
    }, [])

    return (
        <AuthContext.Provider value={[authData, setAuthData]}>
            {loading ?
                <Loader />
                : children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export default AuthContext;