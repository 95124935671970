import axios from "axios";
import { PHP_URL } from "./baseConfig";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

export default function AuthUtils() {
  const http = axios.create({
    baseURL: PHP_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      "Content-Type": "application/json"
    }
  });

  function removeAllCookies() {
    // Get all cookies as an object
    const allCookies = Cookies.get();
  
    // Iterate over all cookies and remove them
    for (let cookieName in allCookies) {
      // Set the cookie's expiration to a past date to remove it
      Cookies.remove(cookieName);
    }
  }

  const logout = () => {
    toast.promise(http.post("/api/auth/logout"), {
      pending: "Logging out...",
      success: {
        render({ data }) {
          setTimeout(() =>  window.location.href = "/signin", 800);
          return data.data.message;
        },
      },
      error: "Failed to logout",
    });
  };

  return {
      http,
      logout,
      removeAllCookies
  }
}