import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineCampaign } from "react-icons/md";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

const MainMenu = [
  {
    label: "Dashboard",
    link: "/",
    icon: <LuLayoutDashboard />,
    hasAccess: true
  },
  {
    label: "Campaigns",
    link: "/campaigns",
    icon: <MdOutlineCampaign />,
    hasAccess: false
  },
  {
    label: "Analytics",
    link: "/analytics",
    icon: <TbDeviceDesktopAnalytics />,
    hasAccess: false
  }
]

const settingsMenu = [
  {
    "label": "Manage Users",
    "link": "/manage-users",
    "icon": "ri-user-settings-line",
    hasAccess: false
  },
  {
    "label": "Conversions",
    "link": "/conversions",
    "icon": "ri-repeat-line",
    hasAccess: true
  },
];

const dataMenu = [
  {
    "label": "Leads",
    "link": "/leads",
    "icon": "ri-bar-chart-fill",
    hasAccess: false
  },
  {
    "label": "Returns",
    "link": "/returns",
    "icon": "ri-arrow-go-back-line",
    hasAccess: false
  },
  {
    "label": "Rejection Reasons",
    "link": "/rejection-reasons",
    "icon": "ri-prohibited-line",
    hasAccess: false
  }
]

export { MainMenu, settingsMenu, dataMenu };