import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthUtils from "../Utils/AuthUtils";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Spinner, Table } from "react-bootstrap";
import "../../assets/css/Report.css";
import { formattedDate } from "../Utils/formatDate";
import moment from "moment";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays, subMonths, startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Link } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import ReportModal from "../../Components/Analytics/ReportModal";
import { BsInfoCircle } from "react-icons/bs";
import { useAuth } from "../../Context/AuthContext";
import { convert_string_to_number } from "../Utils/numberFormatter";

function Report() {
  const { http, logout } = AuthUtils();
  const [authData, setAuthData] = useAuth();
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  // taking empty states for filtering
  // const [campaignId, setCampaignId] = useState("")
  const [campaignArr, setCampaignArr] = useState([]);
  const [campaign, setCampaign] = useState(null);

  const [showComparisonNumber, setShowComparisonNumber] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [hasAccess, setHasAccess] = useState({
    canViewTotalProfit: false,
    canViewTotalCost: false,
    canViewSupplierPayout: false,
    canViewTotalSell: false,
    canViewMargin: false,
    canViewBuyerName: false,
    canViewBuyerRevenue: false,
    canViewBuyerProfit: false,
    canViewBuyerPayout: false,
    canViewBuyerTotalMargin: false,
    canViewTrafficSourceName: false,
    canViewTrafficSourcChannelName: false,
    canViewTrafficSourceRevenue: false,
    canViewTrafficSourceProfit: false,
    canViewTrafficSourcePayout: false,
    canViewTrafficSourceTotalMargin: false,
    canViewSupplierName: false,
    canViewSupplierRevenue: false,
    canViewSupplierProfit: false,
    canViewSupplierPayout: false,
    canViewSupplierTotalMargin: false,
  });

  const [formError, setFormError] = useState({
    errorId: -1
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection"
    }
  ]);
  const [comparedDateRange, setComparedDateRange] = useState([
    {
      startDate: subDays(subMonths(new Date(), 1), 7),
      endDate: subMonths(new Date(), 1),
      key: "selection"
    }
  ]);
  const [dateRangeDirection, setDateRangeDirection] = useState("horizontal");
  const [showDateRange, setShowDateRange] = useState(false);

  const [timezone, setTimezone] = useState(null);
  const [timezones, setTimezones] = useState([]);

  // taking empty records state for all data
  const [camps, setCamps] = useState([]);

  const [totalProfit, setTotalProfit] = useState(0);
  const [wholeTotalCost, setWholeTotalCost] = useState(0);
  const [totalBuy, setTotalBuy] = useState(0);
  const [totalSell, setTotalSell] = useState(0);
  const [totalMargin, setTotalMargin] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalAcceptedLeads, setTotalAcceptedLeads] = useState(0);
  const [totalDuplicates, setTotalDuplicates] = useState(0);
  const [totalRejectedLeads, setTotalRejectedLeads] = useState(0);
  const [totalErrorLeads, setTotalErrorLeads] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [totalTrafficSourcePayout, setTotalTrafficSourcePayout] = useState(0);

  const [onPageLoad, setOnPageLoad] = useState(true);

  const dateRangeRef = useRef();

  // fetching all campaigns
  const fetchCampaigns = async () => {
    await http
      .get("/api/all-campaigns/", {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        let campaignArr = [];
        response.data.data?.map((c) => {
          campaignArr.push({
            value: c.id,
            label: c.campaign_title
          });
        });
        setCampaigns(campaignArr);
        generateRecord({ campArr: campaignArr, showOnlyProfitCampaign: true });
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch campaigns' data!");
      });
  };

  const showFormError = async ({ errId }) => {
    setFormError({
      errorId: errId
    });

    setLoading(false);

    if (errId === 1) {
      toast.error("Please select a campaign!");
    }
  };

  const generateRecord = async ({ campArr, showOnlyProfitCampaign }) => {
    await handleRecord({ campArr: campArr, showOnlyProfitCampaign: showOnlyProfitCampaign, startDate: dateRange[0].startDate, endDate: dateRange[0].endDate });
  };

  // filtering report
  const handleRecord = async ({ campArr, showOnlyProfitCampaign, startDate, endDate }) => {
    setLoading(true);
    setOnPageLoad(false);

    if (!startDate || !endDate) {
      showFormError({ errId: 2 });
      return;
    }

    const fromDate = formattedDate(new Date(startDate));
    const toDate = formattedDate(new Date(endDate));

    const formData = new FormData();
    campArr?.map((camp) => {
      formData.append("campaigns[]", camp.value);
    });
    formData.append("from_date", fromDate);
    formData.append("to_date", toDate);

    try {
      const response = await http.post("/api/analytics/", formData, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const { data } = response.data;

        const {
          total_summary,
          campaign_summary
        } = data;

        const {total_profit,
          total_sell,
          total_supplier_payout,
          total_margin,
          total_leads,
          total_accepted_leads,
          total_duplicated_leads,
          total_rejected_leads,
          total_error_leads,
          total_returned_leads,
          total_trafficsource_payout,
          total_cost,
          total_buyer_cost
        } = total_summary; 

        if (showOnlyProfitCampaign) {
          let campArr = [];
          campaign_summary?.map(c => {
            if (convert_string_to_number(c.campaign_profit) && convert_string_to_number(c.campaign_profit) > 0) {
              campArr.push(c);
            }
          });
          setCamps(campArr);
        } else {
          setCamps(campaign_summary);
        }

        setTotalProfit(total_profit);
        if (authData?.rolenames?.includes("BUYER")) {
          setWholeTotalCost(total_buyer_cost??"0.0");
        } else {
          setWholeTotalCost(total_cost);
        }
        setTotalBuy(total_supplier_payout);
        setTotalSell(total_sell);
        setTotalMargin(total_margin);
        setTotalLeads(total_leads);
        setTotalAcceptedLeads(total_accepted_leads);
        setTotalDuplicates(total_duplicated_leads);
        setTotalRejectedLeads(total_rejected_leads);
        setTotalErrorLeads(total_error_leads);
        setTotalReturns(total_returned_leads);
        setTotalTrafficSourcePayout(total_trafficsource_payout);

        setLoading(false);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to fetch record's data!");

      setLoading(false);
      setCamps([]);
    }

    setLoading(false);
  };

  const getTimezones = async () => {
    await http
      .get("/api/timezones/", {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        let timezoneArr = [];
        response.data.data?.map((t) => {
          timezoneArr.push({
            value: t.id,
            label: t.timezone_title
          });
        });
        setTimezones(timezoneArr);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch timezones' data!");
      });
  };

  const actionOnWindowSize = () => {
    if (window.innerWidth > 640) {
      setDateRangeDirection("horizontal");
    } else {
      setDateRangeDirection("vertical");
    }
  };

  const windowResize = () => {
    actionOnWindowSize();
  };

  const handleRoleBasedPermission = () => {
    // Role based view
    const { rolenames } = authData;

    let hasAccessData = JSON.parse(JSON.stringify(hasAccess));

    if (rolenames.includes("SUPER ADMIN") || rolenames.includes("ADMIN") || rolenames.includes("DEVELOPER")) {
      hasAccessData.canViewMargin = true;
      hasAccessData.canViewTotalProfit = true;
      hasAccessData.canViewTotalCost = true;
      hasAccessData.canViewTotalSell = true;
      hasAccessData.canViewSupplierPayout = true;

      hasAccessData.canViewBuyerName = true;
      hasAccessData.canViewBuyerPayout = true;
      hasAccessData.canViewBuyerProfit = true;
      hasAccessData.canViewBuyerRevenue = true;
      hasAccessData.canViewBuyerTotalMargin = true;

      hasAccessData.canViewTrafficSourceName = true;
      hasAccessData.canViewTrafficSourcChannelName = true;
      hasAccessData.canViewTrafficSourceRevenue = true;
      hasAccessData.canViewTrafficSourceProfit = true;
      hasAccessData.canViewTrafficSourcePayout = true;
      hasAccessData.canViewTrafficSourceTotalMargin = true;

      hasAccessData.canViewSupplierName = true;
      hasAccessData.canViewSupplierPayout = true;
      hasAccessData.canViewSupplierProfit = true;
      hasAccessData.canViewSupplierRevenue = true;
      hasAccessData.canViewSupplierTotalMargin = true;
    } else if (rolenames.includes("BUYER")) {
      hasAccessData.canViewTotalCost = true;
      // hasAccessData.canViewSupplierPayout = true;
      // hasAccessData.canViewTrafficSourcePayout = true;

      hasAccessData.canViewBuyerName = true;
      hasAccessData.canViewBuyerPayout = true;
      // hasAccessData.canViewBuyerProfit = true;
      // hasAccessData.canViewBuyerRevenue = true;
      // hasAccessData.canViewBuyerTotalMargin = true;
    } else if (rolenames.includes("SUPPLIER")) {
      // hasAccessData.canViewSupplierPayout = true;

      hasAccessData.canViewSupplierName = true;
      hasAccessData.canViewSupplierPayout = true;
      // hasAccessData.canViewSupplierProfit = true;
      // hasAccessData.canViewSupplierRevenue = true;
      // hasAccessData.canViewSupplierTotalMargin = true;
    } else if (rolenames.includes("TRAFFIC SOURCE")) {
      hasAccessData.canViewTrafficSourceName = true;
      hasAccessData.canViewTrafficSourcChannelName = true;
      hasAccessData.canViewTrafficSourcePayout = true;
      // hasAccessData.canViewTrafficSourceRevenue = true;
      // hasAccessData.canViewTrafficSourceProfit = true;
      // hasAccessData.canViewTrafficSourceTotalMargin = true;
    }

    setHasAccess(hasAccessData);
  }

  useEffect(() => {
    if (authData) {
      handleRoleBasedPermission();
      fetchCampaigns();
      getTimezones();

      actionOnWindowSize();
      window.addEventListener("resize", windowResize);
    }
  }, [authData]);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleDateRangeSelection = (item) => {
    setDateRange([item.selection]);
    if (showComparisonNumber) {
      setComparedDateRange([
        {
          startDate: subMonths(new Date(item.selection.startDate), 1),
          endDate: addDays(subMonths(new Date(item.selection.endDate), 1), 7),
          key: "selection"
        }
      ]);
    }
  };

  const handleDateRangeChangeState = (show) => {
    setShowDateRange(show);

    show
      ? document.addEventListener("click", dateRangeEventListener)
      : document.removeEventListener("click", dateRangeEventListener);
  };

  const dateRangeEventListener = (e) => {
    if (dateRangeRef.current && dateRangeRef.current.contains(e.target)) return;
    handleDateRangeChangeState(false);
  };

  // const toggleComparisonNumber = (show) => {
  //   setShowComparisonNumber(show);
  // };

  const handleCampaignReportModal = (campaign) => {
    setCampaign(campaign);
    setShowModal(true);
  };

  const handleCampaignArr = e => {
    setCampaignArr(e);
    generateRecord({ campArr: e, showOnlyProfitCampaign: onPageLoad ? true : false });
  }

  return (
    <>
      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <ToastContainer />

      <ReportModal
        hasAccess={hasAccess}
        showModal={showModal}
        setShowModal={setShowModal}
        campaign={campaign}
        setCampaign={setCampaign}
        skin={skin}
      />

      <div className="main main-app p-4 p-lg-5">
        <div className="flex flex-col gap-y-5">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3">
            <h5 className="text-xl font-semibold">Analytics</h5>
          </div>
          <Card>
            {showDateRange && <div className="overlay"></div>}
            <Card.Body>
              <div
                className={`flex flex-col gap-y-3 my-react-select-container ${skin ? "dark__mode" : ""
                  }`}
              >
                <div className="flex flex-col md:flex-row md:items-center justify-between gap-3">
                  <div
                    className={`flex flex-wrap items-center gap-3 w-full my-react-select-container ${skin ? "dark__mode" : ""
                      }`}
                  >
                    <div className="flex w-full sm:w-auto flex-wrap items-center gap-3">
                      <div
                        ref={dateRangeRef}
                        className="w-full sm:w-auto relative"
                      >
                        <div
                          onClick={() =>
                            handleDateRangeChangeState(!showDateRange)
                          }
                          className={`cursor-pointer flex items-center gap-x-1 border border-stone-300 p-2 rounded-md ${skin ? "" : "bg-white"
                            }`}
                        >
                          {dateRange.length > 0 && (
                            <>
                              <span
                                className={`${skin ? "text-stone-400" : ""}`}
                              >
                                <FaRegCalendarAlt />
                              </span>
                              <p
                                className={`mb-0 ${skin ? "text-stone-400" : ""
                                  }`}
                              >
                                {moment(
                                  dateRange[0].startDate.toLocaleDateString()
                                ).format("MMM DD, YYYY")}
                              </p>
                              <span
                                className={`${skin ? "text-stone-400" : ""}`}
                              >
                                -
                              </span>
                              <p
                                className={`mb-0 ${skin ? "text-stone-400" : ""
                                  }`}
                              >
                                {moment(
                                  dateRange[0].endDate.toLocaleDateString()
                                ).format("MMM DD, YYYY")}
                              </p>
                            </>
                          )}
                        </div>

                        {showDateRange && (
                          <div
                            className={`absolute top-full left-0 z-[1001] w-full date__rangePicker report__dateRangePicker ${skin ? "dark__mode" : ""
                              }`}
                          >
                            <DateRangePicker
                              weekStartsOn={1}
                              editableDateInputs={true}
                              onChange={(item) =>
                                handleDateRangeSelection(item)
                              }
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={dateRange}
                              direction={dateRangeDirection}
                            />
                          </div>
                        )}
                      </div>

                      {showComparisonNumber && (
                        <>
                          <p
                            className={`font-semibold mb-0 ${skin ? "text-stone-400" : ""
                              }`}
                          >
                            Compared to
                          </p>

                          <div className="w-full sm:w-auto relative">
                            <div
                              className={`flex items-center gap-x-1 border border-stone-300 p-2 rounded-md ${skin ? "" : "bg-white"
                                }`}
                            >
                              {comparedDateRange.length > 0 && (
                                <>
                                  <span
                                    className={`${skin ? "text-stone-400" : ""
                                      }`}
                                  >
                                    <FaRegCalendarAlt />
                                  </span>
                                  <p
                                    className={`mb-0 ${skin ? "text-stone-400" : ""
                                      }`}
                                  >
                                    {moment(
                                      comparedDateRange[0].startDate.toLocaleDateString()
                                    ).format("MMM DD, YYYY")}
                                  </p>
                                  <span
                                    className={`${skin ? "text-stone-400" : ""
                                      }`}
                                  >
                                    -
                                  </span>
                                  <p
                                    className={`mb-0 ${skin ? "text-stone-400" : ""
                                      }`}
                                  >
                                    {moment(
                                      comparedDateRange[0].endDate.toLocaleDateString()
                                    ).format("MMM DD, YYYY")}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {/* <Select
                      options={timezones}
                      value={timezone}
                      onChange={setTimezone}
                      placeholder="Select timezone"
                      classNamePrefix={"my-react-select"}
                      className="w-full sm:w-auto min-w-[150px] whitespace-nowrap"
                      isSearchable
                      noOptionsMessage={() => "No match found."}
                    /> */}
                  </div>

                  <div>
                    <button
                      onClick={() => generateRecord({ campArr: campaignArr, showOnlyProfitCampaign: false })}
                      type="submit"
                      className="font-medium h-fit rounded-md px-5 py-2.5 bg-indigo-500 hover:bg-indigo-600 text-white btn-sign transition-colors duration-200 ease-linear"
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="whitespace-nowrap">
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Loading...
                        </div>
                      ) : (
                        "Generate"
                      )}
                    </button>
                    {/* {
                      data.length > 0 && dateRange &&
                      camps.map(camp => (
                        <CSVLink data={convertToCSV()} filename={`${camp.campaign_name}_${dateRange.startDate ? moment(dateRange.startDate).format("DD-MM-YYYY") : moment(new Date()).format("DD-MM-YYYY")}-${dateRange.endDate ? moment(dateRange.endDate).format("DD-MM-YYYY") : moment(new Date()).format("DD-MM-YYYY")}.csv`} target="_blank" className={`w-full md:w-fit whitespace-nowrap ${skin ? "text-stone-100" : "text-stone-800"}`}>
                          <button className={`font-medium w-full md:w-fit rounded-md px-5 py-2.5 ${skin ? "bg-slate-700" : "bg-slate-300"} btn-sign transition-colors duration-200 ease-linear`}>
                            <FaDownload className="inline-block" /> Export all
                          </button>
                        </CSVLink>
                      ))
                    } */}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between gap-x-5 gap-y-3">
                  <div className="flex w-full flex-wrap items-center gap-3">
                    <Select
                      options={campaigns}
                      value={campaignArr}
                      onChange={handleCampaignArr}
                      isMulti={true}
                      placeholder="Select campaign"
                      classNamePrefix={"my-react-select"}
                      className="w-full sm:w-auto min-w-[212px] whitespace-nowrap"
                      isSearchable
                      noOptionsMessage={() => "No match found."}
                    />
                  </div>
                  {/* <div onClick={() => toggleComparisonNumber(!showComparisonNumber)} className={`${skin ? "text-stone-400" : ""} w-full cursor-pointer flex items-center gap-x-1 border border-stone-300 rounded-md p-2 ${skin ? "" : "bg-white"}`}>
                    <div className={`relative w-[1.3rem] h-[11px] border border-stone-300 rounded-md ${showComparisonNumber ? "bg-teal-600" : "bg-stone-400"} transition-colors duration-200 ease-linear`}>
                      <div className={`absolute top-0 left-0 ${showComparisonNumber ? "translate-x-full" : "translate-x-0"} w-[10px] h-[10px] rounded-full bg-white transition-all duration-200 ease-linear`}></div>
                    </div>
                    {showComparisonNumber ? "Hide" : "Show"} Comparison Numbers
                  </div> */}
                </div>
              </div>
            </Card.Body>
            {
              <>
                {
                  !loading &&
                  <Card.Body>
                    <div
                      className="grid grid-cols-2 border-y border-stone-300"
                      id="report__dataGrid"
                    >
                      <div className="px-3 grid grid-cols-1 min-[380px]:grid-cols-2 sm:grid-cols-3 items-center gap-x-5 gap-y-3 py-3 uppercase">
                        {
                          hasAccess.canViewTotalProfit &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              Profit
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(totalProfit) < 0 ? "text-danger" : ""}`}
                            >
                              {convert_string_to_number(totalProfit) < 0 ? "-" : ""}$
                              {Math.abs(convert_string_to_number(totalProfit)).toFixed(2)}
                            </p>
                          </div>
                        }
                        {
                          hasAccess.canViewTotalCost &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              {/* total whole cost */}
                              TOTAL COST
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(wholeTotalCost) < 0 ? "text-danger" : ""}`}
                            >
                              {convert_string_to_number(wholeTotalCost) < 0 ? "-" : ""}$
                              {Math.abs(convert_string_to_number(wholeTotalCost)).toFixed(2)}
                            </p>
                          </div>
                        }
                        {
                          hasAccess.canViewSupplierPayout &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              Supplier Payout
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(totalBuy) < 0 ? "text-danger" : ""}`}
                            >
                              {convert_string_to_number(totalBuy) < 0 ? "-" : ""}$
                              {Math.abs(convert_string_to_number(totalBuy)).toFixed(2)}
                            </p>
                          </div>
                        }
                        {
                          hasAccess.canViewTrafficSourcePayout &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              Traffic Source Payout
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(totalTrafficSourcePayout) < 0 ? "text-danger" : ""
                                }`}
                            >
                              {convert_string_to_number(totalTrafficSourcePayout) < 0 ? "-" : ""}$
                              {Math.abs(convert_string_to_number(totalTrafficSourcePayout)).toFixed(2)}
                            </p>
                          </div>
                        }
                        {
                          hasAccess.canViewTotalSell &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              Total Sell
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(totalSell) < 0 ? "text-danger" : ""}`}
                            >
                              {convert_string_to_number(totalSell) < 0 ? "-" : ""}$
                              {Math.abs(convert_string_to_number(totalSell)).toFixed(2)}
                            </p>
                          </div>
                        }
                        {
                          hasAccess.canViewMargin &&
                          <div className="flex flex-col gap-y-2 text-center">
                            <p
                              className={`${skin ? "text-stone-400" : "text-stone-500"
                                } mb-0`}
                            >
                              Margin
                            </p>
                            <p
                              className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                                } ${convert_string_to_number(totalMargin) < 0 ? "text-danger" : ""}`}
                            >
                              {convert_string_to_number(totalMargin) < 0 ? "-" : ""}
                              {Math.abs(convert_string_to_number(totalMargin)).toFixed(2)}%
                            </p>
                          </div>
                        }
                      </div>

                      <div className="px-3 border-l border-stone-300 grid grid-cols-1 min-[380px]:grid-cols-2 sm:grid-cols-3 items-center gap-x-5 gap-y-3 py-3 uppercase">
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Leads
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base text-primary`}
                          >
                            {convert_string_to_number(totalLeads)}
                          </p>
                        </div>
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Accepted
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base text-success`}
                          >
                            {convert_string_to_number(totalAcceptedLeads)}
                          </p>
                        </div>
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Duplicated
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base text-warning`}
                          >
                            {convert_string_to_number(totalDuplicates)}
                          </p>
                        </div>
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Rejected
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base text-danger`}
                          >
                            {convert_string_to_number(totalRejectedLeads)}
                          </p>
                        </div>
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Error
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base text-danger`}
                          >
                            {convert_string_to_number(totalErrorLeads)}
                          </p>
                        </div>
                        <div className="flex flex-col gap-y-2 text-center">
                          <p
                            className={`${skin ? "text-stone-400" : "text-stone-500"
                              } mb-0 whitespace-nowrap`}
                          >
                            Returned
                          </p>
                          <p
                            className={`font-semibold mb-0 text-base ${skin ? "text-stone-300" : ""
                              }`}
                          >
                            {convert_string_to_number(totalReturns)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                }
                <Card.Body>
                  {loading ? (
                    <div className="pb-3 d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : camps.length ? (
                    camps.map((campaign, index) => (
                      <Card
                        key={campaign.campaign_id}
                        className={`${index !== 0 || index !== camps.length - 1
                          ? "my-3"
                          : ""
                          }`}
                      >
                        <Card.Header
                          className={`${!skin && "bg-white"
                            } flex flex-wrap items-center justify-between gap-x-5 gap-y-3`}
                        >
                          <div className="w-full flex flex-wrap items-center justify-between gap-3">
                            <div
                              onClick={() =>
                                handleCampaignReportModal(campaign)
                              }
                              className={`cursor-pointer hover:underline text-indigo-500 hover:text-indigo-700 text-base min-[640px]:text-lg font-semibold transition-colors duration-200 ease-linear`}
                            >
                              {campaign.campaign_name}
                            </div>
                            <button
                              onClick={() =>
                                handleCampaignReportModal(campaign)
                              }
                              type="button"
                              className="px-2 py-1.5 rounded-md flex items-center gap-x-1 bg-indigo-500 hover:bg-indigo-700 text-white transition-colors duration-200 ease-linear"
                            >
                              <BsInfoCircle className="text-lg" />
                              More Info
                            </button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <div className="flex flex-col gap-y-3">
                            <div
                              className={`flex flex-col gap-y-3 border ${skin ? "border-stone-500" : "border-stone-200"
                                } p-3 rounded-md`}
                            >
                              <div
                                className={`uppercase font-semibold ${skin ? "text-stone-400" : "text-stone-500"
                                  }`}
                              >
                                Lead Info
                              </div>
                              <Table
                                variant={skin && "dark"}
                                hover
                                className="mb-0"
                                responsive
                              >
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Posted
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Accepted
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Accepted CPL
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Accepted CPA
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Rejected
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Errors
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Acceptance Rate (CPL)
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Acceptance Rate (CPA)
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Revenue
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Costs
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Profit
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-lg px-3 font-semibold whitespace-nowrap"
                                    >
                                      Margin
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {campaign.campaign_total_posted}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {campaign.campaign_total_accepted}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {campaign.campaign_total_accepted_cpl}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {campaign.campaign_total_accepted_cpa}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_total_rejected)}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {campaign.campaign_total_errors}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          } ${convert_string_to_number(campaign.campaign_total_accepted_cpl_rate) <
                                            0
                                            ? "text-danger"
                                            : ""
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_total_accepted_cpl_rate).toFixed(
                                          2
                                        )}
                                        %
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          } ${convert_string_to_number(campaign.campaign_total_accepted_cpa_rate) <
                                            0
                                            ? "text-danger"
                                            : ""
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_total_accepted_cpa_rate).toFixed(
                                          2
                                        )}
                                        %
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          } ${convert_string_to_number(campaign.campaign_total_revenue) < 0
                                            ? "text-danger"
                                            : ""
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_total_revenue) < 0
                                          ? "-"
                                          : ""}
                                        $
                                        {Math.abs(
                                          convert_string_to_number(campaign.campaign_total_revenue)
                                        ).toFixed(2)}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          } ${convert_string_to_number(campaign.campaign_costs) < 0
                                            ? "text-danger"
                                            : ""
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_costs) < 0 ? "-" : ""}
                                        $
                                        {Math.abs(
                                          convert_string_to_number(campaign.campaign_costs)
                                        ).toFixed(2)}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      <Link
                                        to="#"
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          } ${convert_string_to_number(campaign.campaign_profit) < 0
                                            ? "text-danger"
                                            : ""
                                          }`}
                                      >
                                        {convert_string_to_number(campaign.campaign_profit) < 0
                                          ? "-"
                                          : ""}
                                        $
                                        {Math.abs(
                                          convert_string_to_number(campaign.campaign_profit)
                                        ).toFixed(2)}
                                      </Link>
                                    </td>
                                    <td className="px-3 align-middle">
                                      ${
                                        convert_string_to_number(campaign.campaign_margin) ?
                                          <Link
                                            to="#"
                                            className={`${skin
                                              ? "text-stone-300"
                                              : "text-inherit"
                                              } ${convert_string_to_number(campaign.campaign_margin) < 0
                                                ? "text-danger"
                                                : ""
                                              }`}
                                          >
                                            {convert_string_to_number(campaign.campaign_margin).toFixed(2)}%
                                          </Link> : "0.00"
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <p className="text-lg md:text-xl text-center font-semibold text-stone-500">
                      No data found yet!
                    </p>
                  )}
                </Card.Body>
              </>
            }
          </Card>
        </div>
      </div>
    </>
  );
}

export default Report;
