import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthUtils from "../Utils/AuthUtils";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Card, Col, Row, Form, Spinner } from "react-bootstrap";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import Select from "react-select"
import { MdDeleteOutline } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FaRegCopy, FaUnlock } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { isValidIPAddress } from "../Utils/ipAddressValidation";
import { useAuth } from "../../Context/AuthContext";
import { insertTextAtCursor } from "../Utils/profile";


const IS_ACTIVE = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "No"
  }
]

const STATUSES = [
  {
    value: "ACCEPTED",
    label: "Accepted"
  },
  {
    value: "REJECTED",
    label: "Rejected"
  },
  {
    value: "DUPLICATED",
    label: "Duplicated"
  },
  {
    value: "ERROR",
    label: "Error"
  },
  {
    value: "STORED",
    label: "Stored"
  },
  {
    value: "RETURNED",
    label: "Returned"
  },
  {
    value: "PENDING",
    label: "Pending"
  }
]


const EditClientProfile = () => {
  const { http, logout } = AuthUtils();

  const [authData, setAuthData] = useAuth();

  const clientParams = useParams();

  const [tabState, setTabState] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({
    errorId: -1
  });
  const [isActive, setIsActive] = useState(IS_ACTIVE[0]);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);

  // Postback
  const [postbackUrl, setPostbackUrl] = useState("");
  const [postbackUrlRequired, setPostbackUrlRequired] = useState(true);
  const [isExternalId, setIsExternalId] = useState(false);
  const [isPayout, setIsPayout] = useState(false);
  const [isLeadType, setIsLeadType] = useState(false);
  const [isPostbackStatus, setIsPostbackStatus] = useState(false);
  const [showPostbackStatus, setShowPostbackStatus] = useState(false);
  const [postbackParameters, setPostbackParameters] = useState([
    {
      status: "",
      postback_url: "",
      index: 0
    }
  ])

  const [permissionCampaigns, setPermissionCampaigns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [isPermissionCampaignFetching, setIsPermissionCampaignFetching] = useState(false);

  // API key
  const [apiKey, setApiKey] = useState("");
  const [domains, setDomains] = useState("");
  const [apiButtonLoader, setApiButtonLoader] = useState(false);
  const [domainButtonLoader, setDomainButtonLoader] = useState(false);

  const [hasAccess, setHasAccess] = useState({
    canViewAPIKeyAndDomain: false,
    canViewAPIKey: false,
    canViewDomain: false,
    canUpdateDomain: false
  });

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const domainRef = useRef(null);
  const apiKeyCopiedRef = useRef(null);

  const postbackUrlRef = useRef();

  // const navLinkRef = useRef();

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const showFormError = async ({ errId }) => {
    setFormError({
      errorId: errId,
    });

    if (errId === 1) {
      firstNameRef.current.classList.add("error__field");
      firstNameRef.current?.focus();
    } else if (errId === 2) {
      lastNameRef.current.classList.add("error__field");
      lastNameRef.current?.focus();
    } else if (errId === 4) {
      companyRef.current.classList.add("error__field");
      companyRef.current?.focus();
    } else if (errId === 5) {
      passwordRef.current.classList.add("error__field");
      passwordRef.current?.focus();
    } else if (errId === 6) {
      confirmPasswordRef.current.classList.add("error__field");
      confirmPasswordRef.current?.focus();
    } else if (errId === 8) {
      postbackUrlRef.current.classList.add("error__field");
      postbackUrlRef.current?.focus();
    } else if (errId === 9) {
      domainRef.current.classList.add("error__field");
      domainRef.current?.focus();
    }

    setLoading(false);
  }

  // fetching users
  const getClientDetails = async (clientId) => {
    setIsDataFetching(true);

    await http
      .get(`/api/client-details/${clientId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(async (response) => {
        const { first_name, last_name, email, phone_number, company_name, user, campaigns, postback_url, postback_body, apiKey, domains: ipDomains } = response.data.data;
        // setting the value after fetching
        setFirstName(first_name[0].toUpperCase() + first_name.slice(1,));
        setLastName(last_name[0].toUpperCase() + last_name.slice(1,));
        setEmail(email);
        setPhone(phone_number);
        setCompanyName(company_name);

        apiKey && setApiKey(apiKey);

        let ipDomain = "";

        if (ipDomains?.length) {
          ipDomain = ipDomains.join(", ");
        }
        setDomains(ipDomain);

        if (postback_url) {
          setPostbackUrl(postback_url);

          postback_url.includes("{externalid}") ? setIsExternalId(true) : setIsExternalId(false);
          postback_url.includes("{payout}") ? setIsPayout(true) : setIsPayout(false);
          postback_url.includes("{lead_type}") ? setIsLeadType(true) : setIsLeadType(false);
          postback_url.includes("{status}") ? setIsPostbackStatus(true) : setIsPostbackStatus(false);
        }

        if (postback_body && postback_body.length) {
          setShowPostbackStatus(true);
          setPostbackUrlRequired(false);
          setPostbackParameters(postback_body);
        } else {
          setPostbackUrlRequired(true);
        }

        await fetchCampaigns();

        if (user && user.roles.length) {
          let roleArr = [];

          user.roles?.map(r => {
            roleArr.push({
              value: r.id,
              label: r.name
            })
          })
          setRole(roleArr[0]);

          let campArr = [];

          campaigns?.map(camp => {
            campArr.push({
              ...camp,
              value: camp.id,
              label: camp.campaign_title
            })
          })
          setPermissionCampaigns(campArr);
        }
        if (user.is_active) {
          setIsActive(IS_ACTIVE[0]);
        } else {
          setIsActive(IS_ACTIVE[1]);
        }
        // setUser(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch user details!");
        setIsDataFetching(false);
      });

    setIsDataFetching(false);
  };

  // updating user details
  // updating user's initial state
  const updateProfile = async (e) => {
    e.preventDefault();

    showFormError({
      errId: -1
    });
    setLoading(true);

    if (firstName === "") {
      showFormError({
        errId: 1,
      });
      return;
    }
    if (lastName === "") {
      showFormError({
        errId: 2,
      });
      return;
    }
    if (email === "") {
      showFormError({
        errId: 3
      })
      return;
    }
    if (updatePassword) {
      if (password === "") {
        showFormError({
          errId: 5
        })
        return;
      }
      if (confirmPassword === "") {
        showFormError({
          errId: 6
        })
        return;
      }
    }
    if (!companyName) {
      showFormError({
        errId: 4
      })
      return;
    }
    // For multi role
    // if (!role || role.length === 0) {
    //   toast.error("Please select a role!");
    //   setLoading(false);
    //   return;
    // }

    // For single role
    if (!role) {
      toast.error("Please select a role!");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      showFormError({
        errId: 5
      });
      showFormError({
        errId: 6
      });
      return;
    }

    let roleArr = [], campArr = [];
    // For single role
    roleArr.push(role.label);

    // For multi role
    // role?.map(r => {
    //   roleArr.push(r.label);
    // })

    permissionCampaigns?.map(c => {
      campArr.push(c.value)
    });

    const formData = {
      first_name: firstName[0].toUpperCase() + firstName.slice(1,),
      last_name: lastName[0].toUpperCase() + lastName.slice(1,),
      email: email,
      phone_number: phone,
      company_name: companyName,
      is_active: isActive?.value,
      is_update_password: updatePassword,
      password: password,
      roles: roleArr,
      campaigns: campArr
    }

    await http
      .put(`/api/update-client/${clientParams.id}/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const { first_name, last_name } = response.data.data;

        setFirstName(first_name);
        setLastName(last_name);

        toast.success("User profile is updated successfully.");
        setLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;

        if (data) {
          const { messages, email } = data;

          if (messages === "Unauthenticated.") {
            logout();
          }
          if (messages) {
            const { password, email } = messages;

            if (email) {
              toast.error(email[0]);
              setLoading(false);
              return;
            }
            if (password) {
              toast.error(password[0]);
              setLoading(false);
              return;
            }
          }
        }

        setLoading(false);
        toast.error("Failed to update profile!");
      });
  };

  const fetchAllRoles = async () => {
    const { rolenames } = authData;

    try {
      const res = await http.get("/api/roles/", {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (res.status === 200) {
        const { data } = res.data;
        let rolesArr = [];
        data?.map(r => {
          if (r.name !== "DEVELOPER") {
            rolesArr.push({
              value: r.id,
              label: r.name
            })
          }
        })

        if (!rolenames.includes("SUPER ADMIN") && rolesArr.length) {
          rolesArr.map((r, index) => {
            if (r.label === "SUPER ADMIN") {
              rolesArr.splice(index, 1);
            }
          });
        }

        setRoles(rolesArr);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to load roles' data!");
    }
  }

  // Can update domain or not
  const handleDomainAccess = () => {
    const { rolenames } = authData;

    let hasAccessOptions = JSON.parse(JSON.stringify(hasAccess));

    if (rolenames.includes("SUPER ADMIN")) {
      hasAccessOptions.canViewAPIKeyAndDomain = true;
      hasAccessOptions.canViewAPIKey = true;
      hasAccessOptions.canViewDomain = true;
      hasAccessOptions.canUpdateDomain = true;
    } else if (rolenames.includes("ADMIN")) {
      hasAccessOptions.canViewAPIKeyAndDomain = true;
      hasAccessOptions.canViewAPIKey = true;
      hasAccessOptions.canViewDomain = true;
      hasAccessOptions.canUpdateDomain = true;
    }

    setHasAccess(hasAccessOptions);
  }

  useEffect(() => {
    if (clientParams && authData) {
      fetchAllRoles();
      handleDomainAccess();
      getClientDetails(clientParams.id);
    }
  }, [authData]);

  const handleFieldChange = async (e) => {
    showFormError({
      errId: -1
    });

    if (e.target.name === "first__name") {
      setFirstName(e.target.value);

      if (e.target.value !== "") {
        firstNameRef.current.classList.remove("error__field");
      } else {
        showFormError({ errId: 1 });
      }
    } else if (e.target.name === "last__name") {
      setLastName(e.target.value);

      if (e.target.value !== "") {
        lastNameRef.current.classList.remove("error__field");
      } else {
        showFormError({ errId: 2 });
      }
    } else if (e.target.name === "password") {
      setPassword(e.target.value);

      if (e.target.value !== "") {
        passwordRef.current.classList.remove("error__field");
      } else {
        showFormError({ errId: 5 });
      }
    } else if (e.target.name === "confirm__password") {
      setConfirmPassword(e.target.value);

      if (e.target.value !== "") {
        confirmPasswordRef.current.classList.remove("error__field");
      } else {
        showFormError({ errId: 6 });
      }
    } else if (e.target.name === "company__name") {
      setCompanyName(e.target.value);

      if (e.target.value !== "") {
        companyRef.current.classList.remove("error__field");
      } else {
        showFormError({ errId: 4 });
      }
    } else if (e.target.name === "postback__url") {
      const urlVal = e.target.value;

      urlVal.includes("{externalid}") ? setIsExternalId(true) : setIsExternalId(false);
      urlVal.includes("{payout}") ? setIsPayout(true) : setIsPayout(false);
      urlVal.includes("{lead_type}") ? setIsLeadType(true) : setIsLeadType(false);
      urlVal.includes("{status}") ? setIsPostbackStatus(true) : setIsPostbackStatus(false);

      setPostbackUrl(urlVal);

      if (urlVal !== "") {
        postbackUrlRef.current.classList.remove("error__field");
      } else if (postbackUrlRequired) {
        showFormError({ errId: 8 });
      }
    } else if (e.target.name === "domains") {
      setDomains(e.target.value);

      if (e.target.value !== "") {
        const ipAddressArr = e.target.value.trim().split(",");
        let isValidIp = true;

        for (let i = 0; i < ipAddressArr.length; i++) {
          const ipAddress = ipAddressArr[i]?.trim();

          if (ipAddress) {
            isValidIp = isValidIPAddress(ipAddress);
          }

          if (!isValidIp) break;
        }

        if (isValidIp) {
          domainRef.current.classList.remove("error__field");
        } else {
          showFormError({ errId: 9 });
        }
      } else {
        domainRef.current.classList.remove("error__field");
      }
    }
  }

  const handleTabState = activeTab => {
    setTabState(activeTab);
  }

  const updatePostback = async (e) => {
    e.preventDefault();

    showFormError({
      errId: -1
    });
    setLoading(true);

    if (postbackUrlRequired && postbackUrl === "") {
      showFormError({
        errId: 8,
      });
      return;
    }

    let postbackParamArr = [];

    if (!postbackUrlRequired && showPostbackStatus) {
      postbackParameters?.map(pbp => {
        postbackParamArr.push(pbp);
      })
    }

    const formData = {
      postback_url: postbackUrl,
      show_postback_status: showPostbackStatus,
      postback_body: postbackParamArr
    }

    try {
      const res = await http.put(`/api/update-postback/${clientParams.id}/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        toast.success("Postback updated successfully.");
      }

      setLoading(false);
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to update postback!");
      setLoading(false);
    }
  }

  const handleRemovePostbackParameter = pbIndex => {
    setPostbackParameters(postbackParameters.filter(pbp => pbp.index !== pbIndex));
  }

  const handleAddPostbackParameter = () => {
    const pbIndex = postbackParameters[postbackParameters.length - 1]?.index;
    const newPbIndex = pbIndex + 1;
    setPostbackParameters(prevPb => [...prevPb, {
      status: "",
      postback_url: "",
      index: newPbIndex
    }]);
  }

  const handlePostbackStatusChange = ({ e, index }) => {
    let pbParams = [...postbackParameters];

    for (let i = 0; pbParams.length; i++) {
      const pbParam = pbParams[i];

      if (pbParam.index === index) {
        pbParam["status"] = e.value;
        break;
      }
    }

    setPostbackParameters(pbParams);
  }

  const handlePostbackData = ({ e, index }) => {
    let pbParams = [...postbackParameters];

    for (let i = 0; pbParams.length; i++) {
      const pbParam = pbParams[i];

      if (pbParam.index === index) {
        if (e.target.name === `param__name-${index}`) {
          pbParam.status = e.target.value;
        } else if (e.target.name === `param__val-${index}`) {
          pbParam.postback_url = e.target.value;
        }
        break;
      }
    }

    setPostbackParameters(pbParams);
  }

  const removePostbackExternalOptions = ({ url, param }) => {
    if (url.includes(`{${param}}`)) {
      url = url.replaceAll(`{${param}}`, "");
    }

    return url;
  }

  const handleUpdatePassword = (isUpdate) => {
    setUpdatePassword(isUpdate);

    if (isUpdate) {
      setTimeout(() => {
        passwordRef.current.focus();
      }, 50)
    }
  }

  const handlePostbackOptions = ({ optionType, optionValue }) => {
    let url = postbackUrl;

    if (optionValue) {
      url = insertTextAtCursor(postbackUrlRef.current, `{${optionType.toLowerCase()}}`);
      postbackUrlRef.current.focus();
    } else {
      url = removePostbackExternalOptions({ url: url, param: optionType.toLowerCase() });
      postbackUrlRef.current.focus();
    }

    switch (optionType) {
      case "externalId":
        setIsExternalId(optionValue);
        break;
      case "payout":
        setIsPayout(optionValue);
        break;
      case "lead_type":
        setIsLeadType(optionValue);
        break;
      case "status":
        setIsPostbackStatus(optionValue);
        break;
    }

    setPostbackUrl(url);
  }

  const fetchCampaigns = async () => {
    setIsPermissionCampaignFetching(true);

    try {
      const res = await http.get("/api/all-campaigns/", {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        let campArr = [];

        res.data.data?.map(camp => {
          campArr.push({
            ...camp,
            value: camp.id,
            label: camp.campaign_title
          })
        })

        setAllCampaigns(campArr);
        setIsPermissionCampaignFetching(false);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      // console.log(err);
      setAllCampaigns([]);
      setIsPermissionCampaignFetching(false);
    }
  }

  const handlePermissionCampaign = (e) => {
    if (!e) {
      setPermissionCampaigns([]);
    } else {
      setPermissionCampaigns(e);
    }
  }

  function handlePostbackRelationStatus() {
    setPostbackUrlRequired(prevState => !prevState);
    setShowPostbackStatus(prevState => !prevState);
  }

  const generateAPIKey = async (e) => {
    e.preventDefault();

    setFormError({
      errorId: -1
    });

    setApiButtonLoader(true);

    try {
      const res = await http.post(`/api/generate-api-key/${clientParams.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { data, message } = res.data;

        setApiKey(data.api_key);
        toast.success(message);

        setApiButtonLoader(false);
      }
    } catch (err) {
      const { data } = err?.response;

      if (data) {
        const { message } = data;

        if (message) {
          toast.error(message);
        }
        setApiButtonLoader(false);
        return;
      }

      toast.error("Failed to generate a new API key!");
      setApiButtonLoader(false);
    }
  }

  const updateDomains = async (e) => {
    e.preventDefault();

    setFormError({
      errorId: -1
    });

    setDomainButtonLoader(true);

    if (authData) {
      const { rolenames } = authData;

      if (!rolenames.includes("SUPER ADMIN") && !authData.rolenames.includes("ADMIN")) {
        toast.error("You are not authorized to update the domain!");
        setDomainButtonLoader(false);
        return;
      }
    }

    const ipAddressArr = domains.split(",");
    let isValidIp = true;

    for (let i = 0; i < ipAddressArr.length; i++) {
      const ipAddress = ipAddressArr[i]?.trim();

      if (ipAddress) {
        isValidIp = isValidIPAddress(ipAddress);
      }

      if (!isValidIp) break;
    }

    if (!isValidIp) {
      setDomainButtonLoader(false);
      showFormError({ errId: 9 });
      return;
    }

    let validIpAddr = [];

    ipAddressArr.map(ip => {
      ip.trim() && validIpAddr.push(ip.trim());
    });

    let formData = {
      domains: validIpAddr
    }

    try {
      const res = await http.post(`/api/update-client-domains/${clientParams.id}/`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { data, message } = res.data;

        const ipAddress = data.join(", ");
        setDomains(ipAddress);

        toast.success(message);

        setDomainButtonLoader(false);
      }
    } catch (err) {
      const { data } = err?.response;

      if (data) {
        const { message } = data;

        if (message) {
          toast.error(message);
        }
        setDomainButtonLoader(false);
        return;
      }

      toast.error("Failed to update the domain(s)!");
      setDomainButtonLoader(false);
    }
  }

  const makeCopy = ({ copiedTitle, copiedVal }) => {
    navigator.clipboard.writeText(copiedVal);

    if (copiedTitle === "api_key") {
      apiKeyCopiedRef.current.classList.remove("hidden");
    }

    setTimeout(() => {
      if (copiedTitle === "api_key") {
        apiKeyCopiedRef.current.classList.add("hidden");
      }
    }, 1200);
  }

  return (
    <>
      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <ToastContainer />
      <div className="main main-app p-4 p-lg-5">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
          <li className="breadcrumb-item"><Link to="/manage-users">Manage Users</Link></li>
          <li className={`breadcrumb-item ${skin && "text-stone-400"}`} aria-current="page">Settings</li>
        </ol>
        <h2 className="main-title">Settings</h2>

        <div className="flex items-center gap-x-5 pb-1.5">
          <button type="button" onClick={() => handleTabState(1)} className={`text-sm2 ${tabState === 1 ? `active border-b border-indigo-500 ${skin ? "text-stone-300" : "text-black"}` : "text-stone-500"}`}>General</button>
          <button type="button" onClick={() => handleTabState(2)} className={`text-sm2 ${tabState === 2 ? `active border-b border-indigo-500 ${skin ? "text-stone-300" : "text-black"}` : "text-stone-500"}`}>Postback</button>
          <button type="button" onClick={() => handleTabState(3)} className={`text-sm2 ${tabState === 3 ? `active border-b border-indigo-500 ${skin ? "text-stone-300" : "text-black"}` : "text-stone-500"}`}>Manage API Key &amp; Domains</button>
        </div>

        <Card className="card-settings">
          {
            tabState === 1 ?
              <>
                <Card.Header>
                  <Card.Title>Personal Information</Card.Title>
                </Card.Header>
                <Card.Body className="p-0 card-post">
                  {
                    isDataFetching ? <div className="d-flex justify-content-center my-3">
                      <Spinner animation="border" variant="primary" />
                    </div> : <Form onSubmit={updateProfile} className="pb-3">
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">First Name</h6>
                          </Col>
                          <Col md>
                            <Form.Control autoFocus={true} name="first__name" ref={firstNameRef} type="text" placeholder="Enter first name" value={firstName} onChange={e => handleFieldChange(e)} />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2">
                          <Col md="5">
                            <h6 className="text-stone-500">Last Name</h6>
                          </Col>
                          <Col md>
                            <Form.Control type="text" name="last__name" ref={lastNameRef} placeholder="Enter first name" value={lastName} onChange={e => handleFieldChange(e)} />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Email</h6>
                          </Col>
                          <Col md>
                            <Form.Control ref={emailRef} name="email" type="email" placeholder="Enter email address" value={email} onChange={e => setEmail(e.target.value)} disabled />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Phone No.</h6>
                          </Col>
                          <Col md>
                            <Form.Control type="text" placeholder="Enter phone number" value={phone} onChange={e => setPhone(e.target.value)} />
                          </Col>
                        </Row>
                      </div>
                      <div className="flex flex-col gap-y-5">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md className="flex justify-end">
                              <button onClick={() => handleUpdatePassword(!updatePassword)} type="button" className={`flex items-center gap-x-1.5 ${updatePassword ? "text-rose-600 hover:text-rose-700" : "text-teal-600 hover:text-teal-700"} transition-colors duration-200 ease-linear`}>
                                {
                                  !updatePassword ?
                                    <FaUnlock className="text-lg" /> :
                                    <FaLock className="text-lg" />
                                }
                                {updatePassword ? "Cancel" : ""} Update Password?
                              </button>
                            </Col>
                          </Row>
                        </div>
                        {
                          updatePassword &&
                          <div className={`border-y ${skin ? "border-slate-800" : "border-slate-100"} mb-3`}>
                            <div className="setting-item flex flex-col gap-y-5">
                              <Row className="g-2 align-items-center">
                                <Col md="5">
                                  <h6 className="text-stone-500">Password</h6>
                                </Col>
                                <Col md>
                                  <Form.Control type="password" ref={passwordRef} name="password" placeholder="Enter password" value={password} onChange={e => handleFieldChange(e)} />
                                </Col>
                              </Row>
                              <Row className="g-2 align-items-center">
                                <Col md="5">
                                  <h6 className="text-stone-500">Confirm Password</h6>
                                </Col>
                                <Col md>
                                  <Form.Control type="password" ref={confirmPasswordRef} name="confirm__password" placeholder="Confirm password" value={confirmPassword} onChange={e => handleFieldChange(e)} />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Company Name</h6>
                          </Col>
                          <Col md>
                            <Form.Control type="text" name="company__name" ref={companyRef} placeholder="Enter company name" value={companyName} onChange={e => handleFieldChange(e)} />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Is Active?</h6>
                          </Col>
                          <Col md className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                            <Select
                              options={IS_ACTIVE}
                              value={isActive}
                              onChange={setIsActive}
                              placeholder="Select active mode"
                              classNamePrefix={"my-react-select"}
                              className="w-full whitespace-nowrap"
                              isSearchable
                              noOptionsMessage={() => "No match found."}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Role</h6>
                          </Col>
                          <Col md className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                            <Select
                              // isMulti
                              options={roles}
                              value={role}
                              onChange={setRole}
                              placeholder="Select role"
                              classNamePrefix={"my-react-select"}
                              className="w-full whitespace-nowrap"
                              isSearchable
                              noOptionsMessage={() => "No match found."}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="setting-item">
                        <Row className="g-2 align-items-center">
                          <Col md="5">
                            <h6 className="text-stone-500">Campaign</h6>
                          </Col>
                          <Col md className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                            <Select
                              isMulti
                              options={allCampaigns}
                              value={permissionCampaigns}
                              onChange={handlePermissionCampaign}
                              placeholder="Select campaign(s)"
                              classNamePrefix={"my-react-select"}
                              className="w-full whitespace-nowrap"
                              isSearchable
                              noOptionsMessage={() => "No match found."}
                            />
                          </Col>
                        </Row>
                      </div>

                      <div className="setting-item flex justify-end">
                        <button type="submit" className="btn-sign font-medium px-10 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                          {
                            loading ? <>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </> : "Update"
                          }
                        </button>
                      </div>
                    </Form>
                  }
                </Card.Body>
              </> :
              tabState === 2 ?
                <>
                  <Card.Header>
                    <Card.Title>Postback Information</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0 card-post">
                    <Form onSubmit={updatePostback} className="pb-3">
                      <div className="setting-item">
                        <div className="flex items-center gap-x-3">
                          <Row className="w-full g-2">
                            <Col md="5">
                              <h6 className="text-stone-500">Postback URL</h6>
                            </Col>
                            <Col md>
                              <div className="flex flex-col gap-y-3">
                                <Form.Control autoFocus name="postback__url" ref={postbackUrlRef} type="url" placeholder="Enter postback url" value={postbackUrl} onChange={e => handleFieldChange(e)} />
                                <div className="flex flex-wrap gap-1.5 items-center">
                                  <button onClick={() => handlePostbackOptions({ optionType: "externalId", optionValue: !isExternalId })} type="button" className={`flex items-center gap-x-1 px-2.5 py-1.5 rounded-md border ${isExternalId ? "bg-indigo-600 text-white border-indigo-600" : "border-stone-200 hover:border-stone-400"} ${skin ? "text-stone-400" : ""} transition-colors duration-200 ease-linear`}>
                                    External ID
                                    {
                                      !isExternalId ?
                                        <IoMdAdd /> :
                                        <IoCloseOutline className="text-base" />
                                    }
                                  </button>
                                  <button onClick={() => handlePostbackOptions({ optionType: "payout", optionValue: !isPayout })} type="button" className={`flex items-center gap-x-1 px-2.5 py-1.5 rounded-md border ${isPayout ? "bg-indigo-600 text-white border-indigo-600" : "border-stone-200 hover:border-stone-400"} ${skin ? "text-stone-400" : ""} transition-colors duration-200 ease-linear`}>
                                    Payout
                                    {
                                      !isPayout ?
                                        <IoMdAdd /> :
                                        <IoCloseOutline className="text-base" />
                                    }
                                  </button>
                                  <button onClick={() => handlePostbackOptions({ optionType: "lead_type", optionValue: !isLeadType })} type="button" className={`flex items-center gap-x-1 px-2.5 py-1.5 rounded-md border ${isLeadType ? "bg-indigo-600 text-white border-indigo-600" : "border-stone-200 hover:border-stone-400"} ${skin ? "text-stone-400" : ""} transition-colors duration-200 ease-linear`}>
                                    Lead Type
                                    {
                                      !isLeadType ?
                                        <IoMdAdd /> :
                                        <IoCloseOutline className="text-base" />
                                    }
                                  </button>
                                  <button onClick={() => handlePostbackOptions({ optionType: "status", optionValue: !isPostbackStatus })} type="button" className={`flex items-center gap-x-1 px-2.5 py-1.5 rounded-md border ${isPostbackStatus ? "bg-indigo-600 text-white border-indigo-600" : "border-stone-200 hover:border-stone-400"} ${skin ? "text-stone-400" : ""} transition-colors duration-200 ease-linear`}>
                                    Status
                                    {
                                      !isPostbackStatus ?
                                        <IoMdAdd /> :
                                        <IoCloseOutline className="text-base" />
                                    }
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div>
                            {/* Invisible */}
                            <div className="invisible flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600 text-white transition-colors duration-200 ease-linear">
                              <MdDeleteOutline className="text-lg" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="setting-item">
                        <div className="flex items-center gap-x-3">
                          <Row className="w-full g-2 align-items-center">
                            <Col md="5">
                              <h6 className="text-stone-500">
                                Status postback-relation
                              </h6>
                            </Col>
                            <Col md>
                              <div className="flex items-center gap-x-3">
                                <div onClick={handlePostbackRelationStatus} className={`cursor-pointer relative min-w-11 h-6 rounded-full ${showPostbackStatus ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                                  <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showPostbackStatus ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                                </div>
                                <div className="flex flex-col gap-y-1.5">
                                  <div className="flex flex-wrap items-center gap-x-2">
                                    <h6 className={`mb-0 ${skin ? "text-stone-500" : "text-stone-500"}`}>If conversion status = A, then Postback link = B</h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div>
                            {/* Invisible */}
                            <div className="invisible flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600 text-white transition-colors duration-200 ease-linear">
                              <MdDeleteOutline className="text-lg" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Postback params code */}
                      {
                        showPostbackStatus &&
                        <div className="setting-item flex flex-col gap-y-5">
                          {
                            postbackParameters?.map((pbp, index) => (
                              <div key={index} className="flex items-center gap-x-3">
                                <Row className="w-full g-2 align-items-center">
                                  <Col md="5">
                                    {/* <h6 className={`text-stone-500 ${index !== 0 ? "hidden" : ""}`}>Postback Parameter</h6> */}
                                    <h6 className={`text-stone-500 ${index !== 0 ? "hidden" : ""}`}></h6>
                                  </Col>
                                  <Col md>
                                    <Row className="g-2 align-items-center">
                                      <Col md="6" className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                        {/* <Form.Control type="text" name={`param__name-${pbp.index}`} placeholder="Conversion status" value={pbp?.name} onChange={e => handlePostbackData({ e: e, index: pbp.index })} /> */}
                                        <Select
                                          options={STATUSES}
                                          value={STATUSES.map(s => s.value === pbp["status"] ? s : "")}
                                          // onChange={handlePermissionCampaign}
                                          onChange={e => handlePostbackStatusChange({ e: e, index: pbp.index })}
                                          placeholder="Select status"
                                          classNamePrefix={"my-react-select"}
                                          className="w-full whitespace-nowrap"
                                          isSearchable
                                          noOptionsMessage={() => "No match found."}
                                        />
                                      </Col>
                                      <Col md="6">
                                        <Form.Control type="text" name={`param__val-${pbp.index}`} placeholder="Postback url" value={pbp?.postback_url} onChange={e => handlePostbackData({ e: e, index: pbp.index })} />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                {
                                  (index !== 0 || postbackParameters.length !== 1) ?
                                    <div>
                                      <button onClick={() => handleRemovePostbackParameter(pbp.index)} type="button" className="flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600 text-white transition-colors duration-200 ease-linear">
                                        <MdDeleteOutline className="text-lg" />
                                      </button>
                                    </div> :
                                    <div>
                                      {/* Invisible */}
                                      <div className="invisible flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600 text-white transition-colors duration-200 ease-linear">
                                        <MdDeleteOutline className="text-lg" />
                                      </div>
                                    </div>
                                }
                              </div>
                            ))
                          }

                          <div className="flex items-center gap-x-3">
                            <div className="w-full flex items-center gap-x-3">
                              <Row className="w-full g-2 align-items-center">
                                <Col md="5">
                                  <h6 className={`text-stone-500 hidden`}>Postback Parameter</h6>
                                </Col>
                              </Row>
                              <div className="flex justify-end">
                                <button onClick={() => handleAddPostbackParameter()} type="button" className="flex gap-x-0.5 items-center justify-center h-fit px-2.5 py-1.5 rounded-full bg-teal-500 hover:bg-teal-600 text-white transition-colors duration-200 ease-linear">
                                  <IoMdAdd className="text-base" />
                                  <p className="mb-0 text-white">Add</p>
                                </button>
                              </div>
                              <div>
                                {/* Invisible */}
                                <div className="invisible flex flex-1 items-center justify-center h-fit px-2.5 py-1.5 min-[700px]:p-1 p-1 rounded-full bg-rose-500 hover:bg-rose-600 text-white transition-colors duration-200 ease-linear">
                                  <MdDeleteOutline className="text-lg" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }


                      <div className="setting-item flex justify-end">
                        <button type="submit" className="btn-sign font-medium px-10 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                          {
                            loading ? <>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </> : "Update"
                          }
                        </button>
                      </div>
                    </Form>
                  </Card.Body>
                </> :
                <>
                  <Card.Header>
                    <Card.Title>Manage API Key</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    {
                      isDataFetching ? <div className="d-flex justify-content-center my-3">
                        <Spinner animation="border" variant="primary" />
                      </div> : <Form onSubmit={generateAPIKey} className="pb-3 card-post">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="5">
                              <h6 className="text-stone-500">API Key</h6>
                            </Col>
                            <Col md>
                              <Form.Control autoFocus={true} name="api__key" type="text" placeholder="Generate API key" value={apiKey} readOnly disabled />
                              {
                                apiKey &&
                                <div className="mt-2 flex items-center gap-x-2">
                                  <div className="cursor-pointer flex items-center gap-x-1.5 text-indigo-500" onClick={() => makeCopy({
                                    copiedTitle: "api_key",
                                    copiedVal: apiKey
                                  })}>
                                    <FaRegCopy /> <span>Copy to clipboard</span>
                                  </div>
                                  <p className={`mb-0 hidden text-xs ${skin ? "text-stone-500" : ""}`} ref={apiKeyCopiedRef}>Copied</p>
                                </div>
                              }
                            </Col>
                          </Row>
                        </div>

                        <div className="setting-item flex justify-end">
                          <button type="submit" className="btn-sign font-medium px-10 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={apiButtonLoader}>
                            {
                              apiButtonLoader ? <>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Loading...
                              </> : "Generate New Key"
                            }
                          </button>
                        </div>
                      </Form>
                    }
                  </Card.Body>

                  <Card className="card-settings">
                    <Card.Header className={`border-t ${skin ? "border-stone-200/10" : "border-stone-200/60"}`}>
                      <Card.Title>Manage Domains</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0">
                      {
                        isDataFetching ? <div className="d-flex justify-content-center my-3">
                          <Spinner animation="border" variant="primary" />
                        </div> : <Form onSubmit={updateDomains} className="pb-3 card-post">
                          <div className="setting-item">
                            <Row className="g-2 align-items-center">
                              <Col md="5">
                                <h6 className="text-stone-500">Domains</h6>
                              </Col>
                              <Col md>
                                <div className="flex flex-col gap-y-2">
                                  <Form.Control ref={domainRef} as="textarea" rows={4} name="domains" type="text" placeholder="Enter domains for example: 66.249.64.228, 66.249.64.229, 66.249.64.230" value={domains} onChange={e => handleFieldChange(e)} disabled={!hasAccess.canUpdateDomain || !hasAccess.canViewDomain} readOnly={!hasAccess.canUpdateDomain || !hasAccess.canViewDomain} />
                                  <p className={`text-xs font-medium ${skin ? "text-stone-500" : ""}`}>Enter server IP address using a comma(<span className="font-semibold">,</span>) separated for example: 66.249.64.228, 66.249.64.229</p>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          {
                            (hasAccess.canViewDomain && hasAccess.canUpdateDomain) &&
                            <div className="setting-item flex justify-end">
                              <button type="submit" className="btn-sign font-medium px-10 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={domainButtonLoader}>
                                {
                                  domainButtonLoader ? <>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Loading...
                                  </> : "Update Domains"
                                }
                              </button>
                            </div>
                          }
                        </Form>
                      }
                    </Card.Body>
                  </Card>
                </>
          }
        </Card>
      </div>
    </>
  );
};
export default EditClientProfile;
