import { useEffect, useRef, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify"
import Select from "react-select";
import CreatableSelect from "react-select";

const OUTPUT_OPTIONS = [
    {
        value: "ACCEPT LEADS",
        label: "Accept Leads"
    },
    {
        value: "REJECT LEADS",
        label: "Reject Leads"
    }
]

const PAYOUT_LOGICS = [
    {
        value: "CONTAINS ONE OF THE VALUE",
        label: "CONTAINS ONE OF THE VALUE"
    },
    {
        value: "DOESN'T CONTAINS ONE OF THE VALUE",
        label: "DOESN'T CONTAINS ONE OF THE VALUE"
    }
]

const PAYOUT_OPERATORS = [
    {
        value: "GREATER THAN",
        label: "GREATER THAN"
    },
    {
        value: "GREATER THAN EQUAL",
        label: "GREATER THAN EQUAL"
    },
    {
        value: "LESS THAN",
        label: "LESS THAN"
    },
    {
        value: "LESS THAN EQUAL",
        label: "LESS THAN EQUAL"
    },
    {
        value: "EXACT EQUAL",
        label: "EXACT EQUAL"
    },
]

const AddTrafficSourceFilterModal = ({ skin, http, logout, showModal, setShowModal, formError, setFormError, filterType, setFilterType, getFilters, campaignId, filterData, setFilterData, trafficSources, fields, suppliers, buyers }) => {
    const filterTypeRef = useRef();
    const keyRef = useRef();
    const conditionRef = useRef();
    const valueRef = useRef();
    const headerTitleRef = useRef();
    const payoutValueRef = useRef();
    const multiValueRef = useRef();

    const [filterKey, setFilterKey] = useState(null);

    const [allFields, setAllFields] = useState([]);
    const [allTrafficSources, setAllTrafficSources] = useState([]);
    const [selectedTrafficSources, setSelectedTrafficSources] = useState([]);
    const [loading, setIsLoading] = useState(false);

    const [showKeyField, setShowKeyField] = useState(false);
    const [isCreateMultiList, setIsCreateMultiList] = useState(false);
    const [isSingleList, setIsSingleList] = useState(false);
    const [multiValueOptions, setMultiValueOptions] = useState([]);
    const [multiValues, setMultiValues] = useState([]);
    const [output, setOutput] = useState(OUTPUT_OPTIONS[0]);
    const [payoutLogic, setPayoutLogic] = useState(null);
    const [payoutOperator, setPayoutOperator] = useState(null);
    const [payoutValue, setPayoutValue] = useState(0);
    const [isListType, setIsListType] = useState(false);
    const [showBuyer, setShowBuyer] = useState(false);
    const [showSupplier, setShowSupplier] = useState(false);

    const cleanupFilterTrafficSourceData = () => {
        setSelectedTrafficSources([]);
        setOutput(OUTPUT_OPTIONS[0]);
        setPayoutLogic(null);
        setPayoutOperator(null);
        setPayoutValue(0);
    }

    const getPayoutRule = async (filterId) => {
        try {
            const res = await http.get(`/api/payout-rule/${filterId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { logic, operator, payout } = res.data.data;

                logic &&
                    setPayoutLogic({
                        value: logic,
                        label: logic
                    });
                if (operator) {
                    let operatorSign = "";

                    switch (operator) {
                        case "GREATER THAN":
                            operatorSign = "GREATER THAN";
                            break;
                        case "GREATER THAN EQUAL":
                            operatorSign = "GREATER THAN EQUAL";
                            break;
                        case "LESS THAN":
                            operatorSign = "LESS THAN";
                            break;
                        case "LESS THAN EQUAL":
                            operatorSign = "LESS THAN EQUAL";
                            break;
                        case "EXACT EQUAL":
                            operatorSign = "EXACT EQUAL";
                            break;
                    }

                    setPayoutOperator({
                        value: operator,
                        label: operatorSign
                    });
                }


                payout != null &&
                    setPayoutValue(payout);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
        }
    }

    const getBuyerDetails = async (buyerId) => {
        try {
            const res = await http.get(`/api/fetch-buyer/${buyerId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
        }
    }

    const getSupplierDetails = async (supplierId) => {
        try {
            const res = await http.get(`/api/supplier-details/${supplierId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
        }
    }

    useEffect(() => {
        if (showModal) {
            let fieldArr = [], trafficSourceArr = [];

            trafficSources?.map(ts => {
                trafficSourceArr.push({
                    ...ts,
                    value: ts.id,
                    label: `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`
                })
            })

            setAllTrafficSources(trafficSourceArr);

            fields?.map(field => {
                if (field.field_name !== "api_key") {
                    fieldArr.push({
                        value: field.field_name,
                        label: field.field_name,
                        field_type: field.field_type,
                        field_values: field.field_values
                    })
                }
            })

            let supplierArr = []

            suppliers.map(sup => {
                supplierArr.push({
                    id: sup.id,
                    value_title: sup?.client?.company_name[0].toUpperCase() + sup?.client?.company_name.slice(1,)
                })
            })

            fieldArr.push({
                value: "supplier",
                label: "supplier",
                field_type: "list",
                field_values: supplierArr,
            });
            let buyerArr = [];

            buyers.map(buyer => {
                buyerArr.push({
                    id: buyer.id,
                    value_title: buyer?.client?.company_name[0].toUpperCase() + buyer?.client?.company_name.slice(1,)
                })
            })

            fieldArr.push({
                value: "buyer",
                label: "buyer",
                field_type: "list",
                field_values: buyerArr,
            });

            setAllFields(fieldArr);
        }

        if (showModal && filterData) {
            getPayoutRule(filterData.id);

            headerTitleRef.current.innerText = `Edit ${filterData.filter_type ? filterData.filter_type : "Filter"}`;
            // filterTypeRef.current.value = filterData.filter_type;
            setFilterType(filterData.filter_type);

            conditionRef.current.value = filterData.conditions;
            if (conditionRef.current.value !== "CONTAINS AT LEAST ONE") {
                setFilterKey({
                    value: filterData.key,
                    label: filterData.key
                });
                setShowKeyField(true);
            } else {
                setFilterKey(null);
                setShowKeyField(false);
            }
            if (filterData.value) {
                valueRef.current.value = filterData.value;
            } else {
                let multiValArr = [];


                // filterData.multi_values?.map(mv => {
                //     multiValArr.push({
                //         value: mv.id,
                //         label: mv.value_title.split(`,"company_name":`).length > 1 ? mv.value_title.split(`,"company_name":`)[1].replaceAll("'", "").replaceAll("\"", "").replaceAll("{", "").replaceAll("}", "") : mv.value_title
                //     })
                // })

                if (filterData.conditions !== "HAS MANY") {
                    setIsSingleList(true);
                } else {
                    setIsSingleList(false);
                }

                if (filterData.key === "buyer") {
                    let buyerArr = [];

                    filterData.multi_values?.map(mv => {
                        getBuyerDetails(mv.value_title).then(result => {
                            if (result) {
                                const multiVal = {
                                    value: result.id,
                                    label: result?.client?.company_name[0].toUpperCase() + result?.client?.company_name.slice(1,)
                                }

                                setMultiValues(prevVal => [...prevVal, multiVal]);
                            }
                        }).catch(err => {

                        });
                    })

                    buyers.map(buyer => {
                        buyerArr.push({
                            value: buyer.id,
                            label: buyer?.client?.company_name[0].toUpperCase() + buyer?.client?.company_name.slice(1,)
                        })
                    })

                    setMultiValueOptions(buyerArr);
                } else if (filterData.key === "supplier") {
                    let supplierArr = [];

                    filterData.multi_values?.map(mv => {
                        getSupplierDetails(mv.value_title).then(result => {
                            if (result) {
                                const multiVal = {
                                    value: result.id,
                                    label: result?.client?.company_name[0].toUpperCase() + result?.client?.company_name.slice(1,)
                                }

                                setMultiValues(prevVal => [...prevVal, multiVal]);
                            }
                        }).catch(err => {

                        });
                    })

                    suppliers.map(sup => {
                        supplierArr.push({
                            value: sup.id,
                            label: sup?.client?.company_name[0].toUpperCase() + sup?.client?.company_name.slice(1,)
                        })
                    })

                    setMultiValueOptions(supplierArr);
                } else {
                    filterData.multi_values?.map(mv => {
                        multiValArr.push({
                            value: mv.value_title,
                            label: mv.value_title
                        })
                    })

                    setIsSingleList(false);

                    let fieldValArr = [];

                    for (let i = 0; i < fields.length; i++) {
                        const field = fields[i];
                        if (field.field_name === filterData.key) {
                            field.field_values.map(fv => {
                                fieldValArr.push({
                                    value: fv.value_title,
                                    label: fv.value_title
                                })
                            })
                        }
                    }

                    setMultiValueOptions(fieldValArr);
                }

                setIsListType(true);
                setMultiValues(multiValArr);
                setIsCreateMultiList(true);
            }
            if (filterData.result === "ACCEPT LEADS") {
                setOutput({
                    value: "ACCEPT LEADS",
                    label: "Accept Leads"
                })
            } else if (filterData.result === "REJECT LEADS") {
                setOutput({
                    value: "REJECT LEADS",
                    label: "Reject Leads"
                })
            }
        } else {
            setShowKeyField(true)
        }

        if (filterData) {
            if (filterData.filter_type === "TRAFFIC SOURCE LEVEL") {
                let filterTrafficSourceArr = [];
                filterData.traffic_source?.map(ts => {
                    filterTrafficSourceArr.push({
                        ...ts,
                        value: ts?.id,
                        label: `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`
                    });
                })
                let allTrafficSourceArr = [];
                trafficSources?.map(ts => {
                    allTrafficSourceArr.push({
                        ...ts,
                        value: ts.id,
                        label: `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`
                    })
                })

                setSelectedTrafficSources(filterTrafficSourceArr);
                setAllTrafficSources(allTrafficSourceArr);
            }
        }
    }, [showModal, filterData, trafficSources, buyers, suppliers])

    const handleClose = () => {
        cleanupFilterTrafficSourceData();
        setShowModal(false);
        setFilterData(null);
        setFilterKey(null);
        setFilterType("GLOBAL");
        setIsLoading(false);
        setIsCreateMultiList(false);
        setIsSingleList(false);
        setIsListType(false);
        setMultiValues([]);
        setMultiValueOptions([]);
        setAllFields([]);
    }

    const showFormError = ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            filterTypeRef.current.classList.add("error__field");
            filterTypeRef.current?.focus();
        } else if (errId === 2) {
            toast.error("Please select a key!");
        } else if (errId === 3) {
            conditionRef.current.classList.add("error__field");
            conditionRef.current?.focus();
        } else if (errId === 4) {
            valueRef.current.classList.add("error__field");
            valueRef.current?.focus();
        } else if (errId === 5) {
            toast.error("Please select an output option!");
        } else if (errId === 6) {
            toast.error("Please select a value!");
        } else if (errId === 7) {
            toast.error("Please select a supplier!");
        } else if (errId === 8) {
            toast.error("Please select a buyer!");
        } else if (errId === 9) {
            toast.error("Please select a payout rule!");
        } else if (errId === 10) {
            toast.error("Please select a payout operator!");
        } else if (errId === 11) {
            payoutValueRef.current.classList.add("error__field");
            payoutValueRef.current?.focus();
        } else if (errId === 12) {
            toast.error("The condition for list type should be HAS MANY!");
            conditionRef.current.classList.add("error__field");
            conditionRef.current?.focus();
        }

        setIsLoading(false);
    }

    const postFilter = async (e) => {
        e.preventDefault();

        showFormError({
            errId: -1,
        });

        setIsLoading(true);

        const condition = conditionRef.current.value.trim();
        const fieldValue = valueRef.current?.value?.trim();


        if (condition === "") {
            showFormError({
                errId: 3,
            });
            return;
        }

        if (fieldValue === "") {
            showFormError({
                errId: 4,
            })
            return;
        }
        if (showKeyField) {
            if (!filterKey) {
                showFormError({
                    errId: 2,
                });
                return;
            }
        }
        if (!output) {
            showFormError({
                errId: 5,
            })
            return;
        }
        if (payoutLogic) {
            if (!payoutOperator) {
                showFormError({
                    errId: 10
                })
                return;
            }
            if (payoutValue === "") {
                showFormError({
                    errId: 11
                })
                return;
            }
        }

        const formData = new FormData();
        formData.append("campaign", campaignId);
        formData.append("filter_type", filterType);
        formData.append("key", showKeyField ? filterKey.value : "");
        formData.append("result", output.value);
        formData.append("condition", condition);
        formData.append("logic", payoutLogic ? payoutLogic.value : "");
        formData.append("operator", payoutLogic ? payoutOperator.value : "");
        formData.append("payout", payoutLogic ? payoutValue : 0);
        if (isCreateMultiList) {
            // if (isSingleList) {
            //     if (!Array.isArray(multiValues)) {
            //         formData.append("multi_values[]", JSON.stringify({ id: multiValues.value, company_name: multiValues.label }));
            //     } else {
            //         formData.append("multi_values[]", JSON.stringify({ id: multiValues[0].value, company_name: multiValues[0].label }));
            //     }
            // } else {
            //     multiValues.map(val => {
            //         formData.append("multi_values[]", val.value);
            //     })
            // }
            if (isSingleList) {
                if (!Array.isArray(multiValues)) {
                    formData.append("multi_values[]", JSON.stringify(multiValues.value));
                } else {
                    formData.append("multi_values[]", JSON.stringify(multiValues[0].value));
                }
            } else {
                multiValues.map(val => {
                    formData.append("multi_values[]", val.value);
                })
            }
        } else {
            formData.append("value", fieldValue);
        }

        selectedTrafficSources?.map(ts => {
            formData.append("traffic_sources[]", ts.value);
        })

        if (filterData) {
            try {
                const response = await http.put(`/api/update-filter/${filterData.id}/`, formData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (response.status === 200) {
                    handleClose();
                    setIsLoading(false);
                    toast.success("Filter is updated successfully.");
                    getFilters();
                } else {
                    setIsLoading(false);
                    toast.error("Something went wrong");
                }
            } catch (err) {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to update the filter!");

                // console.log(err);
                setIsLoading(false);
            }
        } else {
            try {
                const response = await http.post("/api/add-filter/", formData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (response.status === 200) {
                    handleClose();
                    setIsLoading(false);
                    toast.success("Filter added successfully.");
                    getFilters();
                } else {
                    setIsLoading(false);
                    toast.error("Something went wrong");
                }
            } catch (err) {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to add filter!");

                // console.log(err);
                setIsLoading(false);
            }
        }
    };

    const handleFieldChange = async (e) => {
        if (e.target.name === "filter__type") {
            if (e.target.value !== "") {
                filterTypeRef.current.classList.remove("error__field");

                if (filterTypeRef.current.value === "TRAFFIC SOURCE LEVEL") {
                    let filterTrafficSourceArr = [];

                    if (filterData && filterData.suppliers) {
                        filterData.traffic_source?.map(ts => {
                            filterTrafficSourceArr.push({
                                ...ts,
                                value: ts.id,
                                label: `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`
                            })
                        })

                        setSelectedTrafficSources(filterTrafficSourceArr);
                    } else {
                        trafficSources?.map(ts => {
                            filterTrafficSourceArr.push({
                                ...ts,
                                value: ts.id,
                                label: `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1,)}${ts?.traffic_channel?.channel_name ? " - " + ts?.traffic_channel?.channel_name : ""} (${ts.id})`
                            })
                        })

                        setAllTrafficSources(filterTrafficSourceArr);
                    }
                }
            } else {
                filterTypeRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "key") {
            if (e.target.value !== "") {
                keyRef.current.classList.remove("error__field");
            } else {
                keyRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "payout__value") {
            setPayoutValue(e.target.value);
            if (e.target.value !== "") {
                payoutValueRef.current.classList.remove("error__field");
            } else {
                payoutValueRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "condition") {
            if (e.target.value !== "") {
                conditionRef.current.classList.remove("error__field");

                if (e.target.value === "HAS MANY" && (filterKey && (filterKey.value === "buyer" || filterKey.value === "supplier"))) {
                    setIsCreateMultiList(true);
                    setIsSingleList(false);
                } else if ((e.target.value === "EQUAL TO" || e.target.value === "NOT EQUAL TO") && (filterKey && (filterKey.value === "buyer" || filterKey.value === "supplier"))) {
                    setIsCreateMultiList(true);
                    setIsSingleList(true);
                } else {
                    if (e.target.value === "HAS MANY") {
                        setIsCreateMultiList(true);
                        setIsSingleList(false);
                    } else {
                        setShowKeyField(true);
                        setIsCreateMultiList(false);
                    }
                }
            } else {
                conditionRef.current.classList.add("error__field");
            }
        } else if (e.target.name === "value") {
            if (e.target.value !== "") {
                valueRef.current.classList.remove("error__field");
            } else {
                valueRef.current.classList.add("error__field");
            }
        }
    }

    const handleMultiValuePaste = event => {
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        if (!pastedText) return;

        const newOptions = pastedText.split("\n").map((item, index) => ({
            value: item.trim(),
            label: item.trim()
        }))

        setMultiValues(prevs => [...prevs, ...newOptions]);

        // Prevent the default behavior of pasting
        event.preventDefault();
    }

    useEffect(() => {
        if (isCreateMultiList) {
            multiValueRef.current?.inputRef?.addEventListener("paste", handleMultiValuePaste);
        } else {
            multiValueRef.current?.inputRef?.removeEventListener("paste", handleMultiValuePaste);
        }
    }, [isCreateMultiList])

    const handleFilterChange = e => {
        setFilterKey(e);

        if (e == null) {
            setMultiValueOptions([]);
            setIsCreateMultiList(false);
        } else {
            let fieldArr = [];
            conditionRef.current.classList.remove("error__field");

            if (e.field_type === "list") {
                if (filterData && filterData.key !== e.value) {
                    setMultiValues([]);
                }

                e.field_values?.map(val => {
                    fieldArr.push({
                        value: (e.value === "buyer" || e.value === "supplier") ? val.id : val.value_title,
                        label: val.value_title,
                        isBuyerOrSupplier: e.value === "buyer" || e.value === "supplier" ? true : false,
                    })
                })

                if (e.value !== "buyer" && e.value !== "supplier") {
                    if (conditionRef.current.value !== "HAS MANY") {
                        conditionRef.current.value = "HAS MANY";
                    }
                }
                setIsListType(true);

                setMultiValueOptions(fieldArr);

                if ((e.value === "buyer" || e.value === "supplier") && conditionRef.current.value !== "HAS MANY") {
                    setIsSingleList(true);
                } else {
                    setIsSingleList(false);
                }
                setIsCreateMultiList(true);
            } else {
                conditionRef.current.value = "EQUAL TO";
                // if (conditionRef.current.value === "CONTAINS") {
                setIsListType(false);

                setMultiValueOptions([]);
                setIsCreateMultiList(false);
                // }
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title ref={headerTitleRef}>Add {filterType ? filterType : "Filter"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={postFilter}>
                    {/* <Form.Group className="mb-3">
                        <Form.Label>Filter Type</Form.Label>
                        <Form.Select onChange={e => handleFieldChange(e)} name="filter__type" ref={filterTypeRef} aria-label="Default select example">
                            <option value="SUPPLIER LEVEL" selected={filterType === "SUPPLIER LEVEL" ? true : false}>Supplier Level</option>
                            <option value="BUYER LEVEL" selected={filterType === "BUYER LEVEL" ? true : false}>Buyer Level</option>
                            <option value="GLOBAL" selected={filterType === "GLOBAL" ? true : false}>GLOBAL</option>
                        </Form.Select>
                    </Form.Group> */}

                    <Form.Group className={`mb-3 flex-1 my-react-select-container ${skin && "dark__mode"}`}>
                        <Form.Label>Select Traffic Sources</Form.Label>
                        <Select
                            isClearable
                            isMulti
                            options={allTrafficSources}
                            value={selectedTrafficSources}
                            onChange={setSelectedTrafficSources}
                            placeholder="Select traffic sources"
                            classNamePrefix={"my-react-select"}
                            isSearchable
                            className="w-full"
                            noOptionsMessage={() => "No match found."}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Condition</Form.Label>
                        <Form.Select onChange={e => handleFieldChange(e)} ref={conditionRef} name="condition" aria-label="Default select example">
                            <option value="EQUAL TO" selected>Equal to</option>
                            <option value="NOT EQUAL TO">Not Equal To</option>
                            <option value="CONTAINS">Contains</option>
                            {/* <option value="HAS REGEX">Has Regex</option> */}
                            <option value="HAS MANY">Has Many</option>
                            {/* <option value="CONTAINS AT LEAST ONE">Contains At Least One</option> */}
                        </Form.Select>
                    </Form.Group>
                    {
                        showKeyField &&
                        <Form.Group className={`mb-3 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                            <Form.Label>Key</Form.Label>
                            {/* <Form.Select onChange={e => handleFieldChange(e)} name="key" ref={keyRef} aria-label="Default select example">
                                <option value="" selected>Select an option</option>
                                {
                                    fields?.map(field => (
                                        field.field_name === "api_key" && field.field_type === "SYSTEM GENERATED" ? null :
                                            <option key={field.id} value={field.field_name}>{field.field_name}</option>
                                    ))
                                }
                            </Form.Select> */}
                            <Select
                                isClearable
                                options={allFields}
                                value={filterKey}
                                onChange={handleFilterChange}
                                placeholder="Select key"
                                classNamePrefix={"my-react-select"}
                                isSearchable
                                className="w-full"
                                noOptionsMessage={() => "No match found."}
                            />
                        </Form.Group>
                    }

                    <Form.Group className={`card-post mb-3 my-react-select-container ${skin && "dark__mode"}`}>
                        <Form.Label>Value</Form.Label>
                        {
                            isCreateMultiList ?
                                isSingleList ?
                                    <CreatableSelect ref={multiValueRef} isClearable options={multiValueOptions} value={multiValues} onChange={setMultiValues} placeholder="Enter values" classNamePrefix={"my-react-select"} /> :
                                    <CreatableSelect ref={multiValueRef} closeMenuOnSelect={false} isClearable isMulti options={multiValueOptions} value={multiValues} onChange={setMultiValues} placeholder="Enter values" classNamePrefix={"my-react-select"} />
                                :
                                <Form.Control onChange={e => handleFieldChange(e)} name="value" ref={valueRef} type="text" placeholder="Enter value" />
                        }
                    </Form.Group>

                    <Form.Group className={`mb-3 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                        <Form.Label>Output</Form.Label>
                        <Select
                            isClearable
                            options={OUTPUT_OPTIONS}
                            value={output}
                            onChange={setOutput}
                            placeholder="Select output"
                            classNamePrefix={"my-react-select"}
                            isSearchable
                            className="w-full"
                            noOptionsMessage={() => "No match found."}
                        />
                    </Form.Group>

                    <Form.Group className={`mb-3 flex-1 my-react-select-container ${skin && "dark__mode"}`}>
                        <Form.Label>Select Payout Rule</Form.Label>
                        <Select
                            isClearable
                            options={PAYOUT_LOGICS}
                            value={payoutLogic}
                            onChange={setPayoutLogic}
                            placeholder="Select payout rule"
                            classNamePrefix={"my-react-select"}
                            isSearchable
                            className="w-full"
                            noOptionsMessage={() => "No match found."}
                        />
                    </Form.Group>
                    {
                        payoutLogic &&
                        <>
                            <Form.Group className={`mb-3 flex-1 my-react-select-container ${skin && "dark__mode"}`}>
                                <Form.Label>Select Payout Operator</Form.Label>
                                <Select
                                    isClearable
                                    options={PAYOUT_OPERATORS}
                                    value={payoutOperator}
                                    onChange={setPayoutOperator}
                                    placeholder="Select payout operator"
                                    classNamePrefix={"my-react-select"}
                                    isSearchable
                                    className="w-full"
                                    noOptionsMessage={() => "No match found."}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Set Payout</Form.Label>
                                <Form.Control onChange={e => handleFieldChange(e)} name="payout__value" value={payoutValue} ref={payoutValueRef} type="text" placeholder="Enter payout" />
                            </Form.Group>
                        </>
                    }

                    {
                        filterData ? <button type="submit" className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                            {
                                loading ? <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </> : "Update"
                            }
                        </button> :
                            <button type="submit" className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                                {
                                    loading ? <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </> : "Create"
                                }
                            </button>
                    }
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddTrafficSourceFilterModal
