import React, { useEffect, useState } from 'react'
import AuthUtils from '../../Pages/Utils/AuthUtils';
import { Card, Col, Row, Spinner } from 'react-bootstrap';


const DashboardRecord = ({ skin }) => {
    const { http, logout } = AuthUtils();
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(true);

    const getRecord = async () => {
        try {
            const res = await http.get("/api/record/", {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                if (data) {
                    const { total_ingested, total_ingested_ratio, total_accepted, total_accepted_ratio, total_rejected, total_rejected_ratio, profit, profit_ratio,
                        weekly_accepted_change, weekly_duplicated_change, weekly_ingested_change, weekly_profit_change, weekly_rejected_change
                    } = data;

                    setRecord([
                        {
                            label: "Posted",
                            icon: "ri-edit-box-line",
                            value: total_ingested ? total_ingested : 0,
                            percent: weekly_ingested_change ? weekly_ingested_change : 0,
                            status: (weekly_ingested_change == null || weekly_ingested_change <= 0) ? "down" : "up"
                        },
                        {
                            label: "Accepted",
                            icon: "ri-checkbox-circle-line",
                            value: total_accepted ? total_accepted : 0,
                            percent: weekly_accepted_change ? weekly_accepted_change : 0,
                            status: (weekly_accepted_change == null || weekly_accepted_change <= 0) ? "down" : "up"
                        },
                        {
                            label: "Rejected",
                            icon: "ri-prohibited-line",
                            value: total_rejected ? total_rejected : 0,
                            percent: weekly_rejected_change ? weekly_rejected_change : 0,
                            status: (weekly_rejected_change == null || weekly_rejected_change <= 0) ? "down" : "up"
                        },
                        {
                            label: "Profit",
                            icon: "ri-bar-chart-2-line",
                            value: profit ? profit : 0,
                            percent: weekly_profit_change ? weekly_profit_change : 0,
                            status: (weekly_profit_change == null || weekly_profit_change <= 0) ? "down" : "up"
                        },
                    ])

                    setLoading(false);
                }
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            setLoading(false);
            // toast.error("Failed to fetch records' data!");
        }
    };

    useEffect(() => {
        getRecord();
    }, [])

    return (
        <div>
            {
                loading ? <div className="mb-5 d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div> :
                    <Row className="g-3 mb-3">
                        {
                            record.map((r) => (
                                <Col xs="6" xl="3" key={r.label}>
                                    <Card className="card-one">
                                        <Card.Body>
                                            <Card.Title as="label" className={`fs-sm fw-medium mb-1 ${skin ? "text-stone-500" : ""}`}>{r.label}</Card.Title>
                                            <h3 className="card-value mb-1 custom__fontSize"><i className={r.icon}></i> {r.value}</h3>
                                            <small className={`${skin ? "text-stone-500" : ""}`}><span className={"d-inline-flex text-" + ((r.status === "up") ? "success" : "danger")}>{r.percent}% <i className={"ri-arrow-" + ((r.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
            }
        </div>
    )
}

export default DashboardRecord