import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import logoImg from "../assets/login-logo.png";
import logoWhiteImg from "../assets/Logo-White-1.png";

const SidebarHeader = () => {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    useEffect(() => {
        setSkin(currentSkin);
    }, [currentSkin]);

  return (
    <div className="sidebar-header">
        <Link to="/" className="sidebar-logo">
            <img src={`${skin ? logoWhiteImg : logoImg}`} className="w-[70%] h-full object-contain" />
        </Link>
    </div>
  )
}

export default SidebarHeader
