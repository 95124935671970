import { React, useEffect, useRef, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineAdd } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";



const STATUSES = [
    {
        value: "ACCEPTED",
        label: "Accepted"
    },
    {
        value: "REJECTED",
        label: "Rejected"
    },
    {
        value: "DUPLICATED",
        label: "Duplicated"
    },
    {
        value: "ERROR",
        label: "Error"
    },
    {
        value: "STORED",
        label: "Stored"
    },
    {
        value: "RETURNED",
        label: "Returned"
    },
    {
        value: "PENDING",
        label: "Pending"
    }
]

const AddReasonModal = ({ http, logout, skin, showModal, setShowModal, formError, setFormError, selectedReason, setSelectedReason, getReasons, page, allCampaigns }) => {
    const reasonNameRef = useRef();
    const [loading, setIsLoading] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isBuyerDataFetching, setIsBuyerDataFetching] = useState(false);
    // const [reasons, setReasons] = useState([
    //     {
    //         campaign: campaign,
    //         buyers: [],
    //         status: "ACCEPTED",
    //         allBuyers: []
    //     }
    // ]);
    const [reasons, setReasons] = useState([]);

    const handleClose = () => {
        setIsDataFetching(false);
        setIsLoading(false);
        setShowModal(false);
        setSelectedReason(null);
        setIsUpdating(false);
        setIsBuyerDataFetching(false);
        setReasons([]);
    }

    useEffect(() => {
        if (showModal && selectedReason) {
            setIsDataFetching(true);

            reasonNameRef.current.value = selectedReason.reason_name;
            // let reasonArr = [];

            // let campaignIdSet = new Set();

            // selectedReason.reason_list?.map(sr => {
            //     campaignIdSet.add(sr.lead_return.campaign.id);
            // })

            // campaignIdSet.forEach(campId => {
            //     let buyerArr = [], status = "", campaign = null;

            //     selectedReason.reason_list?.map(sr => {
            //         if (sr.lead_return.campaign.id === campId) {
            //             campaign = { ...sr.lead_return.campaign, value: sr.lead_return.campaign.id, label: sr.lead_return.campaign.campaign_title };
            //             buyerArr.push({ ...sr.lead_return.buyer, value: sr.lead_return.buyer.id, label: sr.lead_return.buyer.company_name[0]?.toUpperCase() + sr.lead_return.buyer.company_name.slice(1,) });
            //             status = { value: sr.status.toUpperCase(), label: sr.status[0]?.toUpperCase() + sr.status.slice(1,) };
            //         }
            //     })

            //     reasonArr.push({
            //         campaign: campaign,
            //         buyers: buyerArr,
            //         status: status
            //     })

            //     reasonArr.map((r, rIndex) => {
            //         fetchBuyers({
            //             campId: r.campaign.id,
            //             reasonIndex: rIndex,
            //             reasonArr: reasonArr
            //         })
            //     })
            // })

            // setReasons(reasonArr);
            setIsDataFetching(false);

            setIsUpdating(true);
        }
    }, [selectedReason])

    const showFormError = ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            reasonNameRef.current.classList.add("error__field");
            reasonNameRef.current?.focus();
        }

        setIsLoading(false);
    }

    const addReason = async (e) => {
        e.preventDefault();
        showFormError({
            errId: -1,
        });
        setIsLoading(true);

        const reasonName = reasonNameRef.current.value.trim();

        if (reasonName === "") {
            showFormError({
                errId: 1,
            });
            setIsLoading(false);
            return;
        }
        // if (reasons.length === 0) {
        //     toast.error("Please fill up all the options!");
        //     setIsLoading(false);
        //     return;
        // }

        // for (let i = 0; i < reasons.length; i++) {
        //     const reason = reasons[i];

        //     if (!reason.campaign) {
        //         toast.error("Please select a campaign!");
        //         setIsLoading(false);
        //         return;
        //     }

        //     if (reason.buyers.length === 0) {
        //         toast.error("Please select a buyer!");
        //         setIsLoading(false);
        //         return;
        //     }

        //     if (!reason.status) {
        //         toast.error("Please select a status!");
        //         setIsLoading(false);
        //         return;
        //     }
        // }

        if (selectedReason) {
            const formData = new FormData();
            formData.append("reason_name", reasonName);
            // reasons.map(r => {
            //     formData.append("reasons[]", JSON.stringify(r));
            // })

            await http.put(`/api/update-reason/${selectedReason.id}/`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    // const { invalid_rejection_reason_id } = response.data;

                    // if (invalid_rejection_reason_id) {
                    //     toast.error("Invalid rejection reason!");
                    //     return;
                    // }

                    setIsLoading(false);
                    getReasons(page);
                    handleClose();
                })
                .catch((err) => {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }

                    getReasons(page);
                    handleClose();
                })
        } else {
            const formData = new FormData();
            formData.append("reason_name", reasonName);
            // reasons.map(r => {
            //     formData.append("reasons[]", JSON.stringify(r));
            // })

            await http.post("/api/post-reason/", formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    // const { rejection_reason_required } = response.data;

                    // if (rejection_reason_required) {
                    //     toast.error("Please fill up all the options!");
                    //     return;
                    // }

                    setIsLoading(false);
                    getReasons(page);
                    handleClose();
                })
                .catch((err) => {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }

                    getReasons(page);
                    handleClose();
                })
        }

        setIsLoading(false);
    }

    const handleFieldChange = async (e) => {
        showFormError({
            errId: -1
        });

        if (e.target.name === "reason__name") {
            if (e.target.value !== "") {
                reasonNameRef.current.classList.remove("error__field");
            } else {
                reasonNameRef.current.classList.add("error__field");
            }
        }
    }

    async function fetchBuyers({ campId, reasonIndex, reasonArr }) {
        setIsBuyerDataFetching(true);

        try {
            const res = await http.get(`/api/buyers/${campId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                let buyerArr = [];

                res.data.data?.map(buyer => {
                    buyerArr.push({
                        ...buyer,
                        value: buyer?.client?.id,
                        label: buyer?.client?.company_name[0]?.toUpperCase() + buyer?.client?.company_name?.slice(1,)
                    })
                })

                reasonArr[reasonIndex]["allBuyers"] = buyerArr;
            }

            setIsBuyerDataFetching(false);
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
            toast.error("Failed to fetch the buyers!");
            setIsBuyerDataFetching(false);
        }
    }

    const handleSelectReason = async ({ reasonIndex, data, reasonType }) => {
        let reasonArr = [];

        reasons?.map(r => {
            reasonArr.push(r);
        })

        switch (reasonType) {
            case "campaign":
                if (!reasonArr[reasonIndex]) {
                    reasonArr[reasonIndex] = {};
                }
                reasonArr[reasonIndex]["campaign"] = data;
                reasonArr[reasonIndex]["buyers"] = [];
                await fetchBuyers({ campId: data.id, reasonIndex: reasonIndex, reasonArr: reasonArr });
                break;
            case "buyer":
                reasonArr[reasonIndex]["buyers"] = data;
                break;
            case "status":
                reasonArr[reasonIndex]["status"] = data;
                break;
        }

        setReasons(reasonArr);
    }

    const addNewReason = (reasonIndex) => {
        let reasonArr = [];

        reasons?.map(r => {
            reasonArr.push(r);
        })

        const newReasonIndex = reasonIndex + 1;

        reasonArr[newReasonIndex] = {};
        reasonArr[newReasonIndex]["campaign"] = "";

        setReasons(reasonArr);
    }

    const deleteReason = (reasonIndex) => {
        let reasonArr = [];

        reasons?.map(r => {
            reasonArr.push(r);
        })

        reasonArr.splice(reasonIndex, 1);

        setReasons(reasonArr);
    }

    return (
        <>
            <ToastContainer />
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdating ? "Update" : "Add"} Rejection Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isDataFetching ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </div> :
                            <Form onSubmit={addReason}>
                                <Form.Group className="card-post mb-3">
                                    <Form.Label>Reason Name</Form.Label>
                                    <Form.Control autoFocus={true} onChange={e => handleFieldChange(e)} name="reason__name" ref={reasonNameRef} type="text" placeholder="Enter reason name" />
                                </Form.Group>

                                {/* {
                                    reasons.length === 0 ?
                                        <>
                                            <div
                                                className={`mb-3 flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                                    }`}
                                            >
                                                <Form.Label className="font-semibold">Campaign</Form.Label>
                                                <Select
                                                    options={allCampaigns}
                                                    // value={campaign}
                                                    onChange={(e) => handleSelectReason({ reasonIndex: 0, data: e, reasonType: "campaign" })}
                                                    placeholder="Select campaign"
                                                    classNamePrefix={"my-react-select"}
                                                    className="w-full whitespace-nowrap"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </div>
                                        </> :
                                        reasons.map((r, rIndex) => (
                                            <div className="flex flex-col gap-y-3" key={rIndex}>
                                                <div
                                                    className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                                        }`}
                                                >
                                                    <Form.Label className="font-semibold">Campaign</Form.Label>
                                                    <Select
                                                        options={allCampaigns}
                                                        value={r["campaign"]}
                                                        onChange={(e) => handleSelectReason({ reasonIndex: rIndex, data: e, reasonType: "campaign" })}
                                                        placeholder="Select campaign"
                                                        classNamePrefix={"my-react-select"}
                                                        className="w-full whitespace-nowrap"
                                                        isSearchable
                                                        isClearable
                                                        noOptionsMessage={() => "No match found."}
                                                    />
                                                </div>
                                                {
                                                    isBuyerDataFetching ?
                                                        <div className="mt-3 d-flex justify-content-center">
                                                            <Spinner animation="border" variant="primary" />
                                                        </div> : <div key={rIndex}
                                                            className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                                                }`}
                                                        >
                                                            <Form.Label className="font-semibold">Buyer</Form.Label>
                                                            <Select
                                                                options={r["allBuyers"]}
                                                                value={r["buyers"]}
                                                                onChange={(e) => handleSelectReason({ reasonIndex: rIndex, data: e, reasonType: "buyer" })}
                                                                placeholder="Select buyer(s)"
                                                                classNamePrefix={"my-react-select"}
                                                                className="w-full whitespace-nowrap"
                                                                isSearchable
                                                                isMulti
                                                                isClearable
                                                                noOptionsMessage={() => "No match found."}
                                                            />
                                                        </div>
                                                }

                                                <div
                                                    className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                                        }`}
                                                >
                                                    <Form.Label className="font-semibold">Status</Form.Label>
                                                    <Select
                                                        options={STATUSES}
                                                        value={r["status"]}
                                                        onChange={(e) => handleSelectReason({ reasonIndex: rIndex, data: e, reasonType: "status" })}
                                                        placeholder="Select status"
                                                        classNamePrefix={"my-react-select"}
                                                        className="w-full whitespace-nowrap"
                                                        isSearchable
                                                        isClearable
                                                        noOptionsMessage={() => "No match found."}
                                                    />
                                                </div>

                                                <div className="flex items-center gap-x-3 mb-3">
                                                    {
                                                        rIndex === reasons.length - 1 &&
                                                        <button onClick={() => addNewReason(rIndex)} type="button" className="flex items-center justify-center rounded-full px-2 py-1 text-white bg-success">
                                                            <MdOutlineAdd className="text-base" />
                                                            Add
                                                        </button>
                                                    }
                                                    {
                                                        reasons.length !== 0 &&
                                                        <button onClick={() => deleteReason(rIndex)} type="button" className="flex items-center justify-center gap-x-1 rounded-full px-2 py-1 text-white bg-danger">
                                                            <FaRegTrashAlt className="text-sm" />
                                                            Delete
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                } */}

                                <button disabled={loading || isBuyerDataFetching} type="submit" className={`btn-sign font-medium px-5 py-2.5 text-white rounded-md ${loading || isBuyerDataFetching ? "bg-indigo-400" : "bg-indigo-500 hover:bg-indigo-600"} transition-colors duration-200 ease-linear`}>
                                    {
                                        loading ? <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </> : isUpdating ? "Update" : "Create"
                                    }
                                </button>
                            </Form>
                    }
                </Modal.Body>
            </Modal >
        </>
    )
}

export default AddReasonModal
