import React, { useRef, useState } from 'react'
import { Card, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom"
import AuthUtils from '../Utils/AuthUtils';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginLogo from "../../assets/login-logo.png";
import loginLogoWhite from "../../assets/Logo-White-1.png";
import { PHP_URL } from '../Utils/baseConfig';

const Signin = () => {
  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const { http } = AuthUtils();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({
    errorId: -1
  });

  const emailRef = useRef();
  const passwordRef = useRef();

  const showFormError = async ({ errId }) => {
    setFormError({
      errorId: errId,
    });

    if (errId === 1) {
      emailRef.current.classList.add("error__field");
      emailRef.current?.focus();
    } else if (errId === 2) {
      passwordRef.current.classList.add("error__field");
      passwordRef.current?.focus();
    } else if (errId === 3) {
      emailRef.current.classList.add("error__field")
      passwordRef.current.classList.add("error__field")
    }

    setLoading(false);
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    showFormError({
      errId: -1
    });
    setLoading(true);

    if (email === "") {
      showFormError({
        errId: 1
      });
      return;
    }
    if (password === "") {
      showFormError({
        errId: 2
      });
      return;
    }

    await signIn({ email: email, password: password });
  };

  async function signIn({ email, password }) {
    const formData = {
      email: email,
      password: password
    }

    await http.get(`${PHP_URL}/sanctum/csrf-cookie`).then(() => {
        const promise = http.post("/api/auth/login/", formData, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        toast.promise(promise, {
          pending: {
            render() {
              setLoading(true);
              return "Logging in...";
            }
          },
          success: {
            render() {
              emailRef.current.classList.remove("error__field");
              passwordRef.current.classList.remove("error__field");
              setTimeout(() => {
                setLoading(false);
                window.location.href = "/";
              }, 800);
              return "Login successfull. Redirecting...";
            },
          },
          error: {
            render({ data: { response } }) {
              showFormError({
                errId: 3
              });
              setLoading(false);
              return response?.data?.message || "Invalid user credentials!";
            }
          },
        });
    })
  }

  const handleFieldChange = async (e) => {
    showFormError({
      errId: -1
    });

    if (e.target.name === "email") {
      setEmail(e.target.value);

      if (e.target.value === "") {
        emailRef.current.classList.remove("error__field");
        return;
      }

      if (!e.target.value.includes("@")) {
        showFormError({
          errId: 1
        })
      } else {
        emailRef.current.classList.remove("error__field");
      }
    } else if (e.target.name === "password") {
      setPassword(e.target.value);

      if (e.target.value === "") {
        showFormError({
          errId: 2
        })
      } else {
        passwordRef.current.classList.remove("error__field");
      }
    }
  }

  return (
    <div className="page-sign">
      <ToastContainer />
      <Card className="card-sign">
        <Card.Header>
          <div className="mx-auto">
            <img src={skin ? loginLogoWhite : loginLogo} alt="" className='mb-4 w-full h-full max-h-[50px] object-contain' />
          </div>
          <Card.Title>Sign In</Card.Title>
          <Card.Text className={`${skin ? "text-stone-500" : ""}`}>Your can login with your details.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleLogin}>
            <div className="mb-3">
              <Form.Label className={`${skin ? "text-stone-500" : ""}`}>Email address</Form.Label>
              <Form.Control name="email" autoFocus={true} ref={emailRef} type="email" placeholder="Enter email address" value={email} onChange={e => handleFieldChange(e)} />
            </div>
            <div className="mb-3">
              <Form.Label className={`${skin ? "text-stone-500" : ""}`}>Password</Form.Label>
              <Form.Control ref={passwordRef} name="password" type="password" placeholder="Enter password" value={password} onChange={e => handleFieldChange(e)} />
              <div className='flex justify-end mt-1'>
                <Link to="/forgot-password" className={`hover:underline ${skin ? "text-stone-500" : ""}`}>Forgot password?</Link>
              </div>
            </div>

            <button type="submit" disabled={loading} className="btn-sign font-medium flex justify-center w-full px-3 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear">
              {
                loading ?
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                  : "Sign In"
              }
            </button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Signin