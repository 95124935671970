import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import AuthUtils from '../../Pages/Utils/AuthUtils';
import { endOfMonth, subYears } from 'date-fns';

const LeadDeleteConfirmModal = ({ campaign, page, totalLeads, totalDeletedLeads, setTotalDeletedLeads,
    showModal, setShowModal, deletedLeads, setDeletedLeads, setLoading,
    filterLeadsByCampaign, leadOnPageLoad, isDeleteAll, setIsDeleteAll, leadFormData, setLeadFormData }) => {
    const { http, logout } = AuthUtils()

    const handleClose = () => {
        setIsDeleteAll(false);
        setShowModal(false);
        setLeadFormData(null);
    }

    async function deleteAllLeadsFunc({ formData, page }) {
        try {
            const res = await http.post(`/api/leads/?page=${page}`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (res.status === 200) {
                const { data: leads, current_page, last_page } = res.data;

                for (let i = 0; i < leads.length; i++) {
                    const leadId = leads[i].id;

                    try {
                        await http.delete(`/api/delete-lead/${leadId}/`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                    } catch (err) {
                        const { response } = err;

                        if (response) {
                            const { data } = response;
                            if (data) {
                                if (data.message === "Unauthenticated.") {
                                    logout();
                                }
                            }
                        }
                        continue;
                    }
                }

                if (current_page !== last_page) {
                    return deleteAllLeadsFunc({
                        formData: formData,
                        page: current_page + 1
                    });
                }
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
        }
    }

    const deleteSelectedLeads = async () => {
        handleClose();
        setLoading(true);

        if (isDeleteAll) {
            await deleteAllLeadsFunc({
                formData: leadFormData,
                page: 1
            });

            toast.success(`Total ${totalLeads} lead${totalLeads > 1 ? "s" : ""} deleted.`);
        } else {
            for (let i = 0; i < deletedLeads.length; i++) {
                const activePageDeleteLeads = [...deletedLeads[i]];

                for (let j = 0; j < activePageDeleteLeads.length; j++) {
                    const leadId = activePageDeleteLeads[j];

                    try {
                        await http.delete(`/api/delete-lead/${leadId}/`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                    } catch (err) {
                        const { response } = err;

                        if (response) {
                            const { data } = response;
                            if (data) {
                                if (data.message === "Unauthenticated.") {
                                    logout();
                                }
                            }
                        }
                        continue;
                    }
                }
            }
            toast.success(`Total ${totalDeletedLeads} lead${totalDeletedLeads > 1 ? "s" : ""} deleted.`);
        }

        setLoading(false);
        setDeletedLeads([
            []
        ]);
        setTotalDeletedLeads(0);

        if (leadOnPageLoad) {
            filterLeadsByCampaign({
                selPage: page,
                startDate: subYears(new Date(), 1),
                endDate: endOfMonth(new Date())
            });
        } else {
            filterLeadsByCampaign({selPage: page});
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-y-3'>
                    <p className='my-0'>Are you sure you want to delete {isDeleteAll ? totalLeads : totalDeletedLeads} lead{isDeleteAll ? totalLeads > 1 ? "s" : "" : totalDeletedLeads > 1 ? "s" : ""}?</p>

                    <div className='grid grid-cols-2 gap-3'>
                        <button onClick={deleteSelectedLeads} type="button" className='bg-rose-600 hover:bg-rose-700 text-white rounded-md px-3 py-2 transition-colors duration-200 ease-linear'>
                            Yes
                        </button>
                        <button onClick={handleClose} type="button" className='bg-teal-600 hover:bg-teal-700 text-white rounded-md px-3 py-2 transition-colors duration-200 ease-linear'>
                            No
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LeadDeleteConfirmModal
