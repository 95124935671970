import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthUtils from "../Utils/AuthUtils"
import JsonFormatter from 'react-json-formatter'
import { Link, useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Form, Spinner } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import Select from "react-select";

export default function LeadTest() {
    const { http, logout } = AuthUtils();
    const buyerId = useParams();
    const [buyer, setBuyer] = useState("");
    const [response, setResponse] = useState("")
    const [showResponse, setShowResponse] = useState(false)
    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [allFields, setAllFields] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(true);

    const [loading, setLoading] = useState(false);

    const testPayloadFormRef = useRef();


    async function fetchSuppliers(campaignId) {
        try {
            const res = await http.get(`/api/suppliers/${campaignId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                let supplierArr = [];

                data?.map(s => {
                    if (s.client) {
                        const { company_name } = s.client;

                        supplierArr.push({
                            value: s.id,
                            label: company_name[0]?.toUpperCase() + company_name?.slice(1,)
                        })
                    }
                });

                setSuppliers(supplierArr);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch suppliers' data!");
        }
    }


    const fetchBuyerDetails = async () => {
        try {
            const response = await http.get(`/api/fetch-buyer/${buyerId.id}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status === 200) {
                const { campaign } = response.data.data;
                setBuyer(response.data.data);

                let fieldArr = [];

                if (campaign) {
                    // Fetch campaign's all suppliers
                    await fetchSuppliers(campaign.id);

                    campaign?.fields?.map(f => {
                        let fvArr = [];
                        f.field_values?.map(fv => {
                            fvArr.push({
                                ...fv,
                                value: fv.value_title,
                                label: fv.value_title
                            });
                        });

                        f.field_values = fvArr;
                        fieldArr.push(f);
                    })

                    setAllFields(fieldArr);
                }

                setIsFetchingData(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch buyer details!");
            setIsFetchingData(false);
        }
    };

    useEffect(() => {
        fetchBuyerDetails();
    }, []);

    const sendTestLead = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (!supplier) {
            toast.error("Please select a supplier!");
            setLoading(false);
            return;
        }

        const payloadFormDict = {};

        allFields?.map(f => {
            const el = testPayloadFormRef.current.querySelector(`[name='${f.field_name}']`);
            if (el) {
                payloadFormDict[f.field_name] = el.value;
            }
        })

        payloadFormDict["opt_in_timestamp"] = new Date().toISOString();

        const data = {
            campaign_id: buyer?.campaign?.id,
            supplier_id: supplier.value,
            is_test: true,
            buyer_id: buyer.id,
            payload: payloadFormDict
        }

        await http.post(`/api/test-buyer/${buyer.id}/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(resp => {
                if (resp.status === 200) {
                    setResponse(resp.data);
                    setShowResponse(true);
                }

                setLoading(false);
                // console.log(response)
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to test the buyer!");

                setLoading(false);
                setResponse("An error occured while making the request please check the endpoint url and try again!");
                setShowResponse(true);
            })
    }


    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />

            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    {
                        isFetchingData ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </div> :
                            <Card className="flex-1">
                                <Card.Header className={`flex flex-col sm:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${!skin && "bg-primary"}`}>
                                    <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                                        <Link to="/campaigns" className="px-2 py-2 text-white font-semibold hover:underline">Campaigns</Link>
                                        <FaLongArrowAltRight />
                                        {
                                            buyer.campaign &&
                                            <Link to={`/create-campaign/${buyer.campaign?.id}/`} className="px-2 py-2 text-white font-semibold hover:underline">
                                                {buyer.campaign?.campaign_title}
                                            </Link>
                                        }
                                        <FaLongArrowAltRight />
                                        {
                                            buyer.client && buyer.client.company_name &&
                                            <Link to={`/edit-buyer/${buyer?.id}/`} className="px-2 py-2 text-white font-semibold hover:underline">
                                                {buyer.client.company_name}
                                            </Link>
                                        }
                                        <FaLongArrowAltRight />
                                        <div className="px-2 py-2 font-semibold bg-white rounded-md text-stone-600">Test Buyer</div>
                                    </div>
                                </Card.Header>

                                <Card.Body>
                                    <div className="flex flex-col gap-y-5">
                                        <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                            <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Supplier</Form.Label>
                                            <Select
                                                options={suppliers}
                                                value={supplier}
                                                onChange={setSupplier}
                                                placeholder="Select supplier"
                                                classNamePrefix={"my-react-select"}
                                                className="w-full md:w-auto min-w-[150px]"
                                                isSearchable
                                                isClearable
                                                noOptionsMessage={() => "No match found."}
                                            />
                                        </div>
                                        <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Test Buyer</div>
                                        {
                                            // dataObject &&
                                            <Form onSubmit={sendTestLead} ref={testPayloadFormRef} className="card-post">
                                                {
                                                    allFields?.map((field, index) => (
                                                        field.field_name !== "opt_in_timestamp" &&
                                                        <Form.Group key={index} className={`mb-3 my-react-select-container ${skin && "dark__mode"}`}>
                                                            <Form.Label className="font-medium">{field.field_name}</Form.Label>
                                                            {
                                                                field.field_type === "list" ?
                                                                    <Select isClearable name={field.field_name} options={field.field_values} placeholder="Enter allowed values" classNamePrefix={"my-react-select"} />
                                                                    :
                                                                    <Form.Control name={field.field_name} type="text" placeholder={`Enter ${field.field_name}`}
                                                                        defaultValue={field.field_name === "api_key" ? buyer?.campaign?.campaign_api_key : ""} disabled={field.field_name === "api_key" ? true : false}
                                                                    />
                                                            }

                                                        </Form.Group>
                                                    ))
                                                }
                                                <button type="submit" className="btn-sign font-medium flex justify-center w-full px-3 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                                                    {
                                                        loading ?
                                                            <>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                Loading...
                                                            </>
                                                            : <span className="flex items-center gap-x-1">
                                                                Send
                                                                <IoRocketOutline className="text-lg" />
                                                            </span>
                                                    }
                                                </button>
                                            </Form>
                                        }

                                        {
                                            showResponse &&
                                            <Card>
                                                <Card.Header>
                                                    <div className={`text-xl font-medium ${skin && "text-stone-400"}`}>Response</div>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        response?.status && <p className={`${response?.status === "ACCEPTED" ? "text-success" : response?.status === "DUPLICATED" ? "text-warning" : "text-danger"} font-semibold text-lg`}>
                                                            {response?.status}
                                                        </p>
                                                    }
                                                    <pre className={`text-sm ${skin ? "bg-slate-800 text-stone-200" : "bg-stone-100"} p-2 border border-blue-300 rounded`}>Response: <JsonFormatter json={response} tabWith={4} /></pre>
                                                </Card.Body>
                                            </Card>}
                                    </div>
                                </Card.Body>
                            </Card>
                    }
                </div>
            </div>
        </>
    )
}