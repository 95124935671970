import React from 'react'
import userAvatar from "../assets/images/user_dummy.png";
import AuthUtils from '../Pages/Utils/AuthUtils';
import { Link } from 'react-router-dom';

const SidebarFooter = () => {
    const { logout } = AuthUtils();

    const toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    return (
        <div className="sidebar-footer">
            <div className="sidebar-footer-top">
                <nav className="nav w-full">
                    <div onClick={logout} className="cursor-pointer w-full px-5 py-2 bg-danger text-white rounded-md flex justify-center items-center"><i className="ri-logout-box-r-line"></i> Log Out</div>
                </nav>
                {/* <div className="sidebar-footer-thumb">
                    <img src={userAvatar} alt="" />
                </div>
                <div className="sidebar-footer-body">
                    <h6><Link to="../pages/profile.html">Wasek Samin</Link></h6>
                </div>
                <Link onClick={toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link> */}
            </div>
            {/* <div className="sidebar-footer-menu">
                <nav className="nav">
                    <Link to="/user"><i className="ri-edit-2-line"></i> Edit Profile</Link>
                    <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
                </nav>
                <hr />
                <nav className="nav">
                    <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                    <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                    <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
                    <div onClick={logout} className="cursor-pointer"><i className="ri-logout-box-r-line"></i> Log Out</div>
                </nav>
            </div> */}
        </div>
    )
}

export default SidebarFooter
