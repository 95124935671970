export const insertTextAtCursor = (inputField, textToInsert) => {
    if (typeof inputField.selectionStart === "number") {
      // Modern browsers with Selection API support
      const startPos = inputField.selectionStart;
      const endPos = inputField.selectionEnd;
      const newText = inputField.value.substring(0, startPos) + textToInsert + inputField.value.substring(endPos);
      inputField.value = newText;
      inputField.selectionStart = startPos + textToInsert.length;
      inputField.selectionEnd = startPos + textToInsert.length;
    } else {
      // Fallback for older browsers (optional)
      inputField.value += textToInsert;
    }

    return inputField.value;
  }