import { useEffect, useRef, useState } from "react";
import { Modal, Form, Spinner, ProgressBar, Table, Card } from "react-bootstrap";
import "../assets/css/AddReturnsModal.css";
import Select from "react-select";
import { IoWarningOutline } from "react-icons/io5";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from "moment";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { FileUploader } from "react-drag-drop-files";
import CreatableSelect from 'react-select/creatable';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaCaretRight } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import Papa from "papaparse";
import { toast, ToastContainer } from "react-toastify";
import { formattedDate } from "../Pages/Utils/formatDate";
import { PHP_URL, STAGE_PHP_ENDPOINT } from "../Pages/Utils/baseConfig";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";



const STEP_PROGRESS = 20;
const fileTypes = ["CSV"];

const RECORD_MATCH_OPTIONS = [
    {
        value: "RETURN ALL",
        label: "Return all"
    },
    // {
    //     value: "RETURN ALL EXCEPT ONE",
    //     label: "Return all, except one"
    // }
]

const STATUSES = [
    {
        value: "ACCEPTED",
        label: "Accepted"
    },
    {
        value: "REJECTED",
        label: "Rejected"
    },
    {
        value: "DUPLICATED",
        label: "Duplicated"
    },
    {
        value: "ERROR",
        label: "Error"
    },
    {
        value: "STORED",
        label: "Stored"
    },
    {
        value: "RETURNED",
        label: "Returned"
    },
    {
        value: "PENDING",
        label: "Pending"
    }
]

const AddReturnsModal = ({ http, logout, skin, showModal, setShowModal, campaigns, formError, setFormError, getReturns, page, isCampaignLoading }) => {
    const dateRangeRef = useRef();
    // const multiEmailRef = useRef();

    const [step, setStep] = useState(1);
    const [confirmStep, setConfirmStep] = useState(0);
    const [showSteps, setShowSteps] = useState(false);

    const [isBuyerLoading, setIsBuyerLoading] = useState(false);

    const campaignNameRef = useRef();
    const [loading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [buyers, setBuyers] = useState([]);
    const [buyer, setBuyer] = useState(null);
    const [file, setFile] = useState(null);
    // const [emails, setEmails] = useState([]);

    const [leadUniqueIdentifiers, setLeadUniqueIdentifiers] = useState([]);
    const [leadUniqueIdentifier, setLeadUniqueIdentifier] = useState(null);
    const [leadPriceFields, setLeadPriceFields] = useState([]);
    const [leadPriceField, setLeadPriceField] = useState(null);
    const [reasonFields, setReasonFields] = useState([]);
    const [reasonField, setReasonField] = useState(null);

    const [fileDataPreview, setFileDataPreview] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [fileDataPreviewLoading, setFileDataPreviewLoading] = useState(false);
    const [uniqueReasons, setUniqueReasons] = useState([]);
    const [uniqueReasonWithStatus, setUniqueReasonWithStatus] = useState([]);
    const [rejectionStatuses, setRejectionStatuses] = useState([]);

    const [currentColumnIndex, setCurrentColumnIndex] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const [recordMatch, setRecordMatch] = useState(null);

    const [proccedConfirm, setProceedConfirm] = useState(false);

    const returnModalBodyRef = useRef();
    const fileDataPreviewRef = useRef();

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [dateRangeDirection, setDateRangeDirection] = useState("horizontal");
    const [showDateRange, setShowDateRange] = useState(false);

    const actionOnWindowSize = () => {
        if (window.innerWidth > 640) {
            setDateRangeDirection("horizontal");
        } else {
            setDateRangeDirection("vertical");
        }
        if (window.innerWidth < 992) {
            setShowSteps(false);
        } else {
            setShowSteps(true);
        }
    }

    const windowResize = () => {
        actionOnWindowSize();
    }

    useEffect(() => {
        actionOnWindowSize();
        window.addEventListener("resize", windowResize);
    }, [])


    const handleSelectCampaign = async (e) => {
        setCampaign(e);
        setBuyers([]);
        setBuyer(null);

        await fetchBuyers(e.value);
    }

    const fetchBuyers = async (campId) => {
        setIsBuyerLoading(true);

        await http.get(`/api/buyers-by-campaign/${campId}/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                let buyerArr = [];

                res.data.data?.map(b => {
                    buyerArr.push({
                        value: b?.id,
                        label: b?.client?.company_name?.[0]?.toUpperCase() + b?.client?.company_name?.slice(1,)
                    })
                })
                setBuyers(buyerArr);
                setIsBuyerLoading(false);
            }).catch(err => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch buyers' data!");

                setIsBuyerLoading(false);
            })
    }

    const handleClose = () => {
        setShowModal(false);
        setIsLoading(false);
        setStep(1);
        setSelectedColumns([]);
        setConfirmStep(0);
        setCampaign(null);
        setBuyer(null);
        setLeadUniqueIdentifier(null);
        setLeadPriceField(null);
        setReasonField(null);
        setBuyers([]);
        setLeadUniqueIdentifiers([]);
        setLeadPriceFields([]);
        setReasonFields([]);
        setFile(null);
        setFileDataPreview(null);
        setFileDataPreviewLoading(false);
        setFileData([]);
        setUniqueReasons([]);
        setUniqueReasonWithStatus([]);
        setRejectionStatuses([]);
        // setEmails([]);
        setRecordMatch(null);
        setProceedConfirm(false);
        setCurrentColumnIndex(0);
    }

    const dateRangeEventListener = (e) => {
        if (dateRangeRef.current && dateRangeRef.current.contains(e.target)) return;
        handleDateRangeChangeState(false);
    }

    const handleDateRangeChangeState = (show) => {
        setShowDateRange(show);

        show ? document.addEventListener("click", dateRangeEventListener) : document.removeEventListener("click", dateRangeEventListener)
    }

    const handleDateRangeSelection = (item, comparison) => {
        setDateRange([item.selection]);
    }

    const showFormError = ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            campaignNameRef.current.classList.add("error__field");
            campaignNameRef.current?.focus();
        }

        setIsLoading(false);
    }

    const handleFieldChange = async (e) => {
        showFormError({
            errId: -1
        });

        if (e.target.name === "campaign__name") {
            if (e.target.value !== "") {
                campaignNameRef.current.classList.remove("error__field");
            } else {
                campaignNameRef.current.classList.add("error__field");
            }
        }
    }

    async function updateRejectionReason({
        rejection_reasons,
        lead_return_id
    }) {
        // const formData = new FormData();

        // rejection_reasons.map((r) => {
        //     formData.append("rejection_reasons", JSON.stringify(r));
        // })
        // formData.append("lead_return_id", lead_return_id);

        // let rejectionReasonArr = [];
        // rejection_reasons?.map(r => {
        //     rejectionReasonArr.push(JSON.stringify(r));
        // })

        const formData = {
            lead_return_id: lead_return_id,
            rejection_reasons: rejection_reasons
        }

        try {
            const res = await http.put("/api/update-rejection-reason/", formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to add rejection reason!");
        }
    }

    const postReturn = async () => {
        setIsLoading(true);

        let reasonArr = [];

        uniqueReasonWithStatus?.map(r => {
            reasonArr.push(JSON.stringify(r));
        });

        const formData = {
            campaign_id: campaign?.value,
            buyer_id: buyer?.value,
            date_from: formattedDate(new Date(dateRange[0].startDate)),
            date_to: formattedDate(new Date(dateRange[0].endDate)),
            lead_file: file,
            unique_identifier: leadUniqueIdentifier.value,
            reason_identifier: reasonField.value,
            lead_sold_price_identifier: leadPriceField ? leadPriceField.value : "",
            reason_array: reasonArr
        }

        try {
            const res = await http.post(`/api/post-return/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            if (res.status === 200) {
                const { lead_return_id, rejection_reasons } = res.data.data;

                if (lead_return_id && rejection_reasons.length) {
                    updateRejectionReason({
                        rejection_reasons: rejection_reasons,
                        lead_return_id: lead_return_id
                    })
                }

                toast.success("Return added successfully.");
                getReturns(page);
                handleClose();
                setIsLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to create return!");
            setIsLoading(false);
        }
    }

    const handleStep = (state) => {
        setCurrentColumnIndex(0);

        if (state === "increase") {
            if (step === 5) {
                // handleClose();
                postReturn();
                return;
            }

            if (step === 1) {
                if (!campaign || !dateRange || !buyer) {
                    if (!campaign) {
                        toast.error("Please select a campaign!");
                    } else if (!dateRange) {
                        toast.error("Please select a date!");
                    } else if (!buyer) {
                        toast.error("Please select a buyer!");
                    }
                    return;
                }
            } else if (step === 2) {
                if (!file) {
                    toast.error("Please upload a file!");
                    return;
                }
            } else if (step === 3) {
                if (!leadUniqueIdentifier) {
                    toast.error("Please select a unique lead identifier field!");
                    return;
                }
                if (!reasonField) {
                    toast.error("Please select a reason field!");
                    return;
                }

                let uniqueRejectedSet = new Set();
                let uniqueRejectionWithStatusArr = [], rejectStatusArr = [];

                fileData?.map(data => {
                    const reasonName = data[reasonField?.value];
                    if (reasonName) uniqueRejectedSet.add(reasonName);
                })

                uniqueRejectedSet = Array.from(uniqueRejectedSet);

                if (uniqueReasonWithStatus.length === 0) {
                    uniqueRejectedSet.map(rejectVal => {
                        uniqueRejectionWithStatusArr.push({
                            reason_name: rejectVal,
                            status: ""
                        })

                        rejectStatusArr.push(null);
                    })

                    setUniqueReasonWithStatus(uniqueRejectionWithStatusArr);
                    setRejectionStatuses(rejectStatusArr);
                }

                setUniqueReasons(uniqueRejectedSet);
            }
            else if (step === 4) {
                if (rejectionStatuses.includes(null)) {
                    toast.error("Please select the status of all the reasons!");
                    return;
                }

                // if (emails.length === 0 || !recordMatch) {
                //     if (emails.length === 0) {
                //         toast.error("Please enter at least one email address!");
                //     } else if (!recordMatch) {
                //         toast.error("Please select a record!");
                //     }
                //     return;
                // }
                // if (!recordMatch) {
                //     toast.error("Please select a record!");
                //     return;
                // }
            } else if (step === 5) {
                if (!proccedConfirm) {
                    toast.error("Please tick on the checkbox to confirm!");
                    return;
                }
            }

            setStep(prevStep => prevStep + 1);
            setConfirmStep(prevStep => prevStep + 1);
        } else {
            if (step === 1) {
                if (confirmStep !== 0) {
                    setConfirmStep(prevStep => prevStep - 1);
                }
                return;
            }
            setStep(prevStep => prevStep - 1);
            setConfirmStep(prevStep => prevStep - 1);
            setFileDataPreviewLoading(false);
        }
    }

    const resetOnFileUpload = () => {
        setSelectedColumns([]);
        setUniqueReasons([]);
        setUniqueReasonWithStatus([]);
        setRejectionStatuses([]);
        setLeadUniqueIdentifier(null);
        setLeadUniqueIdentifiers([]);
        setLeadPriceField(null);
        setLeadPriceFields([]);
        setReasonField(null);
        setReasonFields([]);
        setFileData([]);
        setFileDataPreview(null);
        setCurrentColumnIndex(0);
    }

    const handleFileChange = (file) => {
        // setFile(file);
        if (file.type !== "text/csv") return;
        setFileDataPreviewLoading(true);
        setFile(file);

        resetOnFileUpload();

        // Parse CSV file
        Papa.parse(file, {
            // preview: 5,
            header: true, // Set to 'true' if your CSV file has a header,
            complete: (result) => {
                const fileData = result.data.slice(0, 5);
                let headerArr = [];

                Object.keys(result.data[0])?.map((header, index) => {
                    if (!headerArr.includes(header)) {
                        headerArr.push({
                            value: header,
                            label: header
                        });
                    }
                })
                setLeadUniqueIdentifiers(headerArr);
                setReasonFields(headerArr);
                setLeadPriceFields(headerArr);
                // 'result.data' contains the parsed CSV data
                setFileDataPreview(fileData);
                setFileData(result.data);
            },
        });

        setFileDataPreviewLoading(false);
    }

    // const handleMultiEmailPaste = event => {
    //     const pastedText = (event.clipboardData || window.clipboardData).getData('text');
    //     if (!pastedText) return;

    //     const newOptions = pastedText.split("\n").map((item, index) => ({
    //         value: item.trim(),
    //         label: item.trim()
    //     }))

    //     setEmails(prevs => [...prevs, ...newOptions]);

    //     // Prevent the default behavior of pasting
    //     event.preventDefault();
    // }

    // useEffect(() => {
    //     if (step === 4) {
    //         multiEmailRef.current?.inputRef?.addEventListener("paste", handleMultiEmailPaste);
    //     } else {
    //         multiEmailRef.current?.inputRef?.removeEventListener("paste", handleMultiEmailPaste);
    //     }
    // }, [step])

    const isValidEmail = (email) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    // const handleEmails = e => {
    //     let emailArr = [];

    //     e?.map(em => {
    //         const validEmail = isValidEmail(em.value);
    //         if (validEmail) {
    //             emailArr.push(em)
    //         } else {
    //             toast.error("Please provide a valid email address!");
    //         }
    //     })

    //     setEmails(emailArr);
    // }

    const handleUniqueReasonWithStatus = ({ status, reason, reasonIndex }) => {
        let uniqueReasonWithStatusArr = [], rejectionStatusArr = [];

        uniqueReasonWithStatus?.map(reasonWithStatus => {
            uniqueReasonWithStatusArr.push(reasonWithStatus);
        })

        rejectionStatuses?.map(r => {
            rejectionStatusArr.push(r);
        })

        uniqueReasonWithStatusArr.map(r => {
            if (r["reason_name"] === reason) {
                r["status"] = status?.value;
            }
        })

        setUniqueReasonWithStatus(uniqueReasonWithStatusArr);

        rejectionStatusArr[reasonIndex] = status;
        setRejectionStatuses(rejectionStatusArr);
    }

    const handleLeadUniqueIdentifier = e => {
        setLeadUniqueIdentifier(e);

        let selectedColumnsArr = [];

        selectedColumns?.map(sc => {
            selectedColumnsArr.push(sc);
        })

        if (e) {
            leadUniqueIdentifiers.map(l => {
                if (l.value === e.value) {
                    selectedColumnsArr[0] = l.value;
                }
            })
        } else {
            selectedColumns.splice(0, 1);
        }

        setSelectedColumns(selectedColumnsArr);
    }

    const handleReasonField = e => {
        setReasonField(e);

        let selectedColumnsArr = [];

        selectedColumns?.map(sc => {
            selectedColumnsArr.push(sc);
        })

        if (e) {
            leadUniqueIdentifiers.map(l => {
                if (l.value === e.value) {
                    selectedColumnsArr[1] = l.value;
                }
            })
        } else {
            selectedColumnsArr.splice(1, 1);
        }

        setSelectedColumns(selectedColumnsArr);
    }

    const handleLeadPriceField = e => {
        setLeadPriceField(e);

        let selectedColumnsArr = [];

        selectedColumns?.map(sc => {
            selectedColumnsArr.push(sc);
        })

        if (e) {
            leadUniqueIdentifiers.map(l => {
                if (l.value === e.value) {
                    selectedColumnsArr[2] = l.value;
                }
            })
        } else {
            selectedColumnsArr.splice(2, 1);
        }

        setSelectedColumns(selectedColumnsArr);
    }

    const handleCurrentColumn = columnState => {
        if (columnState === "add") {
            if (currentColumnIndex === selectedColumns.length - 1) return;

            let colIndex = currentColumnIndex + 1;
            setCurrentColumnIndex(colIndex);

            fileDataPreviewRef.current.querySelector(`.table__data-${colIndex}`)?.focus();
        } else {
            if (currentColumnIndex === 0) return;

            let colIndex = currentColumnIndex - 1;
            setCurrentColumnIndex(colIndex);

            fileDataPreviewRef.current.querySelector(`.table__data-${colIndex}`)?.focus();
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal show={showModal} onHide={handleClose} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Add Return</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={returnModalBodyRef} className="grid" id="return__modalBody">
                        <div className={`pr-3 flex flex-col gap-y-5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                            <div className="flex flex-col gap-y-5">
                                <div className="flex flex-col gap-y-3">
                                    <h6 className="mb-0 uppercase">
                                        {step === 1 ? "Basic Information" : step === 2 ? "Upload Buyer File" : step === 3 ? "Mapping" : step === 4 ? "Settings" : "Confirmation"}
                                    </h6>
                                    <ProgressBar now={STEP_PROGRESS * step} className="mb-2" />
                                </div>
                                {
                                    step === 1 &&
                                    <>
                                        <Form.Group className="mb-3 flex flex-col gap-y-3">
                                            <Form.Label className="font-medium uppercase">1. Select Campaign</Form.Label>
                                            <div className="text-stone-700 flex items-center gap-x-1 bg-amber-100 p-3 rounded-md border-l-4 border-amber-400">
                                                <IoWarningOutline className="text-lg" />
                                                <p className="mb-0">Leads that were sold to more than one buyer cannot be returned.</p>
                                            </div>
                                            {
                                                isCampaignLoading ?
                                                    <Spinner animation="border" variant="primary" className="mx-auto" /> :
                                                    <Select
                                                        options={campaigns}
                                                        value={campaign}
                                                        onChange={handleSelectCampaign}
                                                        placeholder="Select campaign"
                                                        classNamePrefix={"my-react-select"}
                                                        className="w-full whitespace-nowrap"
                                                        isSearchable
                                                        isClearable
                                                        noOptionsMessage={() => "No match found."}
                                                    />
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            {
                                                showDateRange &&
                                                <div className="overlay"></div>
                                            }
                                            <Form.Label className="font-medium uppercase">2. Select Time Period</Form.Label>
                                            <div ref={dateRangeRef} className="relative">
                                                <div onClick={() => handleDateRangeChangeState(!showDateRange)} className={`cursor-pointer flex items-center gap-x-1 border border-stone-300 p-2 rounded-md ${skin ? "" : "bg-white"}`}>
                                                    {
                                                        dateRange.length > 0 &&
                                                        <>
                                                            <span className={`${skin ? "text-stone-400" : ""}`}>
                                                                <FaRegCalendarAlt />
                                                            </span>
                                                            <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{moment(dateRange[0].startDate.toLocaleDateString()).format("MMM DD, YYYY")}</p>
                                                            <span className={`${skin ? "text-stone-400" : ""}`}>-</span>
                                                            <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{moment(dateRange[0].endDate.toLocaleDateString()).format("MMM DD, YYYY")}</p>
                                                        </>
                                                    }
                                                </div>

                                                {
                                                    showDateRange &&
                                                    <div className={`absolute top-full left-0 z-[1001] w-full date__rangePicker ${skin ? "dark__mode" : ""}`}>
                                                        <DateRangePicker
                                                            weekStartsOn={1}
                                                            editableDateInputs={true}
                                                            onChange={(item) => handleDateRangeSelection(item)}
                                                            showSelectionPreview={true}
                                                            moveRangeOnFirstSelection={false}
                                                            months={2}
                                                            maxDate={new Date()}
                                                            ranges={dateRange}
                                                            direction={dateRangeDirection}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-medium uppercase">3. Select Buyer</Form.Label>
                                            {
                                                isBuyerLoading ?
                                                    <div className="flex justify-center">
                                                        <Spinner animation="border" variant="primary" />
                                                    </div> :
                                                    <Select
                                                        options={buyers}
                                                        value={buyer}
                                                        onChange={setBuyer}
                                                        placeholder="Select buyer"
                                                        classNamePrefix={"my-react-select"}
                                                        className="w-full whitespace-nowrap"
                                                        isSearchable
                                                        isClearable
                                                        noOptionsMessage={() => "No match found."}
                                                    />
                                            }
                                        </Form.Group>
                                    </>
                                }
                                {
                                    step === 2 &&
                                    <Form.Group className="mb-3">
                                        <Form.Label className="font-medium uppercase">4. Upload CSV File</Form.Label>
                                        <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="file__uploader" />
                                        {
                                            file &&
                                            <p className="font-medium mb-0 mt-2 text-center">{file.name}</p>
                                        }
                                    </Form.Group>
                                }
                                {
                                    step === 3 &&
                                    <div className="flex flex-col gap-y-3">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-2 items-end">
                                            <Form.Group className="mb-3 w-full">
                                                <Form.Label className="font-medium uppercase">Select UNIQUE LEAD IDENTIFIER field from file</Form.Label>
                                                <Select
                                                    options={leadUniqueIdentifiers}
                                                    value={leadUniqueIdentifier}
                                                    onChange={handleLeadUniqueIdentifier}
                                                    placeholder="Unique field from file"
                                                    classNamePrefix={"my-react-select"}
                                                    className="w-full whitespace-nowrap"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3 w-full">
                                                <Form.Label className="font-medium uppercase">Select REASON field from file</Form.Label>
                                                <Select
                                                    options={reasonFields}
                                                    value={reasonField}
                                                    onChange={handleReasonField}
                                                    placeholder="Reason field from file"
                                                    classNamePrefix={"my-react-select"}
                                                    className="w-full whitespace-nowrap"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-medium uppercase">Do you want to override lead sold price? <span className="text-stone-500">(Optional)</span></Form.Label>
                                                <Select
                                                    options={leadPriceFields}
                                                    value={leadPriceField}
                                                    onChange={handleLeadPriceField}
                                                    placeholder="Select lead sold price field"
                                                    classNamePrefix={"my-react-select"}
                                                    className="whitespace-nowrap"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </Form.Group>
                                        </div>
                                        <Form.Group className="mb-3">
                                            <div className="flex flex-wrap items-center justify-between gap-3">
                                                <Form.Label className="font-medium uppercase">Data Preview</Form.Label>
                                                <div className="flex items-center gap-x-1.5 mb-2">
                                                    <button type="button" onClick={() => handleCurrentColumn("sub")} className="text-lg text-indigo-500 hover:text-indigo-600 transition-colors duration-200 ease-linear">
                                                        <FaCircleChevronLeft />
                                                    </button>
                                                    <button type="button" onClick={() => handleCurrentColumn("add")} className="text-lg text-indigo-500 hover:text-indigo-600 transition-colors duration-200 ease-linear">
                                                        <FaCircleChevronRight />
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <Select
                                                options={reasonFields}
                                                value={reasonField}
                                                onChange={setReasonField}
                                                placeholder="Reason field from file"
                                                classNamePrefix={"my-react-select"}
                                                className="whitespace-nowrap"
                                                isSearchable
                                                noOptionsMessage={() => "No match found."}
                                            /> */}
                                            {
                                                fileDataPreview &&
                                                <>
                                                    <Table ref={fileDataPreviewRef} variant={skin && "dark"} hover className="mb-0" responsive>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    Object.keys(fileDataPreview[0]).map((header, index) => (
                                                                        <th tabIndex={-1} key={index} scope="col" className={`text-lg px-3 font-semibold whitespace-nowrap ${selectedColumns.includes(header) ? `table__data-${selectedColumns.findIndex(col => col === header)} ${skin ? "bg__slate700" : "bg-stone-300"}` : ""}`}>{header}</th>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                fileDataPreview.map((row, index) => (
                                                                    <tr key={index}>
                                                                        {Object.values(row).map((cell, cellIndex) => (
                                                                            <td key={cellIndex} className={`px-3 align-middle ${selectedColumns.includes(Object.keys(row)[cellIndex]) ? `${skin ? "bg__slate700" : "bg-stone-300"}` : ""}`}>
                                                                                <p className={`mb-0 ${(Object.keys(row)[cellIndex] !== "what_happened" && Object.keys(row)[cellIndex] !== "opt_in_message") ? "whitespace-nowrap" : "max__4lines"}`}>{cell}</p>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </>
                                            }

                                        </Form.Group>
                                    </div>
                                }
                                {
                                    step === 4 &&
                                    // <>
                                    //     <Form.Group className="mb-3">
                                    //         <Form.Label className="font-medium uppercase">Send a notification to the following emails (max 3)</Form.Label>
                                    //         <CreatableSelect ref={multiEmailRef} isClearable isMulti value={emails} onChange={handleEmails} placeholder="Add email address" classNamePrefix={"my-react-select"} />
                                    //     </Form.Group>
                                    //     <Form.Group className="mb-3">
                                    //         <Form.Label className="font-medium uppercase">If one record mathces multiple leads</Form.Label>
                                    //         <Select
                                    //             options={RECORD_MATCH_OPTIONS}
                                    //             value={recordMatch}
                                    //             onChange={setRecordMatch}
                                    //             classNamePrefix={"my-react-select"}
                                    //             className="whitespace-nowrap"
                                    //             isSearchable
                                    //             noOptionsMessage={() => "No match found."}
                                    //         />
                                    //     </Form.Group>
                                    // </>
                                    <>
                                        {
                                            uniqueReasons.length ?
                                                uniqueReasons.map((reason, reasonIndex) => (
                                                    <Form.Group key={reasonIndex}>
                                                        <Form.Label className="font-medium uppercase">{reason} Reason Status</Form.Label>
                                                        <Select
                                                            options={STATUSES}
                                                            value={rejectionStatuses[reasonIndex]}
                                                            onChange={(e) => handleUniqueReasonWithStatus({ status: e, reason: reason, reasonIndex: reasonIndex })}
                                                            classNamePrefix={"my-react-select"}
                                                            className="whitespace-nowrap"
                                                            isSearchable
                                                            isClearable
                                                            noOptionsMessage={() => "No match found."}
                                                        />
                                                    </Form.Group>
                                                )) :
                                                <p className="text-center">Nothing to show!</p>
                                        }
                                    </>
                                }
                                {
                                    step === 5 &&
                                    <>
                                        <Card>
                                            <Card.Body>
                                                <div className="grid grid-cols-3 gap-x-5 gap-y-3">
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Campaign</p>
                                                        <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{campaign && campaign.label}</p>
                                                    </div>
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Selected Dates</p>
                                                        {
                                                            dateRange.length > 0 &&
                                                            <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{moment(new Date(dateRange[0]?.startDate)).format("ll")} - {moment(new Date(dateRange[0]?.endDate)).format("ll")}</p>
                                                        }
                                                    </div>
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Buyer</p>
                                                        <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{buyer.label}</p>
                                                    </div>
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Unqiue fields</p>
                                                        <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{leadUniqueIdentifier && leadUniqueIdentifier.label}</p>
                                                    </div>
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Reason Field</p>
                                                        <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{reasonField && reasonField.label}</p>
                                                    </div>
                                                    {/* <div className="flex flex-col gap-y-2">
                                                    <p className="mb-0 font-medium text-indigo-500">Notify Emails</p>
                                                    <div className="flex flex-wrap gap-x-1 gap-y-2">
                                                        {
                                                            emails.map((email, index) => (
                                                                <p key={email.value} className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{email.label}{index !== emails.length - 1 ? ", " : ""}</p>
                                                            ))
                                                        }
                                                    </div>
                                                </div> */}
                                                    {/* <div className="flex flex-col gap-y-2">
                                                        <p className="mb-0 font-medium text-indigo-500">Multiple Match Strategy</p>
                                                        <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>{recordMatch && recordMatch.label}</p>
                                                    </div> */}
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Form.Group className="mb-3">
                                            <div onClick={() => setProceedConfirm(prevState => !prevState)} className="w-fit mx-auto cursor-pointer flex items-center gap-x-0.5">
                                                <Form.Check type="checkbox" checked={proccedConfirm} label="" className="cursor-pointer" />
                                                <label htmlFor="" className="font-medium cursor-pointer">I confirm that the above information is correct and I want to proceed</label>
                                            </div>
                                        </Form.Group>
                                    </>
                                }
                                {
                                    step === 6 &&
                                    <div className="flex items-center gap-x-1">
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </div>
                                }

                            </div>
                            <div className="flex items-center justify-center gap-x-3">
                                <button onClick={() => handleStep("decrease")} type="button" disabled={step === 1 ? true : false} className={`flex items-center justify-center gap-x-1 px-3 py-2 rounded-md ${skin ? `bg-slate-600 ${step === 1 ? "" : "text-white"}` : "bg-stone-200"}`}>
                                    <FaArrowLeftLong />
                                    Back
                                </button>
                                <button disabled={(step === 5 && !proccedConfirm) || loading} onClick={() => handleStep("increase")} type="button" className={`flex items-center justify-center gap-x-1 px-3 py-2 rounded-md ${step === 5 && !proccedConfirm ? "bg-indigo-300" : "bg-indigo-500"} text-white`}>
                                    Next
                                    {
                                        fileDataPreviewLoading || loading ?
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : <FaArrowRightLong />
                                    }
                                </button>
                            </div>
                        </div>
                        {
                            showSteps &&
                            <div className="border-l border-stone-200 flex flex-col gap-y-5">
                                <h5 className="font-medium pl-3">Steps</h5>
                                <div className="flex flex-col gap-y-3">
                                    <div className="flex items-center justify-between gap-x-3">
                                        <div className="flex items-center">
                                            {
                                                step === 1 &&
                                                <FaCaretRight className="text-lg ml-[-0.5rem] text-indigo-600" />
                                            }
                                            <p className={`mb-0 font-medium ${step !== 1 ? "pl-3" : "text-indigo-500"}`}>Basic Information</p>
                                        </div>
                                        {
                                            confirmStep > 0 &&
                                            < FaRegCheckCircle className="text-teal-600 text-lg" />
                                        }
                                    </div>
                                    <div className="flex items-center justify-between gap-x-3">
                                        <div className="flex items-center">
                                            {
                                                step === 2 &&
                                                <FaCaretRight className="text-lg ml-[-0.5rem] text-indigo-600" />
                                            }
                                            <p className={`mb-0 font-medium ${step !== 2 ? "pl-3" : "text-indigo-500"}`}>Upload Buyer File</p>
                                        </div>
                                        {
                                            confirmStep > 1 &&
                                            < FaRegCheckCircle className="text-teal-600 text-lg" />
                                        }
                                    </div>
                                    <div className="flex items-center justify-between gap-x-3">
                                        <div className="flex items-center">
                                            {
                                                step === 3 &&
                                                <FaCaretRight className="text-lg ml-[-0.5rem] text-indigo-600" />
                                            }
                                            <p className={`mb-0 font-medium ${step !== 3 ? "pl-3" : "text-indigo-500"}`}>Mapping</p>
                                        </div>
                                        {
                                            confirmStep > 2 &&
                                            < FaRegCheckCircle className="text-teal-600 text-lg" />
                                        }
                                    </div>
                                    <div className="flex items-center justify-between gap-x-3">
                                        <div className="flex items-center">
                                            {
                                                step === 4 &&
                                                <FaCaretRight className="text-lg ml-[-0.5rem] text-indigo-600" />
                                            }
                                            <p className={`mb-0 font-medium ${step !== 4 ? "pl-3" : "text-indigo-500"}`}>Settings</p>
                                        </div>
                                        {
                                            confirmStep > 3 &&
                                            < FaRegCheckCircle className="text-teal-600 text-lg" />
                                        }
                                    </div>
                                    <div className="flex items-center justify-between gap-x-3">
                                        <div className="flex items-center">
                                            {
                                                step === 5 &&
                                                <FaCaretRight className="text-lg ml-[-0.5rem] text-indigo-600" />
                                            }
                                            <p className={`mb-0 font-medium ${step !== 5 ? "pl-3" : "text-indigo-500"}`}>Confirmation</p>
                                        </div>
                                        {
                                            confirmStep > 4 &&
                                            < FaRegCheckCircle className="text-teal-600 text-lg" />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddReturnsModal
