import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BiSave } from "react-icons/bi";
import AuthUtils from "../Utils/AuthUtils";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Form, Spinner } from "react-bootstrap";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";


const PRICING_TYPES = [
    {
        value: "Fixed",
        label: "Fixed"
    },
    {
        value: "Percentage",
        label: "Percentage"
    }
]

export default function AddTrafficSource() {
    const { http, logout } = AuthUtils();
    const navigate = useNavigate();
    const { campaignId } = useParams();

    const [isSaving, setIsSaving] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [formError, setFormError] = useState({
        errorId: -1
    });
    const [priceType, setPriceType] = useState(null);
    const [payoutPrice, setPayoutPrice] = useState(0);
    const [payoutPercentage, setPayoutPercentage] = useState(0);

    // const sourceIdRef = useRef();
    const payoutPriceRef = useRef();
    const payoutPercentageRef = useRef();
    const channelNameRef = useRef();
    const campaignPageUrlRef = useRef(null);

    const showFormError = ({ errorId }) => {
        setFormError({
            errorId: errorId,
        });

        // if (errorId === 1) {
        //     sourceIdRef.current.classList.add("error__field");
        //     sourceIdRef.current?.focus();
        // } 
        if (errorId === 2) {
            toast.error("Please select a client!");
        } else if (errorId === 3) {
            payoutPriceRef.current.classList.add("error__field");
            payoutPriceRef.current?.focus();
        } else if (errorId === 4) {
            payoutPercentageRef.current.classList.add("error__field");
            payoutPercentageRef.current?.focus();
        } else if (errorId === 5) {
            toast.error("Please select a pricing type!");
        } else if (errorId === 6) {
            channelNameRef.current.classList.add("error__field");
            channelNameRef.current?.focus();
        } else if (errorId === 7) {
            campaignPageUrlRef.current.classList.add("error__field");
            campaignPageUrlRef.current?.focus();
        }
    }

    const handleTrafficSourcePost = async (e) => {
        setIsSaving(true);

        setFormError({
            errorId: -1,
        });

        // const sourceId = sourceIdRef.current.value.trim();
        const channelName = channelNameRef.current?.value?.trim() ?? "";
        const campaignPageUrl = campaignPageUrlRef.current?.value?.trim() ?? "";

        // if (sourceId === "") {
        //     showFormError({
        //         errorId: 1,
        //     });
        //     setIsSaving(false);
        //     return;
        // }

        if (client == null) {
            showFormError({
                errorId: 2,
            })
            setIsSaving(false);
            return;
        }

        if (!priceType) {
            showFormError({
                errorId: 5,
            });
            setIsSaving(false);
            return;
        }
        if (campaignPageUrl === "") {
            showFormError({
                errorId: 7
            });
            setIsSaving(false);
            return;
        }

        let payout = 0;

        if (priceType.value === "Fixed") {
            if (payoutPrice === "") {
                showFormError({
                    errorId: 3,
                });
                setIsSaving(false);
                return;
            }

            payout = payoutPrice;
        }

        if (priceType.value === "Percentage") {
            const payoutPercentage = payoutPercentageRef.current.value.trim();

            if (payoutPercentage === "") {
                showFormError({
                    errorId: 4,
                });
                setIsSaving(false);
                return;
            }

            payout = payoutPercentage;
        }
        if (channelName === "") {
            showFormError({
                errorId: 6,
            });
            setIsSaving(false);
            return;
        }

        const formData = {
            // source_id: sourceId,
            client_id: client.value,
            campaign_id: campaignId,
            pricing_type: priceType.value,
            price: payout,
            channel_name: channelName,
            page_url: campaignPageUrl
        }

        try {
            const response = await http.post(`/api/create-traffic-source/`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status === 200) {
                setIsSaving(false);
                toast.success("Traffic source created successfully.");
                setTimeout(() => {
                    navigate(`/create-campaign/${campaignId}/`)
                }, 1000)
            } else {
                setIsSaving(false);
                toast.error("Something went wrong");
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to create traffic source!");

            // console.log(err);
            setIsSaving(false);
        }
    };

    const getClients = async () => {
        setIsLoading(true);

        try {
            const res = await http.get("/api/clients?per_page=all", {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                let clientArr = [];

                data?.map(c => {
                    const { user, company_name } = c;

                    if (user && company_name) {
                        user.roles?.map(r => {
                            if (r.name === "TRAFFIC SOURCE") {
                                clientArr.push({
                                    ...c,
                                    value: c.id,
                                    label: company_name[0].toUpperCase() + company_name.slice(1,)
                                })
                            }
                        })
                    }
                })

                if (!clientArr.length) {
                    clientArr.push({
                        value: -1,
                        label: "Create New Client"
                    })
                }

                setClients(clientArr);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
            toast.error("Failed to fetch clients' data!");
        }
    }

    const fetchCampaignDetails = async () => {
        setIsLoading(true);

        try {
            const res = await http.get(`/api/campaign-details/${campaignId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                setCampaign(data);
                setIsLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch campaign's data!");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (campaignId) {
            fetchCampaignDetails();
            getClients();
        }
    }, [campaignId]);

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    const handleFieldChange = async (e) => {
        // if (e.target.name === "source__id") {
        //     if (e.target.value !== "") {
        //         sourceIdRef.current.classList.remove("error__field");
        //     } else {
        //         sourceIdRef.current.classList.add("error__field");
        //     }
        // } 
        if (e.target.name === "channel__name") {
            if (e.target.value !== "") {
                channelNameRef.current.classList.remove("error__field");
            } else {
                channelNameRef.current.classList.add("error__field");
            }
        } else if (priceType) {
            if (priceType.value === "Fixed") {
                if (e.target.name === "payout__price") {
                    setPayoutPrice(e.target.value);
                    if (e.target.value !== "") {
                        payoutPriceRef.current.classList.remove("error__field");
                    } else {
                        payoutPriceRef.current.classList.add("error__field");
                    }
                }
            }
            if (priceType.value === "Percentage") {
                if (e.target.name === "payout__percentage") {
                    setPayoutPercentage(e.target.value);
                    if (e.target.value !== "") {
                        payoutPercentageRef.current.classList.remove("error__field");
                    } else {
                        payoutPercentageRef.current.classList.add("error__field");
                    }
                }
            }
        } else if (e.target.name === "campaign__pageUrl") {
            if (e.target.value !== "") {
                campaignPageUrlRef.current.classList.remove("error__field");
            } else {
                campaignPageUrlRef.current.classList.add("error__field");
            }
        }
    }

    const handleSelectClient = e => {
        if (e.value === -1) {
            navigate("/manage-clients");
            return;
        }

        setClient(e);
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />

            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    {
                        loading ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </div> :
                            <div className='flex flex-col gap-y-5'>
                                <Card className="flex-1">
                                    <Card.Header className={`flex flex-col sm:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${!skin && "bg-primary"}`}>
                                        <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                                            <Link to="/campaigns" className="px-2 py-2 text-white font-semibold hover:underline">Campaigns</Link>
                                            <FaLongArrowAltRight />
                                            {
                                                campaign &&
                                                <Link to={`/create-campaign/${campaignId}/`} className="px-2 py-2 text-white font-semibold hover:underline">
                                                    {campaign.campaign_title}
                                                </Link>
                                            }

                                            <FaLongArrowAltRight />
                                            <div className="px-2 py-2 font-semibold bg-white rounded-md text-stone-600">Add Traffic Source</div>
                                        </div>

                                        <div className="flex items-center gap-x-3 gap-y-3 campaign__headerBtns">
                                            <Link to={`/create-campaign/${campaignId}/`} className="flex justify-center items-center gap-x-1.5 flex-1 sm:flex-auto font-medium px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                                                <FaLongArrowAltLeft />
                                                Go Back
                                            </Link>

                                            <button onClick={handleTrafficSourcePost} type="button" className="font-medium flex-1 sm:flex-auto font-medium flex items-center gap-x-1 px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                                                {
                                                    !isSaving &&
                                                    <BiSave />
                                                }
                                                Save
                                                {
                                                    isSaving && <Spinner animation="border" variant="light" size="sm" />
                                                }
                                            </button>
                                        </div>
                                    </Card.Header>
                                </Card>

                                <Card>
                                    <Card.Body className="card-post">
                                        <div className="flex flex-col gap-y-3">
                                            {/* <Form.Group className={`${skin && "dark__mode"}`}>
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Source ID</Form.Label>
                                                <Form.Control onChange={e => handleFieldChange(e)} name="source__id" ref={sourceIdRef} type="text" placeholder="Enter source id" />
                                            </Form.Group> */}
                                            <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Traffic Source</Form.Label>
                                                <Select
                                                    options={clients}
                                                    value={client}
                                                    onChange={handleSelectClient}
                                                    placeholder="Select traffic source"
                                                    classNamePrefix={"my-react-select"}
                                                    className="w-full md:w-auto min-w-[150px]"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </div>
                                            <div className={`mt-1.5 flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Pricing Type</Form.Label>
                                                <Select
                                                    options={PRICING_TYPES}
                                                    value={priceType}
                                                    onChange={setPriceType}
                                                    placeholder="Select pricing type"
                                                    classNamePrefix={"my-react-select"}
                                                    className="w-full"
                                                    isSearchable
                                                    isClearable
                                                    noOptionsMessage={() => "No match found."}
                                                />
                                            </div>
                                            {
                                                priceType && (
                                                    priceType.value === "Fixed" ?
                                                        <Form.Group className="flex flex-col gap-y-1.5">
                                                            <div className="flex items-center gap-x-1">
                                                                <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Fixed Amount</Form.Label>
                                                                {/* <div data-tooltip-id="price__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                                                                    <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                                                                    <Tooltip id="price__info" />
                                                                                </div> */}
                                                            </div>
                                                            <div className="flex items-center gap-x-1 card-post">
                                                                <div className={`font-medium ${skin && "text-stone-400"}`}>$</div>
                                                                <Form.Control name="payout__price" className="flex-1" ref={payoutPriceRef} value={payoutPrice} onChange={e => handleFieldChange(e)} type="text" placeholder="Set default price" />
                                                            </div>
                                                        </Form.Group> :
                                                        priceType.value === "Percentage" ?
                                                            <Form.Group className="flex flex-col gap-y-1.5">
                                                                <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Percentage Amount</Form.Label>
                                                                <div className="flex items-center gap-x-1 card-post">
                                                                    <Form.Control name="payout__percentage" className="flex-1" ref={payoutPercentageRef} value={payoutPercentage} onChange={e => handleFieldChange(e)} type="text" placeholder="Set Percentage" />
                                                                    <div className={`font-medium ${skin && "text-stone-400"}`}>%</div>
                                                                </div>
                                                            </Form.Group> : null
                                                )
                                            }
                                            <Form.Group className={`${skin && "dark__mode"}`}>
                                                <Form.Label htmlFor="channel__name" className={`font-medium ${skin && "text-stone-400"}`}>Channel Name</Form.Label>
                                                <Form.Control id="channel__name" onChange={e => handleFieldChange(e)} name="channel__name" ref={channelNameRef} type="text" placeholder="Enter channel name" />
                                            </Form.Group>
                                            <Form.Group className={`${skin && "dark__mode"}`}>
                                                <Form.Label htmlFor="campaign__pageUrl" className={`font-medium ${skin && "text-stone-400"}`}>Campaign Page Link/URL</Form.Label>
                                                <Form.Control id="campaign__pageUrl" onChange={e => handleFieldChange(e)} name="campaign__pageUrl" ref={campaignPageUrlRef} type="url" placeholder="Enter campaign link/url" />
                                            </Form.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                    }
                </div>
            </div>
        </>
    );
}
