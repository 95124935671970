import { Modal } from 'react-bootstrap'

const ConversionResponseLogModal = ({ showModal, setShowModal, responseLog, setResponseLog, skin }) => {
    const handleClose = () => {
        setShowModal(false);
        setResponseLog(null);
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered className='top__modal'>
            <Modal.Header closeButton>
                <Modal.Title>Response Log</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`flex flex-col gap-y-3 p-3 ${skin ? "bg-slate-700" : "bg-slate-200"} rounded-md`}>
                    <pre>
                        {JSON.stringify(responseLog?.postback_response, null, 2)}
                    </pre>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConversionResponseLogModal
