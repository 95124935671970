import { useEffect, useRef, useState } from 'react'
import Header from '../../layouts/Header'
import HeaderMobile from '../../layouts/HeaderMobile'
import Sidebar from '../../layouts/Sidebar'
import { toast, ToastContainer } from 'react-toastify'
import AuthUtils from '../Utils/AuthUtils'
import { FaInfo, FaListUl } from "react-icons/fa6";
import { Card, Dropdown, Form, Spinner, Table } from 'react-bootstrap';
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { DateRangePicker } from "react-date-range";
import { startOfMonth, endOfMonth } from "date-fns";
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from 'moment';
import { IoIosRefresh } from "react-icons/io";
import { PAGE_LIMIT_SIZE } from '../Utils/baseConfig'
import { IoSettingsOutline } from 'react-icons/io5'
import { PaginationControl } from "react-bootstrap-pagination-control";
import ConversionLogModal from '../../Components/ConversionLog/ConversionLogModal'
import { formattedDate } from '../Utils/formatDate'


const CONVERSION_STATUS = [
    {
        value: "ACCEPTED",
        label: "Accepted"
    },
    {
        value: "REJECTED",
        label: "Rejected"
    },
    {
        value: "DUPLICATED",
        label: "Duplicated"
    },
    {
        value: "ERROR",
        label: "Error"
    },
    {
        value: "STORED",
        label: "Stored"
    },
    {
        value: "RETURNED",
        label: "Returned"
    },
    {
        value: "PENDING",
        label: "Pending"
    }
]

const ConversionLog = () => {
    const { http, logout } = AuthUtils();
    // const [conversionOffers, setConversionOffers] = useState([]);
    // const [conversionGeos, setConversionGeos] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [conversionCampaigns, setConversionCampaigns] = useState([]);
    const [dateRange, setDateRange] = useState([
        {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
            key: "selection"
        }
    ]);
    const [conversionLog, setConversionLog] = useState(null);
    const [conversionLoader, setConversionLoader] = useState(true);
    const [dateRangeDirection, setDateRangeDirection] = useState("horizontal");
    const [showDateRange, setShowDateRange] = useState(false);
    const [page, setPage] = useState(1);
    const [isRefresh, setIsRefresh] = useState(false);
    const [totalConversions, setTotalConversions] = useState(0);
    // const [deletedConversions, setDeletedConversions] = useState([[]]);
    // const [totalDeletedConversions, setTotalDeletedConversions] = useState(0);
    const [conversions, setConversions] = useState([]);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [isDeleteAllConversions, setIsDeleteAllConversions] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [showConversionLogModal, setShowConversionLogModal] = useState(false);
    const [status, setStatus] = useState("");

    const searchTextRef = useRef();
    const dateRangeRef = useRef();

    const customValueRenderer = (selected, _options) => {
        let notSelectedText = "Nothing is selected";

        switch (_options) {
            case "offers":
                notSelectedText = "Select offer(s)";
                break;
            case "geos":
                notSelectedText = "Select geo(s)";
                break;
            case "campaigns":
                notSelectedText = "Select campaign(s)";
                break;
        }
        return selected.length
            ? selected.map(({ label }, index) => `${label}${selected.length && index !== selected.length - 1 ? ", " : ""}`)
            : notSelectedText;
    };

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const actionOnWindowSize = () => {
        if (window.innerWidth > 640) {
            setDateRangeDirection("horizontal");
        } else {
            setDateRangeDirection("vertical");
        }
    };

    const windowResize = () => {
        actionOnWindowSize();
    };

    useEffect(() => {
        actionOnWindowSize();
        window.addEventListener("resize", windowResize);
        fetchAllCampaigns();
        fetchConversions({
            page: 1, campaigns: conversionCampaigns,
            status: status
        });

        return () => {
            setTotalConversions(0);
            setPage(1);
        };
    }, [])

    async function fetchAllCampaigns() {
        try {
            const res = await http.get("/api/all-campaigns/", {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                let campArr = [];

                if (data.length) {
                    data?.map(c => {
                        campArr.push({
                            ...c,
                            value: c.id,
                            label: c.campaign_title
                        });
                    })
                }

                setAllCampaigns(campArr);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch campaigns' data!");
        }
    }

    function resetConversionData() {
        setTotalConversions(0);
        setConversions([]);
    }

    const fetchConversions = async ({ page, campaigns, status }) => {
        resetConversionData();
        setConversionLoader(true);
        setIsRefresh(true);

        const formData = new FormData();

        let startDate = "", endDate = "";

        if (dateRange.length > 0) {
            startDate = formattedDate(new Date(dateRange[0].startDate));
            endDate = formattedDate(new Date(dateRange[0].endDate));
        }

        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("conversionId", searchTextRef.current?.value?.trim());

        campaigns?.map(c => {
            formData.append("conversionCampaign[]", c ? c.value : "");
        })
        formData.append("conversionStatus", status ? status.value : "");

        try {
            const res = await http.post(`/api/conversion/?page=${page}`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })


            if (res.status === 200) {
                const { total, data: leads } = res.data;

                setTotalConversions(total);

                let leadArr = [];

                if (leads.length > 0) {
                    let externalId = null, country = null;
                    leads.map(lead => {
                        if (lead.payload) {
                            const leadPayload = lead.payload;

                            if (leadPayload["externalid"]) {
                                externalId = leadPayload.externalid;
                            }
                            if (leadPayload["country"]) {
                                country = leadPayload.country;
                            }
                        }

                        leadArr.push({
                            id: lead.id,
                            externalid: externalId,
                            country: country,
                            campaign: lead.campaign,
                            traffic_source: lead.traffic_source,
                            created_at: lead.created_at,
                            payout: lead.source_payout,
                            status: lead.status,
                            postback_response_log: lead.postback_response_log
                        })
                    })
                }

                setConversions(leadArr);
                setConversionLoader(false);
                setIsRefresh(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch conversions' data!");
            resetConversionData();
            setConversionLoader(false);
            setIsRefresh(false);
        }
    }

    const dateRangeEventListener = (e) => {
        if (dateRangeRef.current && dateRangeRef.current.contains(e.target)) return;
        handleDateRangeChangeState(false);
    };

    const handleDateRangeChangeState = (show) => {
        setShowDateRange(show);

        show
            ? document.addEventListener("click", dateRangeEventListener)
            : document.removeEventListener("click", dateRangeEventListener);
    };

    const handleDateRangeSelection = (item, comparison) => {
        setDateRange([item.selection]);
    };

    const handleSearchLogId = async(e) => {
        e.preventDefault();

        fetchConversions({
            page: page,
            campaigns: conversionCampaigns,
            status: status
        })
    }

    const deleteAllConversions = () => {
        setIsDeleteAllConversions(true);
        confirmDeleteConversions();
    }

    const confirmDeleteConversions = () => {
        setShowConfirmDeleteModal(true);
    }

    const handleSelectedOptions = ({ data, label }) => {
        switch (label) {
            // case "offers":
            //     setConversionOffers(data);
            //     break;
            // case "geos":
            //     setConversionGeos(data);
            //     break;
            case "campaigns":
                setConversionCampaigns(data);
                fetchConversions({
                    page: page,
                    campaigns: data,
                    status: status
                });
                break;
            case "status":
                setStatus(data);
                fetchConversions({
                    page: page,
                    campaigns: conversionCampaigns,
                    status: data
                });
                break;
        }
    }

    const handleRefreshLog = (page) => {
        setPage(page);
        fetchConversions({
            page: page,
            campaigns: conversionCampaigns,
            status: status
        });
    }

    // deleting conversions
    // const handleDeleteConversions = async (conversionId) => {
    //     try {
    //         let response = await http.delete(`/api/delete-conversion/${conversionId}/`, {
    //             headers: {
    //                 Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    //             }
    //         });
    //         if (response.status === 200) {
    //             fetchConversions(page);
    //             toast.success("Conversion removed!");
    //         } else {
    //             toast.error("Something went wrong!");
    //         }
    //     } catch (err) {
    //         const { response } = err;
    //         if (response) {
    //             const { data } = response;
    //             if (data && data.code === "token_not_valid") {
    //                 logout();
    //             }
    //         }
    //         return err;
    //     }
    // };

    // const handleSelectedConversionsToDelete = async (conversionId) => {
    //     let cArr = [...deletedConversions];

    //     for (let i = 0; i < page; i++) {
    //         if (deletedConversions[i] == null) {
    //             cArr.push([]);
    //         }
    //     }

    //     let activatedPageArr = [...cArr[page - 1]];

    //     let conversionAlreadyExist = false;

    //     // Check if conversion already exist or not
    //     for (let i = 0; i < activatedPageArr.length; i++) {
    //         const l = activatedPageArr[i];

    //         if (l === conversionId) {
    //             activatedPageArr.splice(i, 1);
    //             setTotalDeletedConversions((prevCount) => prevCount - 1);
    //             conversionAlreadyExist = true;
    //             break;
    //         }
    //     }

    //     if (!conversionAlreadyExist) {
    //         // If conversion not exist before, then push it
    //         activatedPageArr.push(conversionId);
    //         setTotalDeletedConversions((prevCount) => prevCount + 1);
    //     }

    //     cArr[page - 1] = [...activatedPageArr];
    //     setDeletedConversions(cArr);
    // };

    // const handleSelectAllConversions = () => {
    //     let cArr = [...deletedConversions];

    //     for (let i = 0; i < page; i++) {
    //         if (deletedConversions[i] == null) {
    //             cArr.push([]);
    //         }
    //     }

    //     let activatedPageArr = [...cArr[page - 1]];

    //     if (activatedPageArr.length === conversions.length) {
    //         activatedPageArr = [];

    //         setTotalDeletedConversions((prevCount) => prevCount - conversions.length);
    //     } else {
    //         activatedPageArr = [];

    //         conversions.map((l) => {
    //             activatedPageArr.push(l.id);
    //             setTotalDeletedConversions((prevCount) => prevCount + 1);
    //         });
    //     }

    //     cArr[page - 1] = [...activatedPageArr];
    //     setDeletedConversions(cArr);
    // };

    function handleConversionDetail(conversionId) {
        if (!conversionId) return;
        setConversionLog(conversionId);
        handleConversionLog();
    }

    const handleConversionPage = async (selPage) => {
        setPage(selPage);
        fetchConversions({
            page: selPage,
            campaigns: conversionCampaigns,
            status: status
        });
    };

    const handleConversionLog = () => {
        setShowConversionLogModal(true);
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />

            {/* <ConversionDeleteConfirmModal
                showModal={showConfirmDeleteModal}
                setShowModal={setShowConfirmDeleteModal}
                page={page}
                deletedConversions={deletedConversions}
                setDeletedConversions={setDeletedConversions}
                setLoading={setLoading}
                fetchConversions={fetchConversions}
                isDeleteAll={isDeleteAllConversions}
                setIsDeleteAll={setIsDeleteAllConversions}
                totalConversions={totalConversions}
                totalDeletedConversions={totalDeletedConversions}
                setTotalDeletedConversions={setTotalDeletedConversions}
            /> */}

            {
                showConversionLogModal &&
                <ConversionLogModal
                    http={http}
                    logout={logout}
                    conversionLog={conversionLog}
                    setConversionLog={setConversionLog}
                    showModal={showConversionLogModal}
                    setShowModal={setShowConversionLogModal}
                    skin={skin}
                    dateRange={dateRange}
                />
            }

            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    <div className='flex flex-col gap-y-3'>
                        <div className='flex flex-wrap items-end gap-2'>
                            <button onClick={handleConversionLog} type="button" className='rounded-md px-2.5 py-1.5 border border-stone-200 flex items-center gap-x-1 hover:border-stone-300 transition-colors duration-200 ease-linear'>
                                <FaListUl />
                                Logs
                            </button>
                            {/* <button type="button" className='rounded-md px-2.5 py-1.5 border border-stone-200 flex items-center gap-x-1 hover:border-stone-300 transition-colors duration-200 ease-linear'>
                                <FaDownload />
                                Export All
                            </button> */}
                        </div>
                        <div className='grid grid-cols-1 sm:flex flex-wrap items-end gap-2'>
                            <Form onSubmit={handleSearchLogId} className='flex-1'>
                                <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                                    <Form.Control ref={searchTextRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search by external id..." />
                                    <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                                        <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                                    </button>
                                </Form.Group>
                            </Form>
                            {/* <div className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                <MultiSelect
                                    options={DUMMY_CONVERSION_ALL_OFFERS}
                                    value={conversionOffers}
                                    onChange={e => handleSelectedOptions({ data: e, label: "offers" })}
                                    valueRenderer={(e) => customValueRenderer(e, "offers")}
                                    labelledBy="All offers"
                                    className='w-full sm:min-w-[180px] sm:max-w-[180px] rounded-md'
                                />
                            </div> */}
                            {/* <div className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                <MultiSelect
                                    options={DUMMY_CONVERSION_ALL_GEOS}
                                    value={conversionGeos}
                                    onChange={e => handleSelectedOptions({ data: e, label: "geos" })}
                                    valueRenderer={(e) => customValueRenderer(e, "geos")}
                                    labelledBy="All GEOs"
                                    className='w-full sm:min-w-[180px] sm:max-w-[180px] rounded-md'
                                />
                            </div> */}
                            <div className={`my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                <MultiSelect
                                    options={allCampaigns}
                                    value={conversionCampaigns}
                                    onChange={(e) => handleSelectedOptions({ data: e, label: "campaigns" })}
                                    valueRenderer={(e) => customValueRenderer(e, "campaigns")}
                                    labelledBy="All campaigns"
                                    className='w-full sm:min-w-[180px] sm:max-w-[180px] rounded-md'
                                />
                            </div>
                            <div
                                className={`w-full sm:min-w-[180px] sm:max-w-[180px] flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                                    }`}
                            >
                                <Select
                                    options={CONVERSION_STATUS}
                                    value={status}
                                    onChange={e => handleSelectedOptions({data: e, label: "status"})}
                                    placeholder="Select status"
                                    classNamePrefix={"my-react-select"}
                                    className="w-full sm:min-w-[180px] sm:max-w-[180px] whitespace-nowrap"
                                    isSearchable
                                    isClearable
                                    noOptionsMessage={() => "No match found."}
                                />
                            </div>
                            <Form.Group>
                                {showDateRange && <div className="overlay"></div>}
                                {/* <Form.Label className="font-medium">Date</Form.Label> */}
                                <div ref={dateRangeRef} className="relative">
                                    <div
                                        onClick={() => handleDateRangeChangeState(!showDateRange)}
                                        className={`whitespace-nowrap min-w-[220px] cursor-pointer flex items-center gap-x-1 border border-stone-300 p-2 rounded-md ${skin ? "" : "bg-white"
                                            }`}
                                    >
                                        {dateRange.length > 0 && (
                                            <>
                                                <span className={`${skin ? "text-stone-400" : ""}`}>
                                                    <FaRegCalendarAlt />
                                                </span>
                                                <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>
                                                    {moment(
                                                        dateRange[0].startDate.toLocaleDateString()
                                                    ).format("MMM DD, YYYY")}
                                                </p>
                                                <span className={`${skin ? "text-stone-400" : ""}`}>
                                                    -
                                                </span>
                                                <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>
                                                    {moment(
                                                        dateRange[0].endDate.toLocaleDateString()
                                                    ).format("MMM DD, YYYY")}
                                                </p>
                                            </>
                                        )}
                                    </div>

                                    {showDateRange && (
                                        <div
                                            className={`absolute top-full left-0 min-[700px]:left-[-150%] z-[1001] w-full date__rangePicker ${skin ? "dark__mode" : ""
                                                }`}
                                        >
                                            <DateRangePicker
                                                weekStartsOn={1}
                                                editableDateInputs={true}
                                                onChange={(item) => handleDateRangeSelection(item)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={dateRange}
                                                direction={dateRangeDirection}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                            <div>
                                <button
                                    onClick={() => handleRefreshLog(page)}
                                    type="submit"
                                    className="font-medium flex items-center justify-center gap-x-1 w-full sm:w-fit rounded-md px-4 py-2.5 bg-indigo-500 hover:bg-indigo-600 text-white btn-sign transition-colors duration-200 ease-linear"
                                    disabled={isRefresh}
                                >
                                    {isRefresh ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </>
                                    ) : (
                                        <>
                                            Refresh
                                            <IoIosRefresh className='text-base' />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card>
                        <Card.Body>
                            {conversionLoader ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : (
                                <>
                                    <div className="flex flex-col md:flex-row gap-y-3 md:items-center justify-between mb-3">
                                        {totalConversions !== 0 && (
                                            <h5 className="my-0 font-medium">
                                                Total{" "}
                                                <span className="text-lg font-semibold">
                                                    {totalConversions}
                                                </span>{" "}
                                                Conversion{totalConversions > 1 ? "s" : ""}
                                            </h5>
                                        )}
                                        {/* <div className="w-full md:w-auto flex flex-col sm:flex-row items-center justify-end gap-y-3 gap-x-1.5">
                                            {totalConversions !== 0 && (
                                                <>
                                                    <button
                                                        onClick={deleteAllConversions}
                                                        type="button"
                                                        className="w-full md:w-auto px-3 py-2 bg-danger text-white rounded-md flex items-center justify-center gap-x-1 transition-colors duration-200 ease-linear"
                                                    >
                                                        <MdDeleteOutline className="text-lg" />
                                                        Delete All Conversions
                                                    </button>
                                                    <button
                                                        disabled={totalDeletedConversions === 0}
                                                        onClick={confirmDeleteConversions}
                                                        type="button"
                                                        className="w-full md:w-auto px-3 py-2 text-stone-700 bg-rose-200 hover:bg-rose-300 rounded-md flex items-center justify-center gap-x-1 transition-colors duration-200 ease-linear"
                                                    >
                                                        <MdDeleteOutline className="text-lg text-stone-700" />
                                                        Delete {totalDeletedConversions} Conversion
                                                        {totalDeletedConversions > 1 ? "s" : ""}
                                                    </button>
                                                </>
                                            )}
                                        </div> */}
                                    </div>
                                    {conversions.length > 0 ? (
                                        <>
                                            <Table
                                                variant={skin && "dark"}
                                                hover
                                                className="mb-0"
                                                responsive
                                            >
                                                <thead>
                                                    <tr>
                                                        {/* <th
                                                            scope="col"
                                                            className="text-lg py-0.5 font-semibold whitespace-nowrap"
                                                        >
                                                            <div className="flex items-center gap-x-2">
                                                                <div
                                                                    onClick={() => handleSelectAllConversions()}
                                                                    className={`cursor-pointer w-[1.5rem] h-[1.5rem] rounded-md border border-stone-200 text-white ${deletedConversions[page - 1] &&
                                                                        deletedConversions[page - 1].length ===
                                                                        conversions.length
                                                                        ? "bg-indigo-600"
                                                                        : ""
                                                                        } flex items-center justify-center`}
                                                                >
                                                                    {deletedConversions[page - 1] &&
                                                                        deletedConversions[page - 1].length ===
                                                                        conversions.length && <FaCheck />}
                                                                </div>
                                                            </div>
                                                        </th> */}
                                                        <th
                                                            scope="col"
                                                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Created At
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            External ID
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Postback Status Code
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Campaign
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Traffic Source
                                                        </th>
                                                        {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">State</th> */}
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Payout
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Lead Status
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Country
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {conversions.map((conversion) => {
                                                        return (
                                                            <tr key={conversion.id}>
                                                                {/* <td className="align-middle">
                                                                    <div
                                                                        onClick={() =>
                                                                            handleSelectedConversionsToDelete(conversion.id)
                                                                        }
                                                                        className={`cursor-pointer w-[1.5rem] h-[1.5rem] rounded-md border border-stone-200 text-white ${deletedConversions[page - 1] &&
                                                                            deletedConversions[page - 1].includes(
                                                                                conversion.id
                                                                            )
                                                                            ? "bg-indigo-600"
                                                                            : ""
                                                                            } flex items-center justify-center`}
                                                                    >
                                                                        {deletedConversions[page - 1] &&
                                                                            deletedConversions[page - 1].includes(
                                                                                conversion.id
                                                                            ) && <FaCheck />}
                                                                    </div>
                                                                </td> */}
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div
                                                                        className={`${skin
                                                                            ? "text-stone-300"
                                                                            : "text-inherit"
                                                                            } title__td whitespace-nowrap`}
                                                                    >
                                                                        {moment(conversion.created_at).format("LL")}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div
                                                                        className={`${skin
                                                                            ? "text-stone-300"
                                                                            : "text-inherit"
                                                                            } title__td whitespace-nowrap`}
                                                                    >
                                                                        {conversion.externalid}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        conversion.postback_response_log && conversion.postback_response_log.postback_response?.status ?
                                                                        <div
                                                                        className={`px-3 align-middle cursor-pointer text-center font-medium ${conversion.postback_response_log.postback_response?.status === 200 ? "text-success" : "text-danger"}`}
                                                                            >
                                                                                {conversion.postback_response_log.postback_response.status}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        conversion.campaign ?
                                                                            <div
                                                                                className={`${skin
                                                                                    ? "text-stone-300"
                                                                                    : "text-inherit"
                                                                                    } whitespace-nowrap`}
                                                                            >
                                                                                {conversion.campaign?.campaign_title}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        conversion.traffic_source ?
                                                                            <div
                                                                                className={`${skin
                                                                                    ? "text-stone-300"
                                                                                    : "text-inherit"
                                                                                    }`}
                                                                            >
                                                                                {
                                                                                    `${conversion?.traffic_source?.client?.company_name[0]?.toUpperCase()}${conversion?.traffic_source?.client?.company_name?.slice(
                                                                                        1
                                                                                    )}${conversion.traffic_source?.traffic_channel?.channel_name
                                                                                        ? " - " + conversion?.traffic_source?.traffic_channel?.channel_name
                                                                                        : ""
                                                                                    } (${conversion?.traffic_source?.id})`
                                                                                }
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    <div
                                                                        className={`whitespace-nowrap ${skin
                                                                            ? "text-stone-300"
                                                                            : "text-inherit"
                                                                            }`}
                                                                    >
                                                                        ${conversion.payout}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="px-3 align-middle cursor-pointer"
                                                                >
                                                                    {
                                                                        conversion.status ?
                                                                            <div
                                                                                className={`whitespace-nowrap text-sm rounded-md p-1.5 font-medium text-center text-white ${conversion.status === "ACCEPTED"
                                                                                    ? "bg-success"
                                                                                    : (conversion.status === "DUPLICATED" || conversion.status === "PENDING")
                                                                                        ? "bg-warning"
                                                                                        : conversion.status === "STORED"
                                                                                            ? "bg-primary"
                                                                                            : "bg-danger"
                                                                                    }`}
                                                                            >
                                                                                {conversion.status ? conversion.status : "ERROR"}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={() =>
                                                                        handleConversionDetail(conversion.externalid)
                                                                    }
                                                                    className="align-middle"
                                                                >
                                                                    {
                                                                        conversion.country ?
                                                                            <div
                                                                                className={`${skin
                                                                                    ? "text-stone-300"
                                                                                    : "text-inherit"
                                                                                    } text-center px-3`}
                                                                            >
                                                                                {conversion.country}
                                                                            </div> :
                                                                            <p className="mb-0 text-center font-medium">
                                                                                -
                                                                            </p>
                                                                    }
                                                                </td>
                                                                <td className="px-3 align-middle">
                                                                    <Dropdown className="static">
                                                                        <Dropdown.Toggle
                                                                            id="dropdown-basic"
                                                                            className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin
                                                                                ? "bg-slate-800 hover:bg-slate-700 text-stone-100"
                                                                                : "bg-white text-stone-800"
                                                                                } rounded-md shadow-sm transition-colors duration-200 ease-linear`}
                                                                        >
                                                                            <IoSettingsOutline className="text-lg" />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleConversionDetail(conversion.externalid)
                                                                                    }
                                                                                    type="button"
                                                                                    className="text-inherit flex w-full items-center gap-x-3"
                                                                                >
                                                                                    <FaInfo className="text-stone-500" />
                                                                                    Detail
                                                                                </button>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>

                                            <div className="mt-3 flex justify-end custom__reactPaginate">
                                                <PaginationControl
                                                    page={page}
                                                    between={3}
                                                    total={totalConversions}
                                                    limit={PAGE_LIMIT_SIZE}
                                                    changePage={(page) => handleConversionPage(page)}
                                                    ellipsis={5}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <p className="text-lg md:text-xl text-center font-semibold text-stone-500">
                                            No data found yet!
                                        </p>
                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ConversionLog
