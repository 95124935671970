import { Modal, Form, Card, Spinner, ProgressBar } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import JsonFormatter from 'react-json-formatter'
import { MdInfoOutline, MdOutlineEdit, MdOutlinePendingActions } from "react-icons/md";
import moment from "moment";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "../../assets/css/LeadDetailModal.css";
import { FaArrowRightLong, FaCheck, FaCircleCheck, FaDollarSign } from "react-icons/fa6";
import { IoCloseCircleSharp, IoCloseOutline, IoWarningOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import Select from "react-select";
import { FiPlus } from "react-icons/fi";
import { BiRepost } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import LeadFilter from "./LeadFilter";
import { IoMdArrowDropup } from "react-icons/io";

const LEAD_HEALTH_INDICATORS = [
    {
        percentage: 0.0,
        progress_bar_color: "stone-500",
        label: "UNKNOWN",
        color: "text-stone-500"
    },
    {
        percentage: 100.0,
        progress_bar_color: "success",
        label: "NO RISK",
        color: "text-success"
    },
    {
        percentage: 90.0,
        progress_bar_color: "teal-400",
        label: "LOW RISK",
        color: "text-teal-400"
    },
    {
        percentage: 50.0,
        progress_bar_color: "primary",
        label: "MODERATE RISK",
        color: "text-primary"
    },
    {
        percentage: 40.0,
        progress_bar_color: "warning",
        label: "SUSPICIOUS",
        color: "text-warning"
    },
    {
        percentage: 10.0,
        progress_bar_color: "danger",
        label: "HIGH RISK",
        color: "text-danger"
    }
]

const RETURN_LOG_STATUSES = [
    {
        status: "PENDING",
        icon: <MdOutlinePendingActions />,
        text_color: "warning"
    },
    {
        status: "ACCEPTED",
        icon: <FaCheck />,
        text_color: "teal-600"
    },
    {
        status: "STORE",
        icon: <FaCheck />,
        text_color: "inidigo-600"
    },
    {
        status: "REJECTED",
        icon: <IoCloseOutline />,
        text_color: "rose-600"
    },
    {
        status: "ERROR",
        icon: <IoCloseOutline />,
        text_color: "rose-600"
    },
    {
        status: "DUPLICATED",
        icon: <IoWarningOutline />,
        text_color: "warning"
    },
    {
        status: "RETURNED",
        icon: <IoWarningOutline />,
        text_color: "warning"
    }
]

// const RETURN_LOGS = [
//     {
//         "id": 228,
//         "lead_id": 97,
//         "log": {
//             "prev_status": "REJECTED",
//             "updated_status": "ACCEPTED",
//             "prev_source_payout": "50",
//             "source_payout": "100",
//             "lead_sold_price": "1000"
//         },
//         "created_at": "2024-11-01T12:40:55.000000Z"
//     },
//     {
//         "id": 278,
//         "lead_id": 97,
//         "log": {
//             "prev_status": "ERROR",
//             "updated_status": "DUPLICATED",
//             "prev_source_payout": "20",
//             "source_payout": "30",
//             "lead_sold_price": "500"
//         },
//         "created_at": "2024-11-01T12:42:23.000000Z"
//     }
// ]

const LeadDetailModal = ({ http, logout, skin, showModal, setShowModal, lead, setLead, leads, setLeads }) => {
    const [campaign, setCampaign] = useState(null);
    const [payload, setPayload] = useState(null);
    const [buyerResponseLog, setBuyerResponseLog] = useState(null);
    // const [pingBuyerResponseLog, setPingBuyerResponseLog] = useState(null);
    const [modalActiveTab, setModalActiveTab] = useState(1);
    const [showLeadReasonForm, setShowLeadReasonForm] = useState(false);
    const [reasonIsSubmitting, setReasonIsSubmitting] = useState(false);
    const [allReasons, setAllReasons] = useState([]);
    const [selReason, setSelReason] = useState(null);
    const [isAddReason, setIsAddReason] = useState(false);
    const [showOptInMessageFullText, setShowOptInMessageFullText] = useState(false);
    const [showEditRepost, setShowEditRepost] = useState(false);
    const [isLeadReposting, setIsLeadReposting] = useState(false);
    const [isLeadReposted, setIsLeadReposted] = useState(true);
    const [additionalFields, setAdditionalFields] = useState(null);
    const [enrichedFields, setEnrichedFields] = useState(null);
    const [leadHealthIndicator, setLeadHealthIndicator] = useState(null);

    const [responseLog, setResponseLog] = useState(null);
    // const [leadIsAnArray, setLeadIsAnArray] = useState(false);
    // const [leadIsRejected, setLeadIsRejected] = useState(false);
    // const [pingleadIsAnArray, setPingLeadIsAnArray] = useState(false);
    // const [pingleadIsRejected, setPingLeadIsRejected] = useState(false);
    // const [leadResponseMsg, setLeadResponseMsg] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [filterHeading, setFilterHeading] = useState({});
    const [payloadResponse, setPayloadResponse] = useState(null);

    const [showPostLog, setShowPostLog] = useState(true);

    const reasonRef = useRef();
    const leadRepostFormRef = useRef();

    const fetchAllReasons = async () => {
        try {
            const res = await http.get("/api/all-reasons/", {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (res.status === 200) {
                const { data } = res.data;

                let reasonArr = [];
                data?.map(reason => {
                    reasonArr.push({
                        label: reason.reason_name,
                        value: reason.id
                    })
                })

                setAllReasons(reasonArr);
            } else {
                toast.error("Something went wrong!")
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to fetch reasons' data!");
        }
    }

    const responseMsgEndsCheck = (data) => {
        if (data.endsWith("}") || data.endsWith("]")) {
            data = data.slice(0, data.length - 1);
            return responseMsgEndsCheck(data);
        }

        data = data.trim();

        if (data.startsWith("'")) {
            data = data.slice(1,);
        }
        if (data.endsWith("'")) {
            data = data.slice(0, data.length - 1);
        }

        return data;
    }

    const updateLeadHealthIndicator = () => {
        if (!lead.email_validity && !lead.phone_validity) {
            setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[5]);
        } else {
            if (lead.fraud_score != null) {
                if (lead.fraud_score >= 90) {
                    setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[5]);
                } else if (lead.fraud_score >= 75) {
                    setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[4]);
                } else if (lead.fraud_score > 40) {
                    setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[3]);
                } else if (lead.fraud_score > 0 && lead.fraud_score <= 40) {
                    setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[2]);
                } else if (lead.fraud_score === 0) {
                    setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[1]);
                }
            } else {
                setLeadHealthIndicator(LEAD_HEALTH_INDICATORS[0]);
            }
        }
    }

    const handleLeadReturnLogs = () => {
        if (lead.return_logs) {
            for (let i=0; i<lead.return_logs.length; i++) {
                const rl = lead.return_logs[i];

                const {log} = rl;

                if (log) {
                    const {prev_status, updated_status} = log;

                    const prevStatusIndex = RETURN_LOG_STATUSES.findIndex(s => s.status === prev_status);
                    const updatedStatusIndex = RETURN_LOG_STATUSES.findIndex(s => s.status === updated_status);

                    if (prevStatusIndex !== -1) {
                        rl.log.prev_status_icon = RETURN_LOG_STATUSES[prevStatusIndex].icon;
                        rl.log.prev_status_color = RETURN_LOG_STATUSES[prevStatusIndex].text_color;
                    }
                    if (updatedStatusIndex !== -1) {
                        rl.log.updated_status_icon = RETURN_LOG_STATUSES[updatedStatusIndex].icon;
                        rl.log.updated_status_color = RETURN_LOG_STATUSES[updatedStatusIndex].text_color;
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (lead) {
            const { campaign: campaignId } = lead;

            // Lead return logs
            // lead.return_logs = RETURN_LOGS;
            handleLeadReturnLogs();

            if (lead.status === "ACCEPTED") {
                setIsLeadReposted(true);
            } else {
                setIsLeadReposted(false);
            }

            // Set lead health indicator
            updateLeadHealthIndicator();


            if (lead.response_log) {
                const checkForDirectPostKey = lead.response_log.hasOwnProperty("direct_posts");

                if (checkForDirectPostKey) {
                    const leadJsonRes = lead.response_log;
                    // console.log(leadJsonRes);
                    let responseLogArr = [];

                    if (leadJsonRes.direct_posts != null) {
                        Object.entries(leadJsonRes?.direct_posts)?.map(([buyerKey, buyerVal]) => {
                            let actualRes = {};

                            actualRes["sold_to"] = buyerKey;
                            if (leadJsonRes.phone_format_error) {
                                actualRes["phone_format_error"] = true;
                            }

                            Object.entries(buyerVal).map(([k, v]) => {
                                actualRes[k] = v;
                            })
                            responseLogArr.push(actualRes);
                        })

                        responseLogArr.sort((a, b) => {
                            const indexA = parseInt(a.order);
                            const indexB = parseInt(b.order);
                            return indexA - indexB; // Compare the index values
                        });
                    }

                    setResponseLog(responseLogArr);
                } else {
                    const leadJsonRes = lead.response_log;
                    let responseLogDict = {};

                    if (typeof (leadJsonRes) === "object") {
                        Object.entries(leadJsonRes).map(([k, v]) => {
                            responseLogDict[k] = v;
                        })

                        if (leadJsonRes.success != null && (leadJsonRes.error != null || leadJsonRes.reason != null)) {
                            responseLogDict["custom_error"] = true;
                        }
                    } else {
                        responseLogDict = {
                            success: false,
                            custom_error: true,
                            reason: leadJsonRes
                        };
                    }
                    // else {
                    //     setShowPostLog(false);
                    // }
                    setResponseLog(responseLogDict);
                }
            } else {
                setShowPostLog(false);
            }

            if (lead.status !== "RETURNED") {
                fetchAllReasons()
            }
            setCampaign(campaignId);
            // fetchCampaign(campaignId);

            if (lead.payload) {
                let leadPayloadJson = lead.payload;

                setPayloadResponse(leadPayloadJson);

                let additionalFieldDict = {}, enrichedFieldDict = {};

                if (leadPayloadJson.sub1 != null) {
                    additionalFieldDict["sub1"] = leadPayloadJson.sub1;
                    delete leadPayloadJson.sub1;
                }
                if (leadPayloadJson.sub2 != null) {
                    additionalFieldDict["sub2"] = leadPayloadJson.sub2;
                    delete leadPayloadJson.sub2;
                }

                additionalFieldDict["affiliate_id"] = leadPayloadJson.affiliate_id;
                additionalFieldDict["traffic_source"] = leadPayloadJson.traffic_source_id ?? leadPayloadJson.traffic_source;

                delete leadPayloadJson.affiliate_id;
                delete leadPayloadJson.traffic_source;
                delete leadPayloadJson?.traffic_source_id;

                additionalFieldDict["is_test"] = lead.is_test ? "True" : "False";

                // Enriched fields
                if (leadPayloadJson.country != null) {
                    enrichedFieldDict["X-GEO-Country"] = leadPayloadJson.country;
                    delete leadPayloadJson.country;
                }

                if (leadPayloadJson.enrich_data != null) {
                    const { device, deviceType, browser, browserVersion, os,
                        engine, engineVersion, robot, isMobile, city, region
                    } = leadPayloadJson.enrich_data;

                    if (city) {
                        enrichedFieldDict["X-GEO-City"] = city;
                    }
                    if (region) {
                        enrichedFieldDict["X-GEO-Region"] = region;
                    }
                    if (browser) {
                        enrichedFieldDict["X-Device-Browser"] = browser;
                    }
                    if (browserVersion) {
                        enrichedFieldDict["X-Device-Browser-Version"] = browserVersion;
                    }
                    if (robot) {
                        enrichedFieldDict["X-Device-Bot"] = robot;
                    }
                    if (isMobile) {
                        enrichedFieldDict["X-Device-Mobile"] = isMobile;
                    }
                    if (os) {
                        enrichedFieldDict["X-Device-OS"] = os;
                    }
                    if (engine) {
                        enrichedFieldDict["X-Device-Engine"] = engine;
                    }
                    if (engineVersion) {
                        enrichedFieldDict["X-Device-Engine-Version"] = engineVersion;
                    }
                    if (deviceType) {
                        enrichedFieldDict["X-Device-Type"] = deviceType;
                    }

                    delete leadPayloadJson.enrich_data;
                }

                setAdditionalFields(additionalFieldDict);
                setEnrichedFields(enrichedFieldDict);
                setPayload(JSON.stringify(leadPayloadJson, null, 2));
            }

            if (lead.buyer_response_log) {
                let buyerResLog = lead.buyer_response_log;

                if (typeof buyerResLog === "object") {
                    if (Array.isArray(buyerResLog) && buyerResLog.length > 0) {
                        setBuyerResponseLog(JSON.stringify(buyerResLog, null, 2));
                    } else {
                        if (Object.keys(buyerResLog).length > 0) {
                            setBuyerResponseLog(JSON.stringify(buyerResLog, null, 2));
                        }
                    }
                } else {
                    let br = JSON.parse(JSON.stringify(buyerResLog, null, 2));

                    if (br.startsWith('"')) {
                        br = br.slice(1,);
                    }
                    if (br.endsWith('"')) {
                        br = br.slice(0, br.length - 1);
                    }
                    setBuyerResponseLog(br)
                }
            }

            // if (lead.ping_buyer_response_log) {
            //     if (lead.ping_buyer_response_log === "None") {
            //         setPingBuyerResponseLog(null);
            //     } else {
            //         let pingBuyerResLog = lead.ping_buyer_response_log;
            //         pingBuyerResLog.length > 0 &&
            //             setPingBuyerResponseLog(JSON.stringify(pingBuyerResLog, null, 2));
            //     }

            // }
        }
    }, [lead])

    const handleLeadTabChange = (pageId) => {
        setModalActiveTab(pageId);
    }

    const hideModal = () => {
        setShowModal(false);
        // setLeadIsAnArray(false);
        // setLeadIsRejected(false);
        setIsLeadReposting(false);
        setShowEditRepost(false);
        setShowLeadReasonForm(false);
        setCampaign(null);
        setBuyerResponseLog(null);
        setAdditionalFields(null);
        setEnrichedFields(null);
        setModalActiveTab(1);
        // setSuccessBuyerIndex(-1);
        setResponseLog(null);
        setFilterData(null);
        setFilterHeading({});
        setPayloadResponse(null);
        setShowOptInMessageFullText(false);
        setShowPostLog(true);
    }

    const getLeadDetails = async (leadId) => {
        await http.get(`/api/lead-details/${leadId}/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                setLead(response.data.data)
            }).catch(err => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch lead details!");
            })
    }

    const handleReasonSubmit = async (e) => {
        e.preventDefault();

        const reason = reasonRef.current?.value.trim();
        if (!reason && selReason == null) return;

        setReasonIsSubmitting(true);

        const formData = new FormData();
        formData.append("lead_id", lead.id);
        formData.append("reason", selReason?.value);
        formData.append("reason_name", reason == null ? "" : reason);

        try {
            const res = await http.post(`/api/leads-to-return/`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (res.status === 200) {
                getLeadDetails(lead.id);

                const updatedLeads = leads.map(l =>
                    l.id === lead.id ? { ...l, status: "RETURNED" } : l
                )
                setLeads(updatedLeads);

                toast.success('Reason submitted successfully.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                try {
                    setSelReason(null);
                    reasonRef.current.value = "";
                    setIsAddReason(false);
                    setReasonIsSubmitting(false);
                    setShowLeadReasonForm(false);
                } catch (err) {
                    const { response } = err;

                    if (response) {
                        const { data } = response;
                        if (data) {
                            if (data.message === "Unauthenticated.") {
                                logout();
                            }
                        }
                    }
                    setIsAddReason(false);
                    setReasonIsSubmitting(false);
                    setShowLeadReasonForm(false);
                }
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to submit the reason!");
            setIsAddReason(false);
            setReasonIsSubmitting(false);
            setShowLeadReasonForm(false);
        }
    }

    const updateRepostedLead = ({data, lead}) => {
        const { status: leadStatus } = data;
        getLeadDetails(lead.id);

        toast.success("Lead has been reposted successfully.");
        // setIsLeadReposted(true);

        if (leadStatus === "ACCEPTED") {
            setIsLeadReposted(true);
        }

        let leadArr = [];

        leads.map(l => {
            if (l.id === lead.id) {
                leadArr.push({ ...l, status: leadStatus, is_repost: true });
            } else {
                leadArr.push(l);
            }
        })
        setLeads(leadArr);
    }

    // Repost lead
    const handleLeadRepost = async () => {
        setIsLeadReposting(true);

        const { campaign, generated_by, sold_to } = lead;

        let leadSupplierId = "";

        if (generated_by) {
            leadSupplierId = generated_by?.id;
        } else if (sold_to) {
            leadSupplierId = sold_to?.id;
        }

        const payloadFormDict = {};

        Object.entries(lead.payload).map(([key, value]) => {
            const payloadKey = key;

            const el = leadRepostFormRef.current.querySelector(`[name='${payloadKey}']`);
            if (el) {
                payloadFormDict[payloadKey] = el.value;
            }
        })

        const data = {
            // campaign_id: campaign.id,
            // supplier_id: leadSupplierId,
            payload: payloadFormDict,
            is_test: false
        }

        try {
            const res = await http.post(`/api/repost-lead/${lead.id}/`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                updateRepostedLead({
                    data: res.data,
                    lead: lead
                })
            }
            setShowEditRepost(false);
            setIsLeadReposting(false);
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    } else if (data.success != null && data.status) {
                        updateRepostedLead({
                            data: data,
                            lead: lead
                        });
                    } else {
                        toast.error("Failed to repost!");
                    }
                } else {
                    toast.error("Failed to repost!");
                }
            } else {
                toast.error("Failed to repost!");
            }
            setShowEditRepost(false);
            setIsLeadReposting(false);
        }
    }

    const toggleRepost = () => {
        setShowEditRepost(prev => !prev);
    }

    const clearFilterData = () => {
        setFilterData(null);
        setFilterHeading({});
    }

    const handleFilterData = ({ rl, title, index }) => {
        if (filterHeading && filterHeading.title === title && filterHeading.index === index) {
            clearFilterData();
            return;
        }

        switch (title) {
            case "Buyer Filter":
                const buyerFilter = rl?.filters;
                setFilterHeading({ title: title, index: index, sold_to: rl.sold_to });
                setFilterData(buyerFilter);
                break;
            case "Supplier Filter":
                const supplierFilter = rl?.supplier_filters;
                setFilterHeading({ title: title, index: index, sold_to: rl.sold_to });
                setFilterData(supplierFilter);
                break;
            case "Traffic Source Filter":
                const tfFilter = rl?.traffic_source_filters;
                setFilterHeading({ title: title, index: index, sold_to: rl.sold_to });
                setFilterData(tfFilter);
                break;
        }
    }

    return (
        <Modal show={showModal} onHide={hideModal} centered size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-full">
                    <div className="flex flex-1 w-full shadow-md">
                        <button type="button" onClick={() => handleLeadTabChange(1)} className={`flex flex-1 gap-x-1.5 items-center justify-center p-3 ${skin ? "bg-slate-700" : "bg-white"} ${modalActiveTab === 1 ? "border-b-[3px] border-indigo-400" : "border border-stone-300"}`}>
                            <MdInfoOutline className="text-lg" />
                            Lead Info
                        </button>
                        {
                            showPostLog &&
                            <button type="button" onClick={() => handleLeadTabChange(2)} className={`flex flex-1 gap-x-1.5 items-center justify-center p-3 ${skin ? "bg-slate-700" : "bg-white"} ${modalActiveTab === 2 ? "border-b-[3px] border-indigo-400" : "border border-stone-300"}`}>
                                <i className="ri-file-text-line text-base"></i>
                                Post Logs
                            </button>
                        }
                        {
                            (lead?.return_logs && lead.return_logs?.length > 0) &&
                            <button type="button" onClick={() => handleLeadTabChange(3)} className={`flex flex-1 gap-x-1.5 items-center justify-center p-3 ${skin ? "bg-slate-700" : "bg-white"} ${modalActiveTab === 3 ? "border-b-[3px] border-indigo-400" : "border border-stone-300"} `}>
                                <i className="ri-file-text-line text-base"></i>
                                Return Logs
                            </button>
                        }
                    </div>

                    {
                        modalActiveTab === 1 ?
                            // <div className="grid mt-3 gap-x-3" id="lead__bodyGrid">
                            <div className="grid mt-3 gap-x-3">
                                <div className="w-full flex flex-col gap-y-3">
                                    <Card className={`${skin ? "text-stone-400" : ""}`}>
                                        <Card.Body>
                                            <div className="flex flex-col sm:flex-row gap-x-14 gap-y-5 justify-between sm:items-center">
                                                <div className="flex flex-1 gap-x-1.5 items-start">
                                                    {/* <div className="px-1.5 py-1 rounded-md bg-indigo-600 text-white">Repost</div> */}
                                                    <div>
                                                        <p className="mb-0">Lead <span className="font-semibold">{lead?.lead_id}</span> from campaign <span className="font-medium">{campaign ? campaign.campaign_title : "_"}</span> was generated on {moment(lead.created_at).format("lll")}.</p>
                                                        {/* <p className="mb-0">This lead was purchased for <span className="text-danger font-semibold">$0.00</span> and sold for <span className="text-primary font-semibold">$0.00</span> with a profit of <span className="text-success font-semibold">$0.00</span></p> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={`flex items-center justify-center gap-x-1 px-2 py-1 rounded-md ${lead.status === "STORED" ? "bg-primary" : lead.status === "DUPLICATED" || lead.status === "PENDING" ? "bg-warning" : lead.status === "ACCEPTED" ? "bg-success" : "bg-danger"} text-white`}>
                                                        {lead.status ? lead.status : "ERROR"}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className={`${skin ? "text-stone-400" : ""}`}>
                                        <Card.Header>
                                            <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                                                <Card.Title className="mb-0">Fields</Card.Title>
                                                {
                                                    <div className="flex w-full sm:w-auto sm:justify-end items-center gap-2 lead__fieldSection">
                                                        {
                                                            !isLeadReposted &&
                                                            <button type="button" onClick={toggleRepost} className={`flex w-full sm:w-auto items-center gap-x-1 justify-center px-3 py-2 rounded-md ${skin ? "bg-slate-700" : "bg-stone-200 text-stone-700"}`}>
                                                                <BiRepost className="text-lg" />
                                                                Edit &amp; Repost
                                                            </button>
                                                        }
                                                    </div>}
                                            </div>
                                        </Card.Header>

                                        {
                                            showEditRepost &&
                                            <Card className={`${skin ? "text-stone-400" : ""}`}>
                                                <Card.Header>
                                                    <h5 className={`uppercase mb-0 font-semibold text-base`}>Repost</h5>
                                                </Card.Header>
                                                <Card.Body className="card-post">
                                                    <div ref={leadRepostFormRef} className="grid grid-cols-1 min-[991px]:grid-cols-2 gap-x-5">
                                                        {
                                                            Object.keys(JSON.parse(payload)).map((pKey, index) => (
                                                                <Form.Group className="mb-3" key={index}>
                                                                    <Form.Label htmlFor={pKey}>{pKey}</Form.Label>
                                                                    {
                                                                        (JSON.parse(payload)[pKey] == "Yes" || JSON.parse(payload)[pKey] == "No") ?
                                                                            <Form.Select name={pKey} className="flex-1" defaultValue={JSON.parse(payload)[pKey]} aria-label="Default select example">
                                                                                <option value="Yes" selected={JSON.parse(payload)[pKey] == true ? true : false}>Yes</option>
                                                                                <option value="No" selected={JSON.parse(payload)[pKey] == false ? true : false}>No</option>
                                                                            </Form.Select> :
                                                                            pKey === "what_happened" ?
                                                                                <Form.Control as="textarea" rows={3} name={pKey} type={`${pKey.toLowerCase() === "email" ? "email" : "text"}`} placeholder={`Enter ${pKey}`} defaultValue={JSON.parse(payload)[pKey]} /> :
                                                                                (pKey === "api_key" || pKey === "ip_address" || pKey === "opt_in_message" || pKey === "opt_in_timestamp" || pKey === "jornaya_lead_id" || pKey === "trusted_cert_url" || pKey === "trusted_cert_id") ?
                                                                                    pKey === "opt_in_message" ?
                                                                                        <Form.Control as="textarea" rows={3} disabled readOnly name={pKey} type={`${pKey.toLowerCase() === "email" ? "email" : "text"}`} placeholder={`Enter ${pKey}`} defaultValue={JSON.parse(payload)[pKey]} /> :
                                                                                        <Form.Control disabled readOnly name={pKey} type={`${pKey.toLowerCase() === "email" ? "email" : "text"}`} placeholder={`Enter ${pKey}`} defaultValue={JSON.parse(payload)[pKey]} /> :
                                                                                    <Form.Control name={pKey} type={`${pKey.toLowerCase() === "email" ? "email" : "text"}`} placeholder={`Enter ${pKey}`} defaultValue={JSON.parse(payload)[pKey]} />
                                                                    }
                                                                </Form.Group>
                                                            ))
                                                        }
                                                    </div>

                                                    <button onClick={handleLeadRepost} type="submit" className="btn-sign font-medium flex justify-center px-5 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={isLeadReposting}>
                                                        {
                                                            isLeadReposting ?
                                                                <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Loading...
                                                                </>
                                                                : "Repost"
                                                        }
                                                    </button>
                                                </Card.Body>
                                            </Card>
                                        }

                                        {
                                            showLeadReasonForm &&
                                            <Card className={`${skin ? "text-stone-400" : ""}`}>
                                                <Card.Header>
                                                    <h5 className={`uppercase mb-0 font-semibold text-base`}>Add Reason</h5>
                                                </Card.Header>
                                                <Card.Body className="card-post">
                                                    <Form className="flex flex-col gap-y-3" onSubmit={handleReasonSubmit}>
                                                        <div className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""}`}>
                                                            <div className="flex items-center gap-x-2 justify-end mb-2">
                                                                <button onClick={() => setIsAddReason(prev => !prev)} type="button" className="flex items-center gap-x-1 text-indigo-500 hover:text-indigo-600 transition-colors duration-200 ease-linear">
                                                                    <FiPlus />
                                                                    Add New Reason
                                                                </button>
                                                            </div>
                                                            {
                                                                !isAddReason &&
                                                                <Select
                                                                    options={allReasons}
                                                                    value={selReason}
                                                                    onChange={setSelReason}
                                                                    placeholder="Select reason"
                                                                    classNamePrefix={"my-react-select"}
                                                                    className="w-full whitespace-nowrap"
                                                                    isSearchable
                                                                    noOptionsMessage={() => "No match found."}
                                                                />
                                                            }

                                                        </div>

                                                        {
                                                            isAddReason &&
                                                            <Form.Group className="card-post">
                                                                <Form.Control as={"textarea"} autoFocus={true} name="return__leadReason" ref={reasonRef} type="text" placeholder="Enter reason" />
                                                            </Form.Group>
                                                        }

                                                        <button type="submit" className="w-fit btn-sign font-medium px-3 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={reasonIsSubmitting}>
                                                            {
                                                                reasonIsSubmitting ? <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Loading...
                                                                </> : "Submit"
                                                            }
                                                        </button>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        }
                                        <Card.Header className={`${skin ? "" : "bg-stone-200"}`}>
                                            <h5 className={`text-base uppercase ${skin ? "" : "text-indigo-600"} mb-0 font-semibold text-lg`}>Result</h5>
                                        </Card.Header>
                                        {
                                            <Card.Body>
                                                <div className="flex flex-col gap-y-3">
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className={`${skin ? "text-stone-400" : "text-stone-600"} mb-0`}>Fraud Score</p>
                                                        <p className="font-semibold mb-0">{lead.fraud_score ? lead.fraud_score : 0}</p>
                                                    </div>
                                                    {
                                                        leadHealthIndicator &&
                                                        <div className="flex flex-col gap-y-2">
                                                            <p className={`${skin ? "text-stone-400" : "text-stone-600"} mb-0`}>Lead Health</p>
                                                            <div className="lead__healthIndicatorProgressEl flex flex-col gap-y-0.5">
                                                                {
                                                                    <ProgressBar animated variant={leadHealthIndicator.progress_bar_color} now={leadHealthIndicator.percentage} />
                                                                }
                                                                <p className={`font-semibold mb-0 text-center ${leadHealthIndicator.color}`}>
                                                                    {leadHealthIndicator.label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Card.Body>
                                        }
                                        <Card.Header className={`${skin ? "" : "bg-stone-200"}`}>
                                            <h5 className={`text-base uppercase ${skin ? "" : "text-indigo-600"} mb-0 font-semibold text-lg`}>Campaign Fields</h5>
                                        </Card.Header>
                                        {
                                            payload &&
                                            <Card.Body>
                                                <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                                                    {
                                                        Object.keys(JSON.parse(payload)).map(pKey => (
                                                            <div key={pKey} className="flex flex-col gap-y-2">
                                                                <div className="flex items-center gap-x-1">
                                                                    <p className={`${skin ? "text-stone-400" : "text-stone-600"} mb-0`}>{pKey}</p>
                                                                    {
                                                                        pKey.startsWith("email") &&
                                                                        (lead.email_validity ? <FaCircleCheck data-tooltip-id="key__checkInfo" data-tooltip-content={`${pKey} is valid`} className="text-lg text-success cursor-pointer" />
                                                                            : <IoCloseCircleSharp data-tooltip-id="key__checkInfo" data-tooltip-content={`${pKey} is invalid`} className="text-lg text-danger cursor-pointer" />)
                                                                    }
                                                                    {
                                                                        pKey.startsWith("phone") &&
                                                                        (lead.phone_validity ? <FaCircleCheck data-tooltip-id="key__checkInfo" data-tooltip-content={`${pKey} is valid`} className="text-lg text-success cursor-pointer" />
                                                                            : <IoCloseCircleSharp data-tooltip-id="key__checkInfo" data-tooltip-content={`${pKey} is invalid`} className="text-lg text-danger cursor-pointer" />)
                                                                    }
                                                                    <Tooltip id="key__checkInfo" />
                                                                </div>
                                                                {
                                                                    pKey === "opt_in_message" ?
                                                                        <div className="flex flex-col gap-y-3">
                                                                            <p className={`font-semibold mb-0 ${!showOptInMessageFullText ? "max__4lines" : ""}`}>{JSON.parse(payload)[pKey]}</p>
                                                                            <button type="button" className="flex items-center justify-center gap-x-1 text-indigo-500 hover:text-indigo-600 transition-colors duration-200" onClick={() => setShowOptInMessageFullText(prev => !prev)}>
                                                                                {
                                                                                    !showOptInMessageFullText ?
                                                                                        <>
                                                                                            <p className="mb-0">Show More</p>
                                                                                            <FaChevronDown />
                                                                                        </>
                                                                                        : <>
                                                                                            <p className="mb-0">Show Less</p>
                                                                                            <FaChevronUp />
                                                                                        </>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                        : <p className="font-semibold mb-0">{JSON.parse(payload)[pKey]}</p>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Card.Body>
                                        }
                                        <Card.Header className={`${skin ? "" : "bg-stone-200"}`}>
                                            <h5 className={`text-base uppercase ${skin ? "" : "text-indigo-600"} mb-0 font-semibold text-lg`}>Additional Fields</h5>
                                        </Card.Header>
                                        {
                                            campaign &&
                                            <Card.Body>
                                                <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                                                    {
                                                        additionalFields &&
                                                        Object.entries(additionalFields).map(([key, value], index) => (
                                                            <div key={index} className="flex flex-col gap-y-2">
                                                                <p className={`${skin ? "text-stone-400" : "text-stone-600"} mb-0`}>{key}</p>
                                                                <p className="font-semibold mb-0">
                                                                    {value}
                                                                </p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Card.Body>
                                        }
                                        {
                                            campaign && enrichedFields && Object.entries(enrichedFields).length ?
                                                <>
                                                    <Card.Header className={`${skin ? "" : "bg-stone-200"}`}>
                                                        <h5 className={`text-base uppercase ${skin ? "" : "text-indigo-600"} mb-0 font-semibold text-lg`}>Enriched Data</h5>
                                                    </Card.Header>

                                                    <Card.Body>
                                                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                                                            {
                                                                Object.entries(enrichedFields).map(([key, value], index) => (
                                                                    <div key={index} className="flex flex-col gap-y-2">
                                                                        <p className={`${skin ? "text-stone-400" : "text-stone-600"} mb-0`}>{key}</p>
                                                                        <p className="font-semibold mb-0">
                                                                            {value}
                                                                        </p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </> : ""
                                        }

                                    </Card>
                                </div>
                            </div>
                            : modalActiveTab === 2 ?
                            <div className="w-full flex flex-col gap-y-3">
                                <div className="mt-3">
                                    <Card className={`${skin ? "text-stone-400" : ""}`}>
                                        <Card.Body>
                                            <div className="flex flex-col sm:flex-row gap-x-14 gap-y-5 justify-between sm:items-center">
                                                <div className="flex flex-1 gap-x-1.5 items-start">
                                                    {/* <div className="px-1.5 py-1 rounded-md bg-indigo-600 text-white">Repost</div> */}
                                                    <div>
                                                        <p className="mb-0">Post <span className="font-semibold">{lead?.lead_id}</span> to campaign <span className="font-medium">{campaign ? campaign.campaign_title : "_"}</span> was sent on {moment(lead.created_at).format("lll")}.</p>
                                                        {/* <p className="mb-0">Total execution time was <span className="font-semibold">0.524s</span>. This lead was purchased for <span className="text-danger font-semibold">$0.00</span> and sold for <span className="text-primary font-semibold">$0.00</span> with a profit of <span className="text-success font-semibold">$0.00</span></p> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={`flex items-center justify-center gap-x-1 px-2 py-1 rounded-md ${lead.status === "STORED" ? "bg-primary" : lead.status === "ACCEPTED" ? "bg-success" : lead.status === "DUPLICATED" || lead.status === "PENDING" ? "bg-warning" : "bg-danger"} text-white`}>
                                                        {lead.status ? lead.status : "ERROR"}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <Card className={`${skin ? "text-stone-400" : ""} timeline__mainEl`}>
                                    <Card.Header>
                                        <Card.Title className="mb-0">Timeline</Card.Title>
                                    </Card.Header>
                                    {
                                        responseLog &&
                                        <Card.Body>
                                            <div className="flex flex-col">
                                                <>
                                                    {
                                                        Array.isArray(responseLog) ?
                                                            responseLog.map((rl, index) => (
                                                                <div key={index} className="flex flex-col">
                                                                    <div className="flex gap-x-3">
                                                                        <div className="flex flex-col items-center">
                                                                            <div className="text-white w-[2rem] min-w-[2rem] max-w-[2rem] h-[2rem] min-h-[2rem] max-h-[2rem] rounded-full bg-stone-500 flex items-center justify-center">
                                                                                {index + 1}
                                                                            </div>
                                                                            {
                                                                                index !== responseLog.length - 1 &&
                                                                                <div className="w-[2px] min-h-[38px] h-full bg-stone-300"></div>
                                                                            }
                                                                        </div>
                                                                        <Card className={`w-full ${filterData && filterHeading && filterHeading.index === index ? "mb-0" : "mb-3"} ${skin ? "text-stone-400" : ""}`}>
                                                                            <Card.Header className="flex items-center gap-x-2">
                                                                                {
                                                                                    rl.sold_to &&
                                                                                    <Card.Title className="mb-0 border-r border-stone-200 pr-2">{rl.sold_to}</Card.Title>
                                                                                }
                                                                                <p className={`text-xs mb-0 ${skin ? "text-stone-400" : "text-stone-500"}`}>{moment(rl.timestamp).format("lll")}</p>
                                                                                {
                                                                                    lead.is_test &&
                                                                                    <p className="mb-0 p-1 text-xs rounded-md bg-teal-600 text-white">Test</p>
                                                                                }
                                                                            </Card.Header>
                                                                            <Card.Body className="overflow-x-auto overflow-y-hidden flex">
                                                                                <div className="flex">
                                                                                    {
                                                                                        rl.success != null &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.active ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                    {
                                                                                                        rl.success ?
                                                                                                            <FaCheck /> :
                                                                                                            <IoCloseOutline />
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    (rl.error != null || rl.reason != null) &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold ${rl.active ? "text-teal-600" : "text-rose-600"}`}>{rl.active ? "Active" : "Inactive"}</p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl.active != null &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.active ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                    {
                                                                                                        rl.active ?
                                                                                                            <FaCheck /> :
                                                                                                            <IoCloseOutline />
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    (rl.filters != null || rl.supplier_filters != null || rl.traffic_source_filters != null || rl.error != null || rl.reason != null || rl.sold) &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold ${rl.active ? "text-teal-600" : "text-rose-600"}`}>{rl.active ? "Active" : "Inactive"}</p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (rl.error != null || rl.reason != null) &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                    <IoCloseOutline />
                                                                                                </div>
                                                                                                {
                                                                                                    rl.active != null &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold text-rose-600`}>
                                                                                                Reason<br />
                                                                                                <span>{rl.error || rl.reason}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl.filters != null && rl.filters?.as != null &&
                                                                                        <div className="flex flex-col gap-y-3 relative">
                                                                                            <div className="flex flex-col gap-y-1">
                                                                                                <div className="flex items-center">
                                                                                                    <div onClick={() => handleFilterData({ rl: rl, title: "Buyer Filter", index: index })} className={`cursor-pointer w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.filters?.passed ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                        {
                                                                                                            rl.filters?.passed ?
                                                                                                                <FaCheck /> :
                                                                                                                <IoCloseOutline />
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        (rl.supplier_filters != null || rl.api_reject != null || rl.duplicate != null || rl.sold != null) &&
                                                                                                        <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 ${rl.filters?.passed ? "text-teal-600" : "text-rose-600"}`}>
                                                                                                    Buyer Filter
                                                                                                    <br />
                                                                                                    <span className="font-semibold">{rl.filters?.passed ? "Passed" : "Failed"}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            {
                                                                                                filterHeading && filterHeading.title === "Buyer Filter" && filterHeading.index === index &&
                                                                                                <IoMdArrowDropup className="absolute top-[102%] left-0 text-2xl text-stone-400" />
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl.supplier_filters != null && rl.supplier_filters?.as != null &&
                                                                                        <div className="flex flex-col gap-y-3 relative">
                                                                                            <div className="flex flex-col gap-y-1">
                                                                                                <div className="flex items-center">
                                                                                                    <div onClick={() => handleFilterData({ rl: rl, title: "Supplier Filter", index: index })} className={`cursor-pointer w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.supplier_filters?.passed ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                        {
                                                                                                            rl.supplier_filters?.passed ?
                                                                                                                <FaCheck /> :
                                                                                                                <IoCloseOutline />
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        (rl.traffic_source_filters != null || rl.api_reject != null || rl.duplicate != null || rl.sold != null) &&
                                                                                                        <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 ${rl.supplier_filters?.passed ? "text-teal-600" : "text-rose-600"}`}>
                                                                                                    Supplier Filter
                                                                                                    <br />
                                                                                                    <span className="font-semibold">{rl.supplier_filters?.passed ? "Passed" : "Failed"}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            {
                                                                                                filterHeading && filterHeading.title === "Supplier Filter" && filterHeading.index === index &&
                                                                                                <IoMdArrowDropup className="absolute top-[102%] left-0 text-2xl text-stone-400" />
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl.traffic_source_filters != null && rl.traffic_source_filters?.as != null &&
                                                                                        <div className="flex flex-col gap-y-3 relative">
                                                                                            <div className="flex flex-col gap-y-1">
                                                                                                <div className="flex items-center">
                                                                                                    <div onClick={() => handleFilterData({ rl: rl, title: "Traffic Source Filter", index: index })} className={`cursor-pointer w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.traffic_source_filters?.passed ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                        {
                                                                                                            rl.traffic_source_filters?.passed ?
                                                                                                                <FaCheck /> :
                                                                                                                <IoCloseOutline />
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        (rl.api_reject != null || rl.duplicate != null || rl.sold != null) &&
                                                                                                        <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 ${rl.traffic_source_filters?.passed ? "text-teal-600" : "text-rose-600"}`}>
                                                                                                    Traffic Source Filter
                                                                                                    <br />
                                                                                                    <span className="font-semibold">{rl.traffic_source_filters?.passed ? "Passed" : "Failed"}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            {
                                                                                                filterHeading && filterHeading.title === "Traffic Source Filter" && filterHeading.index === index &&
                                                                                                <IoMdArrowDropup className="absolute top-[102%] left-0 text-2xl text-stone-400" />
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {/* {
                                                                            rl.caps != null &&
                                                                            <div className="flex flex-col gap-y-1">
                                                                                <div className="flex items-center">
                                                                                    <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${rl.caps?.passed ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                        {
                                                                                            rl.caps?.passed ?
                                                                                                <FaCheck /> :
                                                                                                <IoCloseOutline />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        (rl.sold != null && rl.sold) &&
                                                                                        <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                    }
                                                                                </div>
                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 ${rl.caps?.passed ? "text-teal-600" : "text-rose-600"}`}>
                                                                                    Caps<br />
                                                                                    <span className="font-semibold">{rl.caps?.passed ? "Passed" : "Failed"}</span>
                                                                                </p>
                                                                            </div>
                                                                        } */}
                                                                                    {
                                                                                        rl.api_reject != null && rl.api_reject &&
                                                                                        <>
                                                                                            <div className="flex flex-col gap-y-1">
                                                                                                <div className="flex items-center">
                                                                                                    <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                        {
                                                                                                            <IoCloseOutline />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600`}>
                                                                                                    Reason
                                                                                                    <br />
                                                                                                    <span className="font-semibold">Rejected from API</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            {
                                                                                                rl.response != null &&
                                                                                                <div className="flex flex-col gap-y-1">
                                                                                                    <div className="flex items-center">
                                                                                                        <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                            {
                                                                                                                <IoCloseOutline />
                                                                                                            }
                                                                                                        </div>
                                                                                                        {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                                                                                    </div>
                                                                                                    <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600`}>
                                                                                                        API Rejection Reason
                                                                                                        <br />
                                                                                                        <span className="font-semibold">
                                                                                                            {rl.response?.data?.reason ? rl.response?.data?.reason : rl.response?.data?.errors ? rl.response.data.errors?.[0]?.error : rl.response?.errors ? rl.response?.errors?.[0]?.error : rl.response?.messages ? rl.response?.messages[0]?.message : rl.response?.error ? rl.response?.error : rl.response?.message ? rl.response?.message : rl?.response?.code ? `Code ${rl.response?.code}` : ""}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        rl.duplicate != null && rl.duplicate &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                    {
                                                                                                        <IoCloseOutline />
                                                                                                    }
                                                                                                </div>
                                                                                                {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600`}>
                                                                                                Reason
                                                                                                <br />
                                                                                                <span className="font-semibold">Duplicate</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl.phone_format_error != null && rl.phone_format_error &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                    {
                                                                                                        <IoCloseOutline />
                                                                                                    }
                                                                                                </div>
                                                                                                {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600`}>
                                                                                                Reason
                                                                                                <br />
                                                                                                <span className="font-semibold">Wrong phone format!</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (rl.sold != null && rl.sold) &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-indigo-600 text-white`}>
                                                                                                    <FaDollarSign />
                                                                                                </div>
                                                                                                {
                                                                                                    lead.source_payout != null &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-indigo-600`}>Buyer Price<br />
                                                                                                <span className="font-semibold">${rl.price}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (rl.sold != null && rl.sold && lead.source_payout != null) &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-indigo-600 text-white`}>
                                                                                                    <FaDollarSign />
                                                                                                </div>
                                                                                                {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-indigo-600`}>Source Payout<br />
                                                                                                <span className="font-semibold">${lead.source_payout}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </div>
                                                                    {
                                                                        filterData && filterHeading && filterHeading.index === index &&
                                                                        <LeadFilter
                                                                            skin={skin}
                                                                            filterData={filterData}
                                                                            setFilterData={setFilterData}
                                                                            filterHeading={filterHeading}
                                                                            setFilterHeading={setFilterHeading}
                                                                            payloadResponse={payloadResponse}
                                                                            responseLog={responseLog}
                                                                            clearFilterData={clearFilterData}
                                                                        />
                                                                    }
                                                                </div>
                                                            )) : responseLog.custom_error &&
                                                            <div className="flex flex-col">
                                                                <div className="flex gap-x-3">
                                                                    <div className="flex flex-col items-center">
                                                                        <div className="text-white w-[2rem] min-w-[2rem] max-w-[2rem] h-[2rem] min-h-[2rem] max-h-[2rem] rounded-full bg-stone-500 flex items-center justify-center">
                                                                            1
                                                                        </div>
                                                                        {/* {
                                                                            index !== Object.keys(responseLog).length - 1 &&
                                                                            <div className="w-[2px] min-h-[38px] h-full bg-stone-300"></div>
                                                                        } */}
                                                                    </div>
                                                                    <Card className={`w-full ${skin ? "text-stone-400" : ""}`}>
                                                                        <Card.Body className="overflow-x-auto overflow-y-hidden flex">
                                                                            {
                                                                                Object.entries(responseLog).map(([k, v], index) => (
                                                                                    <div key={index} className="flex w-full">
                                                                                        {
                                                                                            k === "success" &&
                                                                                            <div className="flex flex-col gap-y-1 w-full">
                                                                                                <div className="flex items-center">
                                                                                                    <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full ${v == true ? "bg-teal-600" : "bg-rose-600"} text-white`}>
                                                                                                        {
                                                                                                            v == true ?
                                                                                                                <FaCheck /> :
                                                                                                                <IoCloseOutline />
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        (responseLog.hasOwnProperty("error") || responseLog.hasOwnProperty("reason")) &&
                                                                                                        <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-medium ${v == true ? "text-teal-600" : "text-rose-600"}`}>
                                                                                                    {k}<br />
                                                                                                    <span className="font-semibold">{v == true ? "True" : "False"}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            (k === "error" || k === "reason") &&
                                                                                            <div className="flex flex-col gap-y-1 w-full">
                                                                                                <div className="flex items-center">
                                                                                                    <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white`}>
                                                                                                        <IoCloseOutline />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-medium text-rose-600`}>
                                                                                                    Reason<br />
                                                                                                    <span className="font-semibold">{v}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }

                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                            </div>
                                        </Card.Body>

                                        // <Card.Body>
                                        //     <div className="flex flex-col">
                                        //         {
                                        //             leadIsAnArray ?
                                        //                 responseLog.map((rl, index) => (
                                        //                     <div key={index} className="flex gap-x-3">
                                        //                         <div className="flex flex-col items-center">
                                        //                             <div className="text-white w-[2rem] min-w-[2rem] max-w-[2rem] h-[2rem] min-h-[2rem] max-h-[2rem] rounded-full bg-stone-500 flex items-center justify-center">
                                        //                                 {index + 1}
                                        //                             </div>
                                        //                             {
                                        //                                 index !== responseLog.length - 1 &&
                                        //                                 <div className="w-[2px] min-h-[38px] h-full bg-stone-300"></div>
                                        //                             }
                                        //                         </div>
                                        //                         <Card className={`w-full mb-3 ${skin ? "text-stone-400" : ""}`}>
                                        //                             <Card.Header className="flex items-center gap-x-2">
                                        //                                 {
                                        //                                     rl.sold_to &&
                                        //                                     <Card.Title className="mb-0 border-r border-stone-200 pr-2">{rl.sold_to}</Card.Title>
                                        //                                 }
                                        //                                 <p className={`text-xs mb-0 ${skin ? "text-stone-400" : "text-stone-500"}`}>{moment(lead.created_at).format("ll")}</p>
                                        //                                 {
                                        //                                     lead.is_test &&
                                        //                                     <p className="mb-0 p-1 text-xs rounded-md bg-teal-600 text-white">Test</p>
                                        //                                 }
                                        //                             </Card.Header>
                                        //                             {
                                        //                                 leadIsRejected ?
                                        //                                     <Card.Body className="overflow-x-auto flex">
                                        //                                         {
                                        //                                             Object.entries(rl).map(([k, v], index) => (
                                        //                                                 <div key={index} className="flex flex-col gap-y-1">
                                        //                                                     <div className="flex items-center">
                                        //                                                         <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                             <IoCloseOutline />
                                        //                                                         </div>
                                        //                                                         {
                                        //                                                             index !== Object.keys(rl).length - 1 &&
                                        //                                                             <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                         }
                                        //                                                     </div>
                                        //                                                     <p className={`mb-0 pr-10 ${(k.toLowerCase() === "health_indicator" && v.toLowerCase() === "no risk") ? "text-teal-600" : "text-rose-600"} font-semibold whitespace-nowrap`}>{k}<br /><span className="font-normal">{v}</span></p>
                                        //                                                 </div>
                                        //                                             ))
                                        //                                         }
                                        //                                     </Card.Body>
                                        //                                     :
                                        //                                     <Card.Body className="overflow-x-auto flex">
                                        //                                         {
                                        //                                             (rl.success && rl.payout) ?
                                        //                                                 <div className="flex">
                                        //                                                     <div className="flex flex-col gap-y-1">
                                        //                                                         <div className="flex items-center">
                                        //                                                             <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-teal-600 text-white">
                                        //                                                                 <FaCheck />
                                        //                                                             </div>
                                        //                                                             <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                         </div>
                                        //                                                         <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold text-teal-600">Active</p>
                                        //                                                     </div>
                                        //                                                     <div className="flex flex-col gap-y-1">
                                        //                                                         <div className="flex items-center">
                                        //                                                             <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-teal-600 text-white">
                                        //                                                                 <FaCheck />
                                        //                                                             </div>
                                        //                                                             <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                         </div>
                                        //                                                         <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-teal-600 font-semibold">Filters<br /><span className="font-normal">Passed</span></p>
                                        //                                                     </div>
                                        //                                                     <div className="flex flex-col gap-y-1">
                                        //                                                         <div className="flex items-center">
                                        //                                                             <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-sky-600 text-white">
                                        //                                                                 <FiUser />
                                        //                                                             </div>
                                        //                                                             <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                         </div>
                                        //                                                         <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-sky-600 font-semibold whitespace-nowrap">Generated By<br /><span className="font-normal">{rl.generated_by}</span></p>
                                        //                                                     </div>
                                        //                                                     {
                                        //                                                         lead.status.toLowerCase() === "rejected" || lead.status.toLowerCase() === "error" || lead.status.toLowerCase() === "failed" ?
                                        //                                                             <div className="flex flex-col gap-y-1">
                                        //                                                                 <div className="flex items-center">
                                        //                                                                     <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                                         <IoCloseOutline />
                                        //                                                                     </div>
                                        //                                                                     {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                        //                                                                 </div>
                                        //                                                                 <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600 font-semibold">Status<br /><span className="font-normal">{lead.status}</span></p>
                                        //                                                             </div> :
                                        //                                                             <div className="flex flex-col gap-y-1">
                                        //                                                                 <div className="flex items-center">
                                        //                                                                     <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-sky-600 text-white">
                                        //                                                                         <FaDollarSign />
                                        //                                                                     </div>
                                        //                                                                     {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                        //                                                                 </div>
                                        //                                                                 <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-sky-600 font-semibold">Payout<br /><span className="font-normal">{rl.payout}</span></p>
                                        //                                                             </div>
                                        //                                                     }
                                        //                                                 </div> :
                                        //                                                 <div className="flex">
                                        //                                                     {
                                        //                                                         rl.buyerIndex === 0 &&
                                        //                                                         <div className="flex flex-col gap-y-1">
                                        //                                                             <div className="flex items-center">
                                        //                                                                 <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-teal-600 text-white">
                                        //                                                                     <FaCheck />
                                        //                                                                 </div>
                                        //                                                                 <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                             </div>
                                        //                                                             <p className="max-w-[20rem] mb-0 font-semibold text-teal-600">Active</p>
                                        //                                                         </div>
                                        //                                                     }
                                        //                                                     <div className="flex flex-col gap-y-1">
                                        //                                                         <div className="flex items-center">
                                        //                                                             <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                                 <IoCloseOutline />
                                        //                                                             </div>
                                        //                                                             {
                                        //                                                                 rl.reason &&
                                        //                                                                 <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                             }
                                        //                                                         </div>
                                        //                                                         <p className="max-w-[20rem] mb-0 text-rose-600 font-semibold">{rl.label ? rl.label : "Not sold"}</p>
                                        //                                                     </div>
                                        //                                                     {
                                        //                                                         rl.reason &&
                                        //                                                         <>
                                        //                                                             {
                                        //                                                                 rl.reason.field &&
                                        //                                                                 <div className="flex flex-col gap-y-1">
                                        //                                                                     <div className="flex items-center">
                                        //                                                                         <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                                             <IoCloseOutline />
                                        //                                                                         </div>
                                        //                                                                         <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                                                     </div>
                                        //                                                                     <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600 font-semibold">Reason Occuring to<br /><span className="font-normal">{rl.reason.field} field</span></p>
                                        //                                                                 </div>
                                        //                                                             }
                                        //                                                             {
                                        //                                                                 rl.reason.error &&
                                        //                                                                 <div className="flex flex-col gap-y-1">
                                        //                                                                     <div className="flex items-center">
                                        //                                                                         <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                                             <IoCloseOutline />
                                        //                                                                         </div>
                                        //                                                                         {/* <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div> */}
                                        //                                                                     </div>
                                        //                                                                     <p className="mr-5 min-w-[12rem] max-w-[20rem] mb-0 text-rose-600 font-semibold">Reason<br /><span className="font-normal">{rl.reason.error}</span></p>
                                        //                                                                 </div>
                                        //                                                             }
                                        //                                                         </>
                                        //                                                     }
                                        //                                                 </div>
                                        //                                         }
                                        //                                     </Card.Body>
                                        //                             }
                                        //                         </Card>
                                        //                     </div>
                                        //                 ))
                                        //                 :
                                        //                 <Card className={`w-full mb-3 ${skin ? "text-stone-400" : ""}`}>
                                        //                     <Card.Body className="overflow-x-auto flex">
                                        //                         <div className="flex">
                                        //                             {
                                        //                                 Object.entries(responseLog)?.map(([lrKey, lrVal], index) => (
                                        //                                     <div key={index} className="flex flex-col gap-y-1">
                                        //                                         <div className="flex items-center">
                                        //                                             {
                                        //                                                 lead.status.toLowerCase() === "stored" ?
                                        //                                                     <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-teal-600 text-white">
                                        //                                                         <FaCheck />
                                        //                                                     </div>
                                        //                                                     :
                                        //                                                     <div className="w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] flex items-center justify-center rounded-full bg-rose-600 text-white">
                                        //                                                         <IoCloseOutline />
                                        //                                                     </div>
                                        //                                             }
                                        //                                             {
                                        //                                                 index !== Object.keys(responseLog).length - 1 &&
                                        //                                                 <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                        //                                             }
                                        //                                         </div>
                                        //                                         <p className={`mb-0 pr-10 ${(lead.status.toLowerCase() === "stored") || (lrKey.toLowerCase() === "health_indicator" && !lrVal.error && lrVal.toLowerCase() === "no risk") ? "text-teal-600" : "text-rose-600"} font-semibold whitespace-nowrap`}>{lrKey}<br /><span className="font-normal">{lrVal.error ? lrVal.error : lrVal}</span></p>
                                        //                                     </div>
                                        //                                 ))
                                        //                             }
                                        //                         </div>
                                        //                     </Card.Body>
                                        //                 </Card>
                                        //         }
                                        //     </div>
                                        // </Card.Body>
                                    }

                                </Card>
                                {
                                    buyerResponseLog &&
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">Buyer Response</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="w-full">
                                                <pre className={`text-base ${skin ? "bg-slate-800 text-stone-200" : "bg-stone-100"} p-3 rounded-md`}><JsonFormatter json={buyerResponseLog} tabWith={4} /></pre>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                }
                            </div> : <div className="w-full flex flex-col gap-y-3">
                                <div className="mt-3">
                                    <Card className={`${skin ? "text-stone-400" : ""}`}>
                                        <Card.Body>
                                            <div className="flex flex-col sm:flex-row gap-x-14 gap-y-5 justify-between sm:items-center">
                                                <div className="flex flex-1 gap-x-1.5 items-start">
                                                    {/* <div className="px-1.5 py-1 rounded-md bg-indigo-600 text-white">Repost</div> */}
                                                    <div>
                                                        <p className="mb-0">Lead <span className="font-semibold">{lead?.lead_id}</span> to campaign <span className="font-medium">{campaign ? campaign.campaign_title : "_"}</span> was last returned on {moment(lead?.return_logs?.at(-1)?.created_at).format("lll")}.</p>
                                                        {/* <p className="mb-0">Total execution time was <span className="font-semibold">0.524s</span>. This lead was purchased for <span className="text-danger font-semibold">$0.00</span> and sold for <span className="text-primary font-semibold">$0.00</span> with a profit of <span className="text-success font-semibold">$0.00</span></p> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={`flex items-center justify-center gap-x-1 px-2 py-1 rounded-md ${lead.status === "STORED" ? "bg-primary" : lead.status === "ACCEPTED" ? "bg-success" : lead.status === "DUPLICATED" || lead.status === "PENDING" ? "bg-warning" : "bg-danger"} text-white`}>
                                                        {lead.status ? lead.status : "ERROR"}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <Card className={`${skin ? "text-stone-400" : ""} timeline__mainEl`}>
                                    <Card.Header>
                                        <Card.Title className="mb-0">Return Logs</Card.Title>
                                    </Card.Header>
                                    {
                                        lead?.return_logs &&
                                        <Card.Body>
                                            <div className="flex flex-col">
                                                <>
                                                    {
                                                            lead.return_logs.map((rl, index) => (
                                                                <div key={index} className="flex flex-col">
                                                                    <div className="flex gap-x-3">
                                                                        <div className="flex flex-col items-center">
                                                                            <div className="text-white w-[2rem] min-w-[2rem] max-w-[2rem] h-[2rem] min-h-[2rem] max-h-[2rem] rounded-full bg-stone-500 flex items-center justify-center">
                                                                                {index + 1}
                                                                            </div>
                                                                            {
                                                                                index !== lead.return_logs.length - 1 &&
                                                                                <div className="w-[2px] min-h-[38px] h-full bg-stone-300"></div>
                                                                            }
                                                                        </div>
                                                                        <Card className={`w-full ${filterData && filterHeading && filterHeading.index === index ? "mb-0" : "mb-3"} ${skin ? "text-stone-400" : ""}`}>
                                                                            <Card.Header className="flex items-center gap-x-2">
                                                                                <p className={`text-xs mb-0 ${skin ? "text-stone-400" : "text-stone-500"}`}>{moment(rl.created_at).format("lll")}</p>
                                                                            </Card.Header>
                                                                            <Card.Body className="overflow-x-auto overflow-y-hidden flex">
                                                                                <div className="flex">
                                                                                    {
                                                                                        rl?.log?.prev_status &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-${rl?.log?.updated_status_color ? rl?.log?.updated_status_color : rl?.log?.prev_status_color} text-white`}>
                                                                                                    {
                                                                                                        rl?.log?.updated_status_icon ? rl?.log?.updated_status_icon : 
                                                                                                        rl?.log?.prev_status_icon
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    (rl?.log?.lead_sold_price || rl?.log?.prev_source_payout) &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold text-${rl?.log?.updated_status_color ? rl?.log?.updated_status_color : rl?.log?.prev_status_color}`}>
                                                                                                <p className="mb-0">Status</p>
                                                                                                <p className="mb-0 flex gap-1.5 items-center">
                                                                                                    <span className={`${rl?.log?.updated_status != null ? "line-through" : ""}`}>{rl?.log?.prev_status}</span> {rl?.log?.prev_status && <><FaArrowRightLong /> <span>{rl?.log?.updated_status}</span></>}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl?.log?.lead_sold_price != null &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-indigo-600 text-white`}>
                                                                                                    <FaDollarSign />
                                                                                                </div>
                                                                                                {
                                                                                                    rl?.log?.prev_source_payout != null &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                }
                                                                                            </div>
                                                                                            <p className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold text-indigo-600`}>Payout<br />${rl?.log?.lead_sold_price}</p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        rl?.log?.prev_source_payout != null &&
                                                                                        <div className="flex flex-col gap-y-1">
                                                                                            <div className="flex items-center">
                                                                                                <div className={`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] h-[1.5rem] min-h-[1.5rem] max-h-[1.5rem] flex items-center justify-center rounded-full bg-indigo-600 text-white`}>
                                                                                                    <FaDollarSign />
                                                                                                </div>
                                                                                                {/* {
                                                                                                    rl?.log?.source_payout != null &&
                                                                                                    <div className="min-w-[100px] w-full h-[2px] bg-stone-300"></div>
                                                                                                } */}
                                                                                            </div>
                                                                                            <div className={`mr-5 min-w-[12rem] max-w-[20rem] mb-0 font-semibold text-indigo-600`}>
                                                                                                <p className="mb-0">Source Payout</p>
                                                                                                <p className="mb-0 flex gap-1.5 items-center">
                                                                                                    <span className={`${rl?.log?.source_payout != null ? "line-through" : ""}`}>${rl?.log?.prev_source_payout}</span> {rl?.log?.source_payout && <><FaArrowRightLong /> <span>${rl?.log?.source_payout}</span></>}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </div>
                                                                    {
                                                                        filterData && filterHeading && filterHeading.index === index &&
                                                                        <LeadFilter
                                                                            skin={skin}
                                                                            filterData={filterData}
                                                                            setFilterData={setFilterData}
                                                                            filterHeading={filterHeading}
                                                                            setFilterHeading={setFilterHeading}
                                                                            payloadResponse={payloadResponse}
                                                                            responseLog={responseLog}
                                                                            clearFilterData={clearFilterData}
                                                                        />
                                                                    }
                                                                </div>
                                                            ))
                                                    }
                                                </>
                                            </div>
                                        </Card.Body>
                                    }
                                </Card>
                            </div>
                    }
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default LeadDetailModal;
