import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MainMenu } from '../data/Menu';
import { useAuth } from '../Context/AuthContext';

const SidebarContent = () => {
    const [authData, setAuthData] = useAuth();

    const location = useLocation();
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        if (authData) {
            const { rolenames } = authData;

            const showCampMenu = rolenames.includes("SUPER ADMIN") || rolenames.includes("DEVELOPER") || rolenames.includes("ADMIN") || rolenames.includes("TRAFFIC SOURCE");

            let menuArr = [...MainMenu];
            
            // Give access to "campaign" based on role
            menuArr?.map(m => {
                if (m.label === "Campaigns") {
                    m.hasAccess = showCampMenu;
                } else {
                    m.hasAccess = true;
                }
            });

            setMenus(menuArr);
        }
    }, [authData])

    useEffect(() => {
        setSkin(currentSkin);
    }, [currentSkin]);

    return (
        <div className="flex flex-col gap-y-3">
            {
                menus?.map((m, index) => (
                    m.hasAccess &&
                    <Link key={index} to={m.link} className={`font-medium flex items-center gap-x-3 px-3 first:pt-3 pb-3 border-b last:pb-0 last:border-b-0 ${skin ? `${location.pathname === m.link ? "text-stone-300" : "text-stone-400"} hover:text-stone-300 border-stone-500` : `${location.pathname === m.link ? "text-indigo-500" : "text-stone-600"} hover:text-indigo-500 border-stone-200`} transition-colors duration-200 ease-linear`}>
                        <div className='text-xl'>
                            {m.icon}
                        </div>
                        {m.label}
                    </Link>
                ))
            }
        </div>
    )
}

export default SidebarContent
