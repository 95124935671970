import { Card, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReportModal = ({
  showModal,
  setShowModal,
  hasAccess,
  campaign,
  setCampaign,
  skin
}) => {
  const handleClose = () => {
    setShowModal(false);
    setCampaign(null);
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{campaign && campaign.campaign_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {campaign && (
          <Card>
            {
              campaign?.buyers?.length &&
              <Card.Body>
                <div
                  className={`flex flex-col gap-y-3 border ${skin ? "border-stone-500" : "border-stone-200"
                    } p-3 rounded-md`}
                >
                  <div
                    className={`uppercase font-semibold ${skin ? "text-stone-400" : "text-stone-500"
                      }`}
                  >
                    Buyers
                  </div>
                  <Table
                    variant={skin && "dark"}
                    hover
                    className="mb-0"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          ID
                        </th>
                        {
                          hasAccess.canViewBuyerName &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Name
                          </th>
                        }
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Buyer Type
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Posted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Accepted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Rejected
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Acceptance Rate
                        </th>
                        {
                          hasAccess.canViewBuyerRevenue &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Revenue
                          </th>
                        }
                        {/* {
                          hasAccess.canViewBuyerPayout &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Payout
                          </th>
                        } */}
                        {
                          hasAccess.canViewBuyerProfit &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Profit
                          </th>
                        }
                        {
                          hasAccess.canViewBuyerTotalMargin &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Total Margin
                          </th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {campaign.buyers.map((campBuyer) => (
                        <tr key={campBuyer.id}>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campBuyer.id}
                            </Link>
                          </td>
                          {
                            hasAccess.canViewBuyerName &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  }`}
                              >
                                {campBuyer?.client_company_name[0]?.toUpperCase() +
                                  campBuyer?.client_company_name?.slice(1)}
                              </Link>
                            </td>
                          }
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campBuyer.buyer_type}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campBuyer.ingested}
                            </Link>
                          </td>
                          {/* <td className='px-3 align-middle'>
                                                            <Link to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                {campBuyer.total_sell}
                                                            </Link>
                                                        </td> */}
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campBuyer.total_accepted}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campBuyer.total_rejected}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                } ${campBuyer.acceptance_rate < 0
                                  ? "text-danger"
                                  : ""
                                }`}
                            >
                              {campBuyer.acceptance_rate.toFixed(2)}%
                            </Link>
                          </td>
                          {/* <td className='px-3 align-middle'>
                                                            <Link to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                {campBuyer.total_duplicated}
                                                            </Link>
                                                        </td> */}
                          {
                            hasAccess.canViewBuyerRevenue &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campBuyer.total_revenue < 0 ? "text-danger" : ""
                                  }`}
                              >
                                {campBuyer.total_revenue < 0 ? "-" : ""}$
                                {Math.abs(campBuyer.total_revenue).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {/* {
                            hasAccess.canViewBuyerPayout &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campBuyer.total_payout < 0 ? "text-danger" : ""
                                  }`}
                              >
                                {campBuyer.total_payout < 0 ? "-" : ""}$
                                {Math.abs(campBuyer.total_payout).toFixed(2)}
                              </Link>
                            </td>
                          } */}
                          {
                            hasAccess.canViewBuyerProfit &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campBuyer.total_profit < 0 ? "text-danger" : ""
                                  }`}
                              >
                                {campBuyer.total_profit < 0 ? "-" : ""}$
                                {Math.abs(campBuyer.total_profit).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewBuyerTotalMargin &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campBuyer.total_margin < 0 ? "text-danger" : ""
                                  }`}
                              >
                                {campBuyer.total_margin.toFixed(2)}%
                              </Link>
                            </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            }

            {
              campaign?.suppliers?.length &&
              <Card.Body>
                <div
                  className={`flex flex-col gap-y-3 border ${skin ? "border-stone-500" : "border-stone-200"
                    } p-3 rounded-md`}
                >
                  <div
                    className={`uppercase font-semibold ${skin ? "text-stone-400" : "text-stone-500"
                      }`}
                  >
                    Suppliers
                  </div>
                  <Table
                    variant={skin && "dark"}
                    hover
                    className="mb-0"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          ID
                        </th>
                        {
                          hasAccess.canViewSupplierName &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Name
                          </th>
                        }
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Posted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Accepted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Rejected
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Acceptance Rate
                        </th>
                        {
                          hasAccess.canViewSupplierRevenue &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Revenue
                          </th>
                        }
                        {
                          hasAccess.canViewSupplierPayout &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Payout
                          </th>
                        }
                        {
                          hasAccess.canViewSupplierProfit &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Profit
                          </th>
                        }
                        {
                          hasAccess.canViewSupplierTotalMargin &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Margin
                          </th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {campaign.suppliers.map((campSupplier) => (
                        <tr key={campSupplier.id}>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campSupplier.id}
                            </Link>
                          </td>
                          {
                            hasAccess.canViewSupplierName &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  }`}
                              >
                                {campSupplier?.client_company_name[0]?.toUpperCase() +
                                  campSupplier?.client_company_name?.slice(1)}
                              </Link>
                            </td>
                          }
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campSupplier.total_posted}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campSupplier.total_accepted}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {campSupplier.total_rejected}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                } ${campSupplier.acceptance_rate < 0
                                  ? "text-danger"
                                  : ""
                                }`}
                            >
                              {campSupplier.acceptance_rate.toFixed(2)}%
                            </Link>
                          </td>
                          {
                            hasAccess.canViewSupplierRevenue &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campSupplier.total_revenue < 0
                                    ? "text-danger"
                                    : ""
                                  }`}
                              >
                                {campSupplier.total_revenue < 0 ? "-" : ""}$
                                {Math.abs(campSupplier.total_revenue).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewSupplierPayout &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campSupplier.total_payout < 0
                                    ? "text-danger"
                                    : ""
                                  }`}
                              >
                                {campSupplier.total_payout < 0 ? "-" : ""}$
                                {Math.abs(campSupplier.total_payout).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewSupplierProfit &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campSupplier.total_profit < 0
                                    ? "text-danger"
                                    : ""
                                  }`}
                              >
                                {campSupplier.total_profit < 0 ? "-" : ""}$
                                {Math.abs(campSupplier.total_profit).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewSupplierTotalMargin &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${campSupplier.total_margin < 0
                                    ? "text-danger"
                                    : ""
                                  }`}
                              >
                                {campSupplier.total_margin.toFixed(2)}%
                              </Link>
                            </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            }

            {campaign?.traffic_sources?.length > 0 && (
              <Card.Body>
                <div
                  className={`flex flex-col gap-y-3 border ${skin ? "border-stone-500" : "border-stone-200"
                    } p-3 rounded-md`}
                >
                  <div
                    className={`uppercase font-semibold ${skin ? "text-stone-400" : "text-stone-500"
                      }`}
                  >
                    Traffic Sources
                  </div>
                  <Table
                    variant={skin && "dark"}
                    hover
                    className="mb-0"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          ID
                        </th>
                        {
                          hasAccess.canViewTrafficSourceName &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Name
                          </th>
                        }
                        {/* {
                          hasAccess.canViewTrafficSourcChannelName &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Channel
                          </th>
                        } */}
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Posted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Accepted
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Rejected
                        </th>
                        <th
                          scope="col"
                          className="text-lg px-3 font-semibold whitespace-nowrap"
                        >
                          Acceptance Rate
                        </th>
                        {
                          hasAccess.canViewTrafficSourceRevenue &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Revenue
                          </th>
                        }
                        {
                          hasAccess.canViewTrafficSourcePayout &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Payout
                          </th>
                        }
                        {
                          hasAccess.canViewTrafficSourceProfit &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Profit
                          </th>
                        }
                        {
                          hasAccess.canViewTrafficSourceTotalMargin &&
                          <th
                            scope="col"
                            className="text-lg px-3 font-semibold whitespace-nowrap"
                          >
                            Margin
                          </th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {campaign.traffic_sources.map((ts) => (
                        <tr key={ts.id}>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {ts.id}
                            </Link>
                          </td>
                          {
                            hasAccess.canViewTrafficSourceName &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`whitespace-nowrap ${skin ? "text-stone-300" : "text-inherit"
                                  }`}
                              >
                                {ts.client_company_name ? (
                                  `${ts.client_company_name[0]?.toUpperCase()}${ts.client_company_name?.slice(1)} ${ts.traffic_channel ? " - " + ts.traffic_channel + ` (${ts.id})` : ""}`
                                ) : (
                                  <p
                                    className={`${skin ? "text-stone-300" : "text-inherit"
                                      }`}
                                  >
                                    -
                                  </p>
                                )}
                              </Link>
                            </td>
                          }
                          {/* {
                            hasAccess.canViewTrafficSourcChannelName &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  }`}
                              >
                                {ts.traffic_channel ? (
                                  ts.traffic_channel
                                ) : (
                                  <p
                                    className={`${skin ? "text-stone-300" : "text-inherit"
                                      }`}
                                  >
                                    -
                                  </p>
                                )}
                              </Link>
                            </td>
                          } */}
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {ts.total_posted}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {ts.total_accepted}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                }`}
                            >
                              {ts.total_rejected}
                            </Link>
                          </td>
                          <td className="px-3 align-middle">
                            <Link
                              to="#"
                              className={`${skin ? "text-stone-300" : "text-inherit"
                                } ${ts.acceptance_rate < 0 ? "text-danger" : ""}`}
                            >
                              {ts.acceptance_rate.toFixed(2)}%
                            </Link>
                          </td>
                          {
                            hasAccess.canViewTrafficSourceRevenue &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${ts.total_revenue < 0 ? "text-danger" : ""}`}
                              >
                                {ts.total_revenue < 0 ? "-" : ""}$
                                {Math.abs(ts.total_revenue).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewTrafficSourcePayout &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${ts.total_payout < 0 ? "text-danger" : ""}`}
                              >
                                {ts.total_payout < 0 ? "-" : ""}$
                                {Math.abs(ts.total_payout).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewTrafficSourceProfit &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${ts.total_profit < 0 ? "text-danger" : ""}`}
                              >
                                {ts.total_profit < 0 ? "-" : ""}$
                                {Math.abs(ts.total_profit).toFixed(2)}
                              </Link>
                            </td>
                          }
                          {
                            hasAccess.canViewTrafficSourceTotalMargin &&
                            <td className="px-3 align-middle">
                              <Link
                                to="#"
                                className={`${skin ? "text-stone-300" : "text-inherit"
                                  } ${ts.total_margin < 0 ? "text-danger" : ""}`}
                              >
                                {ts.total_margin.toFixed(2)}%
                              </Link>
                            </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            )}
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
