import React, { useRef, useState } from 'react'
import { Card, Form, Spinner } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import AuthUtils from '../Utils/AuthUtils';
import loginLogo from "../../assets/login-logo.png";
import loginLogoWhite from "../../assets/Logo-White-1.png";
import { useParams, useNavigate } from 'react-router-dom';
import { PHP_URL } from '../Utils/baseConfig';


const ResetPassword = () => {
    ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

    const navigate = useNavigate();
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const { http } = AuthUtils();
    const [formError, setFormError] = useState({ errorId: -1 });

    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const showFormError = async ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            passwordRef.current.classList.add("error__field");
            passwordRef.current?.focus();
        } else if (errId === 2) {
            confirmPasswordRef.current.classList.add("error__field");
            confirmPasswordRef.current?.focus();
        } else if (errId === 3) {
            passwordRef.current.classList.add("error__field");
            confirmPasswordRef.current.classList.add("error__field");
        } else if (errId == 4) {
            emailRef.current.classList.add("error__field");
            emailRef.current?.focus();
        }

        setLoading(false);
    }

    const handleResetPassword = async (e) => {
        e.preventDefault();

        setLoading(true);

        const email = emailRef.current.value.trim();
        const password = passwordRef.current.value.trim();
        const confirmPassword = confirmPasswordRef.current.value.trim();

        if (!token) {
            toast.error("Invalid token!");
            return;
        }
        if (email === "") {
            showFormError({
                errId: 4
            });
            return;
        }
        if (password === "") {
            showFormError({
                errId: 1
            });
            return;
        }
        if (confirmPassword === "") {
            showFormError({
                errId: 2
            })
            return;
        }
        if (password !== confirmPassword) {
            showFormError({
                errId: 3
            });
            return;
        }

        const formData = {
            token: token,
            email: email,
            password: password,
            password_confirmation: password
        }

        await http.get(`${PHP_URL}/sanctum/csrf-cookie`).then(async () => {
            try {
                const res = await http.post("/api/reset-password/", formData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })

                if (res.status === 200) {
                    const {message} = res.data;

                    toast.success(message);
                    setLoading(false);

                    setTimeout(() => {
                        navigate("/signin");
                    }, 1200);
                }
            } catch(err) {
                const {response} = err;

                if (response) {
                    const {data} = response;

                    if (data) {
                        const {messages, message} = data;

                        if (message) {
                            toast.error(message);
                        } else if (messages) {
                            const {email, password} = messages;

                            if (email) {
                                toast.error(email[0]);
                            }
                            if (password) {
                                toast.error(password[0]);
                            }
                        }
                    }
                    setLoading(false);
                    return;
                }
                toast.error("Something went wrong!");
                setLoading(false);
            }
        })
    }

    const handleFieldChange = async (e) => {
        showFormError({
            errId: -1
        });

        if (e.target.name === "email") {
            if (e.target.value === "") {
                showFormError({
                    errId: 4
                })
            } else {
                emailRef.current.classList.remove("error__field");
            }
        } else if (e.target.name === "password") {
            if (e.target.value === "") {
                showFormError({
                    errId: 1
                })
            } else {
                passwordRef.current.classList.remove("error__field");
            }
        } else if (e.target.name === "confirm__password") {
            if (e.target.value === "") {
                showFormError({
                    errId: 2
                })
            } else {
                confirmPasswordRef.current.classList.remove("error__field");
            }
        }
    }

    return (
        <div className="page-sign">
            <ToastContainer />
            <Card className="card-sign">
                <Card.Header>
                    <div className="mx-auto">
                        <img src={skin ? loginLogoWhite : loginLogo} alt="" className='mb-4 w-full h-full max-h-[50px] object-contain' />
                    </div>
                    <Card.Title>Reset Password</Card.Title>
                    <Card.Text className={`${skin ? "text-stone-500" : ""}`}>Please create your new password.</Card.Text>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleResetPassword}>
                        <div className="mb-3">
                            <Form.Label className={`${skin ? "text-stone-500" : ""}`}>Email</Form.Label>
                            <Form.Control name="email" autoFocus={true} ref={emailRef} type="email" placeholder="Enter email address" onChange={e => handleFieldChange(e)} />
                        </div>
                        <div className="mb-3">
                            <Form.Label className={`${skin ? "text-stone-500" : ""}`}>New Password</Form.Label>
                            <Form.Control name="password" ref={passwordRef} type="password" placeholder="Enter new password" onChange={e => handleFieldChange(e)} />
                        </div>
                        <div className="mb-3">
                            <Form.Label className={`${skin ? "text-stone-500" : ""}`}>Confirm New Password</Form.Label>
                            <Form.Control name="confirm__password" ref={confirmPasswordRef} type="password" placeholder="Confirm new password" onChange={e => handleFieldChange(e)} />
                        </div>
                        {
                            formError.errorId === 3 && <p className='text-rose-600 font-medium text-center'>Two password fields did not match!</p>
                        }

                        <button type="submit" disabled={loading} className="btn-sign font-medium flex justify-center w-full px-3 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                            {
                                loading ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </>
                                    : "Reset Password"
                            }
                        </button>

                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ResetPassword
