import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSave } from "react-icons/bi";
import AuthUtils from "../Utils/AuthUtils";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Card, Form, Spinner } from "react-bootstrap";
import { Tooltip } from 'react-tooltip'
import { IoMdInformationCircleOutline } from "react-icons/io";
import Select from "react-select"


const MARGIN_OPTIONS = [
  {
    value: "Fixed",
    label: "Fixed"
  },
  {
    value: "Percentage",
    label: "Percentage"
  }
]

export default function CreateSupplier() {
  const { http, logout } = AuthUtils()
  const navigate = useNavigate()
  const campaignId = useParams()

  const [isSaving, setIsSaving] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState("");
  const [loading, isLoading] = useState(true);
  const [clients, setClients] = useState([])
  const [client, setClient] = useState(null);
  const [source, setSource] = useState("")
  const [timezone, setTimeZone] = useState()
  const [leadVolumeDailyCap, setLeadVolumeDailyCap] = useState(0)
  const [leadVolumeWeeklyCap, setLeadVolumeWeeklyCap] = useState(0)
  const [leadVolumeMonthlyCap, setLeadVolumeMonthlyCap] = useState(0)
  const [budgetDailyCap, setBudgetDailyCap] = useState(0)
  const [budgetWeeklyCap, setBudgetWeeklyCap] = useState(0)
  const [budgetMonthlyCap, setBudgetMonthlyCap] = useState(0)
  const [price, setPrice] = useState(0)
  const [timezones, setTimezones] = useState([])
  const [formError, setFormError] = useState({
    errorId: -1
  });
  const [showPricing, setShowPricing] = useState(false);
  const [marginType, setMarginType] = useState(null);
  const [marginPrice, setMarginPrice] = useState(0);
  const [marginPercentage, setMarginPercentage] = useState(0);

  const supplierNameRef = useRef()
  const sourceRef = useRef();
  const priceRef = useRef();
  const marginPriceRef = useRef();
  const marginPercentageRef = useRef();


  const showFormError = ({ errorId }) => {
    setFormError({
      errorId: errorId,
    });

    if (errorId === 1) {
      toast.error("Please select a supplier!");
    } else if (errorId === 2) {
      sourceRef.current.classList.add("error__field");
      sourceRef.current?.focus();
    } else if (errorId === 3) {
      toast.error("Please select a timezone!");
    } else if (errorId === 4) {
      priceRef.current.classList.add("error__field");
      priceRef.current?.focus();
    } else if (errorId === 5) {
      toast.error("Please select a margin type!");
    } else if (errorId === 6) {
      marginPriceRef.current.classList.add("error__field");
      marginPriceRef.current?.focus();
    } else if (errorId === 7) {
      marginPercentageRef.current.classList.add("error__field");
      marginPercentageRef.current?.focus();
    }
  }

  const handleSupplierPost = async () => {
    setIsSaving(true);
    let marginVal = "";

    if (client == null) {
      showFormError({
        errorId: 1,
      });
      setIsSaving(false);
      return;
    }
    if (!source || source === "") {
      showFormError({
        errorId: 2,
      });
      setIsSaving(false);
      return;
    }
    if (showPricing && price === "") {
      showFormError({
        errorId: 4
      });
      setIsSaving(false);
      return;
    }
    if (!timezone || timezone === "") {
      showFormError({
        errorId: 3,
      });
      setIsSaving(false);
      return;
    }

    let sellingPrice = 0;

    if (showPricing) {
      if (!marginType) {
        showFormError({
          errorId: 5,
        });
        setIsSaving(false);
        return;
      }
      if (marginType.value === "Fixed" && marginPrice === "") {
        showFormError({
          errorId: 6,
        });
        setIsSaving(false);
        return;
      } else if (marginType.value === "Percentage" && marginPercentage === "") {
        showFormError({
          errorId: 7,
        });
        setIsSaving(false);
        return;
      }

      if (marginType.value === "Fixed") {
        sellingPrice = marginPrice;
        marginVal = 0;
        // marginVal = marginPrice;
      } else if (marginType.value === "Percentage") {
        marginVal = marginPercentage;
        sellingPrice = 0;
      }
    }

    const formData = {
      campaign: campaignId.id,
      timezone: timezone?.value,
      client_id: client.value,
      lead_volume_daily_cap: leadVolumeDailyCap ? leadVolumeDailyCap : 0,
      lead_volume_weekly_cap: leadVolumeWeeklyCap ? leadVolumeWeeklyCap : 0,
      lead_volume_monthly_cap: leadVolumeMonthlyCap ? leadVolumeMonthlyCap : 0,
      budget_daily_cap: budgetDailyCap ? budgetDailyCap : 0,
      budget_weekly_cap: budgetWeeklyCap ? budgetWeeklyCap : 0,
      budget_monthly_cap: budgetMonthlyCap ? budgetMonthlyCap : 0,
      price: showPricing ? sellingPrice.toString() : "0",
      showPrice: showPricing,
      source: source,
      is_active: true,
      pricing_type: showPricing ? marginType.value : "Percentage",
      margin: showPricing ? marginVal.toString() : "0.0"
    }

    await http.post(`/api/create-supplier/${campaignId.id}/`, formData, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        toast.success('Supplier is added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setIsSaving(false);
        setTimeout(() => {
          navigate(`/create-campaign/${campaignId.id}/`)
        }, 1000)
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to create supplier!");
        setIsSaving(false);
      })
  }

  const getClients = async () => {
    await http.get("/api/clients?per_page=all", {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        let clientArr = []
        const { data } = response.data;

        data?.map(c => {
          const { user, company_name } = c;

          if (user && company_name) {
            user.roles?.map(r => {
              if (r.name === "SUPPLIER") {
                clientArr.push({
                  ...c,
                  value: c.id,
                  label: company_name[0].toUpperCase() + c.company_name.slice(1,)
                });
              }
            })
          }
        })

        setClients(clientArr);
        isLoading(false);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch clients' data!");
        isLoading(false);
      })
  }

  const getTimezones = async () => {
    await http.get("/api/timezones/", {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        let timezoneArr = [];
        response.data.data?.map(t => {
          timezoneArr.push({
            value: t.id,
            label: t.timezone_title
          })
        })
        setTimezones(timezoneArr);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch timezones' data!");
      })
  }

  const fetchCampaignDetails = async () => {
    await http
      .get(`/api/campaign-details/${campaignId.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const { campaign_title } = response.data.data;
        setCampaignTitle(campaign_title);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch campaign's detail!");
      });
  };

  useEffect(() => {
    fetchCampaignDetails();
    getClients()
    getTimezones()
  }, [])

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleFieldChange = async (e) => {
    if (e.target.name === "source") {
      setSource(e.target.value);

      if (e.target.value !== "") {
        sourceRef.current.classList.remove("error__field");
      } else {
        sourceRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "price") {
      setPrice(e.target.value);
      if (e.target.value !== "") {
        priceRef.current.classList.remove("error__field");
      } else {
        priceRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "margin__price") {
      setMarginPrice(e.target.value);
      if (e.target.value !== "") {
        marginPriceRef.current.classList.remove("error__field");
      } else {
        marginPriceRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "margin__Percentage") {
      setMarginPercentage(e.target.value);
      if (e.target.value !== "") {
        marginPercentageRef.current.classList.remove("error__field");
      } else {
        marginPercentageRef.current.classList.add("error__field");
      }
    }
  }

  return (
    <>
      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <ToastContainer />

      <div className="main main-app p-4 p-lg-5">
        <div className='flex flex-col gap-y-5'>
          {
            loading ?
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div> :
              <div className='flex flex-col gap-y-5'>
                <Card className="flex-1">
                  <Card.Header className={`flex flex-col sm:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${!skin && "bg-primary"}`}>
                    <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                      <Link to="/campaigns" className="px-2 py-2 text-white font-semibold hover:underline">Campaigns</Link>
                      <FaLongArrowAltRight />
                      {
                        campaignTitle &&
                        <Link to={`/create-campaign/${campaignId.id}/`} className="px-2 py-2 text-white font-semibold hover:underline">
                          {campaignTitle}
                        </Link>
                      }
                      <FaLongArrowAltRight />
                      <div className="px-2 py-2 font-semibold bg-white rounded-md text-stone-600">Add Supplier</div>
                    </div>

                    <div className="flex items-center gap-x-3 gap-y-3 campaign__headerBtns">
                      <Link to={`/create-campaign/${campaignId.id}/`} className="flex justify-center items-center gap-x-1.5 flex-1 sm:flex-auto font-medium px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                        <FaLongArrowAltLeft />
                        Go Back
                      </Link>
                      <button onClick={handleSupplierPost} type="button" className="font-medium flex-1 sm:flex-auto font-medium flex items-center gap-x-1 px-3 py-2 border border-white text-white rounded-md hover:bg-white hover:text-stone-700 transition-colors duration-200 ease-linear custom__btn">
                        {
                          !isSaving &&
                          <BiSave />
                        }
                        Save
                        {
                          isSaving && <Spinner animation="border" variant="light" size="sm" />
                        }
                      </button>
                    </div>
                  </Card.Header>
                </Card>

                <Card>
                  <Card.Body className="card-post">
                    <div className="flex flex-col sm:flex-row items-start justify-between gap-x-5 gap-y-3">
                      <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Supplier</Form.Label>
                        <Select
                          options={clients}
                          value={client}
                          onChange={setClient}
                          placeholder="Select supplier"
                          classNamePrefix={"my-react-select"}
                          className="w-full md:w-auto min-w-[150px]"
                          isSearchable
                          noOptionsMessage={() => "No match found."}
                        />
                      </div>
                      <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5">
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Source</Form.Label>
                        <Form.Select name="source" className="flex-1" ref={sourceRef} value={source} onChange={e => handleFieldChange(e)} aria-label="Default select example">
                          <option value="">Select a source</option>
                          <option value="EXTERNAL">External</option>
                          <option value="INTERNAL">Internal</option>
                        </Form.Select>
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body className="card-post">
                    <div className="flex flex-1 items-center gap-x-3">
                      <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-2.5">
                        <div className="flex items-center gap-x-2">
                          <div className="flex items-center flex-wrap flex-1 gap-x-3 justify-between">
                            <div className="flex items-center gap-x-1">
                              <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Pricing</Form.Label>
                              {
                                showPricing &&
                                <div data-tooltip-id="price__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                  <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                  <Tooltip id="price__info" />
                                </div>
                              }
                            </div>
                            <div onClick={() => setShowPricing(prevState => !prevState)} className={`cursor-pointer relative w-11 h-6 rounded-full ${showPricing ? "bg-success" : "bg-stone-600"} transition-colors duration-200 ease-linear`}>
                              <div className={`absolute border border-stone-6 left-0 top-0 w-6 h-6 ${skin ? "bg-stone-300" : "bg-white"} rounded-full ${showPricing ? "translate-x-full" : "translate-x-0"} transition-all duration-200 ease-linear`}></div>
                            </div>
                          </div>
                        </div>
                        {
                          showPricing &&
                          <>
                            <div className={`mt-1.5 flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                              <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Select Margin Type</Form.Label>
                              <Select
                                options={MARGIN_OPTIONS}
                                value={marginType}
                                onChange={setMarginType}
                                placeholder="Select margin type"
                                classNamePrefix={"my-react-select"}
                                className="w-full"
                                isSearchable
                                isClearable
                                noOptionsMessage={() => "No match found."}
                              />
                            </div>
                            {
                              marginType && (
                                marginType.value === "Fixed" ?
                                  <Form.Group className="flex flex-col gap-y-1.5">
                                    <div className="flex items-center gap-x-1">
                                      <Form.Label className={`font-medium mb-0 ${skin && "text-stone-400"}`}>Fixed Amount</Form.Label>
                                      <div data-tooltip-id="margin__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                        <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                        <Tooltip id="margin__info" />
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-x-1 card-post">
                                      <div className={`font-medium ${skin && "text-stone-400"}`}>$</div>
                                      <Form.Control name="margin__price" className="flex-1" ref={marginPriceRef} value={marginPrice} onChange={e => handleFieldChange(e)} type="text" placeholder="Set default price" />
                                    </div>
                                  </Form.Group> :
                                  marginType.value === "Percentage" ?
                                    <Form.Group className="flex flex-col gap-y-1.5">
                                      <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Percentage Amount</Form.Label>
                                      <div className="flex items-center gap-x-1 card-post">
                                        <Form.Control name="margin__Percentage" className="flex-1" ref={marginPercentageRef} value={marginPercentage} onChange={e => handleFieldChange(e)} type="text" placeholder="Set Percentage" />
                                        <div className={`font-medium ${skin && "text-stone-400"}`}>%</div>
                                      </div>
                                    </Form.Group> : null
                              )
                            }
                          </>
                        }
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body className="card-post">
                    <div className="flex flex-1 items-center gap-x-3">
                      <div className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${skin ? "dark__mode" : ""}`}>
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Timezone</Form.Label>
                        <Select
                          options={timezones}
                          value={timezone}
                          onChange={setTimeZone}
                          placeholder="Select timezone"
                          classNamePrefix={"my-react-select"}
                          className="w-full"
                          isSearchable
                          noOptionsMessage={() => "No match found."}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <div className="flex items-center gap-x-2">
                      <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                        Leads Volume
                      </div>
                      <div data-tooltip-id="lead__volumeInfo" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                        <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                        <Tooltip id="lead__volumeInfo" />
                      </div>
                    </div>
                  </Card.Header>

                  <Card.Body className="card-post">
                    <div className="flex flex-wrap flex-1 flex-col sm:flex-row items-center gap-3">
                      <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Daily Cap</Form.Label>
                        <Form.Control className="flex-1" value={leadVolumeDailyCap} onChange={e => setLeadVolumeDailyCap(e.target.value)} type="number" placeholder="Set daily cap" />
                      </div>
                      <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Weekly Cap</Form.Label>
                        <Form.Control className="flex-1" value={leadVolumeWeeklyCap} onChange={e => setLeadVolumeWeeklyCap(e.target.value)} type="number" placeholder="Set weekly cap" />
                      </div>
                      <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                        <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Monthly Cap</Form.Label>
                        <Form.Control className="flex-1" value={leadVolumeMonthlyCap} onChange={e => setLeadVolumeMonthlyCap(e.target.value)} type="number" placeholder="Set monthly cap" />
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                {/* <Card>
                              <Card.Header>
                                <div className="flex items-center gap-x-2">
                                  <div className={`text-lg font-medium ${skin && "text-stone-400"}`}>
                                    Budget
                                  </div>
                                  <div data-tooltip-id="budget__info" data-tooltip-content="If it sets to 0, this will be infinity." className="cursor-pointer">
                                    <IoMdInformationCircleOutline className={`text-xl ${skin && "text-stone-500"}`} />
                                    <Tooltip id="budget__info" />
                                  </div>
                                </div>
                              </Card.Header>
                
                              <Card.Body className="card-post">
                                <div className="flex flex-wrap flex-1 flex-col sm:flex-row items-center gap-3">
                                  <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                                    <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Daily Cap</Form.Label>
                                    <Form.Control className="flex-1" value={budgetDailyCap} onChange={e => setBudgetDailyCap(e.target.value)} type="number" placeholder="Set daily cap" />
                                  </div>
                                  <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                                    <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Weekly Cap</Form.Label>
                                    <Form.Control className="flex-1" value={budgetWeeklyCap} onChange={e => setBudgetWeeklyCap(e.target.value)} type="number" placeholder="Set weekly cap" />
                                  </div>
                                  <div className="flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 card-post">
                                    <Form.Label className={`font-medium ${skin && "text-stone-400"}`}>Monthly Cap</Form.Label>
                                    <Form.Control className="flex-1" value={budgetMonthlyCap} onChange={e => setBudgetMonthlyCap(e.target.value)} type="number" placeholder="Set monthly cap" />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card> */}
              </div>
          }

        </div>
      </div>
    </>
  );
}
