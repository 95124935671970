import { Outlet, Navigate } from "react-router-dom"
import { useAuth } from "../../Context/AuthContext";


const PrivateRoute = () => {
    const [authData, setAuthData] = useAuth();

    return authData ? <Outlet /> : <Navigate to="/signin" />
}

export default PrivateRoute