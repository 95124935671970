import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthUtils from "../Utils/AuthUtils";
import { MdDeleteOutline } from "react-icons/md";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Form, Card, Table, Spinner, Dropdown } from "react-bootstrap";
import moment from "moment";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FaRegEye } from "react-icons/fa6";
import { formattedDate } from "../Utils/formatDate";
import { FaDownload, FaRegCalendarAlt } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import { endOfMonth, startOfMonth, subYears } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../assets/css/Leads.css";
import { FaCheck } from "react-icons/fa6";
import LeadDeleteConfirmModal from "../../Components/Leads/LeadDeleteConfirmModal";
import LeadDetailModal from "../../Components/Leads/LeadDetailModal";
import LeadExportColumn from "../../Components/Leads/LeadExportColumn";
import { PAGE_LIMIT_SIZE } from "../Utils/baseConfig";
import { useAuth } from "../../Context/AuthContext";

const BUYER_TYPES = [
  {
    value: "CPA",
    label: "CPA"
  },
  {
    value: "CPL",
    label: "CPL"
  }
]

export default function Leads() {
  const { http, logout } = AuthUtils();
  const [authData, setAuthData] = useAuth();

  const [campaigns, setCampaigns] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [leadsByCampaign, setLeadsByCampaign] = useState([]);
  const [leadPayload, setLeadPayload] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [trafficSources, setTrafficSources] = useState([]);
  const [isTest, setIsTest] = useState("");
  const [buyerType, setBuyerType] = useState("");

  const [trafficSourceData, setTrafficSourceData] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [isDeleteAllLeads, setIsDeleteAllLeads] = useState(false);
  const [showExportColumns, setShowExportColumns] = useState(false);

  const [listOfHeaders, setListOfHeaders] = useState([]);
  const [listOfHeadersKey, setListOfHeadersKey] = useState([]);
  const [isExportColumnCustomized, setIsExportColumnCustomized] = useState(false);
  const [leadOnPageLoad, setLeadOnPageLoad] = useState(true);

  const [status, setStatus] = useState("");

  const dateRangeRef = useRef();
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection"
    }
  ]);
  const [dateRangeDirection, setDateRangeDirection] = useState("horizontal");
  const [showDateRange, setShowDateRange] = useState(false);

  const [source, setSource] = useState("");
  const [totalLeads, setTotalLeads] = useState(0);

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isFetchingBuyerSupplier, setIsFetchingBuyerSupplier] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [formError, setFormError] = useState({ errorId: -1 });

  const [exportLeadCampaigns, setExportLeadCampaigns] = useState([]);
  const [isSearchingLead, setIsSearchingLead] = useState(false);
  const [deletedLeads, setDeletedLeads] = useState([[]]);
  const [totalDeletedLeads, setTotalDeletedLeads] = useState(0);
  const [showExportLeadButton, setShowExportLeadButton] = useState(true);
  const [leadFormData, setLeadFormData] = useState(null);

  const [hasAccess, setHasAccess] = useState({
    canViewBuyerFilter: false,
    canViewSupplierFilter: false,
    canViewSourceFilter: false,
    canViewTrafficSourceFilter: false
  });

  const searchTextRef = useRef();

  const showFormError = ({ errId }) => {
    setFormError({
      errorId: errId
    });

    setLoading(false);
  };

  // const fetchCampaigns = async () => {
  //   await http
  //     .get("/api/all-campaigns/", {
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     })
  //     .then((response) => {
  //       let campaignArr = [];
  //       response.data.data?.map((c) => {
  //         campaignArr.push({
  //           value: c.id,
  //           label: c.campaign_title
  //         });
  //       });
  //       setCampaigns(campaignArr);
  //       setIsFetchingData(false);
  //     })
  //     .catch((err) => {
  //       const { response } = err;

  //       if (response) {
  //         const { data } = response;
  //         if (data) {
  //           if (data.message === "Unauthenticated.") {
  //             logout();
  //           }
  //         }
  //       }
  //       toast.error("Failed to fetch campaigns' data!");
  //       setIsFetchingData(false);
  //     });
  // };

  const fetchBuyers = async (campaignId) => {
    setBuyer(null);

    if (campaignId) {
      await http
        .get(`/api/buyers-by-campaign/${campaignId}/`, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          let buyerArr = [];
          res.data.data?.map((b) => {
            if (b.client) {
              buyerArr.push({
                value: b?.id,
                label:
                  `${b.client?.company_name[0].toUpperCase()}${b.client?.company_name.slice(1)} ${b?.campaign ? `- (${b.campaign?.campaign_title})` : ""}`
              });
            }
          });
          setBuyers(buyerArr);
        })
        .catch((err) => {
          const { response } = err;

          if (response) {
            const { data } = response;
            if (data) {
              if (data.message === "Unauthenticated.") {
                logout();
              }
            }
          }
          toast.error("Failed to fetch buyers' data!");
          setIsFetchingBuyerSupplier(false);
        });
    } else {
      await http
        .get(`/api/buyers-by-campaign/`, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          let buyerArr = [];
          res.data.data?.map((b) => {
            if (b.client) {
              buyerArr.push({
                value: b.id,
                label:
                  `${b.client?.company_name[0].toUpperCase()}${b.client?.company_name.slice(1)} ${b?.campaign ? `- (${b.campaign?.campaign_title})` : ""}`
              });
            }
          });
          setBuyers(buyerArr);
        })
        .catch((err) => {
          const { response } = err;

          if (response) {
            const { data } = response;
            if (data) {
              if (data.message === "Unauthenticated.") {
                logout();
              }
            }
          }
          toast.error("Failed to fetch buyers' data!");
          setIsFetchingBuyerSupplier(false);
        });
    }
  };

  const fetchSuppliers = async (campaignId) => {
    setSupplier(null);

    if (campaignId) {
      await http
        .get(`/api/suppliers-by-campaign/${campaignId}/`, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          let supplierArr = [];
          res.data.data?.map((s) => {
            if (s.client) {
              supplierArr.push({
                value: s?.id,
                label:
                  `${s.client?.company_name[0].toUpperCase()}${s.client?.company_name.slice(1)} ${s?.campaign ? `- (${s.campaign?.campaign_title})` : ""}`
              });
            }
          });
          setSuppliers(supplierArr);
        })
        .catch((err) => {
          const { response } = err;

          if (response) {
            const { data } = response;
            if (data) {
              if (data.message === "Unauthenticated.") {
                logout();
              }
            }
          }
          toast.error("Failed to fetch suppliers' data!");
          setIsFetchingBuyerSupplier(false);
        });
    } else {
      await http
        .get(`/api/suppliers-by-campaign/`, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          let supplierArr = [];
          res.data.data?.map((s) => {
            if (s.client) {
              supplierArr.push({
                value: s.id,
                label:
                  `${s.client?.company_name[0].toUpperCase()}${s.client?.company_name.slice(1)} ${s?.campaign ? `- (${s.campaign?.campaign_title})` : ""}`
              });
            }
          });
          setSuppliers(supplierArr);
        })
        .catch((err) => {
          const { response } = err;

          if (response) {
            const { data } = response;
            if (data) {
              if (data.message === "Unauthenticated.") {
                logout();
              }
            }
          }
          toast.error("Failed to fetch suppliers' data!");
          setIsFetchingBuyerSupplier(false);
        });
    }
  };

  const makeExportLeads = async () => {
    setExportLeadCampaigns([]);

    setShowExportColumns(false);
    setShowExportLeadButton(false);

    const searchText = searchTextRef.current?.value?.trim() ?? "";

    let startDate = "", endDate = "",
        fromDate = "", toDate = "";

    if (leadOnPageLoad) {
      startDate = new Date().setFullYear(2022);
      endDate = dateRange[0].endDate
    } else {
      startDate = dateRange[0].startDate;
      endDate = dateRange[0].endDate;
    }

    if (startDate) {
      fromDate = formattedDate(new Date(startDate));
    }
    if (endDate) {
      toDate = formattedDate(new Date(endDate));
    }

    const formData = {
      search: searchText,
      campaign_id: campaign?.value,
      status: status,
      buyer: buyer ? buyer.value : "",
      supplier: supplier ? supplier.value : "",
      traffic_source_id: trafficSourceData ? trafficSourceData.value : "",
      lead_date_from: fromDate,
      lead_date_to: toDate,
      is_test: isTest ? Boolean(isTest) : "",
      source: source,
      buyer_type: buyerType ? buyerType.value : "",
    }

    try {
      const res = await http.post("/api/leads/?per_page=all", formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { data } = res.data;

        let headers = [], headerKeyArr = [], exportedLeadsArr = [];

          data?.map(r => {
            const { payload, campaign, buyer_response_log } = r;

            if (payload) {
              if (!headerKeyArr.includes("lead_id_datahubb")) {
                headers.push({
                  key: "lead_id_datahubb",
                  active: true
                });
                headerKeyArr.push("lead_id_datahubb");
              }
              if (!headerKeyArr.includes("lead_id_buyer")) {
                headers.push({
                  key: "lead_id_buyer",
                  active: true
                });
                headerKeyArr.push("lead_id_buyer");
              }
              if (!headerKeyArr.includes("lead_created_at_datahubb")) {
                headers.push({
                  key: "lead_created_at_datahubb",
                  active: true
                });
                headerKeyArr.push("lead_created_at_datahubb");
              }
              if (!headerKeyArr.includes("campaign_id")) {
                headers.push({
                  key: "campaign_id",
                  active: true
                });
                headerKeyArr.push("campaign_id");
              }
              if (!headerKeyArr.includes("campaign_name")) {
                headers.push({
                  key: "campaign_name",
                  active: true
                });
                headerKeyArr.push("campaign_name")
              }
              Object.keys(payload).forEach((key) => {
                if (!headerKeyArr.includes(key)) {
                  headers.push({
                    key: key,
                    active: true
                  });
                  headerKeyArr.push(key);
                }
              })
              if (!headerKeyArr.includes("supplier_id")) {
                headers.push({
                  key: "supplier_id",
                  active: true
                });
                headerKeyArr.push("supplier_id");
              }
              if (!headerKeyArr.includes("supplier_company_name")) {
                headers.push({
                  key: "supplier_company_name",
                  active: true
                });
                headerKeyArr.push("supplier_company_name");
              }
              if (!headerKeyArr.includes("buyer_id")) {
                headers.push({
                  key: "buyer_id",
                  active: true
                });
                headerKeyArr.push("buyer_id");
              }
              if (!headerKeyArr.includes("buyer_company_name")) {
                headers.push({
                  key: "buyer_company_name",
                  active: true
                });
                headerKeyArr.push("buyer_company_name");
              }
              if (!headerKeyArr.includes("buyer_type")) {
                headers.push({
                  key: "buyer_type",
                  active: true
                });
                headerKeyArr.push("buyer_type");
              }
              if (!headerKeyArr.includes("status")) {
                headers.push({
                  key: "status",
                  active: true
                });
                headerKeyArr.push("status");
              }
              if (!headerKeyArr.includes("is_repost")) {
                headers.push({
                  key: "is_repost",
                  active: true
                });
                headerKeyArr.push("is_repost");
              }
              if (!headerKeyArr.includes("is_test")) {
                headers.push({
                  key: "is_test",
                  active: true
                });
                headerKeyArr.push("is_test");
              }
              if (!headerKeyArr.includes("lead_sold_price")) {
                headers.push({
                  key: "lead_sold_price",
                  active: true
                });
                headerKeyArr.push("lead_sold_price");
              }
              if (!headerKeyArr.includes("source_payout")) {
                headers.push({
                  key: "source_payout",
                  active: true
                });
                headerKeyArr.push("source_payout");
              }
              if (!headerKeyArr.includes("rejected_reason")) {
                headers.push({
                  key: "rejected_reason",
                  active: true
                });
                headerKeyArr.push("rejected_reason");
              }
              if (!headerKeyArr.includes("fraud_score")) {
                headers.push({
                  key: "fraud_score",
                  active: true
                });
                headerKeyArr.push("fraud_score");
              }
              if (!headerKeyArr.includes("lead_health_percentage")) {
                headers.push({
                  key: "lead_health_percentage",
                  active: true
                });
                headerKeyArr.push("lead_health_percentage");
              }
              if (!headerKeyArr.includes("buyer_price")) {
                headers.push({
                  key: "buyer_price",
                  active: true
                });
                headerKeyArr.push("buyer_price");
              }
              if (!headerKeyArr.includes("buyer_source_payout")) {
                headers.push({
                  key: "buyer_source_payout",
                  active: true
                });
                headerKeyArr.push("buyer_source_payout");
              }
              if (!headerKeyArr.includes("buyer_sold")) {
                headers.push({
                  key: "buyer_sold",
                  active: true
                });
                headerKeyArr.push("buyer_sold");
              }
              if (!headerKeyArr.includes("buyer_rejected_reason")) {
                headers.push({
                  key: "buyer_rejected_reason",
                  active: true
                });
                headerKeyArr.push("buyer_rejected_reason");
              }
            }

            if (campaign) {
              r["campaign_id"] = campaign.id;
              r["campaign_title"] = campaign.campaign_title;
            }

            // Get buyer lead id
            let buyerLeadId = "";
            if (buyer_response_log) {
              buyerLeadId = buyer_response_log?.lead_id || buyer_response_log?.leadId || buyer_response_log?.lead__id || buyer_response_log?.LeadId;
            }

            r["lead_id_buyer"] = buyerLeadId;

            exportedLeadsArr.push(r);
          })

        setListOfHeaders(headers);
        setListOfHeadersKey(headerKeyArr);
        setExportLeadCampaigns(exportedLeadsArr);
        setShowExportLeadButton(true);
        setShowExportColumns(true);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to export lead data!");
      setShowExportLeadButton(true);
      setShowExportColumns(false);
    }
  }

  const filterLeadsByCampaign = async ({ selPage, startDate, endDate }) => {
    showFormError({ errId: -1 });

    // setCampaign(null);
    setLoading(true);

    if (searchTextRef.current) {
      searchTextRef.current.value = "";
    }
    setIsSearchingLead(false);

    setLeadsByCampaign([]);
    setExportLeadCampaigns([]);

    let fromDate = "", toDate = "";

    if (!startDate && !endDate && dateRange.length > 0) {
      startDate = dateRange[0].startDate;
      endDate = dateRange[0].endDate;
    } else {
      startDate = subYears(new Date(), 1);
      endDate = dateRange[0].endDate;
    }

    if (startDate) {
      fromDate = formattedDate(new Date(startDate));
    }
    if (endDate) {
      toDate = formattedDate(new Date(endDate));
    }

    const formData = {
      campaign_id: campaign?.value,
      status: status,
      buyer: buyer ? buyer.value : "",
      supplier: supplier ? supplier.value : "",
      traffic_source_id: trafficSourceData ? trafficSourceData.value : "",
      lead_date_from: fromDate,
      lead_date_to: toDate,
      is_test: isTest ? Boolean(isTest) : "",
      source: source,
      buyer_type: buyerType ? buyerType.value : "",
    }

    setLeadFormData(formData);

    await http
      .post(
        `/api/leads/?page=${selPage}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(async (response) => {
        const { data, total } = response.data;

        setLeadsByCampaign(data);
        setTotalLeads(total);
        setLoading(false);

        if (campaign && campaign.value) {
          await fetchCampaign(campaign.value);
        }
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch leads' data!");
        setLoading(false);
        setLeadsByCampaign([]);
      });
  };

  const fetchCampaign = async (campId) => {
    await http
      .get(`/api/campaign-details/${campId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const { id, campaign_title } = response.data.data;
        setCampaign({
          value: id,
          label: campaign_title
        });
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch campaign's data!");
      });
  };

  const getLeadDetails = async (lead) => {
    setLoading(true);
    setLeadPayload("");

    await http
      .get(`/api/lead-details/${lead.id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        setShowModal(true);
        setLeadPayload(response.data.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        toast.error("Failed to fetch lead details!");

        setLoading(false);
      });

    setLoading(false);
  };

  const convertToCSV = () => {
    let csvData = [];
    const headers = new Set();

    listOfHeadersKey.includes("lead_id_datahubb") && headers.add("lead_id_datahubb");
    listOfHeadersKey.includes("lead_id_buyer") && headers.add("lead_id_buyer");
    listOfHeadersKey.includes("lead_created_at_datahubb") && headers.add("lead_created_at_datahubb");

    // Extracting all keys from payload
    exportLeadCampaigns.forEach((lead) => {
      const rawPayload = lead.payload;
      let parsedPayload = [];

      if (rawPayload) {
        parsedPayload = lead.payload;

        listOfHeadersKey.includes("campaign_id") && headers.add("campaign_id");
        listOfHeadersKey.includes("campaign_name") && headers.add("campaign_name");
        Object.keys(parsedPayload).forEach((key) => listOfHeadersKey.includes(key) && headers.add(key));
        listOfHeadersKey.includes("supplier_id") && headers.add("supplier_id");
        listOfHeadersKey.includes("supplier_company_name") && headers.add("supplier_company_name");
        listOfHeadersKey.includes("buyer_id") && headers.add("buyer_id");
        listOfHeadersKey.includes("buyer_company_name") && headers.add("buyer_company_name");
        listOfHeadersKey.includes("buyer_type") && headers.add("buyer_type");
        listOfHeadersKey.includes("status") && headers.add("status");
        listOfHeadersKey.includes("is_repost") && headers.add("is_repost");
        listOfHeadersKey.includes("is_test") && headers.add("is_test");
        listOfHeadersKey.includes("lead_sold_price") && headers.add("lead_sold_price");
        listOfHeadersKey.includes("source_payout") && headers.add("source_payout");
        listOfHeadersKey.includes("rejected_reason") && headers.add("rejected_reason");
        listOfHeadersKey.includes("fraud_score") && headers.add("fraud_score");
        listOfHeadersKey.includes("lead_health_percentage") && headers.add("lead_health_percentage");
      }

      let responseLog = null, buyerArr = [];

      if (lead.response_log) {
        responseLog = lead.response_log;

        if (responseLog.direct_posts) {
          Object.entries(responseLog.direct_posts).map(([k, v]) => {
            buyerArr.push(k);
          })

          buyerArr?.map(b => {
            // headers.add(`${b}_key`);
            // headers.add(`${b}_condition`);
            // headers.add(`${b}_value`);
            // headers.add(`${b}_result`);
            listOfHeadersKey.includes("buyer_price") && headers.add(`${b}_price`);
            listOfHeadersKey.includes("buyer_source_payout") && headers.add(`${b}_source_payout`);
            // headers.add(`${b}_payout_by_filter`);
            listOfHeadersKey.includes("buyer_sold") && headers.add(`${b}_sold`);
            // headers.add(`${b}_passed`);
            listOfHeadersKey.includes("buyer_rejected_reason") && headers.add(`${b}_rejected_reason`);
          })
        }
      }
    });

    // pushing inside headers..
    csvData.push([...headers]);

    // const headerArr = [];
    // headers.forEach(h => {
    //   headerArr.push({
    //     title: h,
    //     show: true
    //   })
    // });
    // setlistOfHeaders(headerArr);

    // mapping the data to csv rows
    exportLeadCampaigns.forEach((lead) => {
      let csvRow = [], parsedPayload = [];
      const rawPayload = lead.payload;

      if (rawPayload) {
        parsedPayload = lead.payload;
      }

      let responseLog = null, buyerArr = [];

      if (lead.response_log) {
        responseLog = lead.response_log;

        if (responseLog.direct_posts) {
          Object.entries(responseLog.direct_posts).map(([k, v]) => {
            buyerArr.push(k);
          })
        }
      }

      // Generating csv rows
      headers.forEach((header) => {
        const foundHeaderIndex = csvData[0].findIndex(item => item === header);

        if (foundHeaderIndex !== -1 && listOfHeadersKey.includes(csvData[0][foundHeaderIndex])) {
          csvRow[foundHeaderIndex] = parsedPayload[csvData[0][foundHeaderIndex]] || "";
        }
        switch (header) {
          case "enrich_data":
            if (listOfHeadersKey.includes("enrich_data")) {
              csvRow[foundHeaderIndex] = JSON.stringify(csvRow[foundHeaderIndex]);
            }
            break;
          case "lead_id_datahubb":
            if (listOfHeadersKey.includes("lead_id_datahubb")) {
              csvRow[foundHeaderIndex] = lead.lead_id;
            }
            break;
          case "lead_id_buyer":
            if (listOfHeadersKey.includes("lead_id_buyer")) {
              csvRow[foundHeaderIndex] = lead.lead_id_buyer;
            }
            break;
          case "lead_created_at_datahubb":
            if (listOfHeadersKey.includes("lead_created_at_datahubb")) {
              csvRow[foundHeaderIndex] = lead.created_at;
            }
            break;
          case "campaign_id":
            if (listOfHeadersKey.includes("campaign_id")) {
              csvRow[foundHeaderIndex] = lead.campaign_id;
            }
            break;
          case "campaign_name":
            if (listOfHeadersKey.includes("campaign_name")) {
              csvRow[foundHeaderIndex] = lead.campaign_title;
            }
            break;
          case "supplier_id":
            if (listOfHeadersKey.includes("supplier_id")) {
              csvRow[foundHeaderIndex] = lead.generated_by?.id;
            }
            break;
          case "supplier_company_name":
            if (listOfHeadersKey.includes("supplier_company_name")) {
              csvRow[foundHeaderIndex] = lead.generated_by?.client?.company_name;
            }
            break;
          case "buyer_id":
            if (listOfHeadersKey.includes("buyer_id")) {
              csvRow[foundHeaderIndex] = lead.sold_to?.id;
            }
            break;
          case "buyer_company_name":
            if (listOfHeadersKey.includes("buyer_company_name")) {
              csvRow[foundHeaderIndex] = lead.sold_to?.client?.company_name;
            }
            break;
          case "buyer_type":
            if (listOfHeadersKey.includes("buyer_type")) {
              csvRow[foundHeaderIndex] = lead.sold_to?.buyer_type;
            }
            break;
          case "traffic_source":
            if (listOfHeadersKey.includes("traffic_source")) {
              const ts = lead.traffic_source && `${lead.traffic_source?.client?.company_name[0]?.toUpperCase()}${lead.traffic_source?.client?.company_name?.slice(
                1
              )}${lead.traffic_source?.traffic_channel?.channel_name
                ? " - " + lead.traffic_source?.traffic_channel?.channel_name
                : ""
                } (${lead.traffic_source?.id})` || parsedPayload[header] || ""
              csvRow[foundHeaderIndex] = ts;
            }
            break;
          case "status":
            if (listOfHeadersKey.includes("status")) {
              csvRow[foundHeaderIndex] = lead.status;
            }
            break;
          case "is_repost":
            if (listOfHeadersKey.includes("is_repost")) {
              csvRow[foundHeaderIndex] = lead.is_repost;
            }
            break;
          case "is_test":
            if (listOfHeadersKey.includes("is_test")) {
              csvRow[foundHeaderIndex] = lead.is_test;
            }
            break;
          case "source_payout":
            if (listOfHeadersKey.includes("source_payout")) {
              csvRow[foundHeaderIndex] = lead.source_payout;
            }
            break;
          case "lead_sold_price":
            if (listOfHeadersKey.includes("lead_sold_price")) {
              csvRow[foundHeaderIndex] = lead.lead_sold_price;
            }
            break;
          case "fraud_score":
            if (listOfHeadersKey.includes("fraud_score")) {
              csvRow[foundHeaderIndex] = lead.fraud_score;
            }
            break;
          case "lead_health_percentage":
            if (listOfHeadersKey.includes("lead_health_percentage")) {
              csvRow[foundHeaderIndex] = lead.lead_health_percentage;
            }
            break;
          case "rejected_reason":
            if (listOfHeadersKey.includes("rejected_reason")) {
              if (responseLog) {
                csvRow[foundHeaderIndex] = responseLog.error || responseLog.reason || "";
              }
            }
            break;
          default:
            if (responseLog) {
              buyerArr?.map(b => {
                if (responseLog.direct_posts) {
                  const buyerPost = responseLog.direct_posts?.[b];
                  const phoneFormatError = responseLog?.phone_format_error;

                  const buyerPriceIndex = csvData[0].findIndex(item => item === `${b}__price`);
                  const buyerSourcePayoutIndex = csvData[0].findIndex(item => item === `${b}_source_payout`);
                  const buyerSoldIndex = csvData[0].findIndex(item => item === `${b}_sold`);
                  const buyerRejectedReasonIndex = csvData[0].findIndex(item => item === `${b}_rejected_reason`);
                  const phoneFormatErrorindex = csvData[0].findIndex(item => item === `${b}_phone_error_format`);

                  if (buyerPriceIndex !== -1) {
                    csvRow[buyerPriceIndex] = buyerPost?.price || "";
                  }
                  if (buyerSourcePayoutIndex !== -1) {
                    csvRow[buyerSourcePayoutIndex] = buyerPost?.source_payout || "";
                  }
                  if (buyerSoldIndex) {
                    csvRow[buyerSoldIndex] = buyerPost.sold ? true : false;
                  }
                  if (buyerRejectedReasonIndex !== -1) {
                    let errorMsg = "";

                    if (!buyerPost.sold) {
                      if (buyerPost.api_reject) {
                        const responseMsg = buyerPost?.response;
                        errorMsg = responseMsg?.reason ? responseMsg?.reason : responseMsg?.errors ? responseMsg?.errors?.[0]?.error : responseMsg?.messages ? responseMsg?.messages[0]?.message : responseMsg?.error ? responseMsg?.error : responseMsg?.message ? responseMsg?.message : responseMsg.code ? `Code ${responseMsg.code}` : "";
                      } else if (buyerPost.duplicate) {
                        errorMsg = "Duplicate";
                      } else if (phoneFormatError) {
                        errorMsg = "Wrong phone format!";
                      } else {
                        errorMsg = "Filter not matched";
                      }
                    }
                    csvRow[buyerRejectedReasonIndex] = errorMsg;
                  }

                  if (phoneFormatErrorindex !== -1) {
                    const errorMsg = "Wrong phone format!";
                    csvRow.push(errorMsg);
                  }
                } else if (responseLog?.supplier_filters?.as) {
                  const buyerPost = responseLog.direct_posts?.[b];
                  const phoneFormatError = responseLog?.phone_format_error;

                  const buyerPriceIndex = csvData[0].findIndex(item => item === `${b}__price`);
                  const buyerSourcePayoutIndex = csvData[0].findIndex(item => item === `${b}_source_payout`);
                  const buyerSoldIndex = csvData[0].findIndex(item => item === `${b}_sold`);
                  const buyerRejectedReasonIndex = csvData[0].findIndex(item => item === `${b}_rejected_reason`);
                  const phoneFormatErrorindex = csvData[0].findIndex(item => item === `${b}_phone_error_format`);

                  if (buyerPriceIndex !== -1) {
                    csvRow[buyerPriceIndex] = buyerPost?.price || "";
                  }
                  if (buyerSourcePayoutIndex !== -1) {
                    csvRow[buyerSourcePayoutIndex] = buyerPost?.source_payout || "";
                  }
                  if (buyerSoldIndex) {
                    csvRow[buyerSoldIndex] = buyerPost.sold ? true : false;
                  }
                  if (buyerRejectedReasonIndex !== -1) {
                    let errorMsg = "";

                    if (!buyerPost.sold) {
                      if (buyerPost.api_reject) {
                        const responseMsg = buyerPost?.response;
                        errorMsg = responseMsg?.reason ? responseMsg?.reason : responseMsg?.errors ? responseMsg?.errors?.[0]?.error : responseMsg?.messages ? responseMsg?.messages[0]?.message : responseMsg?.error ? responseMsg?.error : responseMsg?.message ? responseMsg?.message : responseMsg.code ? `Code ${responseMsg.code}` : "";
                      } else if (buyerPost.duplicate) {
                        errorMsg = "Duplicate";
                      } else if (phoneFormatError) {
                        errorMsg = "Wrong phone format!";
                      } else {
                        errorMsg = "Filter not matched";
                      }
                    }
                    csvRow[buyerRejectedReasonIndex] = errorMsg;
                  }
                  if (phoneFormatErrorindex !== -1) {
                    const errorMsg = "Wrong phone format!";
                    csvRow.push(errorMsg);
                  }
                } else if (responseLog?.traffic_source_filters?.as) {
                  const buyerPost = responseLog.direct_posts?.[b];
                  const phoneFormatError = responseLog?.phone_format_error;

                  const buyerPriceIndex = csvData[0].findIndex(item => item === `${b}__price`);
                  const buyerSourcePayoutIndex = csvData[0].findIndex(item => item === `${b}_source_payout`);
                  const buyerSoldIndex = csvData[0].findIndex(item => item === `${b}_sold`);
                  const buyerRejectedReasonIndex = csvData[0].findIndex(item => item === `${b}_rejected_reason`);
                  const phoneFormatErrorindex = csvData[0].findIndex(item => item === `${b}_phone_error_format`);

                  if (buyerPriceIndex !== -1) {
                    csvRow[buyerPriceIndex] = buyerPost?.price || "";
                  }
                  if (buyerSourcePayoutIndex !== -1) {
                    csvRow[buyerSourcePayoutIndex] = buyerPost?.source_payout || "";
                  }
                  if (buyerSoldIndex) {
                    csvRow[buyerSoldIndex] = buyerPost.sold ? true : false;
                  }
                  if (buyerRejectedReasonIndex !== -1) {
                    let errorMsg = "";

                    if (!buyerPost.sold) {
                      if (buyerPost.api_reject) {
                        const responseMsg = buyerPost?.response;
                        errorMsg = responseMsg?.reason ? responseMsg?.reason : responseMsg?.errors ? responseMsg?.errors?.[0]?.error : responseMsg?.messages ? responseMsg?.messages[0]?.message : responseMsg?.error ? responseMsg?.error : responseMsg?.message ? responseMsg?.message : responseMsg.code ? `Code ${responseMsg.code}` : "";
                      } else if (buyerPost.duplicate) {
                        errorMsg = "Duplicate";
                      } else if (phoneFormatError) {
                        errorMsg = "Wrong phone format!";
                      } else {
                        errorMsg = "Filter not matched";
                      }
                    }
                    csvRow[buyerRejectedReasonIndex] = errorMsg;
                  }
                  if (phoneFormatErrorindex !== -1) {
                    const errorMsg = "Wrong phone format!";
                    csvRow.push(errorMsg);
                  }
                }
              })
            }
        }
        // console.log(headers);

        // console.log(csvRow);
      });

      // csvData.push([...headers]);
      csvData.push(csvRow);
    });

    return csvData;
  };

  // deleting leads
  const handleDeleteLeads = async (leadId) => {
    try {
      let response = await http.delete(`/api/delete-lead/${leadId}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.status === 200) {
        if (leadOnPageLoad) {
          filterLeadsByCampaign({
            selPage: page,
            startDate: subYears(new Date(), 1),
            endDate: dateRange[0].endDate
          });
        } else {
          filterLeadsByCampaign({
            selPage: page
          });
        }
        toast.success("Lead is removed successfully!");
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error(`Failed to delete lead-${leadId}!`);
    }
  };

  const actionOnWindowSize = () => {
    if (window.innerWidth > 640) {
      setDateRangeDirection("horizontal");
    } else {
      setDateRangeDirection("vertical");
    }
  };

  const windowResize = () => {
    actionOnWindowSize();
  };

  const getTrafficSources = async (campId) => {
    if (campId) {
      try {
        const res = await http.get(`/api/traffic-sources-by-campaign/${campId}/`, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (res.status === 200) {
          const { data } = res.data;

          let tsArr = [];

          data?.map((ts) => {
            if (ts.client && ts.traffic_channel) {
              const tsLabel = `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1)}${ts.traffic_channel?.channel_name ? " - " + ts.traffic_channel?.channel_name : ""} (${ts?.id})`;

              tsArr.push({
                ...ts,
                value: ts?.id,
                label: tsLabel
              });
            }
          });
          setTrafficSources(tsArr);
        }
      } catch (err) {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        // toast.error("Failed to fetch traffic sources!");
      }
    } else {
      try {
        const res = await http.get(`/api/traffic-sources-by-campaign/`, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        // console.log(res.data);

        if (res.status === 200) {
          const { data } = res.data;

          let tsArr = [];

          data?.map((ts) => {
            if (ts.client && ts.traffic_channel) {
              const tsLabel = `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1)}${ts.traffic_channel?.channel_name ? " - " + ts.traffic_channel?.channel_name : ""} (${ts?.id})`;

              tsArr.push({
                ...ts,
                value: ts?.id,
                label: tsLabel
              });
            }
          });
          setTrafficSources(tsArr);
        }
      } catch (err) {
        const { response } = err;

        if (response) {
          const { data } = response;
          if (data) {
            if (data.message === "Unauthenticated.") {
              logout();
            }
          }
        }
        // toast.error("Failed to fetch traffic sources!");
      }
    }
  };

  const handleRoleBasedPermission = () => {
    const { rolenames } = authData;

    let hasAccessData = JSON.parse(JSON.stringify(hasAccess));

    if (rolenames.includes("SUPER ADMIN") || rolenames.includes("ADMIN") || rolenames.includes("DEVELOPER")) {
      hasAccessData.canViewBuyerFilter = true;
      hasAccessData.canViewSupplierFilter = true;
      hasAccessData.canViewSourceFilter = true;
      hasAccessData.canViewTrafficSourceFilter = true;
    }
    // else if (rolenames.includes("TRAFFIC SOURCE")) {
    //   hasAccessData.canViewTrafficSourceFilter = true;
    // } else if (rolenames.includes("BUYER")) {
    //   hasAccessData.canViewBuyerFilter = true;
    // } else if (rolenames.includes("SUPPLIER")) {
    //   hasAccessData.canViewSupplierFilter = true;
    // }

    setHasAccess(hasAccessData);
  }

  const fetchUserRecords = async () => {
    try {
      const res = await http.get("/api/user-records", {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.status === 200) {
        const { campaigns, buyers, suppliers, traffic_sources } = res.data.data;

        if (campaigns?.length) {
          let campaignArr = [];

          campaigns.map(c => {
            campaignArr.push({
              value: c.id,
              label: c.campaign_title
            });
          })

          setCampaigns(campaignArr);
        }

        if (buyers?.length) {
          let buyerArr = [];

          buyers.map(b => {
            if (b.client) {
              buyerArr.push({
                value: b.id,
                label: `${b.client?.company_name[0].toUpperCase()}${b.client?.company_name.slice(1)} ${b?.campaign ? `- (${b.campaign?.campaign_title})` : ""}`
              });
            }
          });

          setBuyers(buyerArr);
        }

        if (suppliers?.length) {
          let supplierArr = [];

          suppliers.map(s => {
            if (s.client) {
              supplierArr.push({
                value: s.id,
                label:
                  `${s.client?.company_name[0].toUpperCase()}${s.client?.company_name.slice(1)} ${s?.campaign ? `- (${s.campaign?.campaign_title})` : ""}`
              });
            }
          });

          setSuppliers(supplierArr)
        }

        if (traffic_sources?.length) {
          let tsArr = [], tsLabelArr = [];

          traffic_sources.map(ts => {
            if (ts.client && ts.traffic_channel) {
              const tsLabel = `${ts.client?.company_name[0]?.toUpperCase()}${ts.client?.company_name?.slice(1)}${ts.traffic_channel?.channel_name ? " - " + ts.traffic_channel?.channel_name : ""} (${ts?.id})`;

              if (!tsLabelArr.includes(tsLabel.toLowerCase())) {
                tsArr.push({
                  ...ts,
                  value: ts?.id,
                  label: tsLabel
                });
                tsLabelArr.push(tsLabel.toLowerCase());
              }
            }
          })

          setTrafficSources(tsArr);
        }

        setIsFetchingData(false);
        setIsFetchingBuyerSupplier(false);
      }
    } catch (err) {
      // toast.error("Failed to fetch leads data!");
      setIsFetchingData(false);
      setIsFetchingBuyerSupplier(false);
    }
  }

  useEffect(() => {
    if (authData) {
      handleRoleBasedPermission();
      fetchUserRecords();
      actionOnWindowSize();
      handleLeadPage(1);
    }

    window.addEventListener("resize", windowResize);

    return () => {
      setTotalLeads(0);
      setPage(1);
    };
  }, [authData]);


  const dateRangeEventListener = (e) => {
    if (dateRangeRef.current && dateRangeRef.current.contains(e.target)) return;
    handleDateRangeChangeState(false);
  };

  const handleDateRangeChangeState = (show) => {
    setShowDateRange(show);

    show
      ? document.addEventListener("click", dateRangeEventListener)
      : document.removeEventListener("click", dateRangeEventListener);
  };

  const handleDateRangeSelection = (item, comparison) => {
    setDateRange([item.selection]);
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleLeadPage = async (selPage) => {
    setPage(selPage);

    if (leadOnPageLoad) {
      await filterLeadsByCampaign({
        selPage: selPage,
        startDate: new Date().setFullYear(2022),
        endDate: dateRange[0].endDate
      });
    } else {
      await filterLeadsByCampaign({ selPage: selPage });
    }
  };

  const handleSearchLead = async (e) => {
    e.preventDefault();

    setPage(1);
    setLeadsByCampaign([]);
    setExportLeadCampaigns([]);
    setLoading(true);
    setIsSearchingLead(true);

    const searchText = searchTextRef.current?.value.trim();

    const formData = {
      search: searchText
    }

    try {
      const res = await http.post(
        "/api/leads/?page=1", formData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const { data, total } = res.data;
      setLeadsByCampaign(data);
      setTotalLeads(total);

      setLoading(false);
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      setLoading(false);
      toast.error("Failed to search for leads!");
    }
  };

  const handleSelectedLeadsToDelete = async (leadId) => {
    let dlArr = [...deletedLeads];

    for (let i = 0; i < page; i++) {
      if (deletedLeads[i] == null) {
        dlArr.push([]);
      }
    }

    let activatedPageArr = [...dlArr[page - 1]];

    let leadAlreadyExist = false;

    // Check if lead already exist or not
    for (let i = 0; i < activatedPageArr.length; i++) {
      const l = activatedPageArr[i];

      if (l === leadId) {
        activatedPageArr.splice(i, 1);
        setTotalDeletedLeads((prevCount) => prevCount - 1);
        leadAlreadyExist = true;
        break;
      }
    }

    if (!leadAlreadyExist) {
      // If lead not exist before, then push it
      activatedPageArr.push(leadId);
      setTotalDeletedLeads((prevCount) => prevCount + 1);
    }

    dlArr[page - 1] = [...activatedPageArr];
    setDeletedLeads(dlArr);
  };

  const handleSelectAllLeads = () => {
    let dlArr = [...deletedLeads];

    for (let i = 0; i < page; i++) {
      if (deletedLeads[i] == null) {
        dlArr.push([]);
      }
    }

    let activatedPageArr = [...dlArr[page - 1]];

    if (activatedPageArr.length === leadsByCampaign.length) {
      activatedPageArr = [];

      setTotalDeletedLeads((prevCount) => prevCount - leadsByCampaign.length);
    } else {
      activatedPageArr = [];

      leadsByCampaign.map((l) => {
        activatedPageArr.push(l.id);
        setTotalDeletedLeads((prevCount) => prevCount + 1);
      });
    }

    dlArr[page - 1] = [...activatedPageArr];
    setDeletedLeads(dlArr);
  };

  const confirmDeleteLeads = () => {
    setShowConfirmDeleteModal(true);
  };

  const deleteAllLeads = () => {
    setIsDeleteAllLeads(true);
    confirmDeleteLeads();
  };

  const handleSelectCampaign = async (e) => {
    setIsExportColumnCustomized(false);
    setCampaign(e);
    setIsFetchingBuyerSupplier(true);

    if (e) {
      await fetchBuyers(e.value);
      await fetchSuppliers(e.value);
      await getTrafficSources(e.value);
      setDeletedLeads([[]]);
      setTotalDeletedLeads(0);
    } else {
      setIsFetchingBuyerSupplier(true);
      await fetchBuyers();
      await fetchSuppliers();
      await getTrafficSources();
    }

    setIsFetchingBuyerSupplier(false);
  }

  const handleLeadFilter = (page) => {
    setLeadOnPageLoad(false);

    filterLeadsByCampaign({
      selPage: page
    })
  }

  return (
    <>
      <LeadDeleteConfirmModal
        campaign={campaign}
        showModal={showConfirmDeleteModal}
        setShowModal={setShowConfirmDeleteModal}
        page={page}
        deletedLeads={deletedLeads}
        setDeletedLeads={setDeletedLeads}
        setLoading={setLoading}
        filterLeadsByCampaign
        leadOnPageLoad={leadOnPageLoad}
        exportLeadCampaigns={exportLeadCampaigns}
        isDeleteAll={isDeleteAllLeads}
        setIsDeleteAll={setIsDeleteAllLeads}
        totalLeads={totalLeads}
        totalDeletedLeads={totalDeletedLeads}
        setTotalDeletedLeads={setTotalDeletedLeads}
        leadFormData={leadFormData}
        setLeadFormData={setLeadFormData}
      />

      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <LeadDetailModal
        http={http}
        logout={logout}
        skin={skin}
        showModal={showModal}
        setShowModal={setShowModal}
        lead={leadPayload}
        setLead={setLeadPayload}
        leads={leadsByCampaign}
        setLeads={setLeadsByCampaign}
      />
      <LeadExportColumn
        showModal={showExportColumns}
        setShowModal={setShowExportColumns}
        listOfHeaders={listOfHeaders}
        setListOfHeaders={setListOfHeaders}
        setListOfHeadersKey={setListOfHeadersKey}
        setIsExportColumnCustomized={setIsExportColumnCustomized}
        page={page}
        skin={skin}
        convertToCSV={convertToCSV}
        campaign={campaign}
        buyer={buyer}
        supplier={supplier}
        trafficSourceData={trafficSourceData}
        dateRange={dateRange}
      />
      <ToastContainer />

      <div className="main main-app p-4 p-lg-5">
        <div className="flex flex-col gap-y-5">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3">
            <h5 className="text-xl font-semibold">Leads</h5>
          </div>
          {isFetchingData ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="flex flex-col gap-y-3">
              <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 ${hasAccess.canViewTrafficSourceFilter ? "lg:grid-cols-5" : ""} gap-x-3 gap-y-2`}>
                <div
                  className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                    }`}
                >
                  <Form.Label className="font-semibold">Campaign</Form.Label>
                  <Select
                    options={campaigns}
                    value={campaign}
                    onChange={handleSelectCampaign}
                    placeholder="Select campaign"
                    classNamePrefix={"my-react-select"}
                    className="w-full whitespace-nowrap"
                    isSearchable
                    isClearable
                    noOptionsMessage={() => "No match found."}
                  />
                </div>
                {
                  hasAccess.canViewBuyerFilter &&
                  <div
                    className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                      }`}
                  >
                    <Form.Label className="font-semibold">Buyer</Form.Label>
                    {isFetchingBuyerSupplier ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <Select
                        options={buyers}
                        value={buyer}
                        onChange={setBuyer}
                        placeholder="Select buyer"
                        classNamePrefix={"my-react-select"}
                        className="w-full whitespace-nowrap"
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No match found."}
                      />
                    )}
                  </div>
                }
                {
                  hasAccess.canViewSupplierFilter &&
                  <div
                    className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                      }`}
                  >
                    <Form.Label className="font-semibold">Supplier</Form.Label>
                    {isFetchingBuyerSupplier ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <Select
                        options={suppliers}
                        value={supplier}
                        onChange={setSupplier}
                        placeholder="Select supplier"
                        classNamePrefix={"my-react-select"}
                        className="w-full whitespace-nowrap"
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No match found."}
                      />
                    )}
                  </div>
                }
                {
                  hasAccess.canViewTrafficSourceFilter &&
                  <div
                    className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                      }`}
                  >
                    <Form.Label className="font-semibold">
                      Traffic Source
                    </Form.Label>
                    {isFetchingBuyerSupplier ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <Select
                        options={trafficSources}
                        value={trafficSourceData}
                        onChange={setTrafficSourceData}
                        placeholder="Select traffic source"
                        classNamePrefix={"my-react-select"}
                        className="w-full whitespace-nowrap"
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No match found."}
                      />
                    )}
                  </div>
                }
                <div className="flex flex-1 flex-col">
                  <Form.Label className="font-semibold">Status</Form.Label>
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    className="flex-1"
                  >
                    <option value="">Select status</option>
                    <option value="ACCEPTED">Accepted</option>
                    <option value="REJECTED">Rejected</option>
                    <option value="DUPLICATED">Duplicated</option>
                    <option value="ERROR">Error</option>
                    <option value="STORED">Stored</option>
                    <option value="RETURNED">Returned</option>
                    <option value="PENDING">Pending</option>
                  </Form.Select>
                </div>
                {
                  hasAccess.canViewSourceFilter &&
                  <div className="flex flex-1 flex-col">
                    <Form.Label className="font-semibold">Source</Form.Label>
                    <Form.Select
                      onChange={(e) => setSource(e.target.value)}
                      className="flex-1"
                    >
                      <option value="">Select source</option>
                      <option value="EXTERNAL">External</option>
                      <option value="INTERNAL">Internal</option>
                    </Form.Select>
                  </div>
                }
                <div className="flex flex-1 flex-col">
                  <Form.Label className="font-semibold">Test Lead</Form.Label>
                  <Form.Select
                    onChange={(e) => setIsTest(e.target.value)}
                    className="flex-1"
                  >
                    <option value="">Select Test Lead</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Form.Select>
                </div>
                <div
                  className={`flex flex-1 flex-col my-react-select-container ${skin ? "dark__mode" : ""
                    }`}
                >
                  <Form.Label className="font-semibold">Buyer Type</Form.Label>
                  {isFetchingBuyerSupplier ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Select
                      options={BUYER_TYPES}
                      value={buyerType}
                      onChange={(e) => setBuyerType(e)}
                      placeholder="Select buyer type"
                      classNamePrefix={"my-react-select"}
                      className="w-full whitespace-nowrap"
                      isSearchable
                      isClearable
                      noOptionsMessage={() => "No match found."}
                    />
                  )}
                </div>
                <Form.Group>
                  {showDateRange && <div className="overlay"></div>}
                  <Form.Label className="font-medium">Date</Form.Label>
                  <div ref={dateRangeRef} className="relative">
                    <div
                      onClick={() => handleDateRangeChangeState(!showDateRange)}
                      className={`whitespace-nowrap min-w-[220px] cursor-pointer flex items-center gap-x-1 border border-stone-300 p-2 rounded-md ${skin ? "" : "bg-white"
                        }`}
                    >
                      {dateRange.length > 0 && (
                        <>
                          <span className={`${skin ? "text-stone-400" : ""}`}>
                            <FaRegCalendarAlt />
                          </span>
                          <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>
                            {moment(
                              dateRange[0].startDate.toLocaleDateString()
                            ).format("MMM DD, YYYY")}
                          </p>
                          <span className={`${skin ? "text-stone-400" : ""}`}>
                            -
                          </span>
                          <p className={`mb-0 ${skin ? "text-stone-400" : ""}`}>
                            {moment(
                              dateRange[0].endDate.toLocaleDateString()
                            ).format("MMM DD, YYYY")}
                          </p>
                        </>
                      )}
                    </div>

                    {showDateRange && (
                      <div
                        className={`absolute top-full left-0 ${authData?.rolenames?.includes("SUPER ADMIN") || authData?.rolenames?.includes("ADMIN") ? "min-[1024px]:left-[-230%] min-[1120px]:left-[-200%] min-[1299px]:left-[-170%]" : ""} z-[1001] w-full date__rangePicker ${skin ? "dark__mode" : ""
                          }`}
                      >
                        <DateRangePicker
                          weekStartsOn={1}
                          editableDateInputs={true}
                          onChange={(item) => handleDateRangeSelection(item)}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={dateRange}
                          direction={dateRangeDirection}
                        />
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              <div className="flex items-center justify-between gap-3 lead__filterAndSearchEl">
                <div className="flex flex-col sm:flex-row w-full sm:w-fit items-center gap-x-3 gap-y-2">
                  <button
                    onClick={() => handleLeadFilter(1)}
                    type="submit"
                    className="font-medium w-full sm:w-fit rounded-md px-5 py-2.5 bg-indigo-500 hover:bg-indigo-600 text-white btn-sign transition-colors duration-200 ease-linear"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </>
                    ) : (
                      "Filter"
                    )}
                  </button>
                  {
                    leadsByCampaign.length > 0 &&
                    <button
                      disabled={!showExportLeadButton}
                      type="button"
                      onClick={makeExportLeads}
                      className={`font-medium w-full md:w-fit rounded-md px-5 py-2.5 ${skin ? "bg-slate-700" : "bg-slate-300"
                        } btn-sign transition-colors duration-200 ease-linear`}
                    >
                      {
                        showExportLeadButton ?
                          <>
                            <FaDownload className="inline-block" /> Export all
                          </> :
                          <div className="flex items-center justify-center">
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </div>
                      }
                    </button>
                  }
                </div>
                <Form className="w-full sm:w-fit" onSubmit={handleSearchLead}>
                  <Form.Group
                    className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"
                      }`}
                  >
                    <Form.Control
                      ref={searchTextRef}
                      type="text"
                      className={`w-full focus:outline-none py-2 bg-transparent border-0 ${skin ? "text-stone-300" : "text-stone-500"
                        }`}
                      placeholder="Search leads..."
                    />
                    <button
                      type="submit"
                      className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}
                    >
                      <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                    </button>
                  </Form.Group>
                </Form>
              </div>
            </div>
          )}

          {!isFetchingData && (
            <Card>
              <Card.Body>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col md:flex-row gap-y-3 md:items-center justify-between mb-3">
                      {totalLeads !== 0 && (
                        <h5 className="my-0 font-medium">
                          Total{" "}
                          <span className="text-lg font-semibold">
                            {totalLeads}
                          </span>{" "}
                          lead{totalLeads > 1 ? "s" : ""}
                        </h5>
                      )}
                      <div className="w-full md:w-auto flex flex-col sm:flex-row items-center justify-end gap-y-3 gap-x-1.5">
                        {totalLeads !== 0 && (
                          <>
                            <button
                              onClick={deleteAllLeads}
                              type="button"
                              className="w-full md:w-auto px-3 py-2 bg-danger text-white rounded-md flex items-center justify-center gap-x-1 transition-colors duration-200 ease-linear"
                            >
                              <MdDeleteOutline className="text-lg" />
                              Delete All Leads
                            </button>
                            <button
                              disabled={totalDeletedLeads === 0}
                              onClick={confirmDeleteLeads}
                              type="button"
                              className="w-full md:w-auto px-3 py-2 text-stone-700 bg-rose-200 hover:bg-rose-300 rounded-md flex items-center justify-center gap-x-1 transition-colors duration-200 ease-linear"
                            >
                              <MdDeleteOutline className="text-lg text-stone-700" />
                              Delete {totalDeletedLeads} lead
                              {totalDeletedLeads > 1 ? "s" : ""}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {leadsByCampaign.length > 0 ? (
                      <>
                        <Table
                          variant={skin && "dark"}
                          hover
                          className="mb-0"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="text-lg py-0.5 font-semibold whitespace-nowrap"
                              >
                                <div className="flex items-center gap-x-2">
                                  <div
                                    onClick={() => handleSelectAllLeads()}
                                    className={`cursor-pointer w-[1.5rem] h-[1.5rem] rounded-md border border-stone-200 text-white ${deletedLeads[page - 1] &&
                                      deletedLeads[page - 1].length ===
                                      leadsByCampaign.length
                                      ? "bg-indigo-600"
                                      : ""
                                      } flex items-center justify-center`}
                                  >
                                    {deletedLeads[page - 1] &&
                                      deletedLeads[page - 1].length ===
                                      leadsByCampaign.length && <FaCheck />}
                                  </div>
                                </div>
                              </th>
                              <th
                                scope="col"
                                className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                              >
                                Created At
                              </th>
                              <th
                                scope="col"
                                className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                First Name
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Last Name
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Phone
                              </th>
                              <th
                                scope="col"
                                className="text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Email
                              </th>
                              {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">State</th> */}
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Supplier Name
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Traffic Source
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Is Reposted?
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Is Test?
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {leadsByCampaign.map((lead) => {
                              const payload = lead.payload;

                              return (
                                <tr key={lead.id}>
                                  <td className="align-middle">
                                    <div
                                      onClick={() =>
                                        handleSelectedLeadsToDelete(lead.id)
                                      }
                                      className={`cursor-pointer w-[1.5rem] h-[1.5rem] rounded-md border border-stone-200 text-white ${deletedLeads[page - 1] &&
                                        deletedLeads[page - 1].includes(
                                          lead.id
                                        )
                                        ? "bg-indigo-600"
                                        : ""
                                        } flex items-center justify-center`}
                                    >
                                      {deletedLeads[page - 1] &&
                                        deletedLeads[page - 1].includes(
                                          lead.id
                                        ) && <FaCheck />}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        }`}
                                    >
                                      {moment(lead.created_at).format("LL")}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        } title__td whitespace-nowrap`}
                                    >
                                      {lead.lead_id}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        } title__td`}
                                    >
                                      {
                                        payload?.firstName ||
                                        payload?.first_name ||
                                        payload?.Firstname ||
                                        payload?.FirstName ||
                                        <p className="mb-0 text-center font-medium">
                                          -
                                        </p>
                                      }
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        } title__td`}
                                    >
                                      {
                                        payload?.lastName ||
                                        payload?.last_name ||
                                        payload?.Lastname ||
                                        payload?.LastName ||
                                        <p className="mb-0 text-center font-medium">
                                          -
                                        </p>
                                      }
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`whitespace-nowrap ${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        }`}
                                    >
                                      {
                                        payload?.Phone ||
                                        payload?.phone ||
                                        payload?.phoneNumber ||
                                        payload?.phone_number ||
                                        payload?.phoneHome ||
                                        payload?.phonehome ||
                                        payload?.phone_home ||
                                        <p className="mb-0 text-center font-medium">
                                          -
                                        </p>
                                      }
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="px-3 align-middle cursor-pointer"
                                  >
                                    <div
                                      className={`${skin
                                        ? "text-stone-300"
                                        : "text-inherit"
                                        }`}
                                    >
                                      {
                                        payload?.email ||
                                        payload?.Email ||
                                        payload?.email_add ||
                                        payload?.email_address ||
                                        payload?.emailaddress ||
                                        payload?.emailAddress ||
                                        <p className="mb-0 text-center font-medium">
                                          -
                                        </p>
                                      }
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="align-middle"
                                  >
                                    {lead.generated_by &&
                                      lead.generated_by.supplier_name ? (
                                      <div
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {lead.generated_by?.supplier_name?.[0]?.toUpperCase() +
                                          lead.generated_by?.supplier_name?.slice(
                                            1
                                          )}
                                      </div>
                                    ) : (
                                      <p className="mb-0 text-center font-medium">
                                        -
                                      </p>
                                    )}
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="align-middle"
                                  >
                                    {lead?.traffic_source ? (
                                      <div
                                        className={`${skin
                                          ? "text-stone-300"
                                          : "text-inherit"
                                          }`}
                                      >
                                        {
                                          `${lead?.traffic_source?.client?.company_name[0]?.toUpperCase()}${lead?.traffic_source?.client?.company_name?.slice(
                                            1
                                          )}${lead.traffic_source?.traffic_channel?.channel_name
                                            ? " - " + lead?.traffic_source?.traffic_channel?.channel_name
                                            : ""
                                          } (${lead?.traffic_source?.id})`
                                        }
                                      </div>
                                    ) : payload?.traffic_source ? payload?.traffic_source
                                      : (
                                        <p className="mb-0 text-center font-medium">
                                          -
                                        </p>
                                      )}
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="align-middle"
                                  >
                                    <div
                                      className={`text-sm rounded-md p-1.5 font-medium text-center text-white ${lead.status === "ACCEPTED"
                                        ? "bg-success"
                                        : lead.status === "DUPLICATED" || lead.status === "PENDING"
                                          ? "bg-warning"
                                          : lead.status === "STORED"
                                            ? "bg-primary"
                                            : "bg-danger"
                                        }`}
                                    >
                                      {lead.status ? lead.status : "ERROR"}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="align-middle"
                                  >
                                    {lead.is_repost ? (
                                      <div
                                        className={`text-sm rounded-md p-1.5 font-medium text-center text-white bg-success`}
                                      >
                                        REPOST
                                      </div>
                                    ) : (
                                      <p className="mb-0 text-center font-medium">
                                        -
                                      </p>
                                    )}
                                  </td>
                                  <td
                                    onClick={() => getLeadDetails(lead)}
                                    className="align-middle"
                                  >
                                    {lead.is_test ? (
                                      <div
                                        className={`text-sm rounded-md p-1.5 font-medium text-center text-white bg-primary`}
                                      >
                                        Is Test
                                      </div>
                                    ) : (
                                      <p className="mb-0 text-center font-medium">
                                        -
                                      </p>
                                    )}
                                  </td>
                                  <td className="px-3 align-middle">
                                    <Dropdown className="static">
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin
                                          ? "bg-slate-800 hover:bg-slate-700 text-stone-100"
                                          : "bg-white text-stone-800"
                                          } rounded-md shadow-sm transition-colors duration-200 ease-linear`}
                                      >
                                        <IoSettingsOutline className="text-lg" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <button
                                            onClick={() =>
                                              getLeadDetails(lead)
                                            }
                                            className="text-inherit flex w-full items-center gap-x-3"
                                          >
                                            <FaRegEye className="text-base" />
                                            View
                                          </button>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item>
                                          <button
                                            onClick={() =>
                                              handleDeleteLeads(lead.id)
                                            }
                                            type="button"
                                            className="text-inherit flex w-full items-center gap-x-3"
                                          >
                                            <MdDeleteOutline className="text-lg text-stone-500" />
                                            Delete
                                          </button>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>

                        {!isSearchingLead && (
                          <div className="mt-3 flex justify-end custom__reactPaginate">
                            <PaginationControl
                              page={page}
                              between={3}
                              total={totalLeads}
                              limit={PAGE_LIMIT_SIZE}
                              changePage={(page) => handleLeadPage(page)}
                              ellipsis={5}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <p className="text-lg md:text-xl text-center font-semibold text-stone-500">
                        No data found yet!
                      </p>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}