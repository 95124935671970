import React, { useEffect, useState } from 'react'
import AuthUtils from '../../Pages/Utils/AuthUtils'
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';

const DashboardGraph = ({skin}) => {
    const { http, logout } = AuthUtils();
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getGraphData = async () => {
        try {
            const res = await http.get(`/api/get-data/`, {
                headers: {
                    "Content-Type": "application/json",
                }
            })

            if (res.status === 200) {
                const { data } = res.data;

                if (data) {
                    const { total_accepted, total_rejected, total_duplicated, total_error } = data;

                    let acceptedCount = [], acceptedDate = [],
                        rejectedCount = [], rejectedDate = [],
                        duplicatedCount = [], duplicatedDate = [],
                        errorCount = [], errorDate = [];

                    total_accepted.reverse().map(ad => {
                        acceptedCount.push(ad.count);
                        acceptedDate.push(ad.date);
                    })

                    total_rejected.reverse().map(rd => {
                        rejectedCount.push(rd.count);
                        rejectedDate.push(rd.date);
                    })

                    total_duplicated.reverse().map(dd => {
                        duplicatedCount.push(dd.count);
                        duplicatedDate.push(dd.date);
                    })

                    total_error.reverse().map(ed => {
                        errorCount.push(ed.count);
                        errorDate.push(ed.date);
                    })

                    setGraphData([
                        {
                            series: [{
                                name: "Accepted",
                                data: acceptedCount
                            }],
                            graphOption: {
                                chart: {
                                    type: 'area',
                                    parentHeightOffset: 0,
                                    stacked: true,
                                    toolbar: {
                                        show: true
                                    }
                                },
                                colors: ['#5575dc', '#81adee', '#ccd1ed'],
                                dataLabels: {
                                    enabled: false
                                },
                                grid: {
                                    borderColor: 'rgba(72,94,144, 0.07)',
                                    padding: {
                                        top: -20
                                    },
                                    yaxis: {
                                        lines: {
                                            show: false
                                        }
                                    }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1.5
                                },
                                fill: {
                                    type: 'gradient',
                                    opacity: 0.8,
                                    gradient: {
                                        type: 'vertical',
                                        shade: 'light',
                                        opacityFrom: 0.35,
                                        opacityTo: 0.65,
                                        stops: [0, 100]
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: true
                                },
                                yaxis: {
                                    // max: 200,
                                    tickAmount: 6,
                                    // show: false,
                                    categories: acceptedCount
                                },
                                xaxis: {
                                    categories: acceptedDate,
                                    // type: 'numeric',
                                    tickAmount: 12,
                                    labels: {
                                        style: {
                                            colors: '#6e7985',
                                            fontSize: '11px'
                                        }
                                    }
                                }
                            }
                        },
                        {
                            series: [{
                                name: "Rejected",
                                data: rejectedCount
                            }],
                            graphOption: {
                                chart: {
                                    type: 'area',
                                    parentHeightOffset: 0,
                                    stacked: true,
                                    toolbar: {
                                        show: true
                                    }
                                },
                                colors: ['#5575dc', '#81adee', '#ccd1ed'],
                                dataLabels: {
                                    enabled: false
                                },
                                grid: {
                                    borderColor: 'rgba(72,94,144, 0.07)',
                                    padding: {
                                        top: -20
                                    },
                                    yaxis: {
                                        lines: {
                                            show: false
                                        }
                                    }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1.5
                                },
                                fill: {
                                    type: 'gradient',
                                    opacity: 0.8,
                                    gradient: {
                                        type: 'vertical',
                                        shade: 'light',
                                        opacityFrom: 0.35,
                                        opacityTo: 0.65,
                                        stops: [0, 100]
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: true
                                },
                                yaxis: {
                                    // max: 200,
                                    tickAmount: 6,
                                    // show: false,
                                    categories: rejectedCount
                                },
                                xaxis: {
                                    categories: rejectedDate,
                                    // type: 'numeric',
                                    tickAmount: 12,
                                    labels: {
                                        style: {
                                            colors: '#6e7985',
                                            fontSize: '11px'
                                        }
                                    }
                                }
                            }
                        },
                        {
                            series: [{
                                name: "Duplicated",
                                data: duplicatedCount
                            }],
                            graphOption: {
                                chart: {
                                    type: 'area',
                                    parentHeightOffset: 0,
                                    stacked: true,
                                    toolbar: {
                                        show: true
                                    }
                                },
                                colors: ['#5575dc', '#81adee', '#ccd1ed'],
                                dataLabels: {
                                    enabled: false
                                },
                                grid: {
                                    borderColor: 'rgba(72,94,144, 0.07)',
                                    padding: {
                                        top: -20
                                    },
                                    yaxis: {
                                        lines: {
                                            show: false
                                        }
                                    }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1.5
                                },
                                fill: {
                                    type: 'gradient',
                                    opacity: 0.8,
                                    gradient: {
                                        type: 'vertical',
                                        shade: 'light',
                                        opacityFrom: 0.35,
                                        opacityTo: 0.65,
                                        stops: [0, 100]
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: true
                                },
                                yaxis: {
                                    // max: 200,
                                    tickAmount: 6,
                                    // show: false,
                                    categories: duplicatedCount
                                },
                                xaxis: {
                                    categories: duplicatedDate,
                                    // type: 'numeric',
                                    tickAmount: 12,
                                    labels: {
                                        style: {
                                            colors: '#6e7985',
                                            fontSize: '11px'
                                        }
                                    }
                                }
                            }
                        },
                        {
                            series: [{
                                name: "Error",
                                data: errorCount
                            }],
                            graphOption: {
                                chart: {
                                    type: 'area',
                                    parentHeightOffset: 0,
                                    stacked: true,
                                    toolbar: {
                                        show: true
                                    }
                                },
                                colors: ['#5575dc', '#81adee', '#ccd1ed'],
                                dataLabels: {
                                    enabled: false
                                },
                                grid: {
                                    borderColor: 'rgba(72,94,144, 0.07)',
                                    padding: {
                                        top: -20
                                    },
                                    yaxis: {
                                        lines: {
                                            show: false
                                        }
                                    }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1.5
                                },
                                fill: {
                                    type: 'gradient',
                                    opacity: 0.8,
                                    gradient: {
                                        type: 'vertical',
                                        shade: 'light',
                                        opacityFrom: 0.35,
                                        opacityTo: 0.65,
                                        stops: [0, 100]
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: true
                                },
                                yaxis: {
                                    // max: 200,
                                    tickAmount: 6,
                                    // show: false,
                                    categories: errorCount
                                },
                                xaxis: {
                                    categories: errorDate,
                                    // type: 'numeric',
                                    tickAmount: 12,
                                    labels: {
                                        style: {
                                            colors: '#6e7985',
                                            fontSize: '11px'
                                        }
                                    }
                                }
                            }
                        }
                    ]);
                }
                setLoading(false);
            }
        } catch (err) {
            const {response} = err;

            if (response) {
                const {data} = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // toast.error("Failed to fetch graphs' data!");
            setLoading(false);
        }
    }

    useEffect(() => {
        getGraphData();
    }, [])

    return (
        <div>
            {
                loading ? <div className="mb-5 d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div> :
                    <Row className="g-3">
                        {graphData.map(g => (
                            <Col xl="6" key={g?.series[0]?.name}>
                                <Card className="card-one">
                                    <Card.Header>
                                        <Card.Title as="h6">{g?.series[0]?.name}</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="legend mb-3">
                                            <li className={`${skin ? "text-stone-500" : ""}`}>{g?.series[0]?.name}</li>
                                        </ul>
                                        <ReactApexChart series={g?.series} options={g?.graphOption} type="area" height={300} className="apex-chart-one mb-4" />
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
            }
        </div>
    )
}

export default DashboardGraph