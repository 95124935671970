import AuthUtils from "./AuthUtils";

const { http } = AuthUtils();

export const UNAUTH_URLS = [
    "/signin",
    "/forgot-password",
    "/reset-password",
    "/supplier-api-docs/public"
]

export const getAuthData = async () => {
    try {
        const res = await http.get(`/api/user/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (res.status === 200) {
            return res.data;
        }
    } catch (err) {
        return null;
    }
}