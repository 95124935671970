import React, { useContext } from 'react'
import { useEffect, useState } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthUtils from "../Utils/AuthUtils";
import { Link, useNavigate } from "react-router-dom";
import Header from '../../layouts/Header'
import HeaderMobile from '../../layouts/HeaderMobile'
import Sidebar from "../../layouts/Sidebar";
import { Card, Spinner, Table } from "react-bootstrap";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import AddReturnsModal from '../../Components/AddReturnsModal'
import { FiDownload } from "react-icons/fi";
import moment from 'moment'
import { PAGE_LIMIT_SIZE } from '../Utils/baseConfig';
import { useAuth } from '../../Context/AuthContext';


export default function Returns() {
    const navigate = useNavigate();
    const [authData, setAuthData] = useAuth();

    const [returnDataFetching, setReturnDataFetching] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [campaigns, setCampaigns] = useState([]);
    const [leadReturns, setLeadReturns] = useState([]);
    const [page, setPage] = useState(1);
    const [formError, setFormError] = useState({
        errId: -1
    })
    const [totalLeadReturns, setTotalLeadReturns] = useState(0);

    const [hasAccess, setHasAccess] = useState({
        canViewReturnedBy: false
    })

    const { http, logout } = AuthUtils()

    const getCampaigns = async () => {
        await http.get(`/api/all-campaigns/`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                // setCampaigns(response.data.data)

                let campaignArr = [];

                response.data.data?.map(camp => {
                    campaignArr.push({
                        ...camp,
                        value: camp.id,
                        label: camp.campaign_title
                    })
                })

                setCampaigns(campaignArr);
                setLoading(false);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch campaigns' data!");

                setLoading(false)
                setCampaigns([]);
            })
    }

    const getReturns = async (selPage) => {
        setReturnDataFetching(true);
        await http.get(`/api/returns/?page=${selPage}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                const { data, total } = response.data;
                setLeadReturns(data);
                setTotalLeadReturns(total);
            })
            .catch((err) => {
                const { response } = err;

                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message === "Unauthenticated.") {
                            logout();
                        }
                    }
                }
                toast.error("Failed to fetch returns' data!");

                setReturnDataFetching(false)
                setLeadReturns([]);
            })

        setReturnDataFetching(false)
    }

    const handleRoleBasedPermission = () => {
        const { rolenames } = authData;

        if (!rolenames.includes("SUPER ADMIN") && !rolenames.includes("ADMIN") && !rolenames.includes("BUYER")) {
            navigate("/");
        } else {
            if (rolenames.includes("SUPER ADMIN") || rolenames.includes("ADMIN")) {
                const hasAccessOptions = JSON.parse(JSON.stringify(hasAccess));
                hasAccessOptions.canViewReturnedBy = true;
                setHasAccess(hasAccessOptions);
            }

            getReturns(1);
            getCampaigns();
        }
    }

    useEffect(() => {
        if (authData) {
            handleRoleBasedPermission();
        }

        return () => {
            setTotalLeadReturns(0);
            setPage(1);
        }
    }, [authData])

    const toggleModal = (modalStatus) => {
        setShowModal(modalStatus);
        setFormError({ errId: -1 });
    }

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const handlePageUpdate = async (selPage) => {
        setPage(selPage);
        await getReturns(selPage);
    }

    const downloadReturnFile = fileUrl => {
        const fileUrlEl = document.createElement("a");
        fileUrlEl.setAttribute("href", fileUrl);
        fileUrlEl.setAttribute("download", true);
        fileUrlEl.classList.add("hidden");

        document.body.append(fileUrlEl);

        fileUrlEl.click();

        fileUrlEl.remove();
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />
            <AddReturnsModal
                http={http}
                logout={logout}
                skin={skin}
                showModal={showModal}
                setShowModal={setShowModal}
                campaigns={campaigns}
                isCampaignLoading={loading}
                formError={formError}
                setFormError={setFormError}
                getReturns={getReturns}
                page={page}
            />

            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3'>
                        <div className='text-xl font-semibold'>Returns</div>
                        <div className='flex items-center gap-x-3'>
                            <button onClick={() => toggleModal(true)} type="button" className="font-medium cursor-pointer px-2.5 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                                Add New Return
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='mb-0'>This user-friendly feature ensures a straightforward process of returning unwanted leads from your buyers to your suppliers. With just a few clicks, you can mark and return leads that don't meet your buyers criteria.</p>
                    </div>

                    <Card>
                        <Card.Body>
                            {
                                returnDataFetching ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                    leadReturns.length > 0 ?
                                        <>
                                            <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Created at</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Campaign</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Selected Date Range</th>
                                                        {
                                                            hasAccess.canViewReturnedBy &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Returned By</th>
                                                        }
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Status</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        leadReturns.map((lr) => (
                                                            <tr key={lr.id}>
                                                                <th scope="row" className='px-3 align-middle'>
                                                                    <Link href="#" className={`whitespace-nowrap font-normal ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {moment(lr.created_at).format("lll")}
                                                                    </Link>
                                                                </th>
                                                                <th scope="row" className='px-3 align-middle'>
                                                                    <Link href="#" className={`font-normal ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {lr.id}
                                                                    </Link>
                                                                </th>
                                                                <td className='px-3 align-middle title__td'>
                                                                    <Link to={`/create-campaign/${lr.campaign?.id}/`} className={`whitespace-nowrap ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {lr.campaign?.campaign_title}
                                                                    </Link>
                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    {
                                                                        lr.date_from && lr.date_to ?
                                                                            <Link to="#" className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                {moment(lr.date_from).format("ll")} - {moment(lr.date_to).format("ll")}
                                                                            </Link> :
                                                                            <p className="mb-0 text-center font-medium">-</p>
                                                                    }
                                                                </td>
                                                                {
                                                                    hasAccess.canViewReturnedBy &&
                                                                    <td className='px-3 align-middle title__td'>
                                                                        {
                                                                            lr.returned_by ?
                                                                                <Link to={`/user/${lr.returned_by.id}`} className={`capitalize whitespace-nowrap ${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                                    {lr.returned_by?.company_name}
                                                                                </Link> :
                                                                                <p className="mb-0 text-center font-medium">-</p>
                                                                        }
                                                                    </td>
                                                                }
                                                                <td className='px-3 align-middle'>
                                                                    <Link to="#" className={`rounded-md border border-stone-300 p-1.5 bg-teal-600 text-white`}>
                                                                        Done
                                                                    </Link>
                                                                </td>
                                                                <td className='px-3 align-middle'>
                                                                    <Link to="#" onClick={() => downloadReturnFile(lr.file_url)} className={`flex p-1.5 rounded-md bg-indigo-500 text-white items-center justify-center gap-x-1`}>
                                                                        <FiDownload className='text-lg' />
                                                                        Download
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <div className='mt-3 flex justify-end custom__reactPaginate'>
                                                <PaginationControl
                                                    page={page}
                                                    between={3}
                                                    total={totalLeadReturns}
                                                    limit={PAGE_LIMIT_SIZE}
                                                    changePage={(page) => handlePageUpdate(page)}
                                                    ellipsis={5}
                                                />
                                            </div>
                                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
