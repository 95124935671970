import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import TopCampaign from "../../Components/Dashboard/TopCampaign";
import DashboardRecord from "../../Components/Dashboard/DashboardRecord";
import DashboardGraph from "../../Components/Dashboard/DashboardGraph";


function Dashboard() {
  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <>
      <Header onSkin={setSkin} />
      <HeaderMobile onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0 font-medium">Welcome to Dashboard</h4>
          </div>
        </div>

        <DashboardRecord skin={skin} />
        <DashboardGraph skin={skin} />
        <TopCampaign skin={skin} />
      </div>
    </>
  );
}

export default Dashboard;
