import React from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/images/user_dummy.png";
import AuthUtils from "../Pages/Utils/AuthUtils";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useAuth } from "../Context/AuthContext";

export default function Header({ onSkin }) {
    const { logout } = AuthUtils();
    const [authData, setAuthData] = useAuth();

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="dropdown-link"
        >
            {children}
        </Link>
    ));

    const toggleSidebar = (e) => {
        e.preventDefault();
        let isOffset = document.body.classList.contains("sidebar-offset");
        if (isOffset) {
            document.body.classList.toggle("sidebar-show");
        } else {
            if (window.matchMedia("(max-width: 991px)").matches) {
                document.body.classList.toggle("sidebar-show");
            } else {
                document.body.classList.toggle("sidebar-hide");
            }
        }
    }

    const skinMode = (e) => {
        let skin = localStorage.getItem("skin-mode") ? "" : "dark";
        let HTMLTag = document.querySelector("html");

        if (skin === "dark") {
            HTMLTag.setAttribute("data-skin", skin);
            localStorage.setItem('skin-mode', skin);

            onSkin(skin);

        } else {
            HTMLTag.removeAttribute("data-skin");
            localStorage.removeItem('skin-mode');

            onSkin('');
        }

    };

    const sidebarSkin = (e) => {
        e.preventDefault();
        e.target.classList.add("active");

        let node = e.target.parentNode.firstChild;
        while (node) {
            if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove("active");
            node = node.nextElementSibling || node.nextSibling;
        }

        let skin = e.target.textContent.toLowerCase();
        let HTMLTag = document.querySelector("html");

        HTMLTag.removeAttribute("data-sidebar");

        if (skin !== "default") {
            HTMLTag.setAttribute("data-sidebar", skin);
            localStorage.setItem("sidebar-skin", skin);
        } else {
            localStorage.removeItem("sidebar-skin", skin);
        }
    };

    return (
        <div className="header-main px-3 px-lg-4">
            <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

            <div className="form-search me-auto">
                <input type="text" className="form-control" placeholder="Search" />
                <i className="ri-search-line"></i>
            </div>

            <DarkModeSwitch
                checked={localStorage.getItem("skin-mode")}
                onChange={skinMode}
                size={20}
                sunColor="#506FD9"
            />

            <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
                <Dropdown.Toggle as={CustomToggle}>
                    <div className="avatar online">
                        <img src={userAvatar} alt="" />
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-10-f">
                    <div className="dropdown-menu-body">
                        <div className="avatar avatar-xl online mb-3"><img src={userAvatar} alt="" /></div>
                        {
                            authData && <h5 className="capitaize mb-1 text-dark fw-semibold">{authData.first_name && authData.first_name} {authData.last_name && authData.last_name}</h5>
                        }

                        <nav className="nav">
                            <Link to="/user"><i className="ri-edit-2-line"></i> Edit Profile</Link>
                        </nav>
                        <hr />
                        <nav className="nav">
                            <div onClick={logout} className="cursor-pointer"><i className="ri-logout-box-r-line"></i> Log Out</div>
                        </nav>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}