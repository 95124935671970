import { useEffect, useState } from 'react'
import { useAuth } from '../Context/AuthContext';
import { dataMenu, settingsMenu } from '../data/Menu';
import { NavLink } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import Select from "react-select";

const SidebarMenu = ({ onUpdateSize }) => {
    const [authData, setAuthData] = useAuth();
    const [dataMenus, setDataMenus] = useState([]);
    const [settingMenus, setSettingMenus] = useState([]);

    useEffect(() => {
        window.addEventListener("click", function (e) {
            // Close sidebar footer menu when clicked outside of it
            let tar = e.target;
            let sidebar = document.querySelector(".sidebar");
            if (!tar.closest(".sidebar-footer") && sidebar) {
                sidebar.classList.remove("footer-menu-show");
            }

            // Hide sidebar offset when clicked outside of sidebar
            if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
                document.querySelector("body").classList.remove("sidebar-show");
            }
        });

        window.addEventListener("load", function () {
            let skinMode = localStorage.getItem("sidebar-skin");
            let HTMLTag = document.querySelector("html");

            if (skinMode) {
                HTMLTag.setAttribute("data-sidebar", skinMode);
            }
        });
    }, [])

    useEffect(() => {
        if (authData) {
            const { rolenames } = authData;

            const hasAccessMenus = rolenames.includes("SUPER ADMIN") || rolenames.includes("DEVELOPER") || rolenames.includes("ADMIN");

            let dataMenuArr = [...dataMenu];

            // Give access to "returns" based on role
            dataMenuArr?.forEach(d => {
                if (d.label === "Returns") {
                    if (rolenames.includes("BUYER")) {
                        d.hasAccess = true;
                    } else {
                        d.hasAccess = hasAccessMenus;
                    }
                } else {
                    d.hasAccess = true;
                }
            });
            setDataMenus(dataMenuArr);

            let settingMenuArr = [];
            // Give access to all settings menu based on role
            if (hasAccessMenus) {
                settingMenuArr = [...settingsMenu];

                settingMenuArr?.forEach(s => {
                    s.hasAccess = hasAccessMenus;
                });
            } else {
                const conversionMenu = settingsMenu.find(m => m.label === "Conversions");

                if (conversionMenu) {
                    settingMenuArr.push(conversionMenu);
                }
            }
            setSettingMenus(settingMenuArr);
        }
    }, [authData])

    const populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                m.hasAccess &&
                <li key={key} className="nav-item font-medium">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    const sidebarMenuItem = () => {
        return (
            <div className="nav-group show">
                <div className="nav-label" onClick={toggleMenu}>Settings</div>
                {populateMenu(settingMenus)}
            </div>
        )
    }

    // Toggle menu group
    const toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    const toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        onUpdateSize();
    }

    const handleSupplierSelect = e => {
        if (e) {
            window.open(`/supplier-api-docs/${e.value}`, '_blank');
        }
    }

    const populateSupplierProfileMenu = (menuItems) => {
        const menu = menuItems?.map((m, index) => {
            return (
                <li key={index} className="nav-item font-medium">
                    <NavLink to={`/supplier-api-docs/${m.slug}`} target='_blank' className="nav-link"><span>{m.campaign_title} - {m.company_name}</span></NavLink>
                </li>
            )
        })

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    return (
        <>
            <SidebarContent />
            <div className="nav-group show">
                <div className="nav-label" onClick={toggleMenu}>Data</div>
                {populateMenu(dataMenus)}
            </div>
            {
                sidebarMenuItem()
            }
            {
                authData?.rolenames?.includes("SUPPLIER") && authData?.supplier_profiles?.length > 0 &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Supplier API Docs</div>
                    {populateSupplierProfileMenu(authData.supplier_profiles)}
                </div>
            }
        </>
    )
}

export default SidebarMenu