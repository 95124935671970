import { useEffect, useState, useRef } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import AuthUtils from "../Utils/AuthUtils";
import { Link } from "react-router-dom";
import { PAGE_LIMIT_SIZE } from '../Utils/baseConfig'
import Header from '../../layouts/Header'
import HeaderMobile from '../../layouts/HeaderMobile'
import Sidebar from "../../layouts/Sidebar";
import { Card, Dropdown, Form, Spinner, Table } from "react-bootstrap";
import AddCampaignModal from '../../Components/Campaign/AddCampaignModal'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { IoSettingsOutline } from "react-icons/io5";
import { useAuth } from '../../Context/AuthContext'
import { FaRegEye } from "react-icons/fa";

export default function Campaign() {
    const [authData, setAuthData] = useAuth();

    const [loading, setLoading] = useState([false]);
    const [showModal, setShowModal] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const campaignNameRef = useRef();
    const [page, setPage] = useState(1);
    const [formError, setFormError] = useState({
        errId: -1
    })
    const [totalCampaigns, setTotalCampaigns] = useState(0);

    const [hasAccess, setHasAccess] = useState({
        canViewTotalPosted: false,
        canViewTotalAccepted: false,
        canViewTotalRejected: false,
        canViewTotalActiveSuppliers: false,
        canViewTotalActiveBuyers: false,
        canDeleteCampaign: false,
        canUpdateCampaign: false
    })

    const searchTextRef = useRef();

    const { http, logout } = AuthUtils()


    const getCampaigns = async (selPage) => {
        setLoading(true);

        try {
            const res = await http.get(`/api/campaigns/?page=${selPage}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { data, total } = res.data;

                setCampaigns(data);
                setTotalCampaigns(total);
                setLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }

            toast.error("Failed to fetch campaign data!")
            setLoading(false);
            setCampaigns([]);
        }
    }

    const handleCampaignDelete = async (campaignId) => {
        try {
            const res = await http.delete(`/api/delete-campaign/${campaignId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                toast.success("Campaign is deleted successfully.");
                getCampaigns(page);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            toast.error("Failed to delete the campaign!")
        }
    }

    const handleRoleBasedPermission = () => {
        if (!authData) return;

        let hasAccessOptions = { ...hasAccess };

        if (authData.rolenames.includes("SUPER ADMIN") || authData.rolenames.includes("ADMIN") || authData.rolenames.includes("DEVELOPER")) {
            hasAccessOptions = {
                canViewTotalPosted: true,
                canViewTotalAccepted: true,
                canViewTotalRejected: true,
                canViewTotalActiveSuppliers: true,
                canViewTotalActiveBuyers: true,
                canDeleteCampaign: true,
                canUpdateCampaign: true
            }
        }

        setHasAccess(hasAccessOptions);
    }

    useEffect(() => {
        handleRoleBasedPermission()
        getCampaigns(page)

        return () => {
            setTotalCampaigns(0);
            setPage(1);
        }
    }, [authData])

    const toggleModal = (modalStatus) => {
        setShowModal(modalStatus);
        setFormError({ errId: -1 });
        if (modalStatus) {
            setTimeout(() => {
                campaignNameRef.current?.focus();
            }, 50);
        }
    }

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const handleSearchCampaign = async (e) => {
        e.preventDefault();
        setPage(1);
        setCampaigns([]);
        setLoading(true);

        const searchText = searchTextRef.current.value.trim();

        try {
            const res = await http.post("/api/search-campaign/?page=1", {
                query: searchText
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const { data, total } = res.data;
            setCampaigns(data);
            setTotalCampaigns(total);
            setLoading(false);
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            setLoading(false);
            toast.error("Failed to fetch campaign data!");
        }
    }

    const handlePageUpdate = async (selPage) => {
        setPage(selPage);
        await getCampaigns(selPage);
    }

    return (
        <>
            <Header onSkin={setSkin} />
            <HeaderMobile onSkin={setSkin} />
            <Sidebar />
            <ToastContainer />
            <AddCampaignModal
                showModal={showModal}
                setShowModal={setShowModal}
                setFormError={setFormError}
            />
            <div className="main main-app p-4 p-lg-5">
                <div className='flex flex-col gap-y-5'>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-x-5 gap-y-3'>
                        <div className='text-xl font-semibold'>Campaigns</div>
                        <div className='flex items-center gap-x-3'>
                            <div className='card-post flex-1 sm:flex-auto'>
                                <Form onSubmit={handleSearchCampaign}>
                                    <Form.Group className={`flex items-center border border-stone-300 border-r-0 rounded-md ${skin ? "form__searchInputDark" : "form__searchInput"}`}>
                                        <Form.Control ref={searchTextRef} type="text" className={`focus:outline-none py-2 bg-transparent border-0 ${skin ? 'text-stone-300' : 'text-stone-500'}`} placeholder="Search campaign..." />
                                        <button type="submit" className={`font-medium border-0 pr-2 bg-transparent text-stone-500`}>
                                            <i className="ri-search-line text-lg text-stone-400 font-semibold"></i>
                                        </button>
                                    </Form.Group>
                                </Form>
                            </div>

                            <button onClick={() => toggleModal(true)} type="button" className="font-medium cursor-pointer px-2.5 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md transition-colors duration-200 ease-linear">
                                Add New Campaign
                            </button>
                        </div>
                    </div>

                    <Card>
                        <Card.Body>
                            {
                                loading ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />

                                </div> :
                                    campaigns.length > 0 ?
                                        <>
                                            <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">ID</th>
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Name</th>
                                                        {
                                                            hasAccess.canViewTotalPosted &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Posted</th>
                                                        }
                                                        {
                                                            hasAccess.canViewTotalAccepted &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Accepted</th>
                                                        }
                                                        {
                                                            hasAccess.canViewTotalRejected &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Rejected</th>
                                                        }
                                                        {
                                                            hasAccess.canViewTotalActiveSuppliers &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Active Suppliers</th>
                                                        }
                                                        {
                                                            hasAccess.canViewTotalActiveBuyers &&
                                                            <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Active Buyers</th>
                                                        }
                                                        <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        campaigns.map((campaign) => (
                                                            <tr key={campaign.id}>
                                                                <th scope="row" className='px-3 align-middle'>
                                                                    <Link href={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {campaign.id}
                                                                    </Link>
                                                                </th>
                                                                <td className='px-3 align-middle title__td'>
                                                                    <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                        {
                                                                            campaign?.campaign_title
                                                                        }
                                                                    </Link>
                                                                </td>
                                                                {
                                                                    hasAccess.canViewTotalPosted &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                            {
                                                                                campaign.total_posted ? campaign.total_posted : 0
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                {
                                                                    hasAccess.canViewTotalAccepted &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                            {
                                                                                campaign.total_accepted ? campaign.total_accepted : 0
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                {
                                                                    hasAccess.canViewTotalRejected &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                            {
                                                                                campaign.total_rejected ? campaign.total_rejected : 0
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                {
                                                                    hasAccess.canViewTotalActiveSuppliers &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                            {
                                                                                campaign.suppliers_count ? campaign.suppliers_count : 0
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                {
                                                                    hasAccess.canViewTotalActiveBuyers &&
                                                                    <td className='px-3 align-middle'>
                                                                        <Link to={`/create-campaign/${campaign.id}/`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                            {
                                                                                campaign.buyers_count ? campaign.buyers_count : 0
                                                                            }
                                                                        </Link>
                                                                    </td>
                                                                }
                                                                <td className='px-3 align-middle'>
                                                                    <Dropdown className="static">
                                                                        <Dropdown.Toggle id="dropdown-basic" className={`border-0 font-medium after:hidden px-2.5 py-2 ${skin ? "bg-slate-800 hover:bg-slate-700 text-stone-100" : "bg-white text-stone-800"} rounded-md shadow-sm transition-colors duration-200 ease-linear`}>
                                                                            <IoSettingsOutline className='text-lg' />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item>
                                                                                <Link to={`/create-campaign/${campaign.id}/`} className='text-inherit flex w-full items-center gap-x-3'>
                                                                                {
                                                                                    hasAccess.canUpdateCampaign ? 
                                                                                    <>
                                                                                    <MdOutlineEdit className="text-lg text-stone-500" />
                                                                                    Edit
                                                                                    </> : 
                                                                                    <>
                                                                                    <FaRegEye className="text-lg text-stone-500" />
                                                                                    View
                                                                                    </>
                                                                                }
                                                                                </Link>
                                                                            </Dropdown.Item>
                                                                            {
                                                                                hasAccess.canDeleteCampaign &&
                                                                                <>

                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item>
                                                                                        <button onClick={() => handleCampaignDelete(campaign.id)} type="button" className='text-inherit flex w-full items-center gap-x-3'>
                                                                                            <MdDeleteOutline className="text-lg text-stone-500" />
                                                                                            Delete
                                                                                        </button>
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <div className='mt-3 flex justify-end custom__reactPaginate'>
                                                <PaginationControl
                                                    page={page}
                                                    between={3}
                                                    total={totalCampaigns}
                                                    limit={PAGE_LIMIT_SIZE}
                                                    changePage={(page) => handlePageUpdate(page)}
                                                    ellipsis={5}
                                                />
                                            </div>
                                        </> : <p className="text-lg md:text-xl text-center font-semibold text-stone-500">No data found yet!</p>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
