import { useRef, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthUtils from "../../Pages/Utils/AuthUtils";
import { toast } from "react-toastify";

const AddCampaignModal = ({ showModal, setShowModal, setFormError }) => {
    const {http} = AuthUtils();
    const navigate = useNavigate();
    const campaignNameRef = useRef();
    const [loading, setIsLoading] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    }

    const showFormError = ({ errId }) => {
        setFormError({
            errorId: errId,
        });

        if (errId === 1) {
            campaignNameRef.current.classList.add("error__field");
            campaignNameRef.current?.focus();
        }

        setIsLoading(false);
    }

    const addCampaign = async (e) => {
        e.preventDefault();
        showFormError({
            errId: -1,
        });
        setIsLoading(true);

        const campaignName = campaignNameRef.current.value.trim();

        if (campaignName === "") {
            showFormError({
                errId: 1,
            });
            return;
        }

        const formData = {
            "campaign_title": campaignName
        }

        try {
            const res = await http.post("/api/create-campaign/", formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const {data} = res.data;

                setIsLoading(false);
                navigate(`/create-campaign/${data.id}/`);
            }
        } catch(err) {
            setIsLoading(false);
            toast.error("Failed to create new campaign!");
        }
    }

    const handleFieldChange = async (e) => {
        showFormError({
            errId: -1
        });

        if (e.target.name === "campaign__name") {
            if (e.target.value !== "") {
                campaignNameRef.current.classList.remove("error__field");
            } else {
                campaignNameRef.current.classList.add("error__field");
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={addCampaign}>
                    <Form.Group className="card-post mb-3">
                        <Form.Label>Campaign Name</Form.Label>
                        <Form.Control autoFocus={true} onChange={e => handleFieldChange(e)} name="campaign__name" ref={campaignNameRef} type="text" placeholder="Enter campaign name" />
                    </Form.Group>
                    <button type="submit" className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear" disabled={loading}>
                        {
                            loading ? <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </> : "Create"
                        }
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCampaignModal
