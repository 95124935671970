import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
// import Dashboardnav from "../../Components/Dashboard/dashboardnav";
// import Sidebar from "../../Components/Dashboard/sidebar";
import AuthUtils from "../Utils/AuthUtils";
import JsonFormatter from "react-json-formatter";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { FiArrowRight } from "react-icons/fi";
import { BiSave } from "react-icons/bi";
import { LOGOUT_TIMEOUT } from "../Utils/baseConfig";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import Sidebar from "../../layouts/Sidebar";
import { Card, Form, Spinner } from "react-bootstrap";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import moment from "moment";
import Select from "react-select";
// import CreatableSelect from 'react-select/creatable';

export default function TestBuyers() {
  const { http, logout, user } = AuthUtils();
  const { campaign_id } = useParams();
  const [campaign, setCampaign] = useState("");
  const [buyerFields, setBuyerFields] = useState([]);
  const [name, setName] = useState("");
  const [payload, setPayload] = useState(null);
  const [data, setData] = useState("");
  const [response, setResponse] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [allFields, setAllFields] = useState([]);

  const [loading, setLoading] = useState(false);

  // test code

  const testPayloadFormRef = useRef();

  const [dataObject, setDataObject] = useState(null); // State to hold parsed JSON
  const [buyerBody, setBuyerBody] = useState("");

  const initialFormData = {};
  buyerFields?.map((fieldName) => {
    initialFormData[fieldName.field_name] = "";
  });

  const fetchCampaignDetails = async () => {
    try {
      const response = await http.get(`/api/campaign-details/${campaign_id}/`, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      // console.log('Response:', response.data.data); // Log the entire response to check its structure

      let fieldArr = [];

      if (response.status === 200) {
        const { data: campaignData } = response.data;

        setCampaign(campaignData);

        let supplierArr = [];

        campaignData?.suppliers?.map((sup) => {
          if (sup.client) {
            supplierArr.push({
              value: sup.id,
              label:
                sup.client?.company_name[0]?.toUpperCase() +
                sup.client?.company_name?.slice(1)
            });
          }
        });

        campaignData?.fields?.map((f) => {
          fieldArr.push(f);
        });

        let fieldWithValArr = [];

        fieldArr.map((f) => {
          let fvArr = [];
          f.field_values?.map((fv) => {
            fvArr.push({
              ...fv,
              value: fv.value_title,
              label: fv.value_title
            });
          });

          f.field_values = fvArr;
          fieldWithValArr.push(f);
        });

        setAllFields(fieldWithValArr);
        setSuppliers(supplierArr);

        // if (delivery_method === "STORE LEADS") {
        //     const { fields } = campaign;
        //     const jsonData = {
        //         campaign_id: campaign.id,
        //     };

        //     fields.map(field => {
        //         jsonData[field.field_name] = `{{${field.field_name}}}`;
        //     })

        //     let parsedJsonDict = {};

        //     Object.entries(jsonData).map(([key, value]) => {
        //         parsedJsonDict[key] = value;

        //         let fieldValueArr = [], isListType = false;

        //         fieldArr?.map(f => {
        //             if (key === f.field_name && f.field_type === "list") {
        //                 isListType = true;

        //                 f.field_values?.map(fv => {
        //                     fieldValueArr.push({
        //                         ...fv,
        //                         value: fv.value_title,
        //                         label: fv.value_title
        //                     })
        //                 })
        //             }
        //         })

        //         if (isListType) {
        //             parsedJsonDict[key] = fieldValueArr;
        //         }
        //     })

        //     setDataObject(parsedJsonDict);
        //     setPayload(parsedJsonDict?.payload);
        // } else {
        //     const validJSON = response.data.data.body.replace(/'/g, '"');
        //     try {
        //         const parsedJSON = JSON.parse(validJSON);

        //         let parsedJsonDict = {};

        //         Object.entries(parsedJSON).map(([key, value]) => {
        //             parsedJsonDict[key] = value;

        //             let fieldValueArr = [], isListType = false;

        //             fieldArr?.map(f => {
        //                 if (key === f.field_name && f.field_type === "list") {
        //                     isListType = true;

        //                     f.field_values?.map(fv => {
        //                         fieldValueArr.push({
        //                             ...fv,
        //                             value: fv.value_title,
        //                             label: fv.value_title
        //                         })
        //                     })
        //                 }
        //             })

        //             if (isListType) {
        //                 parsedJsonDict[key] = fieldValueArr;
        //             }
        //         })

        //         setDataObject(parsedJsonDict);
        //         setPayload(parsedJsonDict?.payload);
        //         // Update buyerBody here if needed:
        //         setBuyerBody(validJSON);
        //     } catch (error) {
        //         // console.error('Error parsing JSON:', error);
        //     }
        // }
      } else {
        // console.error('Invalid response format');
      }
    } catch (error) {
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data && data.code === "token_not_valid") {
          logout();
        }
      }
      // console.error('Error fetching buyer details:', error);
    }
  };

  useEffect(() => {
    if (campaign_id) {
      fetchCampaignDetails();
    }
  }, [campaign_id]);

  const sendTestLead = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (!supplier) {
      toast.error("Please select a supplier!");
      setLoading(false);
      return;
    }

    const payloadFormDict = {};

    allFields?.map((f) => {
      const el = testPayloadFormRef.current.querySelector(
        `[name='${f.field_name}']`
      );
      if (el) {
        payloadFormDict[f.field_name] = el.value;
      }
    });

    payloadFormDict["opt_in_timestamp"] = new Date().toISOString();

    const data = {
      campaign_id: campaign_id,
      supplier_id: supplier.value,
      is_test: true,
      payload: payloadFormDict
    };

    await http
      .post(`/api/test-buyers/`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        if (resp.status === 200) {
          setResponse(resp.data);
          setShowResponse(true);
        }

        setLoading(false);
        // console.log(response)
      })
      .catch((error) => {
        // console.log(error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.code === "token_not_valid") {
            logout();
          }
        }

        setLoading(false);
        setResponse(
          "An error occured while making the request please check the endpoint url and try again!"
        );
        setShowResponse(true);
      });
  };

  // const handleInputChange = (e, key) => {
  //     const { value } = e.target;
  //     setPayload((prevFormData) => ({
  //         ...prevFormData,
  //         [key]: value
  //     }));
  // };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <>
      <Header onSkin={setSkin} user={user} />
      <HeaderMobile onSkin={setSkin} user={user} />
      <Sidebar />
      <ToastContainer />

      <div className="main main-app p-4 p-lg-5">
        <div className="flex flex-col gap-y-5">
          <Card className="flex-1">
            <Card.Header
              className={`flex flex-col sm:flex-row justify-between sm:items-center gap-x-3 gap-y-3 px-3 py-4 rounded-md ${
                !skin && "bg-primary"
              }`}
            >
              <div className="flex items-center justify-center sm:justify-start flex-wrap gap-x-1.5 text-white">
                <Link
                  to="/campaigns"
                  className="px-2 py-2 text-white font-semibold hover:underline"
                >
                  Campaigns
                </Link>
                <FaLongArrowAltRight />
                {campaign && (
                  <Link
                    to={`/create-campaign/${campaign.id}/`}
                    className="px-2 py-2 text-white font-semibold hover:underline"
                  >
                    {campaign?.campaign_title}
                  </Link>
                )}
                <FaLongArrowAltRight />
                <div className="px-2 py-2 font-semibold bg-white rounded-md text-stone-600">
                  Test Endpoints
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <div className="flex flex-col gap-y-5">
                <div
                  className={`flex w-full sm:w-auto flex-1 flex-col gap-y-1.5 my-react-select-container ${
                    skin ? "dark__mode" : ""
                  }`}
                >
                  <Form.Label
                    className={`font-medium ${skin && "text-stone-400"}`}
                  >
                    Select Supplier
                  </Form.Label>
                  <Select
                    options={suppliers}
                    value={supplier}
                    onChange={setSupplier}
                    placeholder="Select supplier"
                    classNamePrefix={"my-react-select"}
                    className="w-full md:w-auto min-w-[150px]"
                    isSearchable
                    isClearable
                    noOptionsMessage={() => "No match found."}
                  />
                </div>
                <div
                  className={`text-xl font-medium ${skin && "text-stone-400"}`}
                >
                  Test Buyers
                </div>
                {
                  <Form
                    onSubmit={sendTestLead}
                    ref={testPayloadFormRef}
                    className="card-post"
                  >
                    {allFields?.map(
                      (field, index) =>
                        field.field_name !== "opt_in_timestamp" && (
                          <Form.Group
                            key={index}
                            className={`mb-3 my-react-select-container ${
                              skin && "dark__mode"
                            }`}
                          >
                            <Form.Label className="font-medium">
                              {field.field_name}
                            </Form.Label>
                            {field.field_type === "list" ? (
                              <Select
                                isClearable
                                name={field.field_name}
                                options={field.field_values}
                                placeholder="Enter allowed values"
                                classNamePrefix={"my-react-select"}
                              />
                            ) : (
                              <Form.Control
                                name={field.field_name}
                                type="text"
                                placeholder={`Enter ${field.field_name}`}
                                defaultValue={
                                  field.field_name === "api_key"
                                    ? campaign?.campaign_api_key
                                    : ""
                                }
                                disabled={
                                  field.field_name === "api_key" ? true : false
                                }
                              />
                            )}
                          </Form.Group>
                        )
                    )}
                    {/* {
                                            Object.entries(dataObject).map(([key, value], index) => (
                                                <Form.Group key={index} className={`mb-3 my-react-select-container ${skin && "dark__mode"}`}>
                                                    <Form.Label className="font-medium">{key}</Form.Label>
                                                    {
                                                        typeof (value) === "object" ?
                                                            <Select isClearable name={key} options={value} placeholder="Enter allowed values" classNamePrefix={"my-react-select"} /> :
                                                            <Form.Control name={key} type="text" placeholder={`Enter ${key}`}
                                                                defaultValue={value.toString().startsWith("{{") && value.toString().endsWith("}}") ? "" : value}
                                                            />
                                                    }
                                                </Form.Group>
                                            ))
                                        } */}
                    <button
                      type="submit"
                      className="btn-sign font-medium flex justify-center w-full px-3 py-2.5 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 transition-colors duration-200 ease-linear"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Loading...
                        </>
                      ) : (
                        <span className="flex items-center gap-x-1">
                          Send
                          <IoRocketOutline className="text-lg" />
                        </span>
                      )}
                    </button>
                  </Form>
                }

                {showResponse && (
                  <Card>
                    <Card.Header>
                      <div
                        className={`text-xl font-medium ${
                          skin && "text-stone-400"
                        }`}
                      >
                        Response
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {response?.status && (
                        <p
                          className={`${
                            response?.status === "ACCEPTED"
                              ? "text-success"
                              : response?.status === "DUPLICATED"
                              ? "text-warning"
                              : "text-danger"
                          } font-semibold text-lg`}
                        >
                          {response?.status}
                        </p>
                      )}
                      <pre
                        className={`text-sm ${
                          skin ? "bg-slate-800 text-stone-200" : "bg-stone-100"
                        } p-2 border border-blue-300 rounded`}
                      >
                        Response: <JsonFormatter json={response} tabWith={4} />
                      </pre>
                      {/* {
                                                data.response_log &&
                                                <pre className={`${skin ? "bg-slate-800 text-stone-200" : "bg-stone-100"} border border-blue-300 p-2 rounded my-3`}>
                                                    log: {JSON.stringify(JSON.parse(data?.response_log), null, 2)}</pre>
                                            } */}

                      {data.lead_id && (
                        <div className="flex flex-col gap-y-2 w-full text-md border-t border-stone-200 mt-3 pt-3">
                          <Form.Group className="flex flex-wrap gap-x-1">
                            <Form.Label className="font-medium">
                              Created at:{" "}
                            </Form.Label>
                            <p className="mb-0">
                              {moment(data.created_at).format("LL")}
                            </p>
                          </Form.Group>
                          <Form.Group className="flex flex-wrap gap-x-1">
                            <Form.Label className="font-medium">
                              Updated at:{" "}
                            </Form.Label>
                            <p className="mb-0">
                              {moment(data.updated_at).format("LL")}
                            </p>
                          </Form.Group>
                          <Form.Group className="flex flex-wrap gap-x-1">
                            <Form.Label className="font-medium">
                              Lead ID:{" "}
                            </Form.Label>
                            <p className="mb-0">{data.lead_id}</p>
                          </Form.Group>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
