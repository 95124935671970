import { useEffect, useRef, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import { IoClose } from "react-icons/io5";
import AuthUtils from '../../Pages/Utils/AuthUtils';


const LeadFilter = ({ filterData, setFilterData, filterHeading, setFilterHeading, payloadResponse, skin, responseLog, clearFilterData }) => {
    const { http, logout } = AuthUtils();
    const [multiValues, setMultiValues] = useState([]);
    const [filterGivenValue, setFilterGivenValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const filterTableRef = useRef();

    const getBuyerDetails = async (buyerId) => {
        setIsLoading(true);

        try {
            const res = await http.get(`/api/fetch-buyer/${buyerId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { client } = res.data.data;
                return client?.company_name;
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
        }
    }

    const getSupplierDetails = async (supplierId) => {
        setIsLoading(true);

        try {
            const res = await http.get(`/api/supplier-details/${supplierId}/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                const { client } = res.data.data;
                return client?.company_name;
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // console.log(err);
        }
    }


    const checkFilterData = async () => {
        let mvArr = [];

        if (Array.isArray(filterData.as)) {
            let givenValDict = {}, mulValDict = {};

            filterData.as?.map(async (fas, index) => {
                const key = fas.key;

                mulValDict[index] = [];

                if (key === "buyer") {
                    await Promise.all(fas?.multi_values?.map(async (mv) => {
                        const buyerName = await getBuyerDetails(mv?.value_title);
                        mulValDict[index].push({
                            value_title: buyerName
                        });
                    }))
                    setIsLoading(false);
                } else if (key === "supplier") {
                    await Promise.all(fas?.multi_values?.map(async (mv) => {
                        const supplierName = await getSupplierDetails(mv?.value_title);
                        mulValDict[index].push({
                            value_title: supplierName
                        });
                    }))
                    setIsLoading(false);
                } else {
                    fas?.multi_values?.map((mv) => {
                        mulValDict[index].push({
                            value_title: mv.value_title
                        });
                    });
                }

                if (payloadResponse) {
                    let givenVal = "";

                    Object.entries(payloadResponse).map(([k, v]) => {
                        if (k === key) {
                            givenVal = v;
                        }
                    })

                    if (givenVal) {
                        givenValDict[index] = givenVal;
                    } else {
                        givenValDict[index] = filterHeading?.sold_to;
                    }
                    setFilterGivenValue(givenValDict);
                }
            })

            mvArr.push(mulValDict);
            setIsLoading(false);
            setMultiValues(mulValDict);
        } else {
            const key = filterData.as.key;

            if (key === "buyer") {
                await Promise.all(filterData.as?.multi_values?.map(async (mv) => {
                    const buyerName = await getBuyerDetails(mv.value_title);
                    mvArr.push({ ...mv, value_title: buyerName });
                }))
                setIsLoading(false);
            } else if (key === "supplier") {
                await Promise.all(filterData.as?.multi_values?.map(async (mv) => {
                    const supplierName = await getSupplierDetails(mv.value_title);
                    mvArr.push({ ...mv, value_title: supplierName });
                }))
                setIsLoading(false);
            } else {
                mvArr = [...filterData.as?.multi_values];
            }

            if (payloadResponse) {
                let givenVal = "";

                Object.entries(payloadResponse).map(([k, v]) => {
                    if (k === key) {
                        givenVal = v;
                    }
                })

                if (givenVal) {
                    setFilterGivenValue(givenVal);
                } else {
                    setFilterGivenValue(filterHeading?.sold_to);
                }
            }

            setIsLoading(false);
            setMultiValues(mvArr);
        }
    }

    useEffect(() => {
        if (filterData) {
            setMultiValues([]);

            if (Array.isArray(filterData?.as)) {
                if (filterData?.as[0]?.key != null) {
                    checkFilterData();
                }
            } else if (filterData?.as?.key != null) {
                checkFilterData();
            }

            filterTableRef.current?.focus();
        }
    }, [filterData])

    return (
        <div className={`${filterHeading.index !== responseLog.length - 1 ? "ml-[0.94rem] border-l-2 border-stone-300" : "ml-4"} pb-3`}>
            <Card className="ml-[1.7rem]">
                <Card.Header>
                    <div className={`flex flex-wrap items-center justify-between gap-x-3 gap-y-2 ${skin ? "text-stone-400" : ""}`}>
                        <Card.Title className='mb-0'>{filterHeading.title}</Card.Title>
                        <IoClose onClick={clearFilterData} className={`cursor-pointer text-xl ${skin ? "text-stone-400" : "text-stone-500"} `} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table
                        tabIndex={-1}
                        ref={filterTableRef}
                        variant={skin && "dark"}
                        hover
                        className="mb-0"
                        responsive
                    >
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                >
                                    Key
                                </th>
                                <th
                                    scope="col"
                                    className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                >
                                    Condition
                                </th>
                                <th
                                    scope="col"
                                    className="text-lg py-0.5 px-3 font-semibold whitespace-nowrap"
                                >
                                    Expected Value(s)
                                </th>
                                <th
                                    scope="col"
                                    className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                >
                                    Given Value
                                </th>
                                <th
                                    scope="col"
                                    className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                >
                                    Result
                                </th>
                                <th
                                    scope="col"
                                    className="text-lg px-3 font-semibold whitespace-nowrap"
                                >
                                    Payout
                                </th>
                                {/* <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">State</th> */}
                                <th
                                    scope="col"
                                    className="py-0.5 text-lg px-3 font-semibold whitespace-nowrap"
                                >
                                    Passed
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(filterData.as) ?
                                    filterData.as?.map((fas, index) => (
                                        <tr>
                                            <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                                {fas?.key ? fas.key : "N/A"}
                                            </td>
                                            <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                                {fas?.conditions ? fas.conditions : "N/A"}
                                            </td>
                                            <td className={`px-3 min-w-[7rem] align-middle ${skin ? "text-stone-300" : "text-inherit"}`}>
                                                {
                                                    fas?.value ? fas.value :
                                                        <div className='flex min-w-[15rem] flex-col gap-y-2'>
                                                            {
                                                                isLoading ?
                                                                    <Spinner size="sm" animation="border" variant="primary" /> :
                                                                    multiValues?.[index]?.length > 0 ?
                                                                        multiValues?.[index]?.map((mv, mvi) => (
                                                                            <div key={mvi} className="flex items-center gap-x-1.5">
                                                                                <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] rounded-full bg-stone-600"></div>
                                                                                <p className="my-0">
                                                                                    {mv?.value_title}
                                                                                </p>
                                                                            </div>
                                                                        )) : "N/A"
                                                            }
                                                        </div>
                                                }
                                            </td>
                                            <td className={`px-3 align-middle min-w-[12rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                                {
                                                    isLoading ?
                                                        <Spinner size="sm" animation="border" variant="primary" /> :
                                                        filterGivenValue ? filterGivenValue[index] : "N/A"
                                                }
                                            </td>
                                            <td className="px-3 align-middle">
                                                {fas?.result ?
                                                    <p className={`p-1.5 text-center mb-0 rounded-md whitespace-nowrap text-white ${fas.result === "ACCEPT LEADS" ? "bg-success" : "bg-danger"}`}>
                                                        {fas.result}
                                                    </p>
                                                    : "N/A"}
                                            </td>
                                            <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                                {fas?.payout_by_filter && fas.payout_by_filter.payout ? `$${fas.payout_by_filter.payout}` : "N/A"}
                                            </td>
                                            <td className={`px-3 align-middle min-w-[7rem] font-semibold ${filterData?.passed ? "text-success" : "text-danger"}`}>
                                                {filterData?.passed ? "Yes" : "No"}
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                            {filterData.as?.key ? filterData.as.key : "N/A"}
                                        </td>
                                        <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                            {filterData.as?.conditions ? filterData.as.conditions : "N/A"}
                                        </td>
                                        <td className={`px-3 min-w-[7rem] align-middle ${skin ? "text-stone-300" : "text-inherit"}`}>
                                            {
                                                filterData.as?.value ? filterData.as.value :
                                                    <div className='flex min-w-[15rem] flex-col gap-y-2'>
                                                        {
                                                            isLoading ?
                                                                <Spinner size="sm" animation="border" variant="primary" /> :
                                                                multiValues.length > 0 ?
                                                                    multiValues?.map((mv, mvi) => (
                                                                        <div key={mvi} className="flex items-center gap-x-1.5">
                                                                            <div className="w-[7px] min-w-[7px] max-w-[7px] h-[7px] min-h-[7px] max-h-[7px] rounded-full bg-stone-600"></div>
                                                                            <p className="my-0">
                                                                                {mv.value_title}
                                                                            </p>
                                                                        </div>
                                                                    )) : "N/A"
                                                        }
                                                    </div>
                                            }
                                        </td>
                                        <td className={`px-3 align-middle min-w-[12rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                            {
                                                isLoading ?
                                                    <Spinner size="sm" animation="border" variant="primary" /> :
                                                    filterGivenValue ? filterGivenValue : "N/A"
                                            }
                                        </td>
                                        <td className="px-3 align-middle">
                                            {filterData.as?.result ?
                                                <p className={`p-1.5 text-center mb-0 rounded-md whitespace-nowrap text-white ${filterData.as.result === "ACCEPT LEADS" ? "bg-success" : "bg-danger"}`}>
                                                    {filterData.as.result}
                                                </p>
                                                : "N/A"}
                                        </td>
                                        <td className={`px-3 align-middle min-w-[7rem] ${skin ? "text-stone-300" : "text-inherit"}`}>
                                            {filterData.as?.payout_by_filter && filterData.as.payout_by_filter.payout ? `$${filterData.as.payout_by_filter.payout}` : "N/A"}
                                        </td>
                                        <td className={`px-3 align-middle min-w-[7rem] font-semibold ${filterData?.passed ? "text-success" : "text-danger"}`}>
                                            {filterData?.passed ? "Yes" : "No"}
                                        </td>
                                    </tr>
                            }

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default LeadFilter