import { React, useEffect, useState } from 'react'
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import AuthUtils from '../../Pages/Utils/AuthUtils';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';

const TopCampaign = ({ skin }) => {
    const { http, logout } = AuthUtils();
    const [loading, setLoading] = useState(true);
    const [topCampaigns, setTopCampaigns] = useState([]);
    const [authData, setAuthData] = useAuth();
    const [showTopCampaigns, setShowTopCampaigns] = useState(false);

    useEffect(() => {
        if (authData) {
            const { rolenames } = authData;

            const showTopCampaign = rolenames.includes("SUPER ADMIN") || rolenames.includes("DEVELOPER") || rolenames.includes("ADMIN");

            if (showTopCampaign) {
                fetchTopCampaigns();
            }
            setShowTopCampaigns(showTopCampaign);
        }
    }, [authData])

    const fetchTopCampaigns = async () => {
        setLoading(true);

        try {
            const res = await http.get("/api/top-campaigns", {
                headers: {
                    "Content-Type": "application/json",
                }
            })

            if (res.status === 200) {
                setTopCampaigns(res.data.data.slice(0, 5));
                setLoading(false);
            }
        } catch (err) {
            const { response } = err;

            if (response) {
                const { data } = response;
                if (data) {
                    if (data.message === "Unauthenticated.") {
                        logout();
                    }
                }
            }
            // toast.error("Failed to fetch top campaigns data!");
            setLoading(false);
        }
    }

    return (
        showTopCampaigns &&
        <Card>
            <Row>
                <Col xl="12">
                    <Card className="card-one">
                        <Card.Header>
                            <Card.Title as="h6">Top 5 Performing Campaigns</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {
                                loading ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                    <Table variant={skin && "dark"} hover className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Name</th>
                                                <th scope="col" className="text-lg px-3 font-semibold whitespace-nowrap">Total Accepted</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                topCampaigns.map(campaign => (
                                                    <tr key={campaign.id}>
                                                        <td className='px-3 align-middle title__td'>
                                                            <Link to={`${`/create-campaign/${campaign.id}/`}`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                {
                                                                    campaign?.campaign_title
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td className='px-3 align-middle'>
                                                            <Link to={`${`/create-campaign/${campaign.id}/`}`} className={`${skin ? 'text-stone-300' : 'text-inherit'}`}>
                                                                {
                                                                    campaign.total_accepted ? campaign.total_accepted : 0
                                                                }
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Card>
    )
}

export default TopCampaign