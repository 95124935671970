import { useContext, useEffect, useRef, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { useAuth } from "../../Context/AuthContext";

const AddUserModal = ({
  http,
  logout,
  showModal,
  setShowModal,
  formError,
  setFormError,
  getClients,
  skin,
}) => {
  const [authData, setAuthData] = useAuth();
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState([]);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneNoRef = useRef();
  const companyNameRef = useRef();
  const passwordRef = useRef();
  const confPasswordRef = useRef();
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [permissionCampaigns, setPermissionCampaigns] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isPermissionCampaignFetching, setIsPermissionCampaignFetching] =
    useState(false);

  const handleClose = () => {
    setRoles([]);
    setRole([]);
    setAllCampaigns([]);
    setPermissionCampaigns([]);
    setShowModal(false);
  };

  const fetchAllRoles = async () => {
    const { rolenames } = authData;

    try {
      const res = await http.get("/api/roles/", {
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (res.status === 200) {
        const { data } = res.data;
        let rolesArr = [];
        data?.map((r) => {
          rolesArr.push({
            value: r.id,
            label: r.name,
          });
        });

        if (!rolenames.includes("SUPER ADMIN") && rolesArr.length) {
          rolesArr.map((r, index) => {
            if (r.label === "SUPER ADMIN") {
              rolesArr.splice(index, 1);
            }
          });
        }
        setRoles(rolesArr);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      toast.error("Failed to load roles' data!");
    }
  };

  const fetchCampaigns = async () => {
    setIsPermissionCampaignFetching(true);

    try {
      const res = await http.get("/api/all-campaigns/", {
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (res.status === 200) {
        let campArr = [];

        res.data.data?.map((camp) => {
          campArr.push({
            ...camp,
            value: camp.id,
            label: camp.campaign_title,
          });
        });

        setAllCampaigns(campArr);
        setIsPermissionCampaignFetching(false);
      }
    } catch (err) {
      const { response } = err;

      if (response) {
        const { data } = response;
        if (data) {
          if (data.message === "Unauthenticated.") {
            logout();
          }
        }
      }
      // console.log(err);
      setAllCampaigns([]);
      setIsPermissionCampaignFetching(false);
    }
  };

  useEffect(() => {
    if (showModal && authData) {
      fetchAllRoles();
      fetchCampaigns();
    }
  }, [showModal, authData]);

  const showFormError = ({ errId }) => {
    setFormError({
      errorId: errId,
    });

    if (errId === 1) {
      firstNameRef.current.classList.add("error__field");
      firstNameRef.current?.focus();
    } else if (errId === 2) {
      lastNameRef.current.classList.add("error__field");
      lastNameRef.current?.focus();
    } else if (errId === 3) {
      emailRef.current.classList.add("error__field");
      emailRef.current?.focus();
    } else if (errId === 4) {
      phoneNoRef.current.classList.add("error__field");
      phoneNoRef.current?.focus();
    } else if (errId === 5) {
      companyNameRef.current.classList.add("error__field");
      companyNameRef.current?.focus();
    } else if (errId === 7) {
      passwordRef.current.classList.add("error__field");
      passwordRef.current?.focus();
    } else if (errId === 8) {
      confPasswordRef.current.classList.add("error__field");
      confPasswordRef.current?.focus();
    } else if (errId === 9) {
      passwordRef.current.classList.add("error__field");
      passwordRef.current?.focus();
      confPasswordRef.current.classList.add("error__field");
      confPasswordRef.current?.focus();
    }

    setIsLoading(false);
  };

  const addClient = async (e) => {
    e.preventDefault();
    showFormError({
      errId: -1,
    });
    setIsLoading(true);

    const firstName = firstNameRef.current.value.trim();
    const lastName = lastNameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const phoneNo = phoneNoRef.current.value.trim();
    const password = passwordRef.current.value.trim();
    const confirmPassword = confPasswordRef.current.value.trim();
    const companyName = companyNameRef.current.value.trim();

    if (firstName === "") {
      showFormError({
        errId: 1,
      });
      return;
    }
    if (lastName === "") {
      showFormError({
        errId: 2,
      });
      return;
    }
    if (email === "") {
      showFormError({
        errId: 3,
      });
      return;
    }
    if (password === "") {
      showFormError({
        errId: 7,
      });
      return;
    }
    if (confirmPassword === "") {
      showFormError({
        errId: 8,
      });
      return;
    }
    if (password !== confirmPassword) {
      showFormError({
        errId: 9,
      });
      toast.error("Two password fields did not match!");
      return;
    }
    if (companyName === "") {
      showFormError({
        errId: 5,
      });
      return;
    }

    // For multi role
    // if (!role || role.length === 0) {
    //   toast.error("Please select a role!");
    //   setIsLoading(false);
    //   return;
    // }

    // For single role
    if (!role) {
        toast.error("Please select a role!");
        setIsLoading(false);
        return;
    }

    let rolesArr = [], campaignArr = [];
    // For single role
    rolesArr.push(role.label);

    // For multi role
    // role?.map(r => {
    //   rolesArr.push(r.label);
    // })

    permissionCampaigns?.map(camp => {
      campaignArr.push(camp.value);
    })

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNo,
      company_name: companyName,
      password: password,
      created_by: authData?.id,
      roles: rolesArr,
      campaigns: campaignArr
    }

    await http
      .post("/api/add-client/", formData, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        toast.success("User is created successfully.");
        getClients(1);
        handleClose();
        setIsLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;
        if (data) {
          const { messages } = data;

          if (messages === "Unauthenticated.") {
            logout();
          }
          if (messages) {
            const { email } = messages;

            if (email) {
              toast.error(email[0]);
              setIsLoading(false);
              return;
            }
          }
        }

        toast.error("Failed to create client!");
        setIsLoading(false);
      });
  };

  const handleFieldChange = async (e) => {
    showFormError({
      errId: -1,
    });

    if (e.target.name === "first__name") {
      if (e.target.value !== "") {
        firstNameRef.current.classList.remove("error__field");
      } else {
        firstNameRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "last__name") {
      if (e.target.value !== "") {
        lastNameRef.current.classList.remove("error__field");
      } else {
        lastNameRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "email") {
      if (e.target.value !== "") {
        emailRef.current.classList.remove("error__field");
      } else {
        emailRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "phone__no") {
      if (e.target.value !== "") {
        phoneNoRef.current.classList.remove("error__field");
      } else {
        phoneNoRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "company__name") {
      if (e.target.value !== "") {
        companyNameRef.current.classList.remove("error__field");
      } else {
        companyNameRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "password") {
      if (e.target.value !== "") {
        passwordRef.current.classList.remove("error__field");
      } else {
        passwordRef.current.classList.add("error__field");
      }
    } else if (e.target.name === "confirm__password") {
      if (e.target.value !== "") {
        confPasswordRef.current.classList.remove("error__field");
      } else {
        confPasswordRef.current.classList.add("error__field");
      }
    }
  };

  const handlePermissionCampaign = (e) => {
    if (!e) {
      setPermissionCampaigns([]);
    } else {
      setPermissionCampaigns(e);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={addClient}>
          <Form.Group className="card-post mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              autoFocus={true}
              onChange={(e) => handleFieldChange(e)}
              name="first__name"
              ref={firstNameRef}
              type="text"
              placeholder="Enter first name"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="last__name"
              ref={lastNameRef}
              type="text"
              placeholder="Enter last name"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="email"
              ref={emailRef}
              type="email"
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="password"
              ref={passwordRef}
              type="password"
              placeholder="Enter password"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="confirm__password"
              ref={confPasswordRef}
              type="password"
              placeholder="Confirm password"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Phone No</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="phone__no"
              ref={phoneNoRef}
              type="text"
              placeholder="Enter phone number"
            />
          </Form.Group>
          <Form.Group className="card-post mb-3">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              onChange={(e) => handleFieldChange(e)}
              name="company__name"
              ref={companyNameRef}
              type="text"
              placeholder="Enter company name"
            />
          </Form.Group>
          <Form.Group
            className={`card-post mb-3 my-react-select-container ${skin ? "dark__mode" : ""
              }`}
          >
            <Form.Label>Role</Form.Label>
            <Select
              options={roles}
              value={role}
              onChange={setRole}
              // isMulti
              placeholder="Select role"
              classNamePrefix={"my-react-select"}
              className="w-full whitespace-nowrap"
              isSearchable
              noOptionsMessage={() => "No match found."}
            />
          </Form.Group>
          <Form.Group
            className={`card-post mb-3 my-react-select-container ${skin ? "dark__mode" : ""
              }`}
          >
            <Form.Label>Campaign</Form.Label>
            <Select
              options={allCampaigns}
              value={permissionCampaigns}
              onChange={handlePermissionCampaign}
              isMulti
              placeholder="Select campaign(s)"
              classNamePrefix={"my-react-select"}
              className="w-full whitespace-nowrap"
              isSearchable
              noOptionsMessage={() => "No match found."}
            />
          </Form.Group>
          <button
            type="submit"
            className="btn-sign font-medium px-5 py-2.5 text-white rounded-md bg-indigo-500 hover:bg-indigo-600 transition-colors duration-200 ease-linear"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            ) : (
              "Create"
            )}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
